import * as actionTypes from "./actionTypes";
import { LOCAL_STORAGE_KEYS } from "../common/constants";
import { Dispatch } from "../common/types/reduxTypes";
import { UserProfile } from "../shared/src/models/entities/user/UserProfile";
import { logLoginPerfomance } from "../shared/src/services/diagnostic/logger";
import * as localStorage from "../shared/src/modules/localStorage";

export const logLoginTimings = (userProfile: UserProfile) => {
    return async (dispatch: Dispatch) => {
        const timings = localStorage.loadItem(LOCAL_STORAGE_KEYS.LOGIN_TIMINGS);
        if (timings) {
            timings.UserId = userProfile.id;
            timings.EndedLogin = Date.now();
            timings.TotalElapsedTime = timings.EndedLogin - (timings.StartedSSO || timings.StartedLogin);
            if (timings.StartedSSO && timings.EndedSSO) {
                timings.ElapsedTimeSSO = timings.EndedSSO - timings.StartedSSO;
            }
            logLoginPerfomance(timings);
            localStorage.deleteItem(LOCAL_STORAGE_KEYS.LOGIN_TIMINGS);
            dispatch({ type: actionTypes.LOG_LOGGING_TIMINGS_SUCCESS });
        }
    };
};
