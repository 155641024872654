import { Button, Dialog, Text, Flex } from "@fluentui/react-northstar";
import { useState } from "react";
import lclzStor from "../../shared/src/languages/BaseLang";
import Auth from "./login/Auth";
import { LogoPanel } from '../elements/LogoPanel';
import { useTeams } from '../../hooks/useTeams';
import { useSelector } from 'react-redux';
import IStore from '../../common/interfaces/IStore';

const Welcome = () => {
  const [open, setOpen] = useState<boolean>(false);
    const {  fbAction } = useSelector((state: IStore) => state.user);
    const onSignInClick = () => setOpen(true);
    const { theme } = useTeams({})[0];
    const white = theme.siteVariables.colorScheme.white.background1;
    const bkg = fbAction ? {backgroundColor : white} : null;
  return (
    <div className="welcome page" style={bkg}>
      <Flex className="narrow page-padding" vAlign="center" hAlign="center" column={true}>
        <div className="spacing-bottom-xlg">
          <LogoPanel />
          <Text size="large" weight="regular" className="center"
              content={lclzStor.formatString(lclzStor.LoginPage_BeqomMotto, {
                                                happy: <Text error size="large" weight="bold" className="center spacing-bottom-sm" content={lclzStor.LoginPage_BeqomMotto_Happy} />
                                              })} />
        </div>
        <Text size="large" weight="bold" className="center" content={lclzStor.Teams_WelcomePage_Label} />
        <Text size="medium" className="center spacing-bottom-xlg" content={lclzStor.Teams_WelcomePage_Description} />
        <Button className="signIn-btn" onClick={onSignInClick} content={lclzStor.Teams_WelcomePage_Button_SignIn} primary={true} />
      </Flex>
      <Dialog
        backdrop={true}
        closeOnOutsideClick={false}
        content={<Auth />}
        open={open}
        onOpen={() => setOpen(true)}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default Welcome;
