class GlobalEventDistributor {

    private stores = [];

    registerStore(store) {
        this.stores.push(store);
    }

    dispatch(event) {
        if (event?.type) {
            this.stores.forEach(s => s.dispatch(event));
        }
    }
}

const globalEventDistributor = new GlobalEventDistributor();
export default globalEventDistributor;
