import React from 'react';
import { useSelector } from "react-redux";
import IStore from "../common/interfaces/IStore";
import Tile from './elements/Tile';
import { FA_ARROW_UP_RIGHT } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import { getUrlToTandem } from '../utils/common';
import * as localStorage from '../shared/src/modules/localStorage';
import { LOCAL_STORAGE_KEYS } from '../common/constants';
import GiveFeedbackWidget from './GiveFeedbackWidget';
import FeedbackNoteWidget from './FeedbackNoteWidget';
import RequestFeedbackWidget from './RequestFeedbackWidget';

const HomeWidgets = () => {
    const { settings } = useSelector((state: IStore) => state.settings);
    const isFeedbackEnabled = settings?.FeedbackSettings?.EnableFeedbacks;
    const isFeedbackNoteEnabled = isFeedbackEnabled && settings?.FeedbackSettings?.EnableFeedbackNote;
    const urlToTandem = getUrlToTandem(localStorage.loadItem(LOCAL_STORAGE_KEYS.COMPANY_DATA).FrontendUrl, '');
    const navigateToTandem = () => window.open(urlToTandem, '_blank');
    return (
        <div className="ms-Grid">
            <div className="ms-Grid-row">
                {isFeedbackEnabled ? (
                    <>
                        <GiveFeedbackWidget />
                        <RequestFeedbackWidget />
                    </>
                ): null }
            </div>
            <div className="ms-Grid-row">
                    {isFeedbackNoteEnabled ? (
                        <FeedbackNoteWidget />
                    ) : null}
                    <Tile
                        icon={FA_ARROW_UP_RIGHT}
                        title={lclzStor.GotoOurTandem_TeamsLanding}
                        description={lclzStor.GoToOurTandem_Description_TeamsLanding}
                        onClick={navigateToTandem}
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 home-widget-tile"
                    />
            </div>
        </div>
    );
};

export default HomeWidgets;
