import { CardHeader, Flex, CardBody, Card, Text } from '@fluentui/react-northstar';
import React from 'react';
import { useTeams } from '../../hooks/useTeams';

interface IProps {
     icon: string;
     title: string;
     description: string;
     onClick: () => void;
     className?: string;
}
export const Tile = (props: IProps) => {
    const { theme } = useTeams({})[0];
    const { brand } = theme.siteVariables.contextualColors;
    return (
        <div className={props.className}>
            <Card onClick={props.onClick} elevated fluid>
                <CardHeader>
                    <Flex gap="gap.small">
                        <i className={`${props.icon} fa-regular tile-icon`} style={{color: brand[600] }} />
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Text content={props.title} size="large" weight="bold" />
                    <Text content={props.description} size="medium" />
                </CardBody>
            </Card>
        </div>

    );
};
export default Tile;
