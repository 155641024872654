import * as actionTypes from '../actions/actionTypes';
import { AUTOCLOSE } from '../common/constants';
import { AnyAction } from '@reduxjs/toolkit';
import { IModalsStore } from '../common/interfaces/modals/IModalsStore';

const initialState: IModalsStore = {
    toggleLogoutModal: false,
    toggleMessageModal: false,
    toggleCancelModal: false,
    toggleConfirmModal: false,
    toggleOnboardingModal: false,
    textMsg: "",
    descriptionMsg: "",
    dimmer: true,
    closeOnClick: true,
    autoClose: false,
    icon: "",
    image: "",
    goBack: false,
    autoCloseAction: null,
    autoCloseTime: AUTOCLOSE,
    primaryBtnText: '',
    primaryBtnCallback: null,
    primaryBtnType: 'primary',
    secondaryBtnText: '',
    secondaryBtnCallback: null,
    secondaryBtnType: 'primary'
};

export function modals(state: IModalsStore = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.DATA_PRIVACY_MODAL:
            return { ...state, toggleDataPrivacyModal: action.toggleDataPrivacy, dataPrivacyAcceptOnly: action.isAcceptOnly };
        case actionTypes.LOGOUT_MODAL:
            return { ...state, toggleLogoutModal: action.toggleLogout };
        case actionTypes.CANCEL_MODAL:
            return { ...state, toggleCancelModal: action.toggleCancel, textMsg: action.textMsg };
        case actionTypes.CONFIRM_MODAL:
            return { ...state, toggleConfirmModal: action.toggleConfirm, descriptionMsg: action.descriptionMsg, autoCloseAction: action.autoCloseAction };
        case actionTypes.ONBOARDING_MODAL:
            return { ...state, toggleOnboardingModal: action.enable };
        case actionTypes.MESSAGE_MODAL:
            return { ...state, toggleMessageModal: action.toggle, goBack: action.goBack, image: action.image,
                textMsg: action.textMsg, descriptionMsg: action.descriptionMsg, dimmer: action.dimmer,
                closeOnClick: action.closeOnClick, autoClose: action.autoClose, 
                autoCloseAction: action.autoCloseAction, autoCloseTime: action.autoCloseTime, primaryBtnText: action.primaryBtnText,
                primaryBtnCallback: action.primaryBtnCallback, primaryBtnType: action.primaryBtnType, secondaryBtnText: action.secondaryBtnText, 
                secondaryBtnType: action.secondaryBtnType, secondaryBtnCallback: action.secondaryBtnCallback, icon: action.icon };
        default:
            return state;
    }
}
