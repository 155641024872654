// Dates
import { Quarters } from './models/enums/reports/Quarter';

export const DEFAULT_LANG = 'en';

export const DATE_FORMAT_DAY = 'DD';
export const DATE_FORMAT_MONTH = 'MMM';
export const DATE_FORMAT_YEAR = 'YYYY';
export const SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WITHOUT_TIMEZONE = 'YYYY-MM-DD[T]HH:mm:ss';
export const ICS_FORMAT = 'YYYYMMDDTHHmmss';
export const REVIEW_FORMAT = 'MMM D, YYYY';
export const DATE_TIME_YEAR_FORMAT = 'MMM D, YYYY h:mm A';
export const DATE_TIME_YEAR_FORMAT_RU = 'D MMM, YYYY, h:mm a';
export const TIME_ONLY = 'h:mm a';

export const HOURS_PER_DAY = 24;
export const DAYS_PER_YEAR = 365;
export const MINUTES_PER_HOUR = 60;
export const MILLISECONDS_PER_MINUTE = 60000;

// Max length
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_215 = 215;
export const MAX_LENGTH_250 = 250;
export const MAX_LENGTH_500 = 500;
export const MAX_LENGTH_2500 = 2500;
export const MAX_LENGTH_3000 = 3000;
export const MAX_LENGTH_10000 = 10000;
export const COMMENT_MAX_LENGTH = 4000;
export const NOTE_MAX_LENGTH = 3000;
export const MAX_LENGTH_1000 = 1000;

// Timeout
export const EDITOR_DELAY = 100;
export const EDITOR_AUTO_SAVE_TIME = 5000;
export const FRESH_LIST_VIEW_INTERVAL = 30000;
export const AUTOCLOSE_TIME = 6000;
export const REFRESH_ON_UPDATE_TIME = 3000;

// File size
export const TWO_MEGABYTES = 2097152;
export const TEN_MEGABYTES = 10485760;

// Versions
export const VERSION_1_0 = 1.0;
export const VERSION_1_1 = 1.1;

// Images
export const IMAGE_XL = 'xl';
export const IMAGE_LARGE = 'lg';
export const DEFAULT_IMAGE = 'img/empty.png';
export const PLACEHOLDER_IMAGE = 'img/placeholder-image.png';
export const PRELOADER_IMG = 'img/preloader-gif.gif';

// Styles
export const OPACITY_TEXT = 'opacity-text-7';

// Icons
export const FA_INFO_CIRCLE = 'fa-info-circle';
export const FA_CHECK = 'fa-check';
export const FA_CHECK_CIRCLE = 'fa-check-circle';
export const FA_CHECK_SQUARE = 'fa-check-square';
export const FA_CHECK_DOUBLE = 'fa-check-double';
export const FA_COMMENT_ALT_PLUS = 'fa-comment-alt-plus';
export const FA_PLUS_CIRCLE = 'fa-plus-circle';
export const FA_THUMBS_UP = 'fa-thumbs-up';
export const FA_PLAY = 'fa-play';
export const FA_PLAY_CIRCLE = 'fa-play-circle';
export const FA_COFFEE = 'fa-coffee';
export const FA_CLOCK = 'fa-clock';
export const FA_TRASH_ALT = 'fa-trash-alt';
export const FA_TRASH_CLOCK = 'fa-trash-clock';
export const FA_EDIT = 'fa-edit';
export const FA_COMPASS = 'fa-compass';
export const FA_COMPASS_SLASH = 'fa-compass-slash';
export const FA_SHARE_ALT = 'fa-share-alt';
export const FA_PLUS = 'fa-plus';
export const FA_USER = 'fa-user';
export const FA_USER_CLOCK = 'fa-user-clock';
export const FA_USER_TIE = 'fa-user-tie';
export const FA_USER_COG = 'fa-user-cog';
export const FA_USER_CHECK = 'fa-user-check';
export const FA_USER_SHIELD = 'fa-user-shield';
export const FA_USER_CHART = 'fa-user-chart';
export const FA_USER_TAG = 'fa-user-tag';
export const FA_USER_PLUS = 'fa-user-plus';
export const FA_USER_CIRCLE = 'fa-user-circle';
export const FA_USER_FRIENDS = 'fa-user-friends';
export const FA_USER_TIMES = 'fa-user-times';
export const FA_USER_EDIT = 'fa-user-edit';
export const FA_USER_SECRET = 'fa-user-secret';
export const FA_USERS = 'fa-users';
export const FA_USERS_COG = 'fa-users-cog';
export const FA_USERS_CROWN = 'fa-users-crown';
export const FA_USERS_MEDICAL = 'fa-users-medical';
export const FA_USERS_CLASS = 'fa-users-class';
export const FA_COMMENT = 'fa-comment';
export const FA_COMMENT_CHECK = 'fa-comment-check';
export const FA_COMMENT_ALT = 'fa-comment-alt';
export const FA_COMMENT_ALT_EXCLAMATION = 'fa-comment-alt-exclamation';
export const FA_COMMENT_ALT_LINES = 'fa-comment-alt-lines';
export const FA_COMMENT_ALT_EDIT = 'fa-comment-alt-edit';
export const FA_COMMENT_ALT_SLASH = 'fa-comment-alt-slash';
export const FA_COMMENT_ALT_CHECK = 'fa-comment-alt-check';
export const FA_BULLSEYE = 'fa-bullseye';
export const FA_BULLSEYE_POINTER = 'fa-bullseye-pointer';
export const FA_BULLSEYE_ARROW = 'fa-bullseye-arrow';
export const FA_BULLHORN = 'fa-bullhorn';
export const FA_EXCLAMATION_CIRCLE = 'fa-exclamation-circle';
export const FA_REPEAT = 'fa-repeat';
export const FA_EXCLAMATION_TRIANGLE = 'fa-exclamation-triangle';
export const FA_PEN = 'fa-pen';
export const FA_PENCIL = 'fa-pencil';
export const FA_BELL = 'fa-bell';
export const FA_BELL_SLASH = 'fa-bell-slash';
export const FA_CIRCLE = 'fa-circle';
export const FA_CHART_BAR = 'fa-chart-bar';
export const FA_CHART_AREA = 'fa-chart-area';
export const FA_CHART_NETWORK = 'fa-chart-network';
export const FA_CHART_PIE = 'fa-chart-pie';
export const FA_CHEVRON_LEFT = 'fa-chevron-left';
export const FA_CHEVRON_RIGHT = 'fa-chevron-right';
export const FA_CHEVRON_DOWN= 'fa-chevron-down';
export const FA_CHEVRON_UP = 'fa-chevron-up';
export const FA_CHEVRON_CIRCLE_LEFT = 'fa-chevron-circle-left';
export const FA_CHEVRON_CIRCLE_RIGHT = 'fa-chevron-circle-right';
export const FA_INFO = 'fa-info';
export const FA_ANGLE_DOWN = 'fa-angle-down';
export const FA_ANGLE_UP = 'fa-angle-up';
export const FA_ANGLE_LEFT = 'fa-angle-left';
export const FA_ANGLE_RIGHT = 'fa-angle-right';
export const FA_TIMES = 'fa-times';
export const FA_TIMES_CIRCLE = 'fa-times-circle';
export const FA_EYE = 'fa-eye';
export const FA_EYE_SLASH = 'fa-eye-slash';
export const FA_CARET_UP = 'fa-caret-up';
export const FA_CARET_SQUARE_UP = 'fa-caret-square-up';
export const FA_CARET_SQUARE_DOWN = 'fa-caret-square-down';
export const FA_CARET_DOWN = 'fa-caret-down';
export const FA_CARET_CIRCLE_RIGHT = 'fa-caret-circle-right';
export const FA_BARS = 'fa-bars';
export const FA_SORT_UP = 'fa-sort-up';
export const FA_SORT_DOWN = 'fa-sort-down';
export const FA_FILE = 'fa-file';
export const FA_FILE_EDIT = 'fa-file-edit';
export const FA_FILE_UPLOAD = 'fa-file-upload';
export const FA_FILE_IMAGE = 'fa-file-image';
export const FA_FILE_CHART_PIE = 'fa-file-chart-pie';
export const FA_FILE_CHART_LINE = 'fa-file-chart-line';
export const FA_CALENDAR = 'fa-calendar';
export const FA_CALENDAR_CHECK = 'fa-calendar-check';
export const FA_CALENDAR_PLUS = 'fa-calendar-plus';
export const FA_LOCK_ALT = 'fa-lock-alt';
export const FA_GRADUATION_CAP = 'fa-graduation-cap';
export const FA_BAN = 'fa-ban';
export const FA_CLOUD_DOWNLOAD = 'fa-cloud-download';
export const FA_DOWNLOAD = 'fa-download';
export const FA_STAR_HALF_ALT = 'fa-star-half-alt';
export const FA_SEARCH = 'fa-search';
export const FA_SEARCH_PLUS = 'fa-search-plus';
export const FA_COG = 'fa-cog';
export const FA_SHIELD_CHECK = 'fa-shield-check';
export const FA_REDO = 'fa-redo';
export const FA_FILTER = 'fa-filter';
export const FA_SLIDERS_H = 'fa-sliders-h';
export const FA_TABLE = 'fa-table';
export const FA_COPYRIGHT = 'fa-copyright';
export const FA_CAMERA = 'fa-camera';
export const FA_TAG = 'fa-tag';
export const FA_TAGS = 'fa-tags';
export const FA_COMMENTS_ALT = 'fa-comments-alt';
export const FA_BUILDING = 'fa-building';
export const FA_FORWARD = 'fa-forward';
export const FA_BACKSPACE = 'fa-backspace';
export const FA_STAR = 'fa-star';
export const FA_UNDO = 'fa-undo';
export const FA_SIGN_OUT = 'fa-sign-out';
export const FA_TASKS = 'fa-tasks';
export const FA_IMAGE = 'fa-image';
export const FA_FROWN = 'fa-frown';
export const FA_SYNC_ALT = 'fa-sync-alt';
export const FA_FOLDER_TIMES = 'fa-folder-times';
export const FA_REPLY = 'fa-reply';
export const FA_NOTES_MEDICAL = 'fa-notes-medical';
export const FA_QUESTION = 'fa-question';
export const FA_CLIPBOARD_LIST_CHECK = 'fa-clipboard-list-check';
export const FA_CLIPBOARD_LIST = 'fa-clipboard-list';
export const FA_REPEAT_ALT = 'fa-repeat-alt';
export const FA_TACHOMETER_FAST = 'fa-tachometer-fast';
export const FA_HOME = 'fa-home';
export const FA_ARROW_ALT_UP = 'fa-arrow-alt-up';
export const FA_LONG_ARROW_ALT_UP = 'fa-long-arrow-alt-up';
export const FA_LONG_ARROW_ALT_DOWN = 'fa-long-arrow-alt-down';
export const FA_MINUS = 'fa-minus';
export const FA_SITEMAP = 'fa-sitemap';
export const FA_LINK = 'fa-link';
export const FA_UNLINK = 'fa-unlink';
export const FA_FILE_TIMES = 'fa-file-times';
export const FA_LIGHTBULB = 'fa-lightbulb';
export const FA_ITALIC = 'fa-italic';
export const FA_TOOLBOX = 'fa-toolbox';
export const FA_BORDER_ALL = 'fa-border-all';
export const FA_SORT = 'fa-sort';
export const FA_TEXT = 'fa-text';
export const FA_TEXT_SIZE = 'fa-text-size';
export const FA_LIST_UL = 'fa-list-ul';
export const FA_LIST_OL = 'fa-list-ol';
export const FA_QUOTE_RIGHT = 'fa-quote-right';
export const FA_BOLD = 'fa-bold';
export const FA_UNDERLINE = 'fa-underline';
export const FA_STRIKETHROUGH = 'fa-strikethrough';
export const FA_EXTERNAL_LINK = 'fa-external-link';
export const FA_TIMES_SQUARE = 'fa-times-square';
export const FA_CALENDAR_WEEK = 'fa-calendar-week';
export const FA_CALENDAR_DAY = 'fa-calendar-day';
export const FA_CHART_LINE = 'fa-chart-line';
export const FA_PAPERCLIP = 'fa-paperclip';
export const FA_ID_CARD = 'fa-id-card';
export const FA_PAPER_PLANE = 'fa-paper-plane';
export const FA_BOOK_READER = 'fa-book-reader';
export const FA_UPLOAD = 'fa-upload';
export const FA_SPINNER = 'fa-spinner';
export const FA_CARET_LEFT = 'fa-caret-left';
export const FA_CARET_RIGHT = 'fa-caret-right';
export const FA_DESKTOP = 'fa-desktop';
export const FA_ARROW_TO_TOP = 'fa-arrow-to-top';
export const FA_ROCKET_CHAT = 'fa-rocketchat';
export const FA_ARROW_FROM_LEFT = 'fa-arrow-from-left';
export const FA_REMOVE_FORMAT = 'fa-remove-format';
export const FA_SAD_TEAR = 'fa-sad-tear';
export const FA_TRANSPORTER_EMPTY = 'fa-transporter-empty';
export const FA_WINDOW = 'fa-window-alt';
export const FA_GLOBE = 'fa-globe';
export const FA_PEOPLE_ARROWS = 'fa-people-arrows';
export const FA_CUBES = 'fa-cubes';
export const FA_THREE_DOTS = 'fa-ellipsis-h';
export const FA_HANDSHAKE = 'fa-handshake';
export const FA_SCALE = 'fa-balance-scale';
export const FA_LONG_ARROW_RIGHT = 'fa-long-arrow-right';
export const FA_HISTORY = 'fa-history';
export const FA_COPY = 'fa-copy';
export const FA_FILE_SEARCH = 'fa-file-search';
export const FA_FILE_EXPORT = 'fa-file-export';
export const FA_FILE_EXCLAMATION = 'fa-file-exclamation';
export const FA_FILE_CSV = 'fa-file-csv';
export const FA_FILE_EXCEL = 'fa-file-excel';
export const FA_LIST_CHECK = "fa-list-check";
export const FA_GEARS = "fa-gears";
export const FA_NOTE = "fa-note";
export const FA_NOTE_MEDICAL = 'fa-note-medical';
export const FA_CIRCLE_XMARK = "fa-circle-xmark";
export const FA_XMARK = "fa-xmark";
export const FA_GLASS_SEARCH ="fa-magnifying-glass-chart";
export const FA_ARROW_UP_RIGHT = 'fa-arrow-up-right-from-square';
export const FA_MESSAGE = 'fa-messages';
export const FA_MESSAGE_PEN = 'fa-message-pen';
export const FA_PRINT = 'fa-print';
export const FA_HISTORY_CIRCLE_USER ="fa-rectangle-history-circle-user";
export const FA_CIRCLE_NODES = 'fa-circle-nodes';
export const FA_LAYER_PLUS = 'fa-layer-plus';
export const FA_TIMER = 'fa-timer';
export const FA_COINS = 'fa-coins';
export const FA_EUROPE = 'fa-earth-europe';
export const FA_FLAG = 'fa-flag';
export const FA_LIGHTBULB_ON = 'fa-lightbulb-on';

// Old icons
export const ICON_TRIANGLE = 'icon-Arrow-custom';
export const ICON_CONGRATS = 'icon-fa-ico-congrats';
export const ICON_TANDEM = 'icon-tandem-mark ';

// Colors
export const BLACK = '#030303';
export const RED_SLUG = 'red';
export const PALETTE = ['#80d135', '#f4804b', '#32a2b3', '#cf35d1', '#ffc526', '#e43b4b'];
export const MODAL_BKG = 'rgba(0, 0, 0, 0.75)';

// Limits
export const MIN_DAYS = 1;
export const MAX_DAYS = 365;
export const MIN_PERCENT = 1;
export const MAX_PERCENT = 100;
export const HOURS_IN_DAY = 24;
export const SEARCH_SIZE = 4;
export const SEARCH_SIZE_SMALL = 2;
export const MIN_SEARCH_CHARACTERS = 1;
export const MIN_REQUIRED_SECTIONS = 2;

// Device width
export const MOBILE_BREAKPOINT = 767;
export const COLLAPSED_MAX_WIDTH = 1120;

// Storage Keys
export const LOCAL_STORAGE_KEYS = {
    PRIVACY_STATUS: 'privacyStatus',
    PRIVACY_ACCEPT_ONLY: 'privacy_accept_only',
    COMPANY_NAME: 'companyName',
    NOTIFICATION_URL: 'notificationURL',
    REACT_APP_TANDEM_UPDATE: 'REACT_APP_TANDEM_UPDATE',
    REACT_APP_FORCE_LOGOUT_ON_VERSION_UPGRADE: 'REACT_APP_FORCE_LOGOUT_ON_VERSION_UPGRADE',
    LOGIN_TIMINGS: 'LOGIN_TIMINGS',
    DEVICE_PUSH_ID: 'DEVICE_PUSH_ID',
    PREVENT_LOGIN: 'PREVENT_LOGIN',
    PREVENT_CONTERS_LOAD: 'PREVENT_CONTERS_LOAD',
    SHOULD_LANDING_PAGE_BE_VISITED: 'shouldLandingPageBeVisited'
};

export const SESSION_STORAGE_KEYS = {
    STATE: 'state',
    COMPANY: 'company',
    POLICY: 'policy',
    STOP_AUTO_SSO: 'stopAutoSSO',
    TOKEN: 'token',
    DEFAULT_COMPANY_LANGUAGE: 'defaultCompanyLanguage',
    PROXY_MODE: 'PROXY_MODE',
    EXTERNAL_USER_MODE: 'EXTERNAL_USER_MODE',
    EXTERNAL_USER_SURVEY_INFO: 'EXTERNAL_USER_SURVEY_INFO'
};

// Widget item constants
export const WIDGET_FIRST_PAGE = 1;
export const WIDGET_ITEMS_COUNT = 4;
export const WIDGET_QUARTER_FIRST = Quarters.FullYear;

// Counts
export const RADAR_MAX_LENGTH = 13;
export const PRINT_RADAR_MAX_LENGTH = 19;
export const DEFAULT_CHECKINS_COUNT = 1;
export const QTY_OF_WORDS_BEHAVIOUR_LIBRARY = 86;
export const QTY_OF_WORDS_BEHAVIOUR_LIBRARY_MAX = 90;
export const SYMBOL_WIDTH = 8;
export const DISTANCE_BETWEEN_WORDS = 27;
export const BEH_LIB_TITLE_MAX_SYMBOLS = 66;
export const BEH_LIB_TITLE_MAX_SYMBOLS_FOR_SELECT = 40;
export const BEH_TITLE_MAX_SYMBOLS_TO_SHOW = 39;
export const BEH_TITLE_MAX_WIDTH_IN_PX = 300;
export const ADDITIONAl_REVIEWERS_DEFAULT = 3;

// Counters update delay
export const FRESH_LIST_UPDATE_DELAY: number = 90000;
// Pagination settings
export const ITEMS_PER_PAGE = 10;
export const ITEMS_PER_PAGE_SMALL = 3;

export const DOWNLOAD_USERS_CSV = 'users/csv-file?showAdditional=true';
export const DOWNLOAD_OI_CSV = 'organisation-items/csv-file';
export const DOWNLOAD_USERS_FOR_DELETION_CSV = 'users/upcoming-hard-deletion-list/csv';

// Fresh-list
export const UNIX_EPOCH_TICKS = 621355968000000000;
export const TICKS_PER_MILLISECOND = 10000;

// Localization sources with possesive case

export const POSSESSIVE_SOURCES = [
    'PublicGoalByUser_PageHeader',
    'TeamDirectoryManager_Tag',
    'Feedback_PositiveFeedback',
    'Feedback_ContextFeedback',
    'CheckinView_ConversationPoints',
    'CheckinView_GoalsWidgetTitle',
    'CheckinView_BehavioursWheelTitle',
    'FeedbackRequest_RequestedBehaviours_you_reply',
    'FeedbackRequest_RequestedBehaviours_reply_to',
    'FeedbackRequest_RequestedBehaviours_you_reply',
    'SurveyNominations_reviewParticipants',
    'Feedback_ContextFeedback',
    'SurveyNominations_totalAssignedToSolo',
    'SurveyNominations_totalAssignedTo',
    'EmployeeDashboard_tittle',
    'CoachingNotes_EmployeeFbBehavTopText',
    'CoachingNotes_RollingTrendWidgetTitle',
    'CoachingNotes_EmployeeNoFBTopTex',
    'TeamDashboard_Page_DirectTitle',
    'CoachingNotes_EmployeeNoFBTopText',
    'CheckinView_OverallWidgetTitle',
    'Feedback_PositiveFeedbackShared',
    'Feedback_PositiveFeedbackSharedPlural'
];
export const SEARCH_TIMEOUT = 1500;

export const SCREEN_SIZES = {
    MOBILE: '767px'
};
export const BAR_CHART_HEIGHT = 180;
export const LINE_CHART_HEIGHT = 260;
export const THICK_CHART_WIDTH = 36;
export const GOUGE_CHART_RADIUS = 100;
export const RADIANA = 0.0174532925;
export const PI = 3.14;
export const GOUGE_CHART_STROKE_WIDTH = 18;
export const ANGLE_PER_PERCENT = 1.8;
export const DONUT_RADIUS = 25;
export const DONUT_STROKE_WIDTH = 6;
export const THIN_CHART_WIDTH = 8;
export const BEH_CHART_HEIGHT = 250;

// Select
export const OPTIONS_PER_PAGE = 9;
export const DEBOUNCE_TIMEOUT = 300;

export const COUNTRIES = ['AALAND_ISLANDS', 'AFGHANISTAN', 'ALBANIA', 'ALGERIA', 'AMERICAN_SAMOA', 'ANDORRA', 'ANGOLA',
    'ANGUILLA', 'ANTARCTICA', 'ANTIGUA_AND_BARBUDA', 'ARGENTINA', 'ARMENIA', 'ARUBA', 'AUSTRALIA', 'AUSTRIA', 'AZERBAIJAN',
    'BAHAMAS', 'BAHRAIN', 'BANGLADESH', 'BARBADOS', 'BELARUS', 'BELGIUM', 'BELIZE', 'BENIN', 'BERMUDA', 'BHUTAN',
    'BOLIVIA', 'BOSNIA_AND_HERZEGOVINA', 'BOTSWANA', 'BOUVET_ISLAND', 'BRAZIL', 'BRITISH_INDIAN_OCEAN_TERRITORY',
    'BRUNEI_DARUSSALAM', 'BULGARIA', 'BURKINA_FASO', 'BURUNDI', 'CAMBODIA', 'CAMEROON', 'CANADA', 'CAPE_VERDE',
    'CAYMAN_ISLANDS', 'CENTRAL_AFRICAN_REPUBLIC', 'CHAD', 'CHILE', 'CHINA', 'CHRISTMAS_ISLAND', 'COCOS_ISLANDS',
    'COLOMBIA', 'COMOROS', 'CONGO', 'CONGO_REPUBLIC', 'COOK_ISLANDS', 'COSTA_RICA', 'COTE_D_IVOIRE', 'CROATIA', 'CUBA',
    'CYPRUS', 'CZECH_REPUBLIC', 'DENMARK', 'DJIBOUTI', 'DOMINICA', 'DOMINICAN_REPUBLIC', 'ECUADOR', 'EGYPT', 'EL_SALVADOR',
    'EQUATORIAL_GUINEA', 'ERITREA', 'ESTONIA', 'ETHIOPIA', 'FALKLAND_ISLANDS', 'FAROE_ISLANDS', 'FIJI', 'FINLAND', 'FRANCE',
    'FRENCH_GUIANA', 'FRENCH_POLYNESIA', 'FRENCH_SOUTHERN_TERRITORIES', 'GABON', 'GAMBIA', 'GEORGIA', 'GERMANY', 'GHANA',
    'GIBRALTAR', 'GREECE', 'GREENLAND', 'GRENADA', 'GUADELOUPE', 'GUAM', 'GUATEMALA', 'GUINEA_BISSAU', 'GUINEA', 'GUYANA',
    'HAITI', 'HEARD_AND_MC_DONALD_ISLANDS', 'HONDURAS', 'HONG_KONG', 'HUNGARY', 'ICELAND', 'INDIA', 'INDONESIA', 'IRAN',
    'IRAQ', 'IRELAND', 'ISRAEL', 'ITALY', 'JAMAICA', 'JAPAN', 'JORDAN', 'KAZAKHSTAN', 'KENYA', 'KIRIBATI',
    'KOREA_DEMOCRATIC_PEOPLES_REPUBLIC', 'KOREA_REPUBLIC', 'KUWAIT', 'KYRGYZSTAN', 'LAO_PEOPLES_DEMOCRATIC_REPUBLIC',
    'LATVIA', 'LEBANON', 'LESOTHO', 'LIBERIA', 'LIBYAN_ARAB_JAMAHIRIYA', 'LIECHTENSTEIN', 'LITHUANIA', 'LUXEMBOURG',
    'MACAU', 'NORTH_MACEDONIA', 'MADAGASCAR', 'MALAWI', 'MALAYSIA', 'MALDIVES', 'MALI', 'MALTA',
    'MARSHALL_ISLANDS', 'MARTINIQUE', 'MAURITANIA', 'MAURITIUS', 'MAYOTTE', 'MEXICO', 'MICRONESIA_FEDERATED_STATES_OF',
    'MOLDOVA_REPUBLIC_OF', 'MONACO', 'MONGOLIA', 'MONTSERRAT', 'MOROCCO', 'MOZAMBIQUE', 'MYANMAR', 'NAMIBIA', 'NAURU',
    'NEPAL', 'NETHERLANDS', 'NETHERLANDS_ANTILLES', 'NEW_CALEDONIA', 'NEW_ZEALAND', 'NICARAGUA', 'NIGER', 'NIGERIA',
    'NIUE', 'NORFOLK_ISLAND', 'NORTHERN_MARIANA_ISLANDS', 'NORWAY', 'OMAN', 'PAKISTAN', 'PALAU', 'PALESTINIAN_TERRITORY_Occupied',
    'PANAMA', 'PAPUA_NEW_GUINEA', 'PARAGUAY', 'PERU', 'PHILIPPINES', 'PITCAIRN', 'POLAND', 'PORTUGAL', 'PUERTO_RICO',
    'QATAR', 'REUNION', 'ROMANIA', 'RUSSIAN_FEDERATION', 'RWANDA', 'SAINT_HELENA', 'SAINT_KITTS_AND_NEVIS', 'SAINT_LUCIA',
    'SAINT_PIERRE_AND_MIQUELON', 'SAINT_VINCENT_AND_THE_GRENADINES', 'SAMOA', 'SAN_MARINO', 'SAO_TOME_AND_PRINCIPE',
    'SAUDI_ARABIA', 'SENEGAL', 'SERBIA_AND_MONTENEGRO', 'SEYCHELLES', 'SIERRA_LEONE', 'SINGAPORE', 'SLOVAKIA', 'SLOVENIA',
    'SOLOMON_ISLANDS', 'SOMALIA', 'SOUTH_AFRICA', 'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS', 'SPAIN', 'SRI_LANKA',
    'SUDAN', 'SURINAME', 'SVALBARD_AND_JAN_MAYEN_ISLANDS', 'ESWATINI', 'SWEDEN', 'SWITZERLAND', 'SYRIAN_ARAB_REPUBLIC',
    'TAIWAN', 'TAJIKISTAN', 'TANZANIA_UNITED_REPUBLIC_OF', 'THAILAND', 'TIMOR_LESTE', 'TOGO', 'TOKELAU', 'TONGA',
    'TRINIDAD_AND_TOBAGO', 'TUNISIA', 'TURKEY', 'TURKMENISTAN', 'TURKS_AND_CAICOS_ISLANDS', 'TUVALU', 'UGANDA', 'UKRAINE',
    'UNITED_ARAB_EMIRATES', 'UNITED_KINGDOM', 'UNITED_STATES', 'UNITED_STATES_MINOR_OUTLYING_ISLANDS', 'URUGUAY', 'UZBEKISTAN',
    'VANUATU', 'HOLY_SEE', 'VENEZUELA', 'VIET_NAM', 'VIRGIN_ISLANDS_BRITISH', 'VIRGIN_ISLANDS_US', 'WALLIS_AND_FUTUNA_ISLANDS',
    'WESTERN_SAHARA', 'YEMEN', 'ZAMBIA', 'ZIMBABWE', 'BONAIRE_SINT_EUSTATIUS_AND_SABA', 'CURACAO', 'GUERNSEY',
    'ISLE_OF_MAN', 'JERSEY', 'MONTENEGRO', 'SAINT_BARTHELEMY', 'SAINT_MARTIN', 'SINT_MAARTEN', 'SOUTH_SUDAN'];

export const NOTFICATIONS_ABOUT_FEEDBACK = [0, 1, 2, 3, 4, 17, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 43];

export const TIP_KEYS = ['etip1_LP', 'etip2_LP', 'etip3_LP', 'etip4_LP', 'etip5_LP', 'mtip1_LP', 'mtip2_LP', 'mtip3_LP', 'mtip4_LP', 'mtip5_LP'];

export const reviewPeriodsFromBack = [
    {
        Id: 0,
        Name: 'Training Cycle Mock (26/01/2023)',
        Date: '2023-01-26T09:00:00Z'
    },
    {
        Id: 1,
        Name: 'FY 2020 Cycle (19/08/2021)',
        Date: '2021-08-19T09:00:00Z'
    },
    {
        Id: 2,
        Name: 'Cycle Number 2 (01/11/2022)',
        Date: '2022-11-01T09:00:00Z'
    },
    {
        Id: 3,
        Name: 'Review Cycle Now (01/11/2020)',
        Date: '2020-11-01T00:00:00Z'
    }
];

export const CHECKIN_REVIEW_FILE_TYPES = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/plain',
    'text/csv',
    '.ppt',
    '.pptx',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const SIZE_TO_LOAD_CHECKINS = 10;
export const SIZE_TO_LOAD_PAST_CHECKINS = 3;

export const BREADCRUMBS_KEYS = {
    teamDashboard: 'teamDashboard'
};
export const CLIENT_IDS = {
  Teams: 'Teams',
  Desktop: 'Desktop'
};
export const jesusBirthday = '0001-01-01T00:00:00Z';
