import React, { useEffect } from "react";
import { Provider, teamsTheme, usePrevious } from "@fluentui/react-northstar";
import { Router, Redirect, Route } from "react-router";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import TabConfig from "./TabConfig";
import { useTeams } from "../hooks/useTeams";
import { hashHistory } from "../shared/src/utils/history";
import requireAuth from "../utils/requireAuth";
import { useSelector } from "react-redux";
import IStore from "../common/interfaces/IStore";
import Welcome from "./auth/Welcome";
import { APP_CONSTANTS } from "../common/constants";
import { useDispatch } from "react-redux";
import jwt_decode from 'jwt-decode';
import { getAllSettings } from "../actions/settings";
import { addFbNoteSearch, getUserInfo } from '../actions/user';
import uuid from 'uuid/v4';
import * as sessionStorage from '../shared/src/modules/sessionStorage';
import Home from './Home';
import ErrorBoundary from './ErrorBoundary';
import { cloneDeep } from 'lodash';

const App = () => {
  const dispatch: any = useDispatch();
  const getSettings = () => dispatch(getAllSettings());
  const getUserProfile = (guid: string) => dispatch(getUserInfo(guid));
  const { token } = useSelector((state: IStore) => state.auth);
  const [isReadyToRedirect, setIsReadyToRedirect] = React.useState(false);

  const previousToken = usePrevious(token);

  const addSessionId = () => {
    const id = sessionStorage.loadItem(process.env.REACT_APP_SESSION_ID);
    if (!id) {
        sessionStorage.saveItem(process.env.REACT_APP_SESSION_ID, uuid());
    }
  };

  const loadUserData = () => {
     addSessionId();
      const userGuid: any = jwt_decode(token);
      getSettings();
      getUserProfile(userGuid.sub);
  };

  useEffect(() => {
    if (token) {
        loadUserData();
        setIsReadyToRedirect(true);
    } else {
        const search = window.location.hash.split('?')[1];
        if(search) {
            dispatch(addFbNoteSearch(cloneDeep(search)));
            setIsReadyToRedirect(true);
        } else {
            setIsReadyToRedirect(true);
        }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if (token && previousToken !== token) {
          loadUserData();
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { theme } = useTeams({})[0];
  const grey2 = theme.siteVariables.colorScheme.grey.background3;

  return (
        <Provider theme={theme || teamsTheme} styles={{ backgroundColor: grey2 }}>
          <ErrorBoundary>
          <Router history={hashHistory}>
            <Route exact path="/">
              <Redirect to={APP_CONSTANTS.LINKS.HOME} />
            </Route>
            <>
              <Route exact path={APP_CONSTANTS.LINKS.PRIVACY} component={Privacy} />
              <Route exact path={APP_CONSTANTS.LINKS.TERMS_OF_USE} component={TermsOfUse} />
              <Route exact path={`${APP_CONSTANTS.LINKS.HOME}/:widget?`} render={requireAuth(Home, !!token, isReadyToRedirect)} />
              <Route path={APP_CONSTANTS.LINKS.LOGIN} component={Welcome} />
              <Route exact path="/config" component={TabConfig} />
            </>
          </Router>
          </ErrorBoundary>
        </Provider>
  );
};

export default App;
