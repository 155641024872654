import { ITEMS_PER_PAGE } from '../shared/src/constants';
import IIndividualGoalGeneralForm
    from '../shared/src/models/interfaces/priorities/individualPriorities/IIndividualGoalGeneralForm';
import apiGateway from '../shared/src/modules/api/apiGateway';

export const searchForIndividualGoals = (
    SearchString: string,
    Page: number = 1,
    GoalsToExclude: number[],
    Count: number = ITEMS_PER_PAGE,
    StartDateTime?: string,
    EndDateTime?: string,
): Promise<{Goals: IIndividualGoalGeneralForm[]}> => {
    const data = {
        GoalsToExclude, Page, Count, SearchString, StartDateTime, EndDateTime
    };
    return apiGateway.postAsync('goals/individual/search-own/extended', data);
};
