import LocalizedStrings from 'react-localization';

const lclzStor: any = new LocalizedStrings({
    en: {
        "version_upgrade_title": "A new version of beqom is now available, please visit your App store to upgrade!",
        "version_upgrade": "A new version of beqom has been released! Your page will refresh in 3 seconds and you can continue. Thank you!",
        "version_upgrade_app": "",
        "user_deactivated_title": "This user account is no longer active",
        "user_deactivated": "Your account is no longer active. Please contact your internal IT helpdesk or system administrator for support.",
        "user_notfound_title": "This user account could not be found in beqom",
        "user_notfound": "There is no user with this email address in your company beqom account. Please contact your internal IT helpdesk or system administrator for support.",
        "connection_button_reload": "Reload",
        "logIn": "Login",
        "LoginForm_forgotPass": "First time password/forgot your password?",
        "LoginForm_logWithAzureAd": "Sign in with my company login",
        "LoginForm_copyright1": "All rights reserved.",
        "LoginForm_warning": "Warning!",
        "LoginForm_ProblemWithTandemAccount": "There are some problems with your eqom account, please contact your IT helpdesk or system administrator for support.",
        "LoginForm_ProblemWithUserCredentials": "Your username or password is incorrect. Please try again!",
        "LoginForm_FailedError": "Oops, sorry something went wrong in beqom. Please try again or contact your IT helpdesk or system administrator for support.",
        "LoginForm_IncorrectCompanyName": "Your company name could not be found. Please try again or contact your system administrator.",
        "LoginForm_ProblemWithYourCompanyLogin": "There are some problems with your Company login, please contact your IT helpdesk or system administrator for support.",
        "LoginForm_EmptyCompanyName": "Please provide your Company name.",
        "LoginForm_passwordResetRequired": "Password reset required for current user.",
        "LoginForm_companyName": "Company",
        "LoginForm_userName": "Username",
        "LoginForm_password": "Password",
        "LoginForm_signInCompanyText": "You have signed into company:",
        "continueButton": "Continue",
        "cancel": "Cancel",
        "ChangeCompany": "Re-enter my company name",
        "SettingsDataPrivacyStatement_title": "Data privacy statement",
        "SettingsDataPrivacyStatement_popupYes": "Accept",
        "SettingsDataPrivacyStatement_popupNo": "Decline",
        "SettingsDataPrivacyStatement_popupOk": "OK",
        "SettingsDataPrivacyStatement_CloseAndExit": "Close and logout",
        "SettingsDataPrivacyStatement_Version": "Version",
        "SettingsDataPrivacyStatement_YouwillBeLogouted": "You will be logged out, are you sure?",
        "ChangePassword_header": "Change Password",
        "ChangePassword_currentPassword": "current password",
        "ChangePassword_newPassword": "new password",
        "ChangePassword_newPasswordRetype": "confirm new password",
        "PasswordChangeMessage": "Password change",
        "PasswordChange_ok": "Your password has been successfully updated and you will be logged out in 3 seconds.",
        "PasswordChange_errNewPasswordsDoNotMatch": "New passwords do not match.",
        "PasswordChange_err": "Sorry, there is a problem with changing your password. Please try again!",
        "PasswordResetTitle": "Set up/reset password",
        "PasswordResetMessage": "Password reset",
        "PasswordReset_ok": "A password reset link has been sent to your email now.",
        "PasswordReset_err": "Sorry, there is a problem with resetting your password. Please try again!",
        "ChangeExpiredPassword_MessageHeader": "Password expired",
        "ChangeExpiredPassword_Message": "Your password for {0} has expired. Please change your password.",
        "ChangePassword_Policy_Header": "Your password must meet the these requirements: ",
        "ChangePassword_Policy_AllowDictionaryWords": "Dictionary words are not allowed",
        "ChangePassword_Policy_AllowPasswordAsUserName": "You can't use your username in your password",
        "ChangePassword_Policy_MaximumLength": "Maximum password length is",
        "ChangePassword_Policy_MinimumLength": "Minimum password length is",
        "ChangePassword_Policy_RequireDigit": "Must contain at least one number",
        "ChangePassword_Policy_RequireLowercase": "Must contain at least one lowercase letter",
        "ChangePassword_Policy_RequireNonAlphanumeric": "Must contain at least one non-alphanumeric character (!$%@#&^*)",
        "ChangePassword_Policy_RequireUppercase": "Must contain at least one uppercase letter",
        "ChangePassword_Policy_EnforceHistory_Start": "Previous",
        "ChangePassword_Policy_EnforceHistory_End": "passwords are not allowed",
        "DeclinedDataPrivacy_Message": "You have previously declined the data privacy consent statement. In order to re-activate your account please contact your IT helpdesk. Until your account is reactivated; you will be unable to use beqom.",
        "DeclinedDataPrivacy_Title": "Re-activate your account",
        "DataPrivacy_EffectiveDate": "Effective Date",
        "no": "No",
        "yes": "Yes",
        "SettingsLegal_releaseNumber": "Build:",
        "LoginForm_CompanyNotFound": "Your company name could not be found, please check the spelling and try again. If you need further support please contact your internal IT helpdesk or system administrator.",
        "LoginForm_CompanyName": "Company name",
        "LoginForm_Welcome": "Welcome!",
        "LoginForm_Description": "feedback that inspires",
        "LoginForm_CompanySubmit": "Enter",
        "LoginForm_GreatSee": "It’s great to see you",
        "LoginForm_logWithTandem": "Sign in with your beqom login",
        "LoginForm_forgotText": "Forgotten your password or want to set up a first time password? Enter your username below, and we will email you instructions",
        "LoginForm_mailMe": "Email me a link",
        "LoginForm_backToLogin": "Back to Login?",
        "LoginForm_setMyPassword": "Set my password",
        "LoginForm_checkMail": "Check your email account for a mail from beqom with a unique link for your password reset.",
        "LoginForm_checkSpam": "If you don't receive this mail in your inbox, please also check your junk email folders.",
        "LoginForm_dontReceive": "Didn't receive our email? %s If it hasn't arrived in the next 3 minutes, you can request it again!",
        "LoginForm_mailMeAgain": "Email me a link again",
        "LoginForm_passwordResetLinkSent_Popup": "A password reset link has been sent to your email",
        "ChangePassword_newPasswordRepeat": "repeat new password",
        "ChangePassword_submit": "Set up my password",
        "ChangePassword_setMyPasswordSuccessDesc": "Your password has been successfully updated.",
        "ChangePassword_setMyPasswordSuccess": "Password has %s been set up",
        "ChangePassword_InvalidLink": "Your password reset link is no longer valid. Please request a new link",
        "ChangeExpiredPassword_HeaderDesc": "Your password for %s has expired. Please change your current password.",
        "SelfInvite_Copied": "Copied",
        "SelfInvite_requestSent": "Your request to join has already been sent",
        "SelfInvite_ReviewDesc": "Your request for access to beqom is under review. You will receive an email invitation shortly.",
        "Tandem_On_GooglePlay": "beqom on Google Play",
        "Tandem_On_iTunes": "beqom on App Store",
        "Logout_Expire_Session_Title": "Secure Login",
        "Logout_Expire_Session_Text": "Your current session has expired. Please login to continue!",
        "ApplicationError_Title": "Application Error",
        "SnackBar_ErrorId": "Error ID:",
        "SnackBar_Message": "Oops, sorry something went wrong in beqom. Please try again or contact your IT helpdesk or system administrator for support. Please copy error id along with other details in order to speed up the issue investigation.",
        "SnackBar_Copy": "Copy",
        "SnackBar_IsCopied": "Error ID has been copied to clipboard",
        "ResetPassword_AdditionalDescription": "We have just sent an email for user with username: {username}",
        "SettingsDataPrivacyStatement_popupText1": "Please accept the",
        "SettingsDataPrivacyStatement_withUpdates_popupText1": "Please accept the updated",
        "SettingsDataPrivacyStatement_Read": "Data Privacy Notice",
        "SettingsDataPrivacyStatement_popupText2": "to proceed",
        "SettingsDataPrivacyStatement_withUpdates_popupText2": "to proceed",
        "OnboardingWizard_Next": "Next",
        "OnboardingWizard_Back": "Back",
        "MainMenu_EditProfile": "Edit profile",
        "MainMenu_LogOut": "Log out",
        "IdentityError_PasswordIsExpired": "Your password is expired.",
        "IdentityError_PasswordMismatch": "Password mismatch.",
        "IdentityError_PasswordPolicyNotFound": "Password policy not found.",
        "IdentityError_PasswordRequiresDigit": "Password must contain digits.",
        "IdentityError_PasswordRequiresLower": "Password must contain lower letters.",
        "IdentityError_PasswordRequiresNonAlphanumeric": "Password must contain non alphanumeric letters.",
        "IdentityError_PasswordRequiresUniqueChars": "Password must contain unique characters.",
        "IdentityError_PasswordRequiresUpper": "Password must contain upper letters.",
        "IdentityError_PasswordTooLong": "Provided password is too long.",
        "IdentityError_PasswordTooShort": "Provided password is too short.",
        "Office365_AppInfo": "All rights reserved. Build: ",
        "Office365_GoNext": "Go next",
        "Office365_LoginForm_Company_not_found": "Sorry, the company name you have entered is not valid",
        "Office365_LoginForm_companyPlaceholder": "Company name",
        "Office365_LoginForm_userName_place": "Email or Login",
        "Office365_DataPrivacyConsentNotice": "Data Privacy Consent Notice",
        "Office365_AcceptDpToProceed": "Before you can proceed please accept our ",
        "Office365_Ok": "Ok",
        "Office365_Logout_Modal": "Are you sure you want log out?",
        "ExternalParticipant_DataPrivacy_Text": "Read our",
        "ExternalParticipant_DataPrivacy_Link": "privacy policy",
        "ExternalUnsubscribeTitle": "Are you sure you don't want to participate in any surveys from {CompanyName} company?",
        "ExternalUnsubscribeMessage": "If you opt-out of any survey requests from {CompanyName} company, we'll not be able to invite you again or send you notifications about surveys.",
        "ExternalUnsubscribeBtn": "Opt-out of surveys",
        "ExternalUnsubscribeAction": "I would like to opt-out of any survey requests from {CompanyName} company.",
        "ExternalUnsubscribeSuccessTitle": "You've opted out of any future survey requests from {CompanyName} company.",
        "ExternalUnsubscribeSuccessMessage": "If you want to change your decision at any point in the future, please, get in touch with your contact at {CompanyName} company and send them a request in writing to get your consent updated in beqom.",
        "ExternalDeclineSuccessTitle_Declined": "You have declined the survey request.",
        "ExternalDeclineSuccessMessage": "Thanks for letting us know that you were unable to answer their request at this time.",
        "UpdateApp_Button": "Update beqom",
        "UpdateApp_TopBar_Title": "Update required",
        "MaintenancePage_Title": "We'll be back soon",
        "MaintenancePage_Description": "We are currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.",
        "Teams_WelcomePage_Button_SignIn": "Sign in",
        "Teams_WelcomePage_Label": "CPM by beqom",
        "LoginPage_Moto": "powering performance and growth",
        "LoginPage_BeqomMotto": "to make your people {happy}",
        "LoginPage_BeqomMotto_Happy": "happy",
        "Teams_WelcomePage_Description": "Prepare for a new people-focused era with beqom Continuous Performance Management (CPM), a platform that is human-centric, easy to use, and measurable every step of the way.",
    },
    ru: {
        version_upgrade_title: "Доступна новая версия beqom. Посетите магазин приложений, чтобы скачать обновление.",
        version_upgrade: "Вышла новая версия beqom. Через 3 секунды страница будет обновлена и вы сможете продолжить работу. Спасибо!",
        version_upgrade_app: "",
        user_deactivated_title: "Учетная запись пользователя более не активна",
        user_deactivated: "Ваша учетная запись более не активна. Обратитесь в службу технической поддержки или к системному администратору компании.",
        user_notfound_title: "Эта учетная запись пользователя не найдена в системе beqom",
        user_notfound: "В учетной записи beqom вашей компании нет пользователя с таким адресом электронной почты. Обратитесь в службу технической поддержки или к системному администратору компании.",
        connection_button_reload: "Перезагрузить",
        logIn: "Вход",
        cancel: "Отмена",
        LoginForm_forgotPass: "Установить пароль/забыли свой пароль?",
        LoginForm_logWithAzureAd: "Зайти с учетной записью вашей компании",
        LoginForm_copyright1: "Все права защищены.",
        LoginForm_warning: "Внимание!",
        LoginForm_ProblemWithTandemAccount: "Возникли проблемы со входом в учетную запись beqom, обратитесь в службу технической поддержки или к системному администратору компании.",
        LoginForm_ProblemWithUserCredentials: "Имя пользователя или пароль неправильные.  Повторите попытку.",
        LoginForm_FailedError: "В системе beqomвозникла ошибка. Повторите попытку или обратитесь в службу технической поддержки либо к системному администратору компании.",
        LoginForm_IncorrectCompanyName: "Не удалось найти имя вашей компании. Повторите попытку или обратитесь к системному администратору.",
        LoginForm_ProblemWithYourCompanyLogin: "Возникли проблемы со входом в учетную запись компании. Обратитесь в службу технической поддержки или к системному администратору.",
        LoginForm_EmptyCompanyName: "Укажите название своей компании.",
        LoginForm_passwordResetRequired: "Требуется сброс пароля для текущего пользователя.",
        LoginForm_companyName: "Компания",
        LoginForm_userName: "Имя пользователя",
        LoginForm_password: "Пароль",
        LoginForm_signInCompanyText: "Вы вошли в компанию:",
        continueButton: "Продолжить",
        ChangeCompany: "Введите название компании повторно",
        SettingsDataPrivacyStatement_title: "Положение о конфиденциальности данных",
        SettingsDataPrivacyStatement_popupYes: "Согласиться",
        SettingsDataPrivacyStatement_popupNo: "Отклонить",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Закрыть и выйти",
        SettingsDataPrivacyStatement_Version: "Версия",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Вы уверены, что хотите выйти из системы?",
        ChangePassword_header: "Измените пароль",
        ChangePassword_currentPassword: "текущий пароль",
        ChangePassword_newPassword: "новый пароль",
        ChangePassword_newPasswordRetype: "подтвердите новый пароль",
        PasswordChangeMessage: "Изменение пароля",
        PasswordChange_ok: "Ваш пароль был успешно изменен. Вы будете перенаправлены на страницу входа через 3 секунды.",
        PasswordChange_errNewPasswordsDoNotMatch: "Новые пароли не совпадают.",
        PasswordChange_err: "Возникла проблема с изменением пароля. Повторите попытку.",
        PasswordResetTitle: "Настройка/сброс пароля",
        PasswordResetMessage: "Сброс пароля",
        PasswordReset_ok: "Ссылка для сброса пароля отправлена на вашу электронную почту.",
        PasswordReset_err: "Возникла проблема со сбросом пароля. Повторите попытку.",
        ChangeExpiredPassword_MessageHeader: "Срок действия пароля истек",
        ChangeExpiredPassword_Message: "Срок действия вашего пароля для {0} истек. Смените пароль.",
        ChangePassword_Policy_Header: "Пароль должен соответствовать требованиям: ",
        ChangePassword_Policy_AllowDictionaryWords: "Пароль не может быть словом из словаря",
        ChangePassword_Policy_AllowPasswordAsUserName: "Пароль не должен совпадать с именем учетной записи пользователя",
        ChangePassword_Policy_MaximumLength: "Максимальная длина пароля",
        ChangePassword_Policy_MinimumLength: "Минимальная длина пароля",
        ChangePassword_Policy_RequireDigit: "Должен содержать хотя бы одну цифру",
        ChangePassword_Policy_RequireLowercase: "Должен содержать хотя бы одну строчную букву",
        ChangePassword_Policy_RequireNonAlphanumeric: "Должен содержать хотя бы один неалфавитный и нечисловой символ (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "Должен содержать хотя бы одну заглавную букву",
        ChangePassword_Policy_EnforceHistory_Start: "Предыдущие",
        ChangePassword_Policy_EnforceHistory_End: "пароли нельзя использовать повторно",
        DeclinedDataPrivacy_Message: "Ранее вы отклонили положение о конфиденциальности данных. Чтобы снова активировать свою учетную запись, обратитесь в службу технической поддержки. Вы не сможете пользоваться beqom, пока ваша учетная запись не активирована.",
        DeclinedDataPrivacy_Title: "Требуется повторная активация учетной записи",
        DataPrivacy_EffectiveDate: "Дата вступления в силу",
        no: "Нет",
        yes: "Да",
        SettingsLegal_releaseNumber: "Сборка:",
        SelfInvite_SignedIntoCompany: "Вы вошли в компанию:",
        SelfInvite_Welcome: "Добро пожаловать в beqom!",
        SelfInvite_ThanksForComing: "Спасибо, что присоединились к нашему сообществу обмена отзывами.",
        SelfInvite_SendMailShortly: "Мы настроим для Вас учетную запись и отправим Вам письмо с приглашением в ближайшее время.",
        SelfInvite_Select: "Тем временем просто выберите",
        SelfInvite_ThankYou: "Спасибо!",
        SelfInvite_YouWillReceiveInvite: "Вы получите официальное приглашение в ближайшее время.",
        SelfInvite_ShareLink: "Если Вы хотите пригласить коллегу присоединиться к нам, поделитесь этой ссылкой.",
        SelfInvite_InviteSent: "Ваш запрос на присоединение уже отправлен!",
        SelfInvite_Review: "Вы получите приглашение по электронной почте, как только сможете входить в систему.",
        SelfInvite_CopyToClipboard: "Скопировать ссылку в буфер",
        SelfInvite_JoinNow: "Присоединиться сейчас!",
        LoginForm_CompanyNotFound: "Не удалось найти имя вашей компании. Проверьте правильность написания и повторите попытку. Если вам требуется дополнительная помощь, обратитесь в службу технической поддержки или к системному администратору компании.",
        LoginForm_CompanyName: "Название компании",
        LoginForm_Welcome: "Добро пожаловать!",
        LoginForm_Description: "отзыв, который вдохновляет",
        LoginForm_CompanySubmit: "Ввести",
        LoginForm_GreatSee: "Рады видеть вас",
        LoginForm_logWithTandem: "Войдите, используя учетную запись beqom",
        LoginForm_forgotText: "Забыли пароль или требуется установить начальный пароль? Введите имя пользователя ниже, и мы отправим вам инструкции по электронной почте",
        LoginForm_mailMe: "Пришлите мне ссылку по электронной почте",
        LoginForm_backToLogin: "Вернуться на страницу входа?",
        LoginForm_setMyPassword: "Установить пароль",
        LoginForm_checkMail: "На ваш адрес электронной почты отправлено письмо от beqom с уникальной ссылкой для сброса пароля.",
        LoginForm_checkSpam: "Если вы не обнаружили это письмо в папке входящих сообщений, проверьте папки для спама.",
        LoginForm_dontReceive: "Не получили наше письмо? %s Если оно не будет получено в течение следующих 3 минут, отправьте запрос повторно.",
        LoginForm_mailMeAgain: "Пришлите мне ссылку по электронной почте повторно",
        LoginForm_passwordResetLinkSent_Popup: "Ссылка для сброса пароля отправлена на ваш адрес электронной почты",
        ChangePassword_newPasswordRepeat: "повторно введите новый пароль",
        ChangePassword_submit: "Установить пароль",
        ChangePassword_setMyPasswordSuccess: "Пароль %s установлен",
        ChangePassword_setMyPasswordSuccessDesc: "Пароль успешно изменен.",
        ChangePassword_InvalidLink: "Ссылка для сброса пароля больше не действительна. Запросите новую ссылку",
        ChangeExpiredPassword_HeaderDesc: "Срок действия вашего пароля для %s истек. Измените текущий пароль.",
        SelfInvite_Copied: "Скопировано",
        SelfInvite_requestSent: "Ваш запрос на присоединение уже отправлен",
        SelfInvite_ReviewDesc: "Ваш запрос на доступ к beqom находится на рассмотрении. В ближайшее время вы получите приглашение по электронной почте.",
        Tandem_On_GooglePlay: "beqom в Google Play",
        Tandem_On_iTunes: "beqom в App Store",
        Logout_Expire_Session_Title: "Безопасная авторизация",
        Logout_Expire_Session_Text: "Ваш текущий сеанс завершен. Пожалуйста, авторизуйтесь для продолжения!",
        ApplicationError_Title: "Ошибка приложения",
        SnackBar_ErrorId: "Идентификатор ошибки:",
        SnackBar_Message: "В системе beqom возникла ошибка. Повторите попытку или обратитесь в службу технической поддержки либо к системному администратору своей компании. Скопируйте идентификатор ошибки и другие сведения, чтобы ускорить изучение проблемы.",
        SnackBar_Copy: "Копировать",
        SnackBar_IsCopied: "Идентификатор ошибки скопирован в буфер обмена",
        ResetPassword_AdditionalDescription: "Письмо пользователю {username} отправлено",
        SettingsDataPrivacyStatement_popupText1: "Прежде чем продолжить, пожалуйста, согласитесь с нашими",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Прежде чем продолжить, примите обновленное",
        SettingsDataPrivacyStatement_Read: "Уведомление о согласии с положением о конфиденциальности данных",
        SettingsDataPrivacyStatement_popupText2: "для продолжения",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "для продолжения",
        OnboardingWizard_Next: "Далее",
        OnboardingWizard_Back: "Назад",
        MainMenu_EditProfile: "Редактировать профиль",
        MainMenu_LogOut: "Выход",
        IdentityError_PasswordIsExpired: "Срок действия вашего пароля истек.",
        IdentityError_PasswordMismatch: "Пароли не совпадают.",
        IdentityError_PasswordPolicyNotFound: "Политика паролей не найдена.",
        IdentityError_PasswordRequiresDigit: "Пароль должен содержать цифры.",
        IdentityError_PasswordRequiresLower: "Пароль должен содержать строчные буквы.",
        IdentityError_PasswordRequiresNonAlphanumeric: "Пароль должен содержать алфавитные символы.",
        IdentityError_PasswordRequiresUniqueChars: "Пароль должен содержать уникальные символы.",
        IdentityError_PasswordRequiresUpper: "Пароль должен содержать заглавные буквы.",
        IdentityError_PasswordTooLong: "Пароль слишком длинный.",
        IdentityError_PasswordTooShort: "Пароль слишком короткий.",
        Office365_AppInfo: "Все права защищены. Сборка: ",
        Office365_GoNext: "Далее",
        Office365_LoginForm_Company_not_found: "Введенное название компании недействительно",
        Office365_LoginForm_companyPlaceholder: "Название компании",
        Office365_LoginForm_userName_place: "Электронная почта или имя для входа",
        Office365_DataPrivacyConsentNotice: "Уведомление о согласии с положением о конфиденциальности данных",
        Office365_AcceptDpToProceed: "Прежде чем продолжить, пожалуйста, примите условия следующих документов: ",
        Office365_Ok: "ОК",
        Office365_Logout_Modal: "Вы уверены, что хотите выйти из учетной записи?",
        MaintenancePage_Title: "Работа скоро будет восстановлена",
        MaintenancePage_Description: "В данный момент выполняется плановое обслуживание. В скором времени работа системы будет восстановлена. Благодарим за терпение.",
        ExternalParticipant_DataPrivacy_Text: "Ознакомьтесь с нашей",
        ExternalParticipant_DataPrivacy_Link: "политикой конфиденциальности",
        UpdateApp_Button: "Обновить beqom",
        UpdateApp_TopBar_Title: "Требуется обновление",
        ExternalDeclineSuccessTitle_Declined: "Вы отклонили запрос на участие в исследовании.",
        ExternalDeclineSuccessMessage: "Спасибо, что сообщили нам, что в настоящее время не можете ответить на запрос.",
        ExternalUnsubscribeTitle: "Вы уверены, что не хотите участвовать ни в каких исследованиях от компании {CompanyName}?",
        ExternalUnsubscribeMessage: "Если вы откажетесь от всех запросов на участие в исследовании от компании {CompanyName}, мы не сможем снова пригласить вас или отправить вам уведомления об исследованиях.",
        ExternalUnsubscribeBtn: "Отказаться от участия в исследованиях",
        ExternalUnsubscribeAction: "Я хочу отказаться от всех запросов на участие в исследованиях от компании {CompanyName}.",
        ExternalUnsubscribeSuccessTitle: "Вы отказались от всех будущих запросов на участие в исследованиях от компании {CompanyName}.",
        ExternalUnsubscribeSuccessMessage: "Если в будущем вы захотите изменить свое решение, свяжитесь со своим контактным лицом в компании {CompanyName} и отправьте ему письменный запрос, чтобы ваше согласие было обновлено в beqom.",
        Teams_WelcomePage_Button_SignIn: "Войти в систему",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "для повышения производительности и роста",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Подготовьтесь к новой эпохе, в центре которой стоят люди, с платформой Continuous Performance Management (CPM) от beqom. Она ориентирована на человека, проста в использовании и поддается измерению на каждом этапе пути."
    },
    ja: {
        "version_upgrade_title": "beqom の新しいバージョンをご利用いただけます。ご利用の App ストアからアップグレードを行ってください。",
        "version_upgrade": "beqom の新しいバージョンがリリースされました！あと 3 秒でページが更新され、続行できます。ありがとうございます！",
        "version_upgrade_app": "",
        "cancel": "取り消す",
        "user_deactivated_title": "このユーザーアカウントは無効です。",
        "user_deactivated": "あなたのアカウントは無効です。社内の IT ヘルプデスクか、システム管理者に問い合わせてください。",
        "user_notfound_title": "beqom では、このユーザーアカウントは見つかりませんでした。",
        "user_notfound": "貴社の beqom アカウントでこのメールアドレスのユーザーは見つかりませんでした。社内の IT ヘルプデスクか、システム管理者に問い合わせてください。",
        "connection_button_reload": "再度読み込む",
        "logIn": "ログイン",
        "LoginForm_forgotPass": "初めてのログイン / パスワードを忘れた場合",
        "LoginForm_logWithAzureAd": "会社のアカウントでログインする",
        "LoginForm_copyright1": "All rights reserved.",
        "LoginForm_warning": "警告！",
        "LoginForm_ProblemWithTandemAccount": "お使いの beqom アカウントで問題が発生しました。貴社の IT ヘルプデスクか、システム管理者までお問い合わせください。",
        "LoginForm_ProblemWithUserCredentials": "ユーザー名またはパスワードが正しくありません。もう一度お試しください。",
        "LoginForm_FailedError": "申し訳ございません、beqom でエラーが発生しました。もう一度お試しいただくか、貴社の IT ヘルプデスクか、システム管理者に問い合わせてください。",
        "LoginForm_IncorrectCompanyName": "入力された会社名は見つかりませんでした。もう一度お試しいただくか、システム管理者までお問い合わせください。",
        "LoginForm_ProblemWithYourCompanyLogin": "会社名でログインする際に問題が発生しました。貴社の IT ヘルプデスクか、システム管理者までお問い合わせください。",
        "LoginForm_EmptyCompanyName": "会社名を入力してください。",
        "LoginForm_passwordResetRequired": "現在のユーザーはパスワードの再設定が必要です。",
        "LoginForm_companyName": "会社",
        "LoginForm_userName": "ユーザー名",
        "LoginForm_password": "パスワード",
        "LoginForm_signInCompanyText": "サインイン中の会社:",
        "continueButton": "続行する",
        "ChangeCompany": "会社名を再入力する",
        "SettingsDataPrivacyStatement_title": "データ プライバシー ステートメント",
        "SettingsDataPrivacyStatement_popupYes": "同意する",
        "SettingsDataPrivacyStatement_popupNo": "却下する",
        "SettingsDataPrivacyStatement_popupOk": "OK",
        "SettingsDataPrivacyStatement_CloseAndExit": "閉じてログアウトする",
        "SettingsDataPrivacyStatement_Version": "バージョン",
        "SettingsDataPrivacyStatement_YouwillBeLogouted": "ログアウトしてよろしいですか？",
        "ChangePassword_header": "パスワードを変更する",
        "ChangePassword_currentPassword": "現在のパスワード",
        "ChangePassword_newPassword": "新しいパスワード",
        "ChangePassword_newPasswordRetype": "新しいパスワードの確認",
        "PasswordChangeMessage": "パスワードの変更",
        "PasswordChange_ok": "パスワードが更新されました。3 秒後にログアウトします。",
        "PasswordChange_errNewPasswordsDoNotMatch": "新しいパスワードが一致しません。",
        "PasswordChange_err": "申し訳ございません。パスワードを変更する際に、問題が発生しました。もう一度お試しください。",
        "PasswordResetTitle": "パスワードを設定 / 再設定する",
        "PasswordResetMessage": "パスワードの再設定",
        "PasswordReset_ok": "あなたのメール アドレスにパスワード再設定用のリンクが送信されました。",
        "PasswordReset_err": "申し訳ございません。パスワードをリセットする際に、問題が発生しました。もう一度お試しください。",
        "ChangeExpiredPassword_MessageHeader": "パスワードが期限切れです",
        "ChangeExpiredPassword_Message": "あなたの {0} のパスワードは失効しました。パスワードを変更してください。",
        "ChangePassword_Policy_Header": "パスワードは次の要件を満たす必要があります。 ",
        "ChangePassword_Policy_AllowDictionaryWords": "辞書にある単語は使用できません",
        "ChangePassword_Policy_AllowPasswordAsUserName": "パスワードにユーザー名を含むことはできません",
        "ChangePassword_Policy_MaximumLength": "パスワードの長さ (最大)",
        "ChangePassword_Policy_MinimumLength": "パスワードの長さ (最小)",
        "ChangePassword_Policy_RequireDigit": "少なくとも 1 つの数字を含む",
        "ChangePassword_Policy_RequireLowercase": "少なくとも 1 文字の小文字を含む",
        "ChangePassword_Policy_RequireNonAlphanumeric": "少なくとも 1 つの記号 (!$%@#&^*) を含む",
        "ChangePassword_Policy_RequireUppercase": "少なくとも 1 文字の大文字を含む",
        "ChangePassword_Policy_EnforceHistory_Start": "以前の",
        "ChangePassword_Policy_EnforceHistory_End": "パスワードは使用できません",
        "DeclinedDataPrivacy_Message": "データ プライバシー同意書に同意されていません。アカウントを再度有効にする場合は、貴社の IT ヘルプデスクまでお問い合わせください。アカウントが再度有効になるまで、beqom はご利用いただけません。",
        "DeclinedDataPrivacy_Title": "アカウントを再度有効にする",
        "DataPrivacy_EffectiveDate": "発効日",
        "no": "いいえ",
        "yes": "はい",
        "SettingsLegal_releaseNumber": "ビルド:",
        "SelfInvite_SignedIntoCompany": "サインイン中の会社:",
        "SelfInvite_Welcome": "beqom へようこそ！",
        "SelfInvite_ThanksForComing": "フィードバックコミュニティにご登録いただきありがとうございます。",
        "SelfInvite_SendMailShortly": "準備を整え、まもなく招待メールを送信いたします。",
        "SelfInvite_Select": "以下を選択してください: ",
        "SelfInvite_ThankYou": "ありがとうございます！",
        "SelfInvite_YouWillReceiveInvite": "まもなく正式な招待が届きます。",
        "SelfInvite_ShareLink": "同僚の方で同じく beqom に招待したい方がいらっしゃる場合には、こちらのリンクを共有してください。",
        "SelfInvite_InviteSent": "登録リクエストを送信しました！",
        "SelfInvite_Review": "ログインできるようになるとすぐに、招待メールが届きます。",
        "SelfInvite_CopyToClipboard": "リンクをクリップボードにコピー",
        "SelfInvite_JoinNow": "すぐに登録する",
        "LoginForm_CompanyNotFound": "入力された会社名は見つかりませんでした。スペルが合っていることを確認したうえで、もう一度お試しください。サポートが必要な場合は、社内の IT ヘルプデスクか、システム管理者に問い合わせてください。",
        "LoginForm_CompanyName": "会社名",
        "LoginForm_Welcome": "ようこそ",
        "LoginForm_Description": "インスピレーションを与えるフィードバック",
        "LoginForm_CompanySubmit": "送信",
        "LoginForm_GreatSee": "ようこそ",
        "LoginForm_logWithTandem": "beqom のアカウントを使ってログイン",
        "LoginForm_forgotText": "パスワードを忘れたか、パスワードを初めて設定する場合は、以下にユーザー名を入力してください。手続きに関するメールをお送りします。",
        "LoginForm_mailMe": "リンクをメールで受け取る",
        "LoginForm_backToLogin": "ログイン画面に戻る",
        "LoginForm_setMyPassword": "パスワードの設定",
        "LoginForm_checkMail": "beqom からパスワード リセットのための固有のリンクが示されたメールが届いていることを確認してください。",
        "LoginForm_checkSpam": "受信ボックスに見つからない場合は、迷惑メール フォルダに入っていないか確認してください。",
        "LoginForm_dontReceive": "メールを受け取っていない場合、%s今から 3 分以内に届かない場合は、もう一度リクエストしてください。",
        "LoginForm_mailMeAgain": "リンクを再度メールで受け取る",
        "LoginForm_passwordResetLinkSent_Popup": "あなたのメールアドレス宛てにパスワード リセット メールをお送りしました。",
        "ChangePassword_newPasswordRepeat": "新しいパスワードを再入力",
        "ChangePassword_submit": "パスワードの設定",
        "ChangePassword_setMyPasswordSuccess": "パスワードが設定されました%s",
        "ChangePassword_setMyPasswordSuccessDesc": "パスワードが更新されました。",
        "ChangePassword_InvalidLink": "パスワード リセット用リンクは有効期限が切れています。新規にパスワード リセット メールをリクエストしてください",
        "ChangeExpiredPassword_HeaderDesc": "%s のパスワードの有効期限が切れています。現在のパスワードを変更してください。",
        "SelfInvite_Copied": "コピー",
        "SelfInvite_requestSent": "登録リクエストを送信しました",
        "SelfInvite_ReviewDesc": "beqom へのアクセスリクエストの承認手続きを進めています。近日中に招待メールを送信します。",
        "Tandem_On_GooglePlay": "Google Play の beqom",
        "Tandem_On_iTunes": "App Store の beqom",
        "Logout_Expire_Session_Title": "セキュア ログイン",
        "Logout_Expire_Session_Text": "現在のセッションの期限が切れました。続けるにはログインしてください。",
        "ApplicationError_Title": "アプリケーション エラー",
        "SnackBar_ErrorId": "エラー ID:",
        "SnackBar_Message": "申し訳ございません、beqom でエラーが発生しました。もう一度試してみるか、IT ヘルプデスクまたはシステム管理者に連絡してサポートを受けてください。問題の調査を迅速に行うために、エラー ID を他の詳細情報と一緒にコピーしてください。",
        "SnackBar_Copy": "コピー",
        "SnackBar_IsCopied": "エラー ID がクリップボードにコピーされました",
        "ResetPassword_AdditionalDescription": "ユーザー名 {username} さんにメールを送信しました",
        "SettingsDataPrivacyStatement_popupText1": "以下に同意してください: ",
        "SettingsDataPrivacyStatement_withUpdates_popupText1": "以下の更新後の内容に同意してください: ",
        "SettingsDataPrivacyStatement_Read": "データ プライバシーの通知",
        "SettingsDataPrivacyStatement_popupText2": "続行する",
        "SettingsDataPrivacyStatement_withUpdates_popupText2": "続行する",
        "OnboardingWizard_Next": "次へ",
        "OnboardingWizard_Back": "戻る",
        "MainMenu_EditProfile": "プロフィールを編集する",
        "MainMenu_LogOut": "ログアウト",
        "IdentityError_PasswordIsExpired": "パスワードが期限切れです。",
        "IdentityError_PasswordMismatch": "パスワードが一致しません。",
        "IdentityError_PasswordPolicyNotFound": "パスワード ポリシーが見つかりません。",
        "IdentityError_PasswordRequiresDigit": "パスワードには数字を含む必要があります。",
        "IdentityError_PasswordRequiresLower": "パスワードには小文字を含む必要があります。",
        "IdentityError_PasswordRequiresNonAlphanumeric": "パスワードには記号を含む必要があります。",
        "IdentityError_PasswordRequiresUniqueChars": "パスワードには特殊文字を含む必要があります。",
        "IdentityError_PasswordRequiresUpper": "パスワードには大文字を含む必要があります。",
        "IdentityError_PasswordTooLong": "パスワードが長すぎます。",
        "IdentityError_PasswordTooShort": "パスワードが短すぎます。",
        "Office365_AppInfo": "All rights reserved.ビルド: ",
        "Office365_GoNext": "次へ",
        "Office365_LoginForm_Company_not_found": "申し訳ございませんが、入力された会社名は無効です",
        "Office365_LoginForm_companyPlaceholder": "会社名",
        "Office365_Ok": "OK",
        "Office365_LoginForm_userName_place": "メールまたはログイン",
        "Office365_DataPrivacyConsentNotice": "データ プライバシーへの同意に関する通知",
        "Office365_AcceptDpToProceed": "先に進むには、以下に同意してください: ",
        "Office365_Logout_Modal": "ログアウトしますか？",
        "MaintenancePage_Title": "まもなく戻ります",
        "MaintenancePage_Description": "現在、定期メンテナンス中です。まもなく戻ります。ご協力に感謝します。",
        "ExternalParticipant_DataPrivacy_Text": "当社の",
        "ExternalParticipant_DataPrivacy_Link": "プライバシー ポリシーを読む",
        "UpdateApp_Button": "beqom を更新する",
        "UpdateApp_TopBar_Title": "更新が必要です",
        "ExternalDeclineSuccessTitle_Declined": "調査リクエストを却下しました。",
        "ExternalDeclineSuccessMessage": "今回、リクエストに対応できないことをお知らせいただきありがとうございます。",
        "ExternalUnsubscribeTitle": "{CompanyName} 社の調査への参加を拒否しますか？",
        "ExternalUnsubscribeMessage": "{CompanyName} 社の調査リクエストをオプトアウトすると、今後 beqom はあなたを招待したり、調査に関する通知をあなたに送信したりできなくなります。",
        "ExternalUnsubscribeBtn": "調査のオプトアウト",
        "ExternalUnsubscribeAction": "{CompanyName} 社の調査リクエストをオプトアウトします。",
        "ExternalUnsubscribeSuccessTitle": "{CompanyName} 社の今後のすべての調査リクエストがオプトアウトされました。",
        "ExternalUnsubscribeSuccessMessage": "今後この設定を変更したい場合は、{CompanyName} 社の担当者に連絡して、beqom での同意を更新するよう書面のリクエストを送信してください。",
        "Teams_WelcomePage_Button_SignIn": "サインイン",
        "Teams_WelcomePage_Label": "CPM by beqom",
        "LoginPage_Moto": "実績と成長を促進",
        "LoginPage_BeqomMotto": "to make your people {happy}",
        "LoginPage_BeqomMotto_Happy": "happy",
        "Teams_WelcomePage_Description": "人中心で使いやすく、プロセスの各段階を測定できるプラットフォームである beqom Continuous Performance Management（CPM）で、従業員重視の新しい時代に備えましょう。"
    },
    'zh-cn': {
        version_upgrade_title: "现已推出新版 beqom，请访问您的应用商店进行升级！",
        version_upgrade: "已发布新版 beqom！您的页面将在 3 秒后刷新，之后您可继续使用。谢谢！",
        version_upgrade_app: "",
        cancel: "取消",
        user_deactivated_title: "此用户帐户不再处于活跃状态",
        user_deactivated: "您的帐户不再处于活跃状态。请联系您的内部 IT 帮助台或系统管理员来寻求支持。",
        user_notfound_title: "无法在 beqom 中找到此用户帐户",
        user_notfound: "您的公司 beqom 帐户中无使用此电子邮件地址的用户。请联系您的内部 IT 帮助台或系统管理员来寻求支持。",
        connection_button_reload: "重新加载",
        logIn: "登录",
        LoginForm_forgotPass: "首次登录密码/忘记密码？",
        LoginForm_logWithAzureAd: "使用我的公司登录名登录",
        LoginForm_copyright1: "保留所有权利。",
        LoginForm_warning: "警告！",
        LoginForm_ProblemWithTandemAccount: "您的 beqom 帐户出现一些问题，请联系 IT 帮助台或系统管理员来寻求支持。",
        LoginForm_ProblemWithUserCredentials: "您的用户名或密码不正确。请重试！",
        LoginForm_FailedError: "糟糕！很抱歉，beqom 出现错误。请重试或联系 IT 帮助台或系统管理员来寻求支持。",
        LoginForm_IncorrectCompanyName: "找不到您的公司名称。请重试或联系您的系统管理员。",
        LoginForm_ProblemWithYourCompanyLogin: "您的公司登录名出现一些问题，请联系 IT 帮助台或系统管理员来寻求支持。",
        LoginForm_EmptyCompanyName: "请提供您的公司名称。",
        LoginForm_passwordResetRequired: "当前用户需重置密码。",
        LoginForm_companyName: "公司",
        LoginForm_userName: "用户名",
        LoginForm_password: "密码",
        LoginForm_signInCompanyText: "您已登录以下公司帐户：",
        continueButton: "继续",
        ChangeCompany: "重新输入我的公司名称",
        SettingsDataPrivacyStatement_title: "数据隐私声明",
        SettingsDataPrivacyStatement_popupYes: "接受",
        SettingsDataPrivacyStatement_popupNo: "拒绝",
        SettingsDataPrivacyStatement_popupOk: "确定",
        SettingsDataPrivacyStatement_CloseAndExit: "关闭并注销",
        SettingsDataPrivacyStatement_Version: "版本",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "您将被注销，确定要继续吗？",
        ChangePassword_header: "更改密码",
        ChangePassword_currentPassword: "当前密码",
        ChangePassword_newPassword: "新密码",
        ChangePassword_newPasswordRetype: "确认新密码",
        PasswordChangeMessage: "密码更改",
        PasswordChange_ok: "您已成功更新密码，并将在 3 秒后注销。",
        PasswordChange_errNewPasswordsDoNotMatch: "新密码不相符。",
        PasswordChange_err: "抱歉，更改密码时出现问题。请重试！",
        PasswordResetTitle: "设置/重置密码",
        PasswordResetMessage: "密码重置",
        PasswordReset_ok: "系统现已通过电子邮件向您发送密码重置链接。",
        PasswordReset_err: "抱歉，重置密码时出现问题。请重试！",
        ChangeExpiredPassword_MessageHeader: "密码已过期",
        ChangeExpiredPassword_Message: "您的 {0} 密码已过期。请更改密码。",
        ChangePassword_Policy_Header: "您的密码必须满足以下要求：",
        ChangePassword_Policy_AllowDictionaryWords: "请勿使用字典中的词汇",
        ChangePassword_Policy_AllowPasswordAsUserName: "请勿在密码中使用用户名",
        ChangePassword_Policy_MaximumLength: "最大密码长度为",
        ChangePassword_Policy_MinimumLength: "最小密码长度为",
        ChangePassword_Policy_RequireDigit: "必须至少包含一个数字",
        ChangePassword_Policy_RequireLowercase: "必须至少包含一个小写字母",
        ChangePassword_Policy_RequireNonAlphanumeric: "必须至少包含一个非字母数字字符 (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "必须至少包含一个大写字母",
        ChangePassword_Policy_EnforceHistory_Start: "上一步",
        ChangePassword_Policy_EnforceHistory_End: "请勿使用密码",
        DeclinedDataPrivacy_Message: "您之前曾拒绝接受数据隐私同意声明。如要重新激活您的帐户，请联系 IT 帮助台。在重新激活您的帐户之前，您将无法使用 beqom。",
        DeclinedDataPrivacy_Title: "重新激活您的帐户",
        DataPrivacy_EffectiveDate: "生效日期",
        no: "否",
        yes: "是",
        SettingsLegal_releaseNumber: "版本：",
        SelfInvite_SignedIntoCompany: "您已登录以下公司帐户：",
        SelfInvite_Welcome: "欢迎使用 beqom！",
        SelfInvite_ThanksForComing: "感谢您加入我们的反馈社区。",
        SelfInvite_SendMailShortly: "我们会尽快为您安排好一切，并向您发送电子邮件邀请函。",
        SelfInvite_Select: "同时，只需选择",
        SelfInvite_ThankYou: "谢谢！",
        SelfInvite_YouWillReceiveInvite: "您会很快收到我们的官方邀请。",
        SelfInvite_ShareLink: "如果您也想邀请一名同事加入，只需分享此链接即可。",
        SelfInvite_InviteSent: "您的加入请求现已提交！",
        SelfInvite_Review: "一经登录，您便可立即接收电子邮件邀请函。",
        SelfInvite_CopyToClipboard: "将链接复制到剪贴板",
        SelfInvite_JoinNow: "立即加入！",
        LoginForm_CompanyNotFound: "无法找您的公司名称，请检查拼写并重试。如果您需要获取进一步的支持，请联系 IT 帮助台或系统管理员。",
        LoginForm_CompanyName: "公司名称",
        LoginForm_Welcome: "欢迎！",
        LoginForm_Description: "可以激发灵感的反馈",
        LoginForm_CompanySubmit: "输入",
        LoginForm_GreatSee: "很高兴见到您",
        LoginForm_logWithTandem: "使用您的 beqom 登录名登录",
        LoginForm_forgotText: "忘记密码，或想要设置首次登录密码？在下方输入用户名，然后我们会以电子邮件的形式发送说明",
        LoginForm_mailMe: "以电子邮件的形式向我发送链接",
        LoginForm_backToLogin: "返回至登录页面？",
        LoginForm_setMyPassword: "设置密码",
        LoginForm_checkMail: "请检查邮箱，查看来自 beqom 的电子邮件，其中附有用于重置密码的特殊链接。",
        LoginForm_checkSpam: "如未在收件箱中收到此邮件，请检查您的垃圾邮件文件夹。",
        LoginForm_dontReceive: "没有收到邮件？%s 如果 3 分钟内仍未收到邮件，您可以提出再次发送请求！",
        LoginForm_mailMeAgain: "再次以电子邮件的形式向我发送链接",
        LoginForm_passwordResetLinkSent_Popup: "系统已通过电子邮件向您发送密码重置链接",
        ChangePassword_newPasswordRepeat: "再次输入新密码",
        ChangePassword_submit: "设置密码",
        ChangePassword_setMyPasswordSuccess: "密码已设置完成",
        ChangePassword_setMyPasswordSuccessDesc: "已成功更新密码。",
        ChangePassword_InvalidLink: "密码重置链接已失效。请申请新的链接",
        ChangeExpiredPassword_HeaderDesc: "您的 %s 密码已过期。请更改当前密码。",
        SelfInvite_Copied: "已复制",
        SelfInvite_requestSent: "已发送您的加入请求",
        SelfInvite_ReviewDesc: "正在审核您访问 beqom 的请求。您会很快收到电子邮件邀请函。",
        Tandem_On_GooglePlay: "于 Google Play 下载 beqom",
        Tandem_On_iTunes: "于 App Store 下载 beqom",
        Logout_Expire_Session_Title: "安全登录",
        Logout_Expire_Session_Text: "您的当前会话已过期。请登录以继续！",
        ApplicationError_Title: "应用程序错误",
        SnackBar_ErrorId: "错误 ID：",
        SnackBar_Message: "糟糕！很抱歉，beqom 出现错误。请重试或联系 IT 帮助台或系统管理员来寻求支持。请复制错误 ID 以及其他详细信息，以加快问题调查速度。",
        SnackBar_Copy: "复制",
        SnackBar_IsCopied: "已将错误 ID 复制到剪贴板",
        ResetPassword_AdditionalDescription: "我们刚刚向用户名为 {username} 的用户发送了一封电子邮件",
        SettingsDataPrivacyStatement_popupText1: "请接受",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "请接受已更新内容",
        SettingsDataPrivacyStatement_Read: "数据隐私通知",
        SettingsDataPrivacyStatement_popupText2: "以继续",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "以继续",
        OnboardingWizard_Next: "下一步",
        OnboardingWizard_Back: "返回",
        MainMenu_EditProfile: "编辑个人资料",
        MainMenu_LogOut: "注销",
        IdentityError_PasswordIsExpired: "您的密码已过期。",
        IdentityError_PasswordMismatch: "密码不符。",
        IdentityError_PasswordPolicyNotFound: "找不到密码策略。",
        IdentityError_PasswordRequiresDigit: "密码必须包含数字。",
        IdentityError_PasswordRequiresLower: "密码必须包含小写字母。",
        IdentityError_PasswordRequiresNonAlphanumeric: "密码必须包含非字母数字字符。",
        IdentityError_PasswordRequiresUniqueChars: "密码必须包含特殊字符。",
        IdentityError_PasswordRequiresUpper: "密码必须包含大写字母。",
        IdentityError_PasswordTooLong: "提供的密码过长。",
        IdentityError_PasswordTooShort: "提供的密码过短。",
        Office365_AppInfo: "保留所有权利。版本： ",
        Office365_GoNext: "前往下一步",
        Office365_LoginForm_Company_not_found: "抱歉，您输入的公司名称无效",
        Office365_LoginForm_companyPlaceholder: "公司名称",
        Office365_LoginForm_userName_place: "电子邮件地址或登录名",
        Office365_DataPrivacyConsentNotice: "数据隐私同意通知",
        Office365_AcceptDpToProceed: "在您继续操作之前，请接受我们的 ",
        Office365_Ok: "确定",
        Office365_Logout_Modal: "确定要注销吗？",
        MaintenancePage_Title: "请稍等片刻",
        MaintenancePage_Description: "系统目前正在进行定期维护。请稍等片刻。感谢您耐心等待。",
        ExternalParticipant_DataPrivacy_Text: "阅读我们的",
        ExternalParticipant_DataPrivacy_Link: "隐私政策",
        UpdateApp_Button: "更新 beqom",
        UpdateApp_TopBar_Title: "需要更新",
        ExternalDeclineSuccessTitle_Declined: "您已拒绝问卷调查请求。",
        ExternalDeclineSuccessMessage: "感谢您告知我们您目前无法回复他们的请求。",
        ExternalUnsubscribeTitle: "您确定不想参与 {CompanyName} 公司的任何问卷调查吗？",
        ExternalUnsubscribeMessage: "如果您拒绝 {CompanyName} 公司的所有问卷调查请求，我们将无法再次邀请您或向您发送有关问卷调查的通知。",
        ExternalUnsubscribeBtn: "拒绝问卷调查",
        ExternalUnsubscribeAction: "我要拒绝 {CompanyName} 公司的所有问卷调查请求。",
        ExternalUnsubscribeSuccessTitle: "您已拒绝 {CompanyName} 公司未来的所有问卷调查请求。",
        ExternalUnsubscribeSuccessMessage: "如果您后续想更改此设置，请联系 {CompanyName} 公司的联系人，并向他们发送书面请求，以便在\"beqom\"中同意接收请求。",
        Teams_WelcomePage_Button_SignIn: "登录",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "助力绩效和增长",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "借助以人为核心、易于使用并且全面实现工作流程量化的平台—— beqom Continuous Performance Management（持续绩效管理，简称 CPM，开创一个以人为本的新时代。"
    },
    'zh-hk': {
        version_upgrade_title: "現已推出 beqom 的新版本，請前往應用程式商店以升級！",
        version_upgrade: "經已發佈 beqom 新版本！頁面將在 3 秒後重新整理，之後您便可繼續使用。謝謝！",
        version_upgrade_app: "",
        cancel: "取消",
        user_deactivated_title: "此用戶帳戶不再處於活躍狀態",
        user_deactivated: "您的帳戶不再處於活躍狀態。請聯絡您的內部 IT 支援團隊或系統管理員，以獲取相關協助。",
        user_notfound_title: "找不到用戶帳戶",
        user_notfound: "您的公司 beqom 帳戶沒有任何使用此電郵地址的用戶。請聯絡您的內部 IT 支援團隊或系統管理員，以獲取相關協助。",
        connection_button_reload: "重新載入",
        logIn: "登入",
        LoginForm_forgotPass: "首次密碼/忘記了密碼？",
        LoginForm_logWithAzureAd: "使用我的公司名稱登入",
        LoginForm_copyright1: "保留所有權利。",
        LoginForm_warning: "警告！",
        LoginForm_ProblemWithTandemAccount: "您的 beqom 帳戶出現了一些問題，請聯絡您的內部 IT 支援團隊或系統管理員，以獲取相關協助。",
        LoginForm_ProblemWithUserCredentials: "您的用戶名稱或密碼不正確。請再試一次！",
        LoginForm_FailedError: "糟糕！很抱歉，beqom 系統出現錯誤。請再試一次，或聯絡您的內部 IT 支援團隊或系統管理員，以獲取相關協助。",
        LoginForm_IncorrectCompanyName: "找不到您的公司名稱。請再試一次，或聯絡您的系統管理員。",
        LoginForm_ProblemWithYourCompanyLogin: "您的公司登录名出现一些问题，请联系 IT 帮助台或系统管理员来寻求支持。",
        LoginForm_EmptyCompanyName: "請提供您的公司名稱。",
        LoginForm_passwordResetRequired: "當前用戶已要求重新設定密碼。",
        LoginForm_companyName: "公司",
        LoginForm_userName: "用戶名稱",
        LoginForm_password: "密碼",
        LoginForm_signInCompanyText: "您已登入至以下公司：",
        continueButton: "繼續",
        ChangeCompany: "重新輸入我的公司名稱",
        SettingsDataPrivacyStatement_title: "資料私隱聲明",
        SettingsDataPrivacyStatement_popupYes: "接受",
        SettingsDataPrivacyStatement_popupNo: "拒絕",
        SettingsDataPrivacyStatement_popupOk: "確定",
        SettingsDataPrivacyStatement_CloseAndExit: "關閉並登出",
        SettingsDataPrivacyStatement_Version: "版本",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "您將會登出帳戶，確定要繼續嗎？",
        ChangePassword_header: "更改密碼",
        ChangePassword_currentPassword: "當前密碼",
        ChangePassword_newPassword: "新密碼",
        ChangePassword_newPasswordRetype: "確認新密碼",
        PasswordChangeMessage: "密碼變更",
        PasswordChange_ok: "您的密碼已成功更新，您將在 3 秒後登出帳戶。",
        PasswordChange_errNewPasswordsDoNotMatch: "新密碼不相符。",
        PasswordChange_err: "很抱歉，更改密碼時出現問題。請再試一次！",
        PasswordResetTitle: "設定/重新設定密碼",
        PasswordResetMessage: "重新設定密碼",
        PasswordReset_ok: "系統已向您的電郵地址傳送了重新設定密碼連結。",
        PasswordReset_err: "很抱歉，重設密碼時出現問題。請再試一次！",
        ChangeExpiredPassword_MessageHeader: "密碼已失效",
        ChangeExpiredPassword_Message: "您的 {0} 密碼已失效。請更改密碼。",
        ChangePassword_Policy_Header: "您的密碼必須遵循以下要求： ",
        ChangePassword_Policy_AllowDictionaryWords: "不得使用字典單詞",
        ChangePassword_Policy_AllowPasswordAsUserName: "不得在密碼中使用用戶名稱",
        ChangePassword_Policy_MaximumLength: "密碼長度上限為",
        ChangePassword_Policy_MinimumLength: "密碼長度下限為",
        ChangePassword_Policy_RequireDigit: "必須最少包含一個數字",
        ChangePassword_Policy_RequireLowercase: "必須最少包含一個小楷字母",
        ChangePassword_Policy_RequireNonAlphanumeric: "必須最少包含一個非英數字元 (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "必須最少包含一個大楷字母",
        ChangePassword_Policy_EnforceHistory_Start: "上一步",
        ChangePassword_Policy_EnforceHistory_End: "不得使用此等密碼",
        DeclinedDataPrivacy_Message: "您先前曾拒絕接受資料私隱同意聲明。如要重新啟用您的帳戶，請聯絡科技資訊支援人員。您必須重新啟用帳戶，方可使用 beqom。",
        DeclinedDataPrivacy_Title: "重新啟用您的帳戶",
        DataPrivacy_EffectiveDate: "生效日期",
        no: "否",
        yes: "是",
        SettingsLegal_releaseNumber: "建置：",
        SelfInvite_SignedIntoCompany: "您已登入至以下公司：",
        SelfInvite_Welcome: "歡迎使用 beqom！",
        SelfInvite_ThanksForComing: "感謝您加入我們的 Feedback 社群。",
        SelfInvite_SendMailShortly: "我們會盡快為您設定好一切，並向您傳送一封邀請電郵。",
        SelfInvite_Select: "與此同時，只需選擇",
        SelfInvite_ThankYou: "謝謝！",
        SelfInvite_YouWillReceiveInvite: "您即將收到我們的正式邀請。",
        SelfInvite_ShareLink: "如果您也想邀請同事加入我們，只需分享此連結即可。",
        SelfInvite_InviteSent: "您已成功提交加入要求！",
        SelfInvite_Review: "登入後，您便會收到邀請電郵。",
        SelfInvite_CopyToClipboard: "將連結複製至剪貼板",
        SelfInvite_JoinNow: "立即加入！",
        LoginForm_CompanyNotFound: "無法找您的公司名稱，請檢查拼寫並再試一次。如需進一步協助，請聯絡您的內部 IT 支援團隊或系統管理員。",
        LoginForm_CompanyName: "公司名稱",
        LoginForm_Welcome: "歡迎！",
        LoginForm_Description: "激發靈感的 Feedback",
        LoginForm_CompanySubmit: "輸入",
        LoginForm_GreatSee: "很高興見到您",
        LoginForm_logWithTandem: "使用您的 beqom 登入資料登入",
        LoginForm_forgotText: "忘記了密碼，或想設定首次登入密碼？請在下方輸入用戶名稱，我們便會透過電郵向您傳送相關指示",
        LoginForm_mailMe: "透過電郵向我傳送連結",
        LoginForm_backToLogin: "返回至登入頁面嗎？",
        LoginForm_setMyPassword: "設定密碼",
        LoginForm_checkMail: "請檢查收件匣並查看來自 beqom 的電郵，當中包含用於重設密碼的不重覆連結。",
        LoginForm_checkSpam: "如果未有在收件匣收到此電郵，請檢查您的垃圾郵件資料夾。",
        LoginForm_dontReceive: "沒有收到電郵？ %s 如果 3 分鐘內仍未收到電郵，請再次要求傳送電郵！",
        LoginForm_mailMeAgain: "再次透過電郵向我傳送連結",
        LoginForm_passwordResetLinkSent_Popup: "系統已透過電郵向您傳送密碼重設連結",
        ChangePassword_newPasswordRepeat: "再次輸入新密碼",
        ChangePassword_submit: "設定密碼",
        ChangePassword_setMyPasswordSuccess: "已設定密碼%s",
        ChangePassword_setMyPasswordSuccessDesc: "已成功更新密碼。",
        ChangePassword_InvalidLink: "密碼重設連結已失效。請要求獲取新的連結",
        ChangeExpiredPassword_HeaderDesc: "您的 %s 密碼已過期。請更改當前密碼。",
        SelfInvite_Copied: "已複製",
        SelfInvite_requestSent: "已傳送您的加入要求",
        SelfInvite_ReviewDesc: "系統正在審查您的 beqom 存取要求。您即將會收到邀請電郵。",
        Tandem_On_GooglePlay: "於 Google Play 下載 beqom",
        Tandem_On_iTunes: "於 App Store 下載 beqom",
        Logout_Expire_Session_Title: "安全登入",
        Logout_Expire_Session_Text: "您的當前作業階段已過期。請登入以繼續！",
        ApplicationError_Title: "應用程式錯誤",
        SnackBar_ErrorId: "錯誤 ID：",
        SnackBar_Message: "糟糕！很抱歉，beqom 出現錯誤。請再試一次，或者聯絡您的 IT 支援團隊或系統管理員，以獲取相關協助。請複製錯誤 ID 和其他詳情，以加快問題調查速度。",
        SnackBar_Copy: "複製",
        SnackBar_IsCopied: "已將錯誤 ID 複製到剪貼板",
        ResetPassword_AdditionalDescription: "我們剛剛向以下用戶傳送了一封電郵：{username}",
        SettingsDataPrivacyStatement_popupText1: "請接受",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "請接受已更新的",
        SettingsDataPrivacyStatement_Read: "資料私隱通知",
        SettingsDataPrivacyStatement_popupText2: "以繼續",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "以繼續",
        OnboardingWizard_Next: "下一步",
        OnboardingWizard_Back: "返回",
        MainMenu_EditProfile: "編輯個人檔案",
        MainMenu_LogOut: "登出",
        IdentityError_PasswordIsExpired: "您的密碼已過期。",
        IdentityError_PasswordMismatch: "密碼不符。",
        IdentityError_PasswordPolicyNotFound: "找不到密碼政策。",
        IdentityError_PasswordRequiresDigit: "密碼必須包含數字。",
        IdentityError_PasswordRequiresLower: "密碼必須包含小楷字母。",
        IdentityError_PasswordRequiresNonAlphanumeric: "密碼必須包含非英數字元。",
        IdentityError_PasswordRequiresUniqueChars: "密碼必須包含特殊字元。",
        IdentityError_PasswordRequiresUpper: "密碼必須包含大楷字母。",
        IdentityError_PasswordTooLong: "您提供的密碼過長。",
        IdentityError_PasswordTooShort: "您提供的密碼過短。",
        Office365_AppInfo: "保留所有權利。建置： ",
        Office365_GoNext: "前往下一步",
        Office365_LoginForm_Company_not_found: "很抱歉，您輸入的公司名稱無效",
        Office365_LoginForm_companyPlaceholder: "公司名稱",
        Office365_LoginForm_userName_place: "電郵地址或登入名稱",
        Office365_DataPrivacyConsentNotice: "資料私隱同意通知",
        Office365_AcceptDpToProceed: "繼續使用前，請先接受我們的 ",
        Office365_Ok: "確定",
        Office365_Logout_Modal: "確定要登出嗎？",
        MaintenancePage_Title: "請稍等片刻",
        MaintenancePage_Description: "系統目前正在進行定期維護。請稍等片刻。感謝您耐心等待。",
        ExternalParticipant_DataPrivacy_Text: "閱讀我們的",
        ExternalParticipant_DataPrivacy_Link: "私隱政策",
        UpdateApp_Button: "更新 beqom",
        UpdateApp_TopBar_Title: "需要更新",
        ExternalDeclineSuccessTitle_Declined: "您已拒絕問卷調查要求。",
        ExternalDeclineSuccessMessage: "感謝您告知我們您目前無法回覆他們的要求。",
        ExternalUnsubscribeTitle: "您確定不想參與 {CompanyName} 公司的任何問卷調查嗎？",
        ExternalUnsubscribeMessage: "如果您選擇停止接收 {CompanyName} 公司的所有問卷調查要求，我們將無法再次邀請您或向您傳送有關問卷調查的通知。",
        ExternalUnsubscribeBtn: "停止接收問卷調查要求",
        ExternalUnsubscribeAction: "我要想停止接收 {CompanyName} 公司的所有問卷調查要求。",
        ExternalUnsubscribeSuccessTitle: "您已停止接收 {CompanyName} 公司日後的所有問卷調查要求。",
        ExternalUnsubscribeSuccessMessage: "如果您之後想更改此設定，請聯絡 {CompanyName} 公司的聯絡人，並向其傳送書面要求，以便在\"beqom\"中更新您的同意選項。",
        Teams_WelcomePage_Button_SignIn: "登入",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "推動效能與成長",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "beqom Continuous Performance Management (CPM) 是一個以人為本的平台，簡單易用之餘每一步驟皆可衡量，為以人為本的新時代做好準備。"
    },
    'pt-BR': {
        version_upgrade_title: "Uma nova versão do beqom está disponível, por favor visite sua loja de aplicativos para atualizar.",
        version_upgrade: "Uma nova versão do beqom está disponível. A página será atualizada em 3 segundos, e você poderá continuar. Obrigado!",
        version_upgrade_app: "",
        cancel: "Cancelar",
        user_deactivated_title: "Esta conta de usuário não está mais ativa",
        user_deactivated: "Sua conta não está mais ativa. Entre em contato com o administrador do sistema ou seu helpdesk de TI interno para suporte.",
        user_notfound_title: "A conta de usuário não foi encontrada",
        user_notfound: "Não há um usuário com este endereço de e-mail na conta do beqom da sua empresa. Entre em contato com o administrador do sistema ou seu helpdesk de TI interno para suporte.",
        connection_button_reload: "Recarregar",
        logIn: "Entrar",
        LoginForm_forgotPass: "Primeira senha / Esqueceu a senha?",
        LoginForm_logWithAzureAd: "Entrar com o login da empresa",
        LoginForm_copyright1: "Todos os direitos reservados.",
        LoginForm_warning: "Atenção!",
        LoginForm_ProblemWithTandemAccount: "Houve alguns problemas com sua conta do beqom. Entre em contato com o administrador do sistema ou seu helpdesk de TI para suporte.",
        LoginForm_ProblemWithUserCredentials: "Seu nome de usuário ou sua senha estão incorretos.  Tente novamente.",
        LoginForm_FailedError: "Opa! Algo deu errado no beqom. Tente novamente ou entre em contato com o administrador do sistema ou seu helpdesk de TI interno para suporte.",
        LoginForm_IncorrectCompanyName: "O nome da empresa não foi encontrado. Tente novamente ou entre em contato com o administrador do sistema.",
        LoginForm_ProblemWithYourCompanyLogin: "Houve alguns problemas com o login da sua empresa. Entre em contato com o administrador do sistema ou seu helpdesk de TI para suporte.",
        LoginForm_EmptyCompanyName: "Digite o nome da empresa.",
        LoginForm_passwordResetRequired: "O usuário atual precisa redefinir a senha.",
        LoginForm_companyName: "Empresa",
        LoginForm_userName: "Nome de usuário",
        LoginForm_password: "Senha",
        LoginForm_signInCompanyText: "Você entrou na empresa:",
        continueButton: "Continuar",
        ChangeCompany: "Redigite o nome da empresa",
        SettingsDataPrivacyStatement_title: "Declaração de dados privados",
        SettingsDataPrivacyStatement_popupYes: "Aceitar",
        SettingsDataPrivacyStatement_popupNo: "Recusar",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Fechar e sair",
        SettingsDataPrivacyStatement_Version: "Versão",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Você será desconectado, tem certeza disso?",
        ChangePassword_header: "Alterar senha",
        ChangePassword_currentPassword: "senha atual",
        ChangePassword_newPassword: "nova senha",
        ChangePassword_newPasswordRetype: "confirme a nova senha",
        PasswordChangeMessage: "Alteração de senha",
        PasswordChange_ok: "Sua senha foi atualizada e você será desconectado em 3 segundos.",
        PasswordChange_errNewPasswordsDoNotMatch: "As novas senhas não correspondem.",
        PasswordChange_err: "Houve um problema ao alterar sua senha. Tente novamente.",
        PasswordResetTitle: "Configurar/redefinir senha",
        PasswordResetMessage: "Redefinição de senha",
        PasswordReset_ok: "Um link de redefinição de senha foi enviado ao seu e-mail.",
        PasswordReset_err: "Houve um problema ao redefinir sua senha.  Tente novamente.",
        ChangeExpiredPassword_MessageHeader: "Senha expirada",
        ChangeExpiredPassword_Message: "Sua senha para {0} expirou. Altere sua senha.",
        ChangePassword_Policy_Header: "A senha precisa atender aos seguintes requisitos: ",
        ChangePassword_Policy_AllowDictionaryWords: "Não é permitido usar palavras de dicionário",
        ChangePassword_Policy_AllowPasswordAsUserName: "A senha não pode ser igual ao seu nome de usuário",
        ChangePassword_Policy_MaximumLength: "Comprimento máximo da senha é de",
        ChangePassword_Policy_MinimumLength: "Comprimento mínimo da senha é de",
        ChangePassword_Policy_RequireDigit: "Precisa conter ao menos um número",
        ChangePassword_Policy_RequireLowercase: "Precisa conter ao menos uma letra minúscula",
        ChangePassword_Policy_RequireNonAlphanumeric: "Precisa conter ao menos um caractere não alfanumérico (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "Precisa conter ao menos uma letra maiúscula",
        ChangePassword_Policy_EnforceHistory_Start: "Anterior",
        ChangePassword_Policy_EnforceHistory_End: "senhas não são permitidas",
        DeclinedDataPrivacy_Message: "Você recusou a declaração de consentimento de privacidade de dados anteriormente. Para reativar sua conta, entre em contato com seu helpdesk de TI. Você não poderá usar o beqom até que sua conta seja reativada.",
        DeclinedDataPrivacy_Title: "Reative sua conta",
        DataPrivacy_EffectiveDate: "Data de vigência",
        no: "Não",
        yes: "Sim",
        SettingsLegal_releaseNumber: "Compilação:",
        SelfInvite_SignedIntoCompany: "Você entrou na empresa:",
        SelfInvite_Welcome: "Bem-vindo ao beqom!",
        SelfInvite_ThanksForComing: "Agradecemos sua participação na nossa comunidade de feedback.",
        SelfInvite_SendMailShortly: "Processaremos sua solicitação e enviaremos um convite por e-mail em breve.",
        SelfInvite_Select: "Enquanto isso, selecione",
        SelfInvite_ThankYou: "Obrigado!",
        SelfInvite_YouWillReceiveInvite: "Você receberá o convite oficial em breve.",
        SelfInvite_ShareLink: "Se quiser convidar um colega para participar, basta compartilhar este link com ele.",
        SelfInvite_InviteSent: "Sua solicitação para participar foi enviada.",
        SelfInvite_Review: "Você receberá um convite por e-mail assim que puder fazer login.",
        SelfInvite_CopyToClipboard: "Copiar o link para a área de transferência",
        SelfInvite_JoinNow: "Participar agora!",
        LoginForm_CompanyNotFound: "O nome da empresa não foi encontrado. Verifique se ele foi escrito corretamente e tente outra vez. Se precisar de suporte adicional, entre em contato com o administrador do sistema ou seu helpdesk de TI interno.",
        LoginForm_CompanyName: "Nome da empresa",
        LoginForm_Welcome: "Boas-vindas!",
        LoginForm_Description: "feedback inspirador",
        LoginForm_CompanySubmit: "Inserir",
        LoginForm_GreatSee: "É ótimo ver você de novo",
        LoginForm_logWithTandem: "Entre com seu login do beqom",
        LoginForm_forgotText: "Esqueceu sua senha ou quer configurá-la pela primeira vez? Insira seu nome de usuário abaixo e enviaremos um e-mail com as instruções",
        LoginForm_mailMe: "Quero receber um link por e-mail",
        LoginForm_backToLogin: "Voltar para o login?",
        LoginForm_setMyPassword: "Definir minha senha",
        LoginForm_checkMail: "Confira se você recebeu um e-mail do beqom com um link único para redefinir sua senha.",
        LoginForm_checkSpam: "Caso não receba este e-mail na sua caixa de entrada, verifique suas pastas de mensagens descartadas.",
        LoginForm_dontReceive: "Não recebeu nosso e-mail? %s Se não recebê-lo nos próximos 3 minutos, você poderá solicitá-lo novamente.",
        LoginForm_mailMeAgain: "Reenvie o link por e-mail",
        LoginForm_passwordResetLinkSent_Popup: "Um link de redefinição de senha foi enviado para seu e-mail",
        ChangePassword_newPasswordRepeat: "repita a nova senha",
        ChangePassword_submit: "Configurar minha senha",
        ChangePassword_setMyPasswordSuccess: "A senha já %s foi configurada",
        ChangePassword_setMyPasswordSuccessDesc: "Sua senha foi atualizada.",
        ChangePassword_InvalidLink: "Seu link de redefinição de senha não é mais válido. Solicite um novo link",
        ChangeExpiredPassword_HeaderDesc: "Sua senha para %s expirou. Altere sua senha atual.",
        SelfInvite_Copied: "Copiada.",
        SelfInvite_requestSent: "Sua solicitação para participar foi enviada",
        SelfInvite_ReviewDesc: "Sua solicitação para acessar o beqom está sendo avaliada. Você receberá um convite por e-mail em breve.",
        Tandem_On_GooglePlay: " beqom no Google Play",
        Tandem_On_iTunes: "beqom no App Store",
        Logout_Expire_Session_Title: "Login seguro",
        Logout_Expire_Session_Text: "Sua sessão atual expirou. Faça login para continuar.",
        ApplicationError_Title: "Erro do aplicativo",
        SnackBar_ErrorId: "ID do erro:",
        SnackBar_Message: "Ocorreu algum erro no beqom. Tente novamente ou entre em contato com o administrador do sistema ou seu helpdesk de TI para suporte. Copie o ID do erro juntamente com outros detalhes para agilizar a investigação do problema.",
        SnackBar_Copy: "Copiar",
        SnackBar_IsCopied: "O ID do erro foi copiado para a área de transferência",
        ResetPassword_AdditionalDescription: "Acabamos de enviar um e-mail ao usuário {username}",
        SettingsDataPrivacyStatement_popupText1: "Aceite o",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Aceite a atualização do",
        SettingsDataPrivacyStatement_Read: "Aviso de privacidade de dados",
        SettingsDataPrivacyStatement_popupText2: "para prosseguir",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "para prosseguir",
        OnboardingWizard_Next: "Próximo",
        OnboardingWizard_Back: "Voltar",
        MainMenu_EditProfile: "Editar perfil",
        MainMenu_LogOut: "Sair",
        IdentityError_PasswordMismatch: "Senha incompatível.",
        IdentityError_PasswordPolicyNotFound: "A política de senha não foi encontrada.",
        IdentityError_PasswordRequiresDigit: "A senha deve conter dígitos.",
        IdentityError_PasswordRequiresLower: "A senha deve conter letras minúsculas.",
        IdentityError_PasswordRequiresNonAlphanumeric: "A senha deve conter caracteres não alfanuméricos.",
        IdentityError_PasswordRequiresUniqueChars: "A senha deve conter caracteres exclusivos.",
        IdentityError_PasswordRequiresUpper: "A senha deve que conter letras maiúsculas.",
        IdentityError_PasswordTooLong: "A senha digitada é muito longa.",
        IdentityError_PasswordTooShort: "A senha digitada é muito curta.",
        Office365_AppInfo: "Todos os direitos reservados. Compilação: ",
        Office365_GoNext: "Ir para o próximo",
        Office365_LoginForm_Company_not_found: "O nome da empresa inserido não é válido",
        Office365_LoginForm_companyPlaceholder: "Nome da empresa",
        Office365_LoginForm_userName_place: "E-mail ou Entrar",
        Office365_DataPrivacyConsentNotice: "Aviso de consentimento de privacidade de dados",
        Office365_AcceptDpToProceed: "Antes de prosseguir, aceite nosso ",
        Office365_Logout_Modal: "Tem certeza de que quer sair?",
        Office365_Ok: "Ok",
        MaintenancePage_Title: "Voltaremos em breve",
        MaintenancePage_Description: "Estamos passando por uma manutenção programada. Em breve estaremos de volta. Agradecemos a paciência.",
        ExternalParticipant_DataPrivacy_Text: "Leia nossa",
        ExternalParticipant_DataPrivacy_Link: "política de privacidade",
        UpdateApp_Button: "Atualizar beqom",
        UpdateApp_TopBar_Title: "Atualização obrigatória",
        ExternalDeclineSuccessTitle_Declined: "Você recusou o convite para a pesquisa.",
        ExternalDeclineSuccessMessage: "Agradecemos por nos avisar que você não pode aceitar o convite desta vez.",
        ExternalUnsubscribeTitle: "Tem certeza de que não quer participar de nenhuma pesquisa da empresa {CompanyName}?",
        ExternalUnsubscribeMessage: "Se você decidir não receber pesquisas da {CompanyName}, não poderemos mais convidar você ou enviar notificações sobre pesquisas.",
        ExternalUnsubscribeBtn: "Parar de receber pesquisas",
        ExternalUnsubscribeAction: "Quero parar de receber convites para pesquisas da {CompanyName}.",
        ExternalUnsubscribeSuccessTitle: "Você decidiu parar de receber convites para pesquisas da {CompanyName}.",
        ExternalUnsubscribeSuccessMessage: "Se você quiser alterar essa decisão, entre em contato com a empresa {CompanyName} e envie uma solicitação por escrito para atualizar o consentimento no beqom.",
        Teams_WelcomePage_Button_SignIn: "Faça login",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "potencializando o desempenho e o crescimento",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Prepare-se para uma nova era focada nas pessoas com a beqom Continuous Performance Management (CPM), uma plataforma centrada no ser humano, fácil de usar e mensurável em todas as etapas do caminho."
    },
    de: {
        version_upgrade_title: "Eine neue Version von beqom wurde veröffentlicht, bitte besuche deinen App Store für ein Upgrade.",
        version_upgrade: "Eine neue Version von beqom wurde veröffentlicht. Deine Seite wird in 3 Sekunden neu geladen, danach kannst du fortfahren. Vielen Dank!",
        version_upgrade_app: "",
        cancel: "Abbrechen",
        user_deactivated_title: "Dieses Benutzerkonto ist nicht mehr aktiv",
        user_deactivated: "Dein Konto ist nicht mehr aktiv. Wende dich an den internen IT-Helpdesk oder deinen Systemadministrator.",
        user_notfound_title: "Dieses Benutzerkonto konnte in beqom nicht gefunden werden",
        user_notfound: "In deinem beqom-Firmenkonto gibt es keinen Benutzer mit dieser E-Mail-Adresse. Wende dich an den internen IT-Helpdesk oder deinen Systemadministrator.",
        connection_button_reload: "Neu laden",
        logIn: "Anmeldung",
        LoginForm_forgotPass: "Erstes Passwort / Passwort vergessen?",
        LoginForm_logWithAzureAd: "Melde dich mit dem Firmenlogin an",
        LoginForm_copyright1: "Alle Rechte vorbehalten.",
        LoginForm_warning: "Warnung!",
        LoginForm_ProblemWithTandemAccount: "Es gibt Probleme mit deinem beqom-Konto. Wende dich an deinen IT-Helpdesk oder Systemadministrator.",
        LoginForm_ProblemWithUserCredentials: "Dein Benutzername oder Passwort ist falsch.  Versuche es noch einmal.",
        LoginForm_FailedError: "Hoppla, es tut mir leid, dass in beqom etwas schief gelaufen ist. Bitte versuche es erneut oder wende dich an deinen IT-Helpdesk oder Systemadministrator.",
        LoginForm_IncorrectCompanyName: "Der Firmenname konnte nicht gefunden werden. Bitte versuche es erneut oder wende dich an den Systemadministrator.",
        LoginForm_ProblemWithYourCompanyLogin: "Es gibt Probleme mit deinem Firmen-Login. Wende dich an deinen IT-Helpdesk oder Systemadministrator.",
        LoginForm_EmptyCompanyName: "Bitte gebe deinen Firmennamen an.",
        LoginForm_passwordResetRequired: "Das Zurücksetzen des Passworts ist für den aktuellen Benutzer erforderlich.",
        LoginForm_companyName: "Unternehmen",
        LoginForm_userName: "Benutzername",
        LoginForm_password: "Passwort",
        LoginForm_signInCompanyText: "Du hast dich in der Firma angemeldet:",
        continueButton: "Fortsetzen",
        ChangeCompany: "Gib den Firmennamen erneut ein",
        SettingsDataPrivacyStatement_title: "Datenschutzerklärungen",
        SettingsDataPrivacyStatement_popupYes: "Akzeptieren",
        SettingsDataPrivacyStatement_popupNo: "Ablehnen",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Schließen and abmelden",
        SettingsDataPrivacyStatement_Version: "Ausführung",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Du wirst abgemeldet, bist du sicher?",
        ChangePassword_header: "Passwort ändern",
        ChangePassword_currentPassword: "aktuelles Passwort",
        ChangePassword_newPassword: "neues Passwort",
        ChangePassword_newPasswordRetype: "bestätige neues Passwort",
        PasswordChangeMessage: "Passwortänderung",
        PasswordChange_ok: "Dein Passwort wurde erfolgreich aktualisiert und du wirst in 3 Sekunden abgemeldet.",
        PasswordChange_errNewPasswordsDoNotMatch: "Neue Passwörter stimmen nicht überein.",
        PasswordChange_err: "Leider gibt es beim Ändern deines Passworts ein Problem. Versuche es noch einmal.",
        PasswordResetTitle: "Passwort einrichten / zurücksetzen",
        PasswordResetMessage: "Passwort zurücksetzen",
        PasswordReset_ok: "Ein Link zum Zurücksetzen des Passworts wurde jetzt an deine E-Mail gesendet.",
        PasswordReset_err: "Leider gibt es beim Zurücksetzen deines Passworts ein Problem.  Versuche es noch einmal.",
        ChangeExpiredPassword_MessageHeader: "Passwort abgelaufen",
        ChangeExpiredPassword_Message: "Dein Passwort für {0} ist abgelaufen. Bitte ändere dein Passwort.",
        ChangePassword_Policy_Header: "Passwort muss den Komplexitätsanforderungen entsprechen ",
        ChangePassword_Policy_AllowDictionaryWords: "Wörter aus dem Wörterbuch sind nicht erlaubt",
        ChangePassword_Policy_AllowPasswordAsUserName: "Passwort bezieht sich nicht auf den Benutzernamen",
        ChangePassword_Policy_MaximumLength: "Maximale Passwortlänge ist",
        ChangePassword_Policy_MinimumLength: "Minimale Passwortlänge ist",
        ChangePassword_Policy_RequireDigit: "Das Muss enthält mindestens eine Nummer",
        ChangePassword_Policy_RequireLowercase: "Muss mindestens einen Kleinbuchstaben enthalten",
        ChangePassword_Policy_RequireNonAlphanumeric: "Muss mindestens ein nicht-alphanumerisches Zeichen enthalten (! $% @ # & ^ *)",
        ChangePassword_Policy_RequireUppercase: "Muss mindestens einen Großbuchstaben enthalten",
        ChangePassword_Policy_EnforceHistory_Start: "Bisherige",
        ChangePassword_Policy_EnforceHistory_End: "Passwörter sind nicht erlaubt",
        DeclinedDataPrivacy_Message: "Du hast deine Zustimmung zur Datenschutzerklärung abgelehnt. Wende dich an den IT-Helpdesk, um dein Konto wieder zu aktivieren. Du kannst beqom erst nach der erneuten Aktivierung deines Kontos wieder nutzen.",
        DeclinedDataPrivacy_Title: "Dein Konto wieder aktivieren",
        DataPrivacy_EffectiveDate: "Gültigkeitsdatum",
        no: "Nein",
        yes: "Ja",
        SettingsLegal_releaseNumber: "Build:",
        SelfInvite_SignedIntoCompany: "Du hast dich in der Firma angemeldet:",
        SelfInvite_Welcome: "Willkommen bei beqom!",
        SelfInvite_ThanksForComing: "Vielen Dank, dass du bei unserer Feedback-Community teilnehmen möchtest!",
        SelfInvite_SendMailShortly: "Wir werden alles für dich einrichten und dir in Kürze eine Einladungs-E-Mail senden.",
        SelfInvite_Select: "Wähle in der Zwischenzeit einfach Folgendes aus:",
        SelfInvite_ThankYou: "Vielen Dank!",
        SelfInvite_YouWillReceiveInvite: "Du erhältst in Kürze deine offizielle Einladung.",
        SelfInvite_ShareLink: "Wenn du einen Kollegen einladen möchtest, auch beizutreten, gib einfach diesen Link weiter.",
        SelfInvite_InviteSent: "Deine Anforderung zum Beitreten wurden bereits gesendet.",
        SelfInvite_Review: "Du erhältst eine Einladung per E-Mail, sobald du dich anmelden kannst.",
        SelfInvite_CopyToClipboard: "Link in Zwischenablage kopieren",
        SelfInvite_JoinNow: "Jetzt beitreten!",
        LoginForm_CompanyNotFound: "Dein Firmenname konnte nicht gefunden werden. Überprüfe die Schreibweise und versuche es noch einmal. Wende dich an den internen IT-Helpdesk oder deinen Systemadministrator, wenn du noch weitere Unterstützung benötigst.",
        LoginForm_CompanyName: "Firmenname",
        LoginForm_Welcome: "Herzlich willkommen!",
        LoginForm_Description: "inspirierendes Feedback",
        LoginForm_CompanySubmit: "Eingabe",
        LoginForm_GreatSee: "Toll, dass du hier bist",
        LoginForm_logWithTandem: "Mit deinem beqom-Login anmelden",
        LoginForm_forgotText: "Du hast dein Passwort vergessen oder möchtest zum ersten Mal ein Passwort einrichten? Gib unten deinen Benutzernamen ein und wir senden dir die Anweisungen per E-Mail",
        LoginForm_mailMe: "Link per E-Mail an mich senden",
        LoginForm_backToLogin: "Zurück zum Login?",
        LoginForm_setMyPassword: "Mein Passwort einrichten",
        LoginForm_checkMail: "In deinem Posteingang solltest du eine E-Mail von beqom mit einem eindeutigen Link zum Zurücksetzen deines Passworts finden.",
        LoginForm_checkSpam: "Prüfe andernfalls deinen Spam-Ordner.",
        LoginForm_dontReceive: "Unsere E-Mail ist nicht angekommen? %sSollte sie in den nächsten 3 Minuten nicht bei dir ankommen, kannst du sie erneut anfordern.",
        LoginForm_mailMeAgain: "Link per E-Mail erneut an mich senden",
        LoginForm_passwordResetLinkSent_Popup: "Ein Link zum Zurücksetzen des Passworts wurde an deine E-Mail-Adresse gesendet.",
        ChangePassword_newPasswordRepeat: "neues Passwort wiederholen",
        ChangePassword_submit: "Mein Passwort einrichten",
        ChangePassword_setMyPasswordSuccess: "Passwort %swurde eingerichtet",
        ChangePassword_setMyPasswordSuccessDesc: "Dein Passwort wurde erfolgreich aktualisiert.",
        ChangePassword_InvalidLink: "Der Link zum Zurücksetzen deines Passworts ist nicht mehr gültig. Fordere einen neuen Link an",
        ChangeExpiredPassword_HeaderDesc: "Dein Passwort für %s ist abgelaufen. Bitte ändere dein aktuelles Passwort.",
        SelfInvite_Copied: "Kopiert",
        SelfInvite_requestSent: "Deine Anforderung zum Beitreten wurde bereits gesendet.",
        SelfInvite_ReviewDesc: "Deine Anforderung zum Zugriff auf beqom wird geprüft. Du erhältst in Kürze eine Einladung per E-Mail.",
        Tandem_On_GooglePlay: "beqom bei Google Play",
        Tandem_On_iTunes: "beqom bei App Store",
        Logout_Expire_Session_Title: "Sichere Anmeldung",
        Logout_Expire_Session_Text: "Deine aktuelle Sitzung ist abgelaufen. Melde dich an, um fortzufahren.",
        ApplicationError_Title: "Anwendungsfehler",
        SnackBar_ErrorId: "Fehler-ID",
        SnackBar_Message: "Hoppla, es tut mir leid, dass in beqom etwas schief gelaufen ist. Bitte versuche es erneut oder wende dich an deinen IT-Helpdesk oder Systemadministrator. Bitte kopiere die Fehler-ID zusammen mit weiteren Angaben, damit die Sache schnell untersucht werden kann.",
        SnackBar_Copy: "Kopieren",
        SnackBar_IsCopied: "Fehler-ID wurde in die Zwischenablage kopiert",
        ResetPassword_AdditionalDescription: "Wir haben gerade eine E-Mail an den Benutzer mit folgendem Benutzernamen gesendet: {username}",
        SettingsDataPrivacyStatement_popupText1: "Bitte akzeptiere den",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Bitte akzeptiere den aktualisierten",
        SettingsDataPrivacyStatement_Read: "Datenschutzhinweis,",
        SettingsDataPrivacyStatement_popupText2: "um fortzufahren.",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "um fortzufahren.",
        OnboardingWizard_Next: "Weiter",
        OnboardingWizard_Back: "Zurück",
        MainMenu_EditProfile: "Profil bearbeiten",
        MainMenu_LogOut: "Abmelden",
        IdentityError_PasswordIsExpired: "Dein Passwort ist abgelaufen.",
        IdentityError_PasswordMismatch: "Die Passwörter stimmen nicht überein.",
        IdentityError_PasswordPolicyNotFound: "Passwort-Richtlinie nicht gefunden.",
        IdentityError_PasswordRequiresDigit: "Das Passwort muss Ziffern enthalten.",
        IdentityError_PasswordRequiresLower: "Das Passwort muss untere Buchstaben enthalten.",
        IdentityError_PasswordRequiresNonAlphanumeric: "Das Passwort darf keine alphanumerischen Buchstaben enthalten.",
        IdentityError_PasswordRequiresUniqueChars: "Das Passwort muss eindeutige Zeichen enthalten.",
        IdentityError_PasswordRequiresUpper: "Das Passwort muss Großbuchstaben enthalten.",
        IdentityError_PasswordTooLong: "Das angegebene Passwort ist zu lang.",
        IdentityError_PasswordTooShort: "Das angegebene Passwort ist zu kurz.",
        Office365_AppInfo: "Alle Rechte vorbehalten. Build: ",
        Office365_GoNext: "Weiter",
        Office365_LoginForm_Company_not_found: "Der eingegebene Firmenname ist leider nicht gültig",
        Office365_LoginForm_companyPlaceholder: "Firmenname",
        Office365_LoginForm_userName_place: "E-Mail oder Login",
        Office365_DataPrivacyConsentNotice: "Datenschutzhinweis",
        Office365_AcceptDpToProceed: "Bevor du fortfahren kannst, akzeptiere bitte unsere ",
        Office365_Ok: "OK",
        Office365_Logout_Modal: "Bist du sicher, dass du dich abmelden möchtest?",
        MaintenancePage_Title: "Wir sind bald wieder zurück",
        MaintenancePage_Description: "Zur Zeit werden planmäßige Wartungsarbeiten durchgeführt. Wir werden in Kürze wieder zurück sein. Danke für deine Geduld.",
        ExternalUnsubscribeAction: "Ich möchte mich von allen Umfrageanforderungen des Unternehmens {CompanyName} abmelden.",
        ExternalUnsubscribeSuccessTitle: "Du hast dich von allen Umfrageanforderungen des Unternehmens {CompanyName} abgemeldet.",
        ExternalUnsubscribeSuccessMessage: "Solltest du deine Meinung ändern, wende dich bitte an deinen Kontakt beim Unternehmen {CompanyName} und sende eine schriftliche Anforderung, damit deine Zustimmung in \"beqom\" wieder aufgenommen wird.",
        UpdateApp_Button: "\"beqom\" aktualisieren",
        UpdateApp_TopBar_Title: "Aktualisierung erforderlich",
        ExternalUnsubscribeTitle: "Bist du sicher, dass du nicht an Umfragen des Unternehmens {CompanyName} teilnehmen möchtest?",
        ExternalUnsubscribeMessage: "Wenn du dich von den Umfrageanforderungen des Unternehmens {CompanyName} abmeldest, können wir dich nicht mehr einladen oder dir Benachrichtigungen zu Umfragen schicken.",
        ExternalUnsubscribeBtn: "Von den Umfragen abmelden",
        ExternalDeclineSuccessTitle_Declined: "Du hast die Umfrageanforderung abgelehnt.",
        ExternalDeclineSuccessMessage: "Danke für deine Mitteilung, dass du der Anforderung im Moment nicht nachkommen kannst.",
        ExternalParticipant_DataPrivacy_Text: "Lies unsere",
        ExternalParticipant_DataPrivacy_Link: "Datenschutzerklärung.",
        Teams_WelcomePage_Button_SignIn: "Anmelden",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "Leistung und Wachstum fördern",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Entdecken Sie das neue Continuous Performance Management (CPM) von beqom – eine Plattform mit Fokus auf dem Menschen, die benutzerfreundlich und bei jedem Schritt nachverfolgbar ist."
    },
    fr: {
        version_upgrade_title: "Une nouvelle version de beqom est désormais disponible, veuillez visiter l'App Store pour effectuer la mise à niveau !",
        version_upgrade: "Une nouvelle version de beqom est disponible! Votre page va être actualisée dans 3 secondes et vous allez pouvoir continuer. Merci !",
        version_upgrade_app: "",
        cancel: "Annuler",
        user_deactivated_title: "Ce compte utilisateur n'est plus actif",
        user_deactivated: "Votre compte n'est plus actif. Veuillez contacter votre support informatique interne ou administrateur système pour obtenir de l'aide.",
        user_notfound_title: "Ce compte utilisateur est introuvable dans beqom.",
        user_notfound: "Il n'existe aucun utilisateur associé à cette adresse e-mail dans votre compte d'entreprise beqom. Veuillez contacter votre support informatique interne ou administrateur système pour obtenir de l'aide.",
        connection_button_reload: "Recharger",
        logIn: "Connexion",
        LoginForm_forgotPass: "Premier mot de passe/Mot de passe oublié ?",
        LoginForm_logWithAzureAd: "Se connecter avec l'identifiant de mon entreprise",
        LoginForm_copyright1: "Tous droits réservés.",
        LoginForm_warning: "Attention !",
        LoginForm_ProblemWithTandemAccount: "Votre compte beqom présente des problèmes, veuillez contacter votre support informatique interne ou administrateur système pour obtenir de l'aide.",
        LoginForm_ProblemWithUserCredentials: "Votre nom d'utilisateur ou votre mot de passe est incorrect.  Veuillez réessayer !",
        LoginForm_FailedError: "Oups, un problème est survenu sur beqom. Veuillez réessayer ou contacter votre support informatique ou administrateur système pour obtenir de l'aide.",
        LoginForm_IncorrectCompanyName: "Le nom de votre entreprise n'a pas pu être trouvé. Veuillez réessayer ou contactez votre administrateur système.",
        LoginForm_ProblemWithYourCompanyLogin: "Votre compte d'entreprise présente des problèmes, veuillez contacter votre support informatique interne ou administrateur système pour obtenir de l'aide.",
        LoginForm_EmptyCompanyName: "Veuillez fournir le nom de votre entreprise",
        LoginForm_passwordResetRequired: "Réinitialisation du mot de passe requise pour l'utilisateur actuel.",
        LoginForm_companyName: "Entreprise",
        LoginForm_userName: "Nom d'utilisateur",
        LoginForm_password: "Mot de passe",
        LoginForm_signInCompanyText: "Vous vous êtes connecté(e) en tant qu'entreprise :",
        continueButton: "Continuer",
        ChangeCompany: "Entrer à nouveau le nom de mon entreprise",
        SettingsDataPrivacyStatement_title: "Déclaration de confidentialité des données",
        SettingsDataPrivacyStatement_popupYes: "Accepter",
        SettingsDataPrivacyStatement_popupNo: "Refuser",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Fermer et se déconnecter",
        SettingsDataPrivacyStatement_Version: "Version",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Vous allez être déconnecté, êtes-vous sûr ?",
        ChangePassword_header: "Changer le mot de passe",
        ChangePassword_currentPassword: "mot de passe actuel",
        ChangePassword_newPassword: "nouveau mot de passe",
        ChangePassword_newPasswordRetype: "confirmer le nouveau mot de passe",
        PasswordChangeMessage: "Changement de mot de passe",
        PasswordChange_ok: "Votre mot de passe a été mis à jour avec succès et vous allez être déconnecté dans 3 secondes.",
        PasswordChange_errNewPasswordsDoNotMatch: "Les nouveaux mots de passe ne correspondent pas.",
        PasswordChange_err: "Désolé, un problème est survenu lors de votre changement de mot de passe. Veuillez réessayer !",
        PasswordResetTitle: "Configurer/Réinitialiser le mot de passe",
        PasswordResetMessage: "Réinitialiser le mot de passe",
        PasswordReset_ok: "Un lien de réinitialisation du mot de passe vient d'être envoyé à votre adresse e-mail.",
        PasswordReset_err: "Désolé, un problème est survenu lors de la reconfiguration de votre mot de passe.  Veuillez réessayer !",
        ChangeExpiredPassword_MessageHeader: "Mot de passe expiré",
        ChangeExpiredPassword_Message: "Votre mot de passe pour {0} a expiré. Veuillez changer le mot de passe.",
        ChangePassword_Policy_Header: "Votre mot de passe doit répondre aux exigences suivantes : ",
        ChangePassword_Policy_AllowDictionaryWords: "Les mots du dictionnaire ne sont pas autorisés",
        ChangePassword_Policy_AllowPasswordAsUserName: "Vous ne pouvez pas utiliser votre nom d'utilisateur dans votre mot de passe",
        ChangePassword_Policy_MaximumLength: "La longueur maximale du mot de passe est",
        ChangePassword_Policy_MinimumLength: "La longueur minimale du mot de passe est",
        ChangePassword_Policy_RequireDigit: "Doit contenir au moins un numéro",
        ChangePassword_Policy_RequireLowercase: "Doit contenir au moins une lettre minuscule",
        ChangePassword_Policy_RequireNonAlphanumeric: "Doit contenir au moins un caractère non alphanumérique (! $% @ # & ^ *)",
        ChangePassword_Policy_RequireUppercase: "Doit contenir au moins une lettre majuscule",
        ChangePassword_Policy_EnforceHistory_Start: "Précédent",
        ChangePassword_Policy_EnforceHistory_End: "les mots de passe ne sont pas autorisés",
        DeclinedDataPrivacy_Message: "Vous avez refusé auparavant de consentir à la déclaration de confidentialité des données. Veuillez contacter votre support informatique afin de réactiver votre compte. Vous ne pouvez pas utiliser beqom tant que votre compte n'est pas réactivé.",
        DeclinedDataPrivacy_Title: "Réactiver votre compte",
        DataPrivacy_EffectiveDate: "Date d'effet",
        no: "Non",
        yes: "Oui",
        SettingsLegal_releaseNumber: "Version :",
        SelfInvite_SignedIntoCompany: "Vous vous êtes connecté(e) en tant qu'entreprise :",
        SelfInvite_Welcome: "Bienvenue dans beqom!",
        SelfInvite_ThanksForComing: "Merci d'avoir rejoint notre communauté de feedbacks.",
        SelfInvite_SendMailShortly: "Nous vous aiderons à configurer votre compte et vous enverrons un e-mail d'invitation dans les plus brefs délais.",
        SelfInvite_Select: "En attendant, sélectionnez",
        SelfInvite_ThankYou: "Merci !",
        SelfInvite_YouWillReceiveInvite: "Vous allez recevoir une invitation officielle dans les plus brefs délais.",
        SelfInvite_ShareLink: "Partagez ce lien si vous souhaitez inviter un collègue à vous rejoindre.",
        SelfInvite_InviteSent: "Votre demande d'ajout a déjà été soumise !",
        SelfInvite_Review: "Vous recevrez une invitation par e-mail dès que vous vous serez connecté(e).",
        SelfInvite_CopyToClipboard: "Copier le lien dans le presse-papiers",
        SelfInvite_JoinNow: "Rejoignez-nous dès maintenant !",
        LoginForm_CompanyNotFound: "Le nom de votre entreprise n'a pas pu être trouvé. Veuillez vérifier l'orthographe et réessayer. Si vous avez besoin d'aide supplémentaire, veuillez contacter votre support informatique interne ou administrateur système.",
        LoginForm_CompanyName: "Nom de l'entreprise",
        LoginForm_Welcome: "Bienvenue !",
        LoginForm_Description: "feedback inspirant",
        LoginForm_CompanySubmit: "Entrer",
        LoginForm_GreatSee: "Ravis de vous voir",
        LoginForm_logWithTandem: "Connectez-vous avec votre identifiant beqom",
        LoginForm_forgotText: "Vous avez oublié votre mot de passe ou souhaitez en paramétrer un pour la première fois ? Saisissez votre nom d'utilisateur ci-dessous et nous vous enverrons des instructions par e-mail",
        LoginForm_mailMe: "M'envoyer un lien par e-mail",
        LoginForm_backToLogin: "Retour vers la page de connexion ?",
        LoginForm_setMyPassword: "Configurer mon mot de passe",
        LoginForm_checkMail: "Vérifiez que vous avez reçu un e-mail de beqom contenant un lien unique de réinitialisation de votre mot de passe.",
        LoginForm_checkSpam: "Si vous ne recevez pas cet e-mail dans votre boîte de réception, vérifier votre dossier des courriers indésirables.",
        LoginForm_dontReceive: "Vous n'avez pas reçu notre e-mail ? %s S'il n'est toujours pas arrivé dans les 3 prochaines minutes, vous pouvez en redemander un autre !",
        LoginForm_mailMeAgain: "M'envoyer un nouveau lien par e-mail",
        LoginForm_passwordResetLinkSent_Popup: "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail",
        ChangePassword_newPasswordRepeat: "répéter le nouveau mot de passe",
        ChangePassword_submit: "Configurer mon mot de passe",
        ChangePassword_setMyPasswordSuccess: "Le mot de passe a %s été configuré",
        ChangePassword_setMyPasswordSuccessDesc: "Votre mot de passe a été mis à jour avec succès.",
        ChangePassword_InvalidLink: "Le lien de réinitialisation de votre mot de passe n'est plus valide. Veuillez demander un nouveau lien",
        ChangeExpiredPassword_HeaderDesc: "Votre mot de passe pour %s a expiré. Veuillez changer votre mot de passe actuel.",
        SelfInvite_Copied: "Copié",
        SelfInvite_requestSent: "Votre demande d'ajout a déjà été envoyée",
        SelfInvite_ReviewDesc: "Votre demande d'accès à beqom est en cours d'examen. Vous allez recevoir une invitation par e-mail dans les plus brefs délais.",
        Tandem_On_GooglePlay: "beqom sur Google Play",
        Tandem_On_iTunes: "beqom sur App Store",
        Logout_Expire_Session_Title: "Connexion sécurisée",
        Logout_Expire_Session_Text: "Votre session en cours a expiré. Merci de vous connecter pour continuer !",
        ApplicationError_Title: "Erreur de l'application",
        SnackBar_ErrorId: "ID de l'erreur :",
        SnackBar_Message: "Oups, un problème est survenu sur beqom. Veuillez réessayer ou contacter votre support informatique ou administrateur système pour obtenir de l'aide. Veuillez copier l'ID de l'erreur et fournir d'autres informations afin d'accélérer les recherches concernant ce problème.",
        SnackBar_Copy: "Copier",
        SnackBar_IsCopied: "L'ID de l'erreur a été copiée dans le presse-papiers",
        ResetPassword_AdditionalDescription: "Nous venons d'envoyer un e-mail à l'utilisateur associé au nom d'utilisateur suivant : {username}",
        SettingsDataPrivacyStatement_popupText1: "Veuillez accepter",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Veuillez accepter la mise à jour de",
        SettingsDataPrivacyStatement_Read: "l'Avis de confidentialité des données",
        SettingsDataPrivacyStatement_popupText2: "pour continuer",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "pour continuer",
        OnboardingWizard_Next: "Suivant",
        OnboardingWizard_Back: "Retour",
        MainMenu_EditProfile: "Modifier le profil",
        MainMenu_LogOut: "Déconnexion",
        IdentityError_PasswordIsEmpty: "Le mot de passe ne peut pas être vide !",
        IdentityError_PasswordIsExpired: "Votre mot de passe a expiré.",
        IdentityError_PasswordMismatch: "Mots de passe non correspondants.",
        IdentityError_PasswordPolicyNotFound: "Politique de mot de passe introuvable.",
        IdentityError_PasswordRequiresDigit: "Le mot de passe doit contenir des chiffres.",
        IdentityError_PasswordRequiresLower: "Le mot de passe doit contenir des lettres minuscules.",
        IdentityError_PasswordRequiresNonAlphanumeric: "Le mot de passe doit contenir des lettres non alphanumériques.",
        IdentityError_PasswordRequiresUniqueChars: "Le mot de passe doit contenir des caractères uniques.",
        IdentityError_PasswordRequiresUpper: "Le mot de passe doit contenir des lettres majuscules.",
        IdentityError_PasswordTooLong: "Le mot de passe fourni est trop long.",
        IdentityError_PasswordTooShort: "Le mot de passe fourni est trop court.",
        Office365_AppInfo: "Tous droits réservés. Version : ",
        Office365_GoNext: "Passer à la suite",
        Office365_LoginForm_Company_not_found: "Désolé, le nom d'entreprise que vous avez entré n'est pas valide",
        Office365_LoginForm_companyPlaceholder: "Nom de l'entreprise",
        Office365_LoginForm_userName_place: "E-mail ou connexion",
        Office365_DataPrivacyConsentNotice: "Avis de consentement relatif à la confidentialité des données",
        Office365_AcceptDpToProceed: "Avant de pouvoir continuer, veuillez accepter notre ",
        Office365_Ok: "OK",
        Office365_Logout_Modal: "Êtes-vous sûr de vouloir vous déconnecter ?",
        MaintenancePage_Title: "Nous serons bientôt de retour",
        MaintenancePage_Description: "Nous procédons actuellement à une maintenance planifiée. Nous devrions bientôt être de retour. Merci pour votre patience.",
        ExternalParticipant_DataPrivacy_Text: "Lire notre",
        ExternalParticipant_DataPrivacy_Link: "politique de confidentialité",
        UpdateApp_Button: "Mettre à jour beqom",
        UpdateApp_TopBar_Title: "Mise à jour requise",
        ExternalDeclineSuccessTitle_Declined: "Vous avez refusé la demande de participation au sondage.",
        ExternalUnsubscribeTitle: "Êtes-vous sûr(e) de ne pas vouloir participer aux sondages de l'entreprise {CompanyName} ?",
        ExternalUnsubscribeMessage: "Si vous vous désabonnez des demandes de participation aux sondages de l'entreprise {CompanyName}, nous ne pourrons plus vous inviter ou vous envoyer de notifications concernant les sondages.",
        ExternalUnsubscribeBtn: "Se désabonner des sondages",
        ExternalUnsubscribeAction: "Je souhaite me désabonner des demandes de participation aux sondages de l'entreprise {CompanyName}.",
        ExternalUnsubscribeSuccessTitle: "Vous vous êtes désabonné des futures demandes de participation aux sondages de l'entreprise {CompanyName}.",
        ExternalUnsubscribeSuccessMessage: "Si vous changez d'avis à tout moment, veuillez vous adresser à votre contact au sein de l'entreprise {CompanyName} et lui envoyer par écrit une demande de mise à jour de votre consentement dans beqom.",
        ExternalDeclineSuccessMessage: "Merci de nous avoir informés que vous ne pouviez pas répondre à sa demande dans l'immédiat.",
        Teams_WelcomePage_Button_SignIn: "Se connecter",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "stimuler les performances et la croissance",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Préparez-vous à entrer dans une nouvelle ère centrée sur les personnes avec la Continuous Performance Management (CPM) de beqom, une plateforme centrée sur l'humain, facile à utiliser et mesurable à chaque étape du processus."
    },
    es: {
        version_upgrade_title: "Hay una nueva versión de beqom disponible, visita la tienda de aplicaciones para actualizarla.",
        version_upgrade: "¡Se ha lanzado una nueva versión de beqom! La página se actualizará en 3 segundos y podrás continuar. ¡Gracias!",
        version_upgrade_app: "",
        cancel: "Cancelar",
        user_deactivated_title: "Esta cuenta de usuario ya no está activa",
        user_deactivated: "Tu cuenta ya no está activa. Ponte en contacto con el departamento interno de soporte informático o con el administrador del sistema para solicitar asistencia.",
        user_notfound_title: "No se pudo encontrar esta cuenta de usuario en beqom",
        user_notfound: "No hay ningún usuario con esta dirección de correo electrónico en tu cuenta de empresa de beqom. Ponte en contacto con el departamento interno de soporte informático o con el administrador del sistema para solicitar asistencia.",
        connection_button_reload: "Volver a cargar",
        logIn: "Iniciar sesión",
        LoginForm_forgotPass: "Contraseña por primera vez/¿has olvidado tu contraseña?",
        LoginForm_logWithAzureAd: "Iniciar sesión con las credenciales de mi empresa",
        LoginForm_copyright1: "Todos los derechos reservados.",
        LoginForm_warning: "¡Advertencia!",
        LoginForm_ProblemWithTandemAccount: "Hay algunos problemas con tu cuenta de beqom; ponte en contacto el departamento de soporte informático o con el administrador del sistema para solicitar asistencia.",
        LoginForm_ProblemWithUserCredentials: "Nombre de usuario o contraseña incorrectos.  Vuelve a intentarlo.",
        LoginForm_FailedError: "¡Vaya! Se ha producido un error en beqom. Vuelve a intentarlo o ponte en contacto con el departamento de soporte informático o con el administrador del sistema para solicitar asistencia.",
        LoginForm_IncorrectCompanyName: "No se pudo encontrar el nombre de tu empresa. Inténtalo de nuevo o ponte en contacto con tu administrador del sistema.",
        LoginForm_ProblemWithYourCompanyLogin: "Hay algunos problemas con las credenciales de tu empresa; ponte en contacto el departamento de soporte informático o con el administrador del sistema para solicitar asistencia.",
        LoginForm_EmptyCompanyName: "Por favor, introduce el nombre de tu empresa.",
        LoginForm_passwordResetRequired: "Restablecimiento de contraseña requerido para el usuario actual.",
        LoginForm_companyName: "Empresa",
        LoginForm_userName: "Nombre de usuario",
        LoginForm_password: "Contraseña",
        LoginForm_signInCompanyText: "Has iniciado sesión en la empresa:",
        continueButton: "Continuar",
        ChangeCompany: "Volver a introducir el nombre de mi empresa",
        SettingsDataPrivacyStatement_title: "Declaración de protección de datos",
        SettingsDataPrivacyStatement_popupYes: "Aceptar",
        SettingsDataPrivacyStatement_popupNo: "Rechazar",
        SettingsDataPrivacyStatement_popupOk: "Aceptar",
        SettingsDataPrivacyStatement_CloseAndExit: "Cerrar y cerrar sesión",
        SettingsDataPrivacyStatement_Version: "Versión",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Se cerrará la sesión, ¿estás seguro?",
        ChangePassword_header: "Cambiar contraseña",
        ChangePassword_currentPassword: "contraseña actual",
        ChangePassword_newPassword: "nueva contraseña",
        ChangePassword_newPasswordRetype: "confirmar nueva contraseña",
        PasswordChangeMessage: "Cambio de contraseña",
        PasswordChange_ok: "Tu contraseña se ha actualizado correctamente. La sesión se cerrará en 3 segundos.",
        PasswordChange_errNewPasswordsDoNotMatch: "Las nuevas contraseñas no coinciden.",
        PasswordChange_err: "Lo sentimos, no podemos cambiar la contraseña. Vuelve a intentarlo.",
        PasswordResetTitle: "Configurar/restablecer la contraseña",
        PasswordResetMessage: "Restablecimiento de contraseña",
        PasswordReset_ok: "El enlace para el restablecimiento de la contraseña ha sido enviado a tu correo electrónico.",
        PasswordReset_err: "Lo sentimos, no podemos restablecer la contraseña.  Vuelve a intentarlo.",
        ChangeExpiredPassword_MessageHeader: "La contraseña expiró",
        ChangeExpiredPassword_Message: "Tu contraseña de {0} ha caducado. Por favor, cambia tu contraseña.",
        ChangePassword_Policy_Header: "La contraseña debe cumplir los siguientes requisitos: ",
        ChangePassword_Policy_AllowDictionaryWords: "Las palabras que aparecen en el diccionario no están permitidas",
        ChangePassword_Policy_AllowPasswordAsUserName: "No puedes usar tu nombre de usuario en la contraseña",
        ChangePassword_Policy_MaximumLength: "La longitud máxima de la contraseña es",
        ChangePassword_Policy_MinimumLength: "La longitud mínima de la contraseña es",
        ChangePassword_Policy_RequireDigit: "Debe contener al menos un número",
        ChangePassword_Policy_RequireLowercase: "Debe contener al menos una letra minúscula",
        ChangePassword_Policy_RequireNonAlphanumeric: "Debe contener al menos un carácter no alfanumérico (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "Debe contener al menos una letra mayúscula",
        ChangePassword_Policy_EnforceHistory_Start: "Anterior",
        ChangePassword_Policy_EnforceHistory_End: "las contraseñas no están permitidas",
        DeclinedDataPrivacy_Message: "Anteriormente rechazaste la declaración de consentimiento de protección de datos. Para volver a activar tu cuenta, ponte en contacto con el departamento de soporte informático. No podrás usar beqom hasta que se vuelva a activar tu cuenta.",
        DeclinedDataPrivacy_Title: "Vuelve a activar tu cuenta",
        DataPrivacy_EffectiveDate: "Fecha de entrada en vigor",
        no: "No",
        yes: "Sí",
        SettingsLegal_releaseNumber: "Compilación:",
        SelfInvite_SignedIntoCompany: "Has iniciado sesión en la empresa:",
        SelfInvite_Welcome: "¡Bienvenido a beqom!",
        SelfInvite_ThanksForComing: "Gracias por tu interés en participar en nuestra comunidad de feedback.",
        SelfInvite_SendMailShortly: "Lo configuraremos todo y te enviaremos un correo electrónico de invitación dentro de poco.",
        SelfInvite_Select: "Mientras tanto, solo tienes que seleccionar",
        SelfInvite_ThankYou: "¡Gracias!",
        SelfInvite_YouWillReceiveInvite: "Muy pronto recibirás tu invitación oficial.",
        SelfInvite_ShareLink: "Si quieres invitar a un compañero para que también participe, solo tienes que compartir este enlace.",
        SelfInvite_InviteSent: "Tu solicitud para participar ya se ha enviado.",
        SelfInvite_Review: "Recibirás un correo electrónico de invitación en cuanto puedas iniciar sesión.",
        SelfInvite_CopyToClipboard: "Copiar enlace en el portapapeles",
        SelfInvite_JoinNow: "¡Participa!",
        LoginForm_CompanyNotFound: "No hemos podido encontrar el nombre de tu empresa. Comprueba que esté bien escrito y vuelve a intentarlo. Si necesitas más asistencia, ponte en contacto con el departamento interno de soporte informático o con el administrador del sistema.",
        LoginForm_CompanyName: "Nombre de la empresa",
        LoginForm_Welcome: "Te damos la bienvenida.",
        LoginForm_Description: "feedback que inspira",
        LoginForm_CompanySubmit: "Entrar",
        LoginForm_GreatSee: "Nos alegramos de verte",
        LoginForm_logWithTandem: "Iniciar sesión con tus credenciales de beqom",
        LoginForm_forgotText: "¿Has olvidado la contraseña y deseas crear una nueva? Introduce tu nombre de usuario a continuación y te enviaremos un correo electrónico con instrucciones",
        LoginForm_mailMe: "Enviarme un enlace por correo electrónico",
        LoginForm_backToLogin: "¿Quieres volver al inicio de sesión?",
        LoginForm_setMyPassword: "Crear la contraseña",
        LoginForm_checkMail: "Comprueba si has recibido un correo electrónico de beqom con un enlace exclusivo para restablecer la contraseña.",
        LoginForm_checkSpam: "Si no recibes este correo en tu bandeja de entrada, revisa también las carpetas de correo no deseado.",
        LoginForm_dontReceive: "¿No has recibido nuestro correo electrónico? %s Si no te llega en los próximos 3 minutos, puedes volver a solicitarlo.",
        LoginForm_mailMeAgain: "Volver a enviarme un enlace por correo electrónico",
        LoginForm_passwordResetLinkSent_Popup: "Te hemos enviado a tu correo electrónico el enlace para restablecer la contraseña",
        ChangePassword_newPasswordRepeat: "repetir nueva contraseña",
        ChangePassword_submit: "Configurar mi contraseña",
        ChangePassword_setMyPasswordSuccess: "Se ha %s configurado la contraseña",
        ChangePassword_setMyPasswordSuccessDesc: "u contraseña se ha actualizado correctamente.",
        ChangePassword_InvalidLink: "Tu enlace para restablecer la contraseña ya no es válido. Solicita un enlace nuevo",
        ChangeExpiredPassword_HeaderDesc: "Tu contraseña de %s ha caducado. Por favor, cambia tu contraseña actual.",
        SelfInvite_Copied: "Copiado",
        SelfInvite_requestSent: "Tu solicitud para participar ya se ha enviado",
        SelfInvite_ReviewDesc: "Tu solicitud de acceso a beqom está siendo revisada. Muy pronto recibirás una invitación por correo electrónico.",
        Tandem_On_GooglePlay: "beqom en Google Play",
        Tandem_On_iTunes: "beqom en App Store",
        Logout_Expire_Session_Title: "Inicio de sesión seguro",
        Logout_Expire_Session_Text: "Tu sesión actual ha caducado. Por favor, inicia sesión para continuar.",
        ApplicationError_Title: "Error de aplicación",
        SnackBar_ErrorId: "ID de error:",
        SnackBar_Message: "¡Vaya! Se ha producido un error en beqom. Vuelve a intentarlo o ponte en contacto con el departamento de soporte informático o con el administrador del sistema para solicitar asistencia. Copia el ID de error y demás detalles para agilizar la investigación del caso.",
        SnackBar_Copy: "Copiar",
        SnackBar_IsCopied: "El ID de error se ha copiado en el portapapeles",
        ResetPassword_AdditionalDescription: "Hemos enviado un correo electrónico al usuario con el nombre de usuario: {username}",
        SettingsDataPrivacyStatement_popupText1: "Acepta el",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Acepta el nuevo",
        SettingsDataPrivacyStatement_Read: "Aviso de protección de datos",
        SettingsDataPrivacyStatement_popupText2: "para continuar",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "para continuar",
        OnboardingWizard_Next: "Siguiente",
        OnboardingWizard_Back: "Atrás",
        MainMenu_EditProfile: "Editar perfil",
        MainMenu_LogOut: "Cerrar sesión",
        IdentityError_PasswordIsExpired: "Tu contraseña ha expirado.",
        IdentityError_PasswordMismatch: "La contraseña no coincide.",
        IdentityError_PasswordPolicyNotFound: "Política de contraseñas no encontrada.",
        IdentityError_PasswordRequiresDigit: "La contraseña debe contener dígitos.",
        IdentityError_PasswordRequiresLower: "La contraseña debe contener letras minúsculas.",
        IdentityError_PasswordRequiresNonAlphanumeric: "La contraseña debe contener caracteres no alfanuméricos.",
        IdentityError_PasswordRequiresUniqueChars: "La contraseña debe contener caracteres únicos.",
        IdentityError_PasswordRequiresUpper: "La contraseña debe contener letras mayúsculas.",
        IdentityError_PasswordTooLong: "La contraseña proporcionada es demasiado larga.",
        IdentityError_PasswordTooShort: "La contraseña proporcionada es demasiado corta.",
        Office365_AppInfo: "Todos los derechos reservados. Compilación: ",
        Office365_GoNext: "Ir al siguiente",
        Office365_LoginForm_Company_not_found: "Lo sentimos, el nombre de la empresa que has introducido no es válido",
        Office365_LoginForm_companyPlaceholder: "Nombre de la empresa",
        Office365_LoginForm_userName_place: "Correo electrónico o credenciales",
        Office365_DataPrivacyConsentNotice: "Aviso de consentimiento de protección de datos",
        Office365_AcceptDpToProceed: "Antes de continuar, acepta nuestra ",
        Office365_Ok: "Aceptar",
        Office365_Logout_Modal: "¿Seguro que quieres cerrar sesión?",
        MaintenancePage_Title: "El servicio volverá a estar disponible pronto",
        MaintenancePage_Description: "Estamos llevando a cabo un mantenimiento programado. El servicio volverá a estar disponible pronto. Gracias por tu paciencia.",
        UpdateApp_Button: "Actualizar beqom",
        UpdateApp_TopBar_Title: "Actualización necesaria",
        ExternalUnsubscribeTitle: "¿Seguro que no quieres participar en las encuestas de la empresa {CompanyName}?",
        ExternalUnsubscribeMessage: "Si decides no participar en las encuestas de la empresa {CompanyName}, no podremos volver a invitarte ni enviarte notificaciones sobre encuestas.",
        ExternalUnsubscribeBtn: "No participar en las encuestas",
        ExternalUnsubscribeAction: "Me gustaría no recibir solicitudes de encuestas de la empresa {CompanyName}.",
        ExternalUnsubscribeSuccessTitle: "Has decidido no participar en futuras solicitudes de encuestas de la empresa {CompanyName}.",
        ExternalUnsubscribeSuccessMessage: "Si quieres cambiar tu decisión en cualquier momento, ponte en contacto con la empresa {CompanyName} y envíales una solicitud por escrito para que se actualice tu consentimiento en beqom.",
        ExternalDeclineSuccessMessage: "Gracias por avisarnos de que no puedes responder a la solicitud en este momento.",
        ExternalDeclineSuccessTitle_Declined: "Has rechazado la solicitud de encuesta.",
        ExternalParticipant_DataPrivacy_Text: "Lee nuestra",
        ExternalParticipant_DataPrivacy_Link: "política de privacidad",
        Teams_WelcomePage_Button_SignIn: "Iniciar sesión",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "fomento del rendimiento y el crecimiento",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Prepárese para una nueva era centrada en las personas con la plataforma Continuous Performance Management (CPM) de beqom, una herramienta fácil de usar que pone el foco en las personas y permite analizar cada paso del proceso."
    },
    th: {
        version_upgrade_title: "beqom เวอร์ชันใหม่พร้อมให้ใช้งานแล้ว โปรดไปที่ App Store เพื่ออัปเกรด!",
        version_upgrade: "เราเปิดตัว beqom เวอร์ชันใหม่แล้ว! หน้าของคุณจะรีเฟรชใน 3 วินาทีและคุณจะดำเนินการต่อได้ ขอขอบคุณ!",
        version_upgrade_app: "",
        cancel: "ยกเลิก",
        user_deactivated_title: "บัญชีผู้ใช้นี้ไม่ได้ใช้งานอีกต่อไป",
        user_deactivated: "บัญชีของคุณไม่ได้ใช้งานอีกต่อไป โปรดติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบภายในของคุณเพื่อขอความช่วยเหลือ",
        user_notfound_title: "ไม่พบบัญชีผู้ใช้นี้ใน beqom",
        user_notfound: "ไม่มีผู้ใช้ที่มีที่อยู่อีเมลนี้ในบัญชี beqom ของบริษัท โปรดติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบภายในของคุณเพื่อขอความช่วยเหลือ",
        connection_button_reload: "โหลดใหม่",
        logIn: "เข้าสู่ระบบ",
        LoginForm_forgotPass: "รหัสผ่านครั้งแรก/ลืมรหัสผ่านใช่หรือไม่",
        LoginForm_logWithAzureAd: "ลงชื่อเข้าสู่ระบบโดยใช้การเข้าสู่ระบบของบริษัท",
        LoginForm_copyright1: "ขอสงวนลิขสิทธิ์",
        LoginForm_warning: "คำเตือน!",
        LoginForm_ProblemWithTandemAccount: "มีปัญหาบางอย่างเกี่ยวกับบัญชี beqom ของคุณ โปรดติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบของคุณเพื่อขอความช่วยเหลือ",
        LoginForm_ProblemWithUserCredentials: "ชื่อผู้ใช้หรือรหัสผ่านของคุณไม่ถูกต้อง  โปรดลองใหม่อีกครั้ง!",
        LoginForm_FailedError: "โอ้ ขออภัย มีข้อผิดพลาดเกิดขึ้นใน beqom โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบของคุณเพื่อขอรับความช่วยเหลือ",
        LoginForm_IncorrectCompanyName: "ไม่พบชื่อบริษัทของคุณ โปรดลองใหม่อีกครั้งหรือติดต่อผู้ดูแลระบบของคุณ",
        LoginForm_ProblemWithYourCompanyLogin: "มีปัญหาบางอย่างเกี่ยวกับการเข้าสู่ระบบของบริษัท โปรดติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบของคุณเพื่อขอความช่วยเหลือ",
        LoginForm_EmptyCompanyName: "โปรดระบุชื่อบริษัทของคุณ",
        LoginForm_passwordResetRequired: "ต้องรีเซ็ตรหัสผ่านของผู้ใช้รายปัจจุบัน",
        LoginForm_companyName: "บริษัท",
        LoginForm_userName: "ชื่อผู้ใช้",
        LoginForm_password: "รหัสผ่าน",
        LoginForm_signInCompanyText: "คุณลงชื่อเข้าใช้ในบริษัทแล้ว:",
        continueButton: "ดำเนินการต่อ",
        ChangeCompany: "กรอกชื่อบริษัทของฉันอีกครั้ง",
        SettingsDataPrivacyStatement_title: "คำชี้แจงเกี่ยวกับความเป็นส่วนตัวของข้อมูล",
        SettingsDataPrivacyStatement_popupYes: "ยอมรับ",
        SettingsDataPrivacyStatement_popupNo: "ปฏิเสธ",
        SettingsDataPrivacyStatement_popupOk: "ตกลง",
        SettingsDataPrivacyStatement_CloseAndExit: "ปิดและออกจากระบบ",
        SettingsDataPrivacyStatement_Version: "เวอร์ชัน",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "คุณจะออกจากระบบ คุณแน่ใจใช่หรือไม่",
        ChangePassword_header: "เปลี่ยนรหัสผ่าน",
        ChangePassword_currentPassword: "รหัสผ่านปัจจุบัน",
        ChangePassword_newPassword: "รหัสผ่านใหม่",
        ChangePassword_newPasswordRetype: "ยืนยันรหัสผ่านใหม่",
        PasswordChangeMessage: "การเปลี่ยนรหัสผ่าน",
        PasswordChange_ok: "อัปเดตรหัสผ่านของคุณเรียบร้อยแล้วและคุณจะออกจากระบบภายใน 3 วินาที",
        PasswordChange_errNewPasswordsDoNotMatch: "รหัสผ่านใหม่ไม่ตรงกัน",
        PasswordChange_err: "ขออภัย มีปัญหาในการเปลี่ยนรหัสผ่านของคุณ โปรดลองใหม่อีกครั้ง!",
        PasswordResetTitle: "ตั้งค่า/รีเซ็ตรหัสผ่าน",
        PasswordResetMessage: "การรีเซ็ตรหัสผ่าน",
        PasswordReset_ok: "ระบบส่งลิงก์รีเซ็ตรหัสผ่านให้คุณทางอีเมลแล้วตอนนี้",
        PasswordReset_err: "ขออภัย มีปัญหาในการรีเซ็ตรหัสผ่านของคุณ  โปรดลองใหม่อีกครั้ง!",
        ChangeExpiredPassword_MessageHeader: "รหัสผ่านหมดอายุแล้ว",
        ChangeExpiredPassword_Message: "รหัสผ่านสำหรับ {0} ของคุณหมดอายุแล้ว โปรดเปลี่ยนรหัสผ่านของคุณ",
        ChangePassword_Policy_Header: "รหัสผ่านของคุณต้องตรงกับข้อกำหนดต่อไปนี้ ",
        ChangePassword_Policy_AllowDictionaryWords: "ไม่อนุญาตให้ใช้คำศัพท์ในพจนานุกรม",
        ChangePassword_Policy_AllowPasswordAsUserName: "คุณไม่สามารถใช้ชื่อผู้ใช้ในรหัสผ่านของคุณได้",
        ChangePassword_Policy_MaximumLength: "ความยาวสูงสุดสำหรับรหัสผ่านคือ",
        ChangePassword_Policy_MinimumLength: "ความยาวขั้นต่ำสำหรับรหัสผ่านคือ",
        ChangePassword_Policy_RequireDigit: "ต้องมีตัวเลขอย่างน้อยหนึ่งตัว",
        ChangePassword_Policy_RequireLowercase: "ต้องมีตัวพิมพ์เล็กอย่างน้อยหนึ่งตัว",
        ChangePassword_Policy_RequireNonAlphanumeric: "ต้องมีอักขระที่ไม่ใช่ตัวอักษรและตัวเลขอย่างน้อยหนึ่งตัว (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "ต้องมีตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัว",
        ChangePassword_Policy_EnforceHistory_Start: "ก่อนหน้านี้",
        ChangePassword_Policy_EnforceHistory_End: "ไม่อนุญาตให้ใช้รหัสผ่านนี้",
        DeclinedDataPrivacy_Message: "คุณได้ปฏิเสธคำชี้แจงเกี่ยวกับการยินยอมความเป็นส่วนตัวของข้อมูลไปแล้วก่อนหน้านี้ หากต้องการเปิดใช้งานบัญชีของคุณอีกครั้ง โปรดติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT ของคุณ คุณจะไม่สามารถใช้ beqom ได้จนกว่าบัญชีของคุณจะเปิดใช้งานอีกครั้ง",
        DeclinedDataPrivacy_Title: "เปิดใช้งานบัญชีของคุณอีกครั้ง",
        DataPrivacy_EffectiveDate: "วันที่มีผล",
        no: "ไม่",
        yes: "ใช่",
        SettingsLegal_releaseNumber: "เวอร์ชัน:",
        SelfInvite_SignedIntoCompany: "คุณลงชื่อเข้าใช้ในบริษัทแล้ว:",
        SelfInvite_Welcome: "ขอต้อนรับสู่ beqom!",
        SelfInvite_ThanksForComing: "ขอขอบคุณสำหรับการเข้าร่วมชุมชน Feedback",
        SelfInvite_SendMailShortly: "เราจะช่วยคุณตั้งค่าและส่งอีเมลคำเชิญในอีกไม่ช้า",
        SelfInvite_Select: "ในระหว่างนี้ แค่เลือก",
        SelfInvite_ThankYou: "ขอขอบคุณ!",
        SelfInvite_YouWillReceiveInvite: "คุณจะได้รับคำเชิญอย่างเป็นทางการในอีกไม่ช้า",
        SelfInvite_ShareLink: "หากคุณต้องการเชิญเพื่อนร่วมงานให้เข้าร่วมเช่นกัน เพียงแค่แบ่งปันลิงก์นี้เท่านั้น",
        SelfInvite_InviteSent: "ส่งคำขอเข้าร่วมของคุณแล้ว!",
        SelfInvite_Review: "คุณจะได้รับคำเชิญผ่านอีเมลทันทีที่คุณเข้าสู่ระบบ",
        SelfInvite_CopyToClipboard: "คัดลอกลิงก์ไปยังคลิปบอร์ด",
        SelfInvite_JoinNow: "เข้าร่วมเลยตอนนี้!",
        LoginForm_CompanyNotFound: "ไม่พบชื่อบริษัทของคุณ โปรดตรวจสอบการสะกดแล้วลองอีกครั้ง หากคุณต้องการความช่วยเหลือเพิ่มเติม โปรดติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบภายในของคุณเพื่อขอความช่วยเหลือ",
        LoginForm_CompanyName: "ชื่อบริษัท",
        LoginForm_Welcome: "ยินดีต้อนรับ!",
        LoginForm_Description: "Feedback ที่สร้างแรงบันดาลใจ",
        LoginForm_CompanySubmit: "ระบุ",
        LoginForm_GreatSee: "ยินดีที่ได้พบคุณ",
        LoginForm_logWithTandem: "ลงชื่อเข้าสู่ระบบโดยใช้การเข้าสู่ระบบ beqom ของคุณ",
        LoginForm_forgotText: "ลืมรหัสผ่านหรือต้องการตั้งค่ารหัสผ่านครั้งแรกใช่หรือไม่ ระบุชื่อผู้ใช้ที่ด้านล่าง แล้วเราจะส่งอีเมลคำแนะนำให้คุณ",
        LoginForm_mailMe: "ส่งอีเมลลิงก์มาให้ฉัน",
        LoginForm_backToLogin: "ต้องการกลับไปยังการเข้าสู่ระบบใช่หรือไม่",
        LoginForm_setMyPassword: "ตั้งค่ารหัสผ่าน",
        LoginForm_checkMail: "ตรวจสอบอีเมลจาก beqom ที่มีลิงก์สำหรับการรีเซ็ตรหัสผ่านของคุณโดยเฉพาะในอีเมลของคุณ",
        LoginForm_checkSpam: "หากคุณไม่ได้รับอีเมลนี้ในกล่องจดหมายเข้า โปรดตรวจสอบโฟลเดอร์อีเมลขยะของคุณ",
        LoginForm_dontReceive: "ไม่ได้รับอีเมลของเราใช่หรือไม่ %s หากไม่ได้รับอีเมลภายใน 3 นาที คุณสามารถส่งคำขอได้อีกครั้ง!",
        LoginForm_mailMeAgain: "ส่งอีเมลลิงก์มาให้ฉันอีกครั้ง",
        LoginForm_passwordResetLinkSent_Popup: "ระบบส่งลิงก์รีเซ็ตรหัสผ่านให้คุณทางอีเมลแล้ว",
        ChangePassword_newPasswordRepeat: "ใส่รหัสผ่านใหม่อีกครั้ง",
        ChangePassword_submit: "ตั้งค่ารหัสผ่านของฉัน",
        ChangePassword_setMyPasswordSuccess: "%s ตั้งค่ารหัสผ่านแล้ว",
        ChangePassword_setMyPasswordSuccessDesc: "อัปเดตรหัสผ่านของคุณเรียบร้อยแล้ว",
        ChangePassword_InvalidLink: "ลิงก์รีเซ็ตรหัสผ่านของคุณไม่สามารถใช้ได้อีกต่อไป โปรดส่งคำขอลิงก์ใหม่",
        ChangeExpiredPassword_HeaderDesc: "รหัสผ่านสำหรับ %s ของคุณหมดอายุแล้ว โปรดเปลี่ยนรหัสผ่านปัจจุบันของคุณ",
        SelfInvite_Copied: "คัดลอกแล้ว",
        SelfInvite_requestSent: "ส่งคำขอเข้าร่วมของคุณแล้ว",
        SelfInvite_ReviewDesc: "คำขอเข้าถึง  beqom ของคุณอยู่ระหว่างการตรวจสอบ คุณจะได้รับคำเชิญทางอีเมลในอีกสักครู่",
        Tandem_On_GooglePlay: "beqom ใน Google Play",
        Tandem_On_iTunes: "beqom ใน App Store",
        Logout_Expire_Session_Title: "รักษาความปลอดภัยให้การเข้าสู่ระบบ",
        Logout_Expire_Session_Text: "เซสชันปัจจุบันของคุณหมดอายุแล้ว โปรดเข้าสู่ระบบเพื่อดำเนินการต่อ!",
        ApplicationError_Title: "ข้อผิดพลาดของแอปพลิเคชัน",
        SnackBar_ErrorId: "รหัสข้อผิดพลาด:",
        SnackBar_Message: "โอ้ ขออภัย มีข้อผิดพลาดเกิดขึ้นใน beqom โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ให้ความช่วยเหลือด้าน IT หรือผู้ดูแลระบบของคุณเพื่อขอรับความช่วยเหลือ โปรดคัดลอกรหัสข้อผิดพลาดพร้อมรายละเอียดอื่นๆ เพื่อช่วยให้การตรวจสอบปัญหาเป็นไปได้อย่างรวดเร็ว",
        SnackBar_Copy: "คัดลอก",
        SnackBar_IsCopied: "คัดลอกรหัสข้อผิดพลาดไปยังคลิปบอร์ดแล้ว",
        ResetPassword_AdditionalDescription: "เราเพิ่งส่งอีเมลให้แก่ผู้ใช้ที่มีชื่อผู้ใช้ว่า: {username}",
        SettingsDataPrivacyStatement_popupText1: "โปรดยอมรับ",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "โปรดยอมรับข้อมูลที่อัปเดตเกี่ยวกับ",
        SettingsDataPrivacyStatement_Read: "ประกาศเรื่องความเป็นส่วนตัวของข้อมูล",
        SettingsDataPrivacyStatement_popupText2: "เพื่อดำเนินการต่อ",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "เพื่อดำเนินการต่อ",
        OnboardingWizard_Next: "ถัดไป",
        OnboardingWizard_Back: "ย้อนกลับ",
        MainMenu_EditProfile: "แก้ไขโปรไฟล์",
        MainMenu_LogOut: "ออกจากระบบ",
        IdentityError_PasswordIsExpired: "รหัสผ่านของคุณหมดอายุแล้ว",
        IdentityError_PasswordMismatch: "รหัสผ่านไม่ตรงกัน",
        IdentityError_PasswordPolicyNotFound: "ไม่พบนโยบายรหัสผ่าน",
        IdentityError_PasswordRequiresDigit: "รหัสผ่านต้องมีตัวเลข",
        IdentityError_PasswordRequiresLower: "รหัสผ่านต้องมีตัวพิมพ์เล็ก",
        IdentityError_PasswordRequiresNonAlphanumeric: "รหัสผ่านต้องมีอักขระที่ไม่ใช่ตัวอักษรและตัวเลข",
        IdentityError_PasswordRequiresUniqueChars: "รหัสผ่านต้องมีอักขระพิเศษ",
        IdentityError_PasswordRequiresUpper: "รหัสผ่านต้องมีตัวพิมพ์ใหญ่",
        IdentityError_PasswordTooLong: "รหัสผ่านที่แจ้งยาวเกินไป",
        IdentityError_PasswordTooShort: "รหัสผ่านที่แจ้งสั้นเกินไป",
        Office365_AppInfo: "ขอสงวนลิขสิทธิ์ เวอร์ชัน: ",
        Office365_GoNext: "ถัดไป",
        Office365_LoginForm_Company_not_found: "ขออภัย ชื่อบริษัทที่คุณระบุนั้นไม่ถูกต้อง",
        Office365_LoginForm_companyPlaceholder: "ชื่อบริษัท",
        Office365_LoginForm_userName_place: "อีเมลหรือการเข้าสู่ระบบ",
        Office365_DataPrivacyConsentNotice: "ประกาศการยินยอมเกี่ยวกับความเป็นส่วนตัวของข้อมูล",
        Office365_AcceptDpToProceed: "ก่อนที่คุณจะสามารถดำเนินการต่อ โปรดยอมรับ ",
        Office365_Ok: "ตกลง",
        Office365_Logout_Modal: "คุณแน่ใจว่าต้องการออกจากระบบใช่หรือไม่",
        MaintenancePage_Title: "เราจะกลับมาให้บริการในเร็วๆ นี้",
        MaintenancePage_Description: "เรากำลังอยู่ระหว่างการบำรุงรักษาตามกำหนดเวลาในขณะนี้ เราจะกลับมาให้บริการในเร็วๆ นี้ ขอขอบคุณที่อดทนรอ",
        ExternalParticipant_DataPrivacy_Text: "อ่าน",
        ExternalParticipant_DataPrivacy_Link: "นโยบายความเป็นส่วนตัวของเรา",
        UpdateApp_Button: "อัปเดต beqom",
        UpdateApp_TopBar_Title: "จำเป็นต้องอัปเดต",
        ExternalDeclineSuccessTitle_Declined: "คุณปฏิเสธคำขอให้กรอกแบบสำรวจแล้ว",
        ExternalDeclineSuccessMessage: "ขอบคุณที่แจ้งให้เราทราบว่าคุณไม่สามารถตอบกลับคำขอของพวกเขาได้ในขณะนี้",
        ExternalUnsubscribeTitle: "คุณแน่ใจหรือไม่ว่าไม่ต้องการเข้าร่วมกรอกแบบสำรวจใดๆ จากบริษัท {CompanyName}",
        ExternalUnsubscribeMessage: "หากคุณเลือกไม่รับคำขอให้กรอกแบบสำรวจจากบริษัท {CompanyName} เราจะไม่สามารถเชิญซ้ำหรือส่งการแจ้งเตือนเกี่ยวกับแบบสำรวจให้แก่คุณได้อีก",
        ExternalUnsubscribeBtn: "เลือกไม่รับแบบสำรวจ",
        ExternalUnsubscribeAction: "ฉันต้องการเลือกไม่รับคำขอให้กรอกแบบสำรวจใดๆ จากบริษัท {CompanyName} ในอนาคต",
        ExternalUnsubscribeSuccessTitle: "คุณเลือกไม่รับคำขอให้กรอกแบบสำรวจจากบริษัท {CompanyName} ทั้งหมดในอนาคตแล้ว",
        ExternalUnsubscribeSuccessMessage: "หากคุณต้องการเปลี่ยนแปลงการตัดสินใจเมื่อใดก็ตามในอนาคต โปรดสอบถามผู้ติดต่อของคุณจากบริษัท {CompanyName} และส่งคำขอเป็นลายลักษณ์อักษรให้แก่ผู้ติดต่อเพื่ออัปเดตคำยินยอมของคุณใน beqom",
        Teams_WelcomePage_Button_SignIn: "ลงชื่อเข้าใช้",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "ขับเคลื่อนประสิทธิภาพและการเติบโต",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "เตรียมตัวสำหรับยุคใหม่ที่มุ่งเน้นบุคคล ด้วยแพลตฟอร์ม Continuous Performance Management (CPM) ของ beqom แพลตฟอร์มที่เน้นการใช้งานโดยมนุษย์ ใช้งานง่าย และวัดผลได้ในทุกขั้นตอน"
    },
    it: {
        version_upgrade_title: "Ora è disponibile una nuova versione di beqom, visita il tuo app store per l'aggiornamento!",
        version_upgrade: "È stata rilasciata una nuova versione di beqom! La pagina si aggiornerà tra 3 secondi e potrai continuare. Grazie!",
        version_upgrade_app: "",
        cancel: "Annulla",
        user_deactivated_title: "Questo account utente non è più attivo",
        user_deactivated: "Il tuo account non è più attivo. Per ricevere assistenza, contatta il supporto IT interno o l'amministratore di sistema.",
        user_notfound_title: "Questo account utente non è stato trovato in beqom",
        user_notfound: "Non è presente alcun utente con questo indirizzo e-mail nell'account beqom della tua azienda. Per ricevere assistenza, contatta il supporto IT interno o l'amministratore di sistema.",
        connection_button_reload: "Ricarica",
        logIn: "Accedi",
        LoginForm_forgotPass: "Prima password/hai dimenticato la password?",
        LoginForm_logWithAzureAd: "Accedi con le credenziali dell'azienda",
        LoginForm_copyright1: "Tutti i diritti riservati.",
        LoginForm_warning: "Attenzione!",
        LoginForm_ProblemWithTandemAccount: "Si sono verificati alcuni problemi con il tuo account beqom. Per ricevere assistenza, contatta il supporto IT o l'amministratore di sistema.",
        LoginForm_ProblemWithUserCredentials: "Nome utente o password non corretti.  Riprova!",
        LoginForm_FailedError: "Si è verificato un errore in beqom. Per ricevere assistenza, contatta il supporto IT o l'amministratore di sistema.",
        LoginForm_IncorrectCompanyName: "Impossibile trovare il nome dell'azienda. Riprova o contatta l'amministratore di sistema.",
        LoginForm_ProblemWithYourCompanyLogin: "Si sono verificati dei problemi con le credenziali aziendali. Per ricevere assistenza, contatta il supporto IT o l'amministratore di sistema.",
        LoginForm_EmptyCompanyName: "Fornisci il nome dell'azienda.",
        LoginForm_passwordResetRequired: "È richiesta la reimpostazione della password per l'utente attuale.",
        LoginForm_companyName: "Azienda",
        LoginForm_userName: "Nome utente",
        LoginForm_password: "Password",
        LoginForm_signInCompanyText: "Hai effettuato l'accesso all'azienda:",
        continueButton: "Continua",
        ChangeCompany: "Inserisci nuovamente nome azienda",
        SettingsDataPrivacyStatement_title: "Informativa privacy dati",
        SettingsDataPrivacyStatement_popupYes: "Accetta",
        SettingsDataPrivacyStatement_popupNo: "Rifiuta",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Chiudi ed esci",
        SettingsDataPrivacyStatement_Version: "Versione",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Sarà effettuata la disconnessione, desideri continuare?",
        ChangePassword_header: "Modifica password",
        ChangePassword_currentPassword: "password attuale",
        ChangePassword_newPassword: "nuova password",
        ChangePassword_newPasswordRetype: "conferma nuova password",
        PasswordChangeMessage: "Modifica password",
        PasswordChange_ok: "La password è stata aggiornata correttamente e sarà effettuata la disconnessione in 3 secondi.",
        PasswordChange_errNewPasswordsDoNotMatch: "Le nuove password non corrispondono.",
        PasswordChange_err: "Spiacenti, si è verificato un problema con la modifica della password. Riprova!",
        PasswordResetTitle: "Configura/reimposta password",
        PasswordResetMessage: "Reimpostazione password",
        PasswordReset_ok: "Un collegamento per la reimpostazione della password è stato inviato ora alla tua e-mail.",
        PasswordReset_err: "Spiacenti, si è verificato un problema con la reimpostazione della password.  Riprova!",
        ChangeExpiredPassword_MessageHeader: "Password scaduta",
        ChangeExpiredPassword_Message: "La password per {0} è scaduta. Modifica la password.",
        ChangePassword_Policy_Header: "La password deve soddisfare i seguenti requisiti: ",
        ChangePassword_Policy_AllowDictionaryWords: "Le parole non sono consentite",
        ChangePassword_Policy_AllowPasswordAsUserName: "Non puoi usare il tuo nome utente nella password",
        ChangePassword_Policy_MaximumLength: "La lunghezza massima della password è",
        ChangePassword_Policy_MinimumLength: "La lunghezza minima della password è",
        ChangePassword_Policy_RequireDigit: "Deve contenere almeno un numero",
        ChangePassword_Policy_RequireLowercase: "Deve contenere almeno un lettera minuscola",
        ChangePassword_Policy_RequireNonAlphanumeric: "Deve contenere almeno un carattere non alfanumerico (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "Deve contenere almeno una lettera maiuscola",
        ChangePassword_Policy_EnforceHistory_Start: "Indietro",
        ChangePassword_Policy_EnforceHistory_End: "le password non sono consentite",
        DeclinedDataPrivacy_Message: "In precedenza, hai rifiutato l'informativa sul consenso alla privacy dei dati. Per poter riattivare il tuo account, contatta il supporto tecnico IT. Fino a quando l'account non sarà riattivato, non potrai utilizzare beqom.",
        DeclinedDataPrivacy_Title: "Riattiva account",
        DataPrivacy_EffectiveDate: "Data di entrata in vigore",
        no: "No",
        yes: "Sì",
        SettingsLegal_releaseNumber: "Build:",
        SelfInvite_SignedIntoCompany: "Hai effettuato l'accesso all'azienda:",
        SelfInvite_Welcome: "Benvenuto in beqom!",
        SelfInvite_ThanksForComing: "Grazie per aver scelto di partecipare alla nostra community di feedback.",
        SelfInvite_SendMailShortly: "Ci aiuteremo nella configurazione e ti invieremo un'e-mail di invito a breve.",
        SelfInvite_Select: "Nel frattempo, seleziona",
        SelfInvite_ThankYou: "Grazie!",
        SelfInvite_YouWillReceiveInvite: "Riceverai l'invito ufficiale a breve.",
        SelfInvite_ShareLink: "Se desideri invitare anche un collega a partecipare, condividi questo link.",
        SelfInvite_InviteSent: "La richiesta di partecipazione è già stata inviata!",
        SelfInvite_Review: "Riceverai un invito tramite e-mail non appena potrai effettuare l'accesso.",
        SelfInvite_CopyToClipboard: "Copia collegamento negli appunti",
        SelfInvite_JoinNow: "Partecipa ora!",
        LoginForm_CompanyNotFound: "Impossibile trovare il nome della tua azienda, controlla l'ortografia e riprova. Se hai bisogno di ulteriore assistenza, contatta il supporto IT interno o l'amministratore di sistema.",
        LoginForm_CompanyName: "Nome azienda",
        LoginForm_Welcome: "Benvenuto!",
        LoginForm_Description: "feedback in grado di ispirare",
        LoginForm_CompanySubmit: "Invia",
        LoginForm_GreatSee: "È bello vederti qui",
        LoginForm_logWithTandem: "Accedi con le credenziali beqom",
        LoginForm_forgotText: "Hai dimenticato la password o desideri impostarne una per la prima volta? Inserisci il tuo nome utente di seguito e ti invieremo le istruzioni via e-mail",
        LoginForm_mailMe: "Inviami un link tramite e-mail",
        LoginForm_backToLogin: "Tornare alla home?",
        LoginForm_setMyPassword: "Imposta password",
        LoginForm_checkMail: "Controlla se hai ricevuto un'e-mail da beqom con un link univoco per reimpostare la password.",
        LoginForm_checkSpam: "Se non trovi l'e-mail nella posta in arrivo, controlla anche le cartelle di posta indesiderata.",
        LoginForm_dontReceive: "Non hai ricevuto la nostra e-mail? %s Se non la ricevi nei prossimi 3 minuti, invia una nuova richiesta.",
        LoginForm_mailMeAgain: "Inviami di nuovo un link tramite e-mail",
        LoginForm_passwordResetLinkSent_Popup: "Un link per reimpostare la password è stato inviato al tuo indirizzo e-mail",
        ChangePassword_newPasswordRepeat: "ripeti nuova password",
        ChangePassword_submit: "Configura la password",
        ChangePassword_setMyPasswordSuccess: "La password per %s è stata configurata",
        ChangePassword_setMyPasswordSuccessDesc: "La password è stata aggiornata correttamente.",
        ChangePassword_InvalidLink: "Il link per reimpostare la password non è più valido. Richiedi un nuovo link",
        ChangeExpiredPassword_HeaderDesc: "La password per %s è scaduta. Modifica la password attuale.",
        SelfInvite_Copied: "Copiata",
        SelfInvite_requestSent: "La tua richiesta di partecipazione è già stata inviata",
        SelfInvite_ReviewDesc: "La tua richiesta di accesso a beqom è in fase di revisione. A breve riceverai un invito tramite e-mail.",
        Tandem_On_GooglePlay: "beqom su Google Play",
        Tandem_On_iTunes: "beqom su App Store",
        Logout_Expire_Session_Title: "Accesso protetto",
        Logout_Expire_Session_Text: "La sessione attuale è scaduta. Accedi per continuare!",
        ApplicationError_Title: "Errore dell'applicazione",
        SnackBar_ErrorId: "ID errore:",
        SnackBar_Message: "Si è verificato un errore in beqom. Per ricevere assistenza, contatta il supporto IT o l'amministratore di sistema. Copia l'ID dell'errore insieme a eventuali altri dettagli in modo da accelerare l'indagine del problema.",
        SnackBar_Copy: "Copia",
        SnackBar_IsCopied: "ID errore copiato negli appunti",
        ResetPassword_AdditionalDescription: "Abbiamo appena inviato un'e-mail per l'utente con il nome utente: {username}",
        SettingsDataPrivacyStatement_popupText1: "Accetta la",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Accetta la nostra versione aggiornata della",
        SettingsDataPrivacyStatement_Read: "Informativa sulla privacy dei dati",
        SettingsDataPrivacyStatement_popupText2: "per procedere",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "per procedere",
        OnboardingWizard_Next: "Avanti",
        OnboardingWizard_Back: "Indietro",
        MainMenu_EditProfile: "Modifica profilo",
        MainMenu_LogOut: "Esci",
        IdentityError_PasswordIsExpired: "La password è scaduta.",
        IdentityError_PasswordMismatch: "La password non corrisponde.",
        IdentityError_PasswordPolicyNotFound: "Policy per le password non trovata.",
        IdentityError_PasswordRequiresDigit: "La password deve contenere cifre.",
        IdentityError_PasswordRequiresLower: "La password deve contenere lettere minuscole.",
        IdentityError_PasswordRequiresNonAlphanumeric: "La password deve contenere lettere non alfanumeriche.",
        IdentityError_PasswordRequiresUniqueChars: "La password deve contenere caratteri univoci.",
        IdentityError_PasswordRequiresUpper: "La password deve contenere lettere maiuscole.",
        IdentityError_PasswordTooLong: "La password fornita è troppo lunga.",
        IdentityError_PasswordTooShort: "La password fornita è troppo breve.",
        Office365_AppInfo: "Tutti i diritti riservati. Build: ",
        Office365_GoNext: "Vai avanti",
        Office365_LoginForm_Company_not_found: "Siamo spiacenti, il nome dell'azienda che hai inserito non è valido",
        Office365_LoginForm_companyPlaceholder: "Nome azienda",
        Office365_LoginForm_userName_place: "E-mail o credenziali",
        Office365_DataPrivacyConsentNotice: "Informativa sul consenso alla privacy dei dati",
        Office365_AcceptDpToProceed: "Prima di procedere, accetta la nostra ",
        Office365_Ok: "Ok",
        Office365_Logout_Modal: "Desideri disconnetterti?",
        MaintenancePage_Title: "Torneremo presto",
        MaintenancePage_Description: "Attualmente, siamo in fase di manutenzione programmata. Torneremo presto. Grazie per la pazienza.",
        ExternalParticipant_DataPrivacy_Text: "Leggi la nostra",
        ExternalParticipant_DataPrivacy_Link: "informativa sulla privacy",
        UpdateApp_Button: "Aggiorna beqom",
        UpdateApp_TopBar_Title: "Aggiornamento obbligatorio",
        ExternalDeclineSuccessTitle_Declined: "Hai rifiutato la richiesta di sondaggio.",
        ExternalUnsubscribeTitle: "Desideri partecipare ai sondaggi dell'azienda {CompanyName}?",
        ExternalUnsubscribeMessage: "Se hai annullato la ricezione delle richieste di sondaggio dell'azienda {CompanyName}, non potremo invitarti di nuovo o inviarti notifiche sui sondaggi.",
        ExternalUnsubscribeBtn: "Annulla la ricezione di sondaggi",
        ExternalUnsubscribeAction: "Desidero annullare la ricezione delle richieste di sondaggio dell'azienda {CompanyName}.",
        ExternalUnsubscribeSuccessTitle: "Ho annullato la ricezione di tutte le future richieste di sondaggio dell'azienda {CompanyName}.",
        ExternalUnsubscribeSuccessMessage: "Se desideri modificare la tua decisione in qualsiasi momento in futuro, contatta l'azienda {CompanyName} e invia loro una richiesta per iscritto di aggiornamento del consenso in beqom.",
        ExternalDeclineSuccessMessage: "Grazie per averci informato che al momento non hai potuto rispondere alla loro richiesta.",
        Teams_WelcomePage_Button_SignIn: "Accedi",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "potenziamento delle prestazioni e crescita",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Preparati a una nuova era incentrata sulle persone con beqom Continuous Performance Management (CPM), una piattaforma incentrata sulle persone, facile da usare e misurabile in ogni fase del percorso."

    },
    'en-US': {
        version_upgrade_title: "A new version of beqom is now available. Please visit your app store to upgrade!",
        version_upgrade: "A new version of beqom has been released! Your page will refresh in three seconds and allow you to continue. Thank you!",
        version_upgrade_app: "",
        cancel: "Cancel",
        user_deactivated_title: "This user account is no longer active",
        user_deactivated: "Your account is no longer active. Please contact your internal IT help desk or system administrator for support.",
        user_notfound_title: "This user account could not be found in beqom",
        user_notfound: "There is no user with this email address in your company beqom account. Please contact your internal IT help desk or system administrator for support.",
        connection_button_reload: "Reload",
        logIn: "Login",
        LoginForm_forgotPass: "First time logging in or forgot your password?",
        LoginForm_logWithAzureAd: "Sign in with my company login",
        LoginForm_copyright1: "All rights reserved.",
        LoginForm_warning: "Warning!",
        LoginForm_ProblemWithTandemAccount: "There are some problems with your beqom account. Please contact your IT help desk or system administrator for support.",
        LoginForm_ProblemWithUserCredentials: "Your username or password is incorrect. Please try again!",
        LoginForm_FailedError: "Oops, sorry—something went wrong in beqom. Please try again or contact your IT help desk or system administrator for support.",
        LoginForm_IncorrectCompanyName: "Your company name could not be found. Please try again or contact your system administrator.",
        LoginForm_ProblemWithYourCompanyLogin: "There are some problems with your company login. Please contact your IT help desk or system administrator for support.",
        LoginForm_EmptyCompanyName: "Please provide your Company name.",
        LoginForm_passwordResetRequired: "Password reset required for current user.",
        LoginForm_companyName: "Company",
        LoginForm_userName: "Username",
        LoginForm_password: "Password",
        LoginForm_signInCompanyText: "You have signed into the following company:",
        continueButton: "Continue",
        ChangeCompany: "Re-enter my company name",
        SettingsDataPrivacyStatement_title: "Data privacy statement",
        SettingsDataPrivacyStatement_popupYes: "Accept",
        SettingsDataPrivacyStatement_popupNo: "Decline",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Close and log out",
        SettingsDataPrivacyStatement_Version: "Version",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "Are you sure? You will be logged out.",
        ChangePassword_header: "Change password",
        ChangePassword_currentPassword: "current password",
        ChangePassword_newPassword: "new password",
        ChangePassword_newPasswordRetype: "confirm new password",
        PasswordChangeMessage: "Password change",
        PasswordChange_ok: "Your password has been successfully updated and you will be logged out in three seconds.",
        PasswordChange_errNewPasswordsDoNotMatch: "New passwords do not match.",
        PasswordChange_err: "Sorry, there was a problem with changing your password. Please try again!",
        PasswordResetTitle: "Set up/reset password",
        PasswordResetMessage: "Password reset",
        PasswordReset_ok: "A password reset link has now been sent to you via email.",
        PasswordReset_err: "Sorry, there was a problem with resetting your password. Please try again!",
        ChangeExpiredPassword_MessageHeader: "Password expired",
        ChangeExpiredPassword_Message: "Your password for {0} has expired. Please change your password.",
        ChangePassword_Policy_Header: "Your password must meet the following requirements: ",
        ChangePassword_Policy_AllowDictionaryWords: "Dictionary words are not allowed",
        ChangePassword_Policy_AllowPasswordAsUserName: "You can't use your username in your password",
        ChangePassword_Policy_MaximumLength: "Maximum password length is",
        ChangePassword_Policy_MinimumLength: "Minimum password length is",
        ChangePassword_Policy_RequireDigit: "Must contain at least one number",
        ChangePassword_Policy_RequireLowercase: "Must contain at least one lowercase letter",
        ChangePassword_Policy_RequireNonAlphanumeric: "Must contain at least one non-alphanumeric character (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "Must contain at least one uppercase letter",
        ChangePassword_Policy_EnforceHistory_Start: "Previous",
        ChangePassword_Policy_EnforceHistory_End: "passwords are not allowed",
        DeclinedDataPrivacy_Message: "You previously declined the data privacy consent statement. In order to re-activate your account, please contact your IT help desk. Until your account is reactivated, you will be unable to use beqom.",
        DeclinedDataPrivacy_Title: "Re-activate your account",
        DataPrivacy_EffectiveDate: "Effective Date",
        no: "No",
        yes: "Yes",
        SettingsLegal_releaseNumber: "Build:",
        SelfInvite_SignedIntoCompany: "You have signed into the following company:",
        SelfInvite_Welcome: "Welcome to beqom!",
        SelfInvite_ThanksForComing: "Thanks for coming to join our feedback community.",
        SelfInvite_SendMailShortly: "We will get you set up and send you an invitation email shortly.",
        SelfInvite_Select: "In the meantime, just select",
        SelfInvite_ThankYou: "Thank you!",
        SelfInvite_YouWillReceiveInvite: "You will receive your official invitation shortly.",
        SelfInvite_ShareLink: "If you'd like to invite a coworker to join in too, just share this link.",
        SelfInvite_InviteSent: "Your request to join in has already been submitted!",
        SelfInvite_Review: "You will receive an invitation via email as soon as you can log in.",
        SelfInvite_CopyToClipboard: "Copy link to clipboard",
        SelfInvite_JoinNow: "Join in now!",
        LoginForm_CompanyNotFound: "Your company name could not be found. Please check the spelling and try again. If you need further support, please contact your internal IT help desk or system administrator.",
        LoginForm_CompanyName: "Company name",
        LoginForm_Welcome: "Welcome!",
        LoginForm_Description: "feedback that inspires",
        LoginForm_CompanySubmit: "Enter",
        LoginForm_GreatSee: "It's great to see you",
        LoginForm_logWithTandem: "Sign in with your beqom login",
        LoginForm_forgotText: "Forgotten your password or want to set up your password for the first time? Enter your username below, and we will send you instructions via email",
        LoginForm_mailMe: "Send me a link via email",
        LoginForm_backToLogin: "Back to login page?",
        LoginForm_setMyPassword: "Set my password",
        LoginForm_checkMail: "Check your email account for a mail from beqom with a unique link for your password reset.",
        LoginForm_checkSpam: "If you don't receive this mail in your inbox, please also check your junk email folders.",
        LoginForm_dontReceive: "Didn't receive our email? %s If it doesn't arrive in the next three minutes, you can request it again!",
        LoginForm_mailMeAgain: "Send me a link via email again",
        LoginForm_passwordResetLinkSent_Popup: "A password reset link has been sent to your email",
        ChangePassword_newPasswordRepeat: "repeat new password",
        ChangePassword_submit: "Set up my password",
        ChangePassword_setMyPasswordSuccessDesc: "Your password has been successfully updated.",
        ChangePassword_setMyPasswordSuccess: "Password has %s been set up",
        ChangePassword_InvalidLink: "Your password reset link is no longer valid. Please request a new link",
        ChangeExpiredPassword_HeaderDesc: "Your password for %s has expired. Please change your current password.",
        SelfInvite_Copied: "Copied",
        SelfInvite_requestSent: "Your request to join has already been sent",
        SelfInvite_ReviewDesc: "Your request for access to beqom is under review. You will receive an email invitation shortly.",
        Tandem_On_GooglePlay: "beqom on Google Play",
        Tandem_On_iTunes: "beqom on App Store",
        Logout_Expire_Session_Title: "Secure Login",
        Logout_Expire_Session_Text: "Your current session has expired. Please log in to continue!",
        ApplicationError_Title: "Application Error",
        SnackBar_ErrorId: "Error ID:",
        SnackBar_Message: "Oops, sorry—something went wrong in beqom. Please try again or contact your IT help desk or system administrator for support. Please copy the error ID along with other details in order to speed up the issue investigation.",
        SnackBar_Copy: "Copy",
        SnackBar_IsCopied: "Error ID has been copied to clipboard",
        ResetPassword_AdditionalDescription: "We have just sent an email for the user with the username {username}",
        SettingsDataPrivacyStatement_popupText1: "Please accept the",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Please accept the updated",
        SettingsDataPrivacyStatement_Read: "Data Privacy Notice",
        SettingsDataPrivacyStatement_popupText2: "to proceed",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "to proceed",
        OnboardingWizard_Next: "Next",
        OnboardingWizard_Back: "Back",
        MainMenu_EditProfile: "Edit profile",
        MainMenu_LogOut: "Log out",
        IdentityError_PasswordIsExpired: "Your password has expired.",
        IdentityError_PasswordMismatch: "Password mismatch.",
        IdentityError_PasswordPolicyNotFound: "Password policy not found.",
        IdentityError_PasswordRequiresDigit: "Password must contain digits.",
        IdentityError_PasswordRequiresLower: "Password must contain lowercase letters.",
        IdentityError_PasswordRequiresNonAlphanumeric: "Password must contain non-alphanumeric characters.",
        IdentityError_PasswordRequiresUniqueChars: "Password must contain unique characters.",
        IdentityError_PasswordRequiresUpper: "Password must contain uppercase letters.",
        IdentityError_PasswordTooLong: "Password provided is too long.",
        IdentityError_PasswordTooShort: "Password provided is too short.",
        Office365_AppInfo: "All rights reserved. Build: ",
        Office365_GoNext: "Go next",
        Office365_LoginForm_Company_not_found: "Sorry, the company name you have entered is not valid",
        Office365_LoginForm_companyPlaceholder: "Company name",
        Office365_LoginForm_userName_place: "Email or Login",
        Office365_DataPrivacyConsentNotice: "Data Privacy Consent Notice",
        Office365_AcceptDpToProceed: "Before you can proceed, please accept our ",
        Office365_Ok: "OK",
        Office365_Logout_Modal: "Are you sure you want log out?",
        UpdateApp_Button: "Update beqom",
        UpdateApp_TopBar_Title: "Update required",
        MaintenancePage_Title: "We'll be back soon",
        MaintenancePage_Description: "We are currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.",
        ExternalUnsubscribeTitle: "Are you sure you don't want to participate in any surveys from {CompanyName} company?",
        ExternalUnsubscribeMessage: "If you opt-out of any survey requests from {CompanyName} company, won't not be able to invite you again or send you notifications about surveys.",
        ExternalUnsubscribeBtn: "Opt-out of surveys",
        ExternalUnsubscribeAction: "I would like to opt-out of any survey requests from {CompanyName} company.",
        ExternalUnsubscribeSuccessTitle: "You've opted out of any future survey requests from {CompanyName} company.",
        ExternalUnsubscribeSuccessMessage: "If you want to change your decision at any point in the future, please, get in touch with your contact at {CompanyName} company and send them a request in writing to get your consent updated in beqom.",
        ExternalDeclineSuccessTitle_Declined: "You have declined the survey request.",
        ExternalDeclineSuccessMessage: "Thanks for letting us know that you were unable to answer their request at this time.",
        ExternalParticipant_DataPrivacy_Text: "Read our",
        ExternalParticipant_DataPrivacy_Link: "privacy policy",
        Teams_WelcomePage_Button_SignIn: "Sign in",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "powering performance and growth",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Prepare for a new people-focused era with beqom Continuous Performance Management (CPM), a platform that is human-centric, easy to use, and measurable every step of the way.",

    },
    'pt-PT': {
        version_upgrade_title: "Está disponível uma nova versão da beqom . Visita a tua App Store para atualizar!",
        version_upgrade: "Foi lançada uma nova versão da beqom! A tua página será atualizada dentro de 3 segundos. Em seguida, poderás continuar. Obrigado!",
        version_upgrade_app: "",
        cancel: "Cancelar",
        user_deactivated_title: "A conta deste utilizador já não está ativa",
        user_deactivated: "A tua conta já não está ativa. Contacta o suporte técnico de TI interno ou o administrador de sistema para obter ajuda.",
        user_notfound_title: "Não foi possível encontrar a conta deste utilizador na beqom",
        user_notfound: "Não existe um utilizador com este endereço de e-mail na conta beqom da tua empresa. Contacta o suporte técnico de TI interno ou o administrador de sistema para obter ajuda.",
        connection_button_reload: "Recarregar",
        logIn: "Iniciar sessão",
        LoginForm_forgotPass: "Palavra-passe inicial/Esqueceste-te da palavra-passe?",
        LoginForm_logWithAzureAd: "Iniciar sessão com a conta da empresa",
        LoginForm_copyright1: "Todos os direitos reservados.",
        LoginForm_warning: "Aviso!",
        LoginForm_ProblemWithTandemAccount: "Existem alguns problemas com a tua conta beqom. Contacta o suporte técnico de TI ou o administrador de sistema para obter ajuda.",
        LoginForm_ProblemWithUserCredentials: "O teu nome de utilizador ou a palavra-passe estão incorretos. Tenta novamente!",
        LoginForm_FailedError: "Ups, ocorreu um problema na beqom. Tenta novamente ou contacta o suporte técnico de TI ou o administrador de sistema para obter ajuda.",
        LoginForm_IncorrectCompanyName: "Não foi possível encontrar o nome da tua empresa. Tenta novamente ou contacta o administrador de sistema.",
        LoginForm_ProblemWithYourCompanyLogin: "Existem alguns problemas com o início de sessão da conta da empresa. Contacta o suporte técnico de TI ou o administrador de sistema para obter ajuda.",
        LoginForm_EmptyCompanyName: "Indica o nome da tua empresa.",
        LoginForm_passwordResetRequired: "É necessária a reposição da palavra-passe do utilizador atual.",
        LoginForm_companyName: "Empresa",
        LoginForm_userName: "Nome de utilizador",
        LoginForm_password: "Palavra-passe",
        LoginForm_signInCompanyText: "Iniciaste sessão na empresa:",
        continueButton: "Continuar",
        ChangeCompany: "Inserir novamente o nome da minha empresa",
        SettingsDataPrivacyStatement_title: "Declaração de privacidade de dados",
        SettingsDataPrivacyStatement_popupYes: "Aceitar",
        SettingsDataPrivacyStatement_popupNo: "Recusar",
        SettingsDataPrivacyStatement_popupOk: "OK",
        SettingsDataPrivacyStatement_CloseAndExit: "Fechar e terminar sessão",
        SettingsDataPrivacyStatement_Version: "Versão",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "A tua sessão irá terminar. Tens a certeza?",
        ChangePassword_header: "Alterar palavra-passe",
        ChangePassword_currentPassword: "palavra-passe atual",
        ChangePassword_newPassword: "nova palavra-passe",
        ChangePassword_newPasswordRetype: "confirmar nova palavra-passe",
        PasswordChangeMessage: "Alteração da palavra-passe",
        PasswordChange_ok: "A tua palavra-passe foi atualizada com êxito. A tua sessão será terminada dentro de 3 segundos.",
        PasswordChange_errNewPasswordsDoNotMatch: "As palavras-passe novas não coincidem.",
        PasswordChange_err: "Lamentamos, ocorreu um problema ao alterar a palavra-passe. Tenta novamente!",
        PasswordResetTitle: "Configurar/repor palavra-passe",
        PasswordResetMessage: "Reposição da palavra-passe",
        PasswordReset_ok: "Foi enviada agora uma ligação de reposição de palavra-passe para o teu e-mail.",
        PasswordReset_err: "Lamentamos, ocorreu um problema ao repor a palavra-passe. Tenta novamente!",
        ChangeExpiredPassword_MessageHeader: "Palavra-passe expirada",
        ChangeExpiredPassword_Message: "A tua palavra-passe para {0} expirou. Altera a palavra-passe.",
        ChangePassword_Policy_Header: "A tua palavra-passe deve cumprir estes requisitos: ",
        ChangePassword_Policy_AllowDictionaryWords: "Não são permitidas palavras do dicionário",
        ChangePassword_Policy_AllowPasswordAsUserName: "Não podes utilizar o nome de utilizador na palavra-passe",
        ChangePassword_Policy_MaximumLength: "O comprimento máximo da palavra-passe é de",
        ChangePassword_Policy_MinimumLength: "O comprimento mínimo da palavra-passe é de",
        ChangePassword_Policy_RequireDigit: "Deve conter, pelo menos, um número",
        ChangePassword_Policy_RequireLowercase: "Deve conter, pelo menos, uma letra minúscula",
        ChangePassword_Policy_RequireNonAlphanumeric: "Deve conter, pelo menos, um caracter não alfanumérico (!$%@#&^*)",
        ChangePassword_Policy_RequireUppercase: "Deve conter, pelo menos, uma letra maiúscula",
        ChangePassword_Policy_EnforceHistory_Start: "Anterior",
        ChangePassword_Policy_EnforceHistory_End: "palavras-passe não são permitidas",
        DeclinedDataPrivacy_Message: "Anteriormente, recusaste a declaração de consentimento de privacidade de dados. Para ativares novamente a conta, contacta o suporte técnico de TI. Só depois de a conta ser novamente ativada é que poderás utilizar a beqom.",
        DeclinedDataPrivacy_Title: "Ativar novamente a conta",
        DataPrivacy_EffectiveDate: "Data efetiva",
        no: "Não",
        yes: "Sim",
        SettingsLegal_releaseNumber: "Build:",
        SelfInvite_SignedIntoCompany: "Iniciaste sessão na empresa:",
        SelfInvite_Welcome: "Damos-te as boas-vindas à beqom!",
        SelfInvite_ThanksForComing: "Obrigado por aderires à nossa comunidade de feedback.",
        SelfInvite_SendMailShortly: "Vamos ajudar-te a configurar e enviar-te um e-mail de convite em breve.",
        SelfInvite_Select: "Entretanto, basta selecionares",
        SelfInvite_ThankYou: "Obrigado!",
        SelfInvite_YouWillReceiveInvite: "Em breve, irás receber o teu convite oficial.",
        SelfInvite_ShareLink: "Se quiseres convidar um colega para aderir também, basta partilhar a ligação.",
        SelfInvite_InviteSent: "O teu pedido de adesão já foi enviado!",
        SelfInvite_Review: "Irás receber um convite por e-mail assim que conseguires iniciar sessão!",
        SelfInvite_CopyToClipboard: "Copiar ligação para a área de transferências",
        SelfInvite_JoinNow: "Adere agora!",
        LoginForm_CompanyNotFound: "Não foi possível encontrar o nome da tua empresa. Verifica a ortografia e tenta novamente. Se precisares de ajuda adicional, contacta o suporte técnico de TI interno ou o administrador de sistema.",
        LoginForm_CompanyName: "Nome da empresa",
        LoginForm_Welcome: "Boas-vindas!",
        LoginForm_Description: "feedback que inspira",
        LoginForm_CompanySubmit: "Inserir",
        LoginForm_GreatSee: "Que bom ver-te",
        LoginForm_logWithTandem: "Iniciar sessão com a conta beqom",
        LoginForm_forgotText: "Esqueceste-te da palavra-passe ou pretendes configurar uma palavra-passe inicial? Insere o nome de utilizador abaixo. Iremos enviar as instruções por e-mail",
        LoginForm_mailMe: "Enviar-me uma ligação por e-mail",
        LoginForm_backToLogin: "Voltar ao início de sessão?",
        LoginForm_setMyPassword: "Definir palavra-passe",
        LoginForm_checkMail: "Procura um e-mail da beqom na tua conta de e-mail com uma ligação exclusiva para repores a palavra-passe.",
        LoginForm_checkSpam: "Se não tiveres recebido o e-mail na tua caixa de entrada, confirma também nas pastas de e-mail indesejado.",
        LoginForm_dontReceive: "Não recebeste o nosso e-mail? %s Se não o receberes nos próximos 3 minutos, podes pedi-lo novamente!",
        LoginForm_mailMeAgain: "Enviar-me novamente uma ligação por e-mail",
        LoginForm_passwordResetLinkSent_Popup: "Foi enviada uma ligação de reposição de palavra-passe para o teu e-mail",
        ChangePassword_newPasswordRepeat: "repetir nova palavra-passe",
        ChangePassword_submit: "Configurar palavra-passe",
        ChangePassword_setMyPasswordSuccessDesc: "A tua palavra-passe foi atualizada com êxito.",
        ChangePassword_setMyPasswordSuccess: "A palavra-passe foi configurada %s",
        ChangePassword_InvalidLink: "A ligação de reposição de palavra-passe já não é válida. Pede uma nova ligação",
        ChangeExpiredPassword_HeaderDesc: "A tua palavra-passe para %s expirou. Altera a palavra-passe atual.",
        SelfInvite_Copied: "Copiado",
        SelfInvite_requestSent: "O teu pedido de adesão já foi enviado",
        SelfInvite_ReviewDesc: "O teu pedido de acesso à beqom está em processo de revisão. Em breve, irás receber um convite por e-mail.",
        Tandem_On_GooglePlay: "beqom no Google Play",
        Tandem_On_iTunes: "beqom na App Store",
        Logout_Expire_Session_Title: "Início de sessão seguro",
        Logout_Expire_Session_Text: "A tua sessão atual expirou. Inicia sessão para continuar!",
        ApplicationError_Title: "Erro de aplicação",
        SnackBar_ErrorId: "ID do erro:",
        SnackBar_Message: "Ups, ocorreu um problema na beqom. Tenta novamente ou contacta o suporte técnico de TI ou o administrador de sistema para obter ajuda. Copia o ID do erro juntamente com outros detalhes para agilizar a análise do problema.",
        SnackBar_Copy: "Copiar",
        SnackBar_IsCopied: "O ID do erro foi copiado para a área de transferências",
        ResetPassword_AdditionalDescription: "Acabámos de enviar um e-mail para o utilizador com o nome de utilizador: {username}",
        SettingsDataPrivacyStatement_popupText1: "Aceita a",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "Aceita a atualização da",
        SettingsDataPrivacyStatement_Read: "Aviso sobre a privacidade de dados",
        SettingsDataPrivacyStatement_popupText2: "para avançar",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "para avançar",
        OnboardingWizard_Next: "Próximo",
        OnboardingWizard_Back: "Voltar",
        MainMenu_EditProfile: "Editar perfil",
        MainMenu_LogOut: "Terminar sessão",
        IdentityError_PasswordIsExpired: "A palavra-passe expirou.",
        IdentityError_PasswordMismatch: "Erro de correspondência da palavra-passe.",
        IdentityError_PasswordPolicyNotFound: "Não foi possível encontrar a política de palavra-passe.",
        IdentityError_PasswordRequiresDigit: "A palavra-passe deve conter dígitos.",
        IdentityError_PasswordRequiresLower: "A palavra-passe deve conter letras minúsculas.",
        IdentityError_PasswordRequiresNonAlphanumeric: "A palavra-passe deve conter letras não alfanuméricas.",
        IdentityError_PasswordRequiresUniqueChars: "A palavra-passe deve conter caracteres únicos.",
        IdentityError_PasswordRequiresUpper: "A palavra-passe deve conter letras maiúsculas.",
        IdentityError_PasswordTooLong: "A palavra-passe indicada é demasiado comprida.",
        IdentityError_PasswordTooShort: "A palavra-passe fornecida é demasiado curta.",
        Office365_AppInfo: "Todos os direitos reservados. Build: ",
        Office365_GoNext: "Avançar",
        Office365_LoginForm_Company_not_found: "Lamentamos, o nome da empresa que inseriste não é válido",
        Office365_LoginForm_companyPlaceholder: "Nome da empresa",
        Office365_LoginForm_userName_place: "E-mail ou Início de sessão",
        Office365_DataPrivacyConsentNotice: "Aviso sobre o consentimento da privacidade de dados",
        Office365_AcceptDpToProceed: "Antes de avançar, aceita o nosso ",
        Office365_Ok: "OK",
        Office365_Logout_Modal: "Tens a certeza de que pretendes terminar sessão?",
        MaintenancePage_Title: "Voltaremos em breve",
        MaintenancePage_Description: "Atualmente, estamos em processo de manutenção programada. Devemos voltar em breve. Agradecemos a vossa paciência.",
        ExternalParticipant_DataPrivacy_Text: "Lê a nossa",
        ExternalParticipant_DataPrivacy_Link: "Política de Privacidade",
        UpdateApp_Button: "Atualizar o beqom",
        UpdateApp_TopBar_Title: "Atualização obrigatória",
        ExternalDeclineSuccessTitle_Declined: "Recusaste o pedido de resposta ao inquérito.",
        ExternalDeclineSuccessMessage: "Agradecemos-te por nos avisares da tua indisponibilidade para responder neste momento.",
        ExternalUnsubscribeTitle: "Tens a certeza de que não pretendes participar em qualquer inquérito da empresa {CompanyName}?",
        ExternalUnsubscribeMessage: "Se decidires não receber nenhum pedido de resposta a um inquérito da empresa {CompanyName}, não será possível convidar-te outra vez ou enviar-te notificações sobre inquéritos.",
        ExternalUnsubscribeBtn: "Não receber inquéritos",
        ExternalUnsubscribeAction: "Gostaria de não receber pedidos para quaisquer inquéritos da empresa {CompanyName}.",
        ExternalUnsubscribeSuccessTitle: "Decidiste não receber pedidos futuros para quaisquer inquéritos da empresa {CompanyName}.",
        ExternalUnsubscribeSuccessMessage: "Se pretenderes reverter a tua decisão no futuro, fala com a tua pessoa de contacto da empresa {CompanyName}, enviando-lhe um pedido escrito para atualizares o teu consentimento no beqom.",
        Teams_WelcomePage_Button_SignIn: "Iniciar sessão",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "a potenciar o desempenho e o crescimento",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "Prepare-se para uma nova era focada nas pessoas com o beqom Continuous Performance Management (CPM), uma plataforma centrada no utilizador, fácil de utilizar e mensurável em todos os passos do processo."
    },
    'ko-KR': {
        version_upgrade_title: "beqom 새 버전이 나왔습니다. 앱 스토어에 방문하여 업그레이드하세요!",
        version_upgrade: "beqom 새 버전이 출시되었습니다! 3초 후 페이지를 새로 고치고 계속하면 됩니다. 감사합니다!",
        version_upgrade_app: "",
        cancel: "취소",
        user_deactivated_title: "이 사용자 계정은 더 이상 활성 상태가 아님",
        user_deactivated: "계정이 더 이상 활성 상태가 아닙니다. 사내 IT 헬프데스크나 시스템 관리자에게 문의하여 지원을 받으시기 바랍니다.",
        user_notfound_title: "이 사용자 계정을  beqom 에서 찾을 수 없음",
        user_notfound: "귀사 beqom 계정에 이 이메일 주소를 가진 사용자가 없습니다. 사내 IT 헬프데스크나 시스템 관리자에게 문의하여 지원을 받으시기 바랍니다.",
        connection_button_reload: "다시 로드",
        logIn: "로그인",
        LoginForm_forgotPass: "첫 비밀번호이거나 비밀번호를 잊었습니까?",
        LoginForm_logWithAzureAd: "내 회사 로그인 정보로 로그인",
        LoginForm_copyright1: "All rights reserved.",
        LoginForm_warning: "경고!",
        LoginForm_ProblemWithTandemAccount: "귀하의 beqom 계정에 몇 가지 문제가 있습니다. IT 헬프데스크나 시스템 관리자에게 문의하여 지원을 받으세요.",
        LoginForm_ProblemWithUserCredentials: "사용자 이름(ID) 또는 비밀번호가 잘못되었습니다. 다시 시도하세요!",
        LoginForm_FailedError: "이런, 죄송합니다. beqom 에서 오류가 발생했습니다. 다시 시도하거나 IT 헬프데스크 또는 시스템 관리자에게 문의하여 지원을 받으시기 바랍니다.",
        LoginForm_IncorrectCompanyName: "회사 이름을 찾을 수 없습니다. 다시 시도하거나 시스템 관리자에게 문의하세요.",
        LoginForm_ProblemWithYourCompanyLogin: "귀하의 회사 로그인 정보에 몇 가지 문제가 있습니다. IT 헬프데스크나 시스템 관리자에게 문의하여 지원을 받으세요.",
        LoginForm_EmptyCompanyName: "회사 이름을 제공하세요.",
        LoginForm_passwordResetRequired: "현재 사용자는 비밀번호를 재설정해야 합니다.",
        LoginForm_companyName: "회사",
        LoginForm_userName: "사용자 이름(ID)",
        LoginForm_password: "비밀번호",
        LoginForm_signInCompanyText: "다음 회사로 로그인함:",
        continueButton: "계속",
        ChangeCompany: "회사 이름 다시 입력",
        SettingsDataPrivacyStatement_title: "데이터 프라이버시 방침",
        SettingsDataPrivacyStatement_popupYes: "수락",
        SettingsDataPrivacyStatement_popupNo: "거부",
        SettingsDataPrivacyStatement_popupOk: "확인",
        SettingsDataPrivacyStatement_CloseAndExit: "종료하고 로그아웃",
        SettingsDataPrivacyStatement_Version: "버전",
        SettingsDataPrivacyStatement_YouwillBeLogouted: "로그아웃됩니다. 지금 로그아웃하시겠습니까?",
        ChangePassword_header: "비밀번호 변경",
        ChangePassword_currentPassword: "현재 비밀번호",
        ChangePassword_newPassword: "새 비밀번호",
        ChangePassword_newPasswordRetype: "새 비밀번호 확인",
        PasswordChangeMessage: "비밀번호 변경",
        PasswordChange_ok: "비밀번호가 업데이트되었으며 3초 후 로그아웃됩니다.",
        PasswordChange_errNewPasswordsDoNotMatch: "새 비밀번호가 일치하지 않습니다.",
        PasswordChange_err: "죄송합니다. 비밀번호를 변경하던 중 문제가 발생했습니다. 다시 시도하세요!",
        PasswordResetTitle: "비밀번호 설정/재설정",
        PasswordResetMessage: "비밀번호 재설정",
        PasswordReset_ok: "지금 비밀번호 재설정 링크를 이메일로 보냈습니다.",
        PasswordReset_err: "죄송합니다. 비밀번호를 재설정하던 중 문제가 발생했습니다. 다시 시도하세요!",
        ChangeExpiredPassword_MessageHeader: "비밀번호 만료됨",
        ChangeExpiredPassword_Message: "{0} 비밀번호가 만료되었습니다. 비밀번호를 변경하세요.",
        ChangePassword_Policy_Header: "비밀번호가 부합해야 하는 요구사항: ",
        ChangePassword_Policy_AllowDictionaryWords: "사전적인 낱말은 허용되지 않음",
        ChangePassword_Policy_AllowPasswordAsUserName: "비밀번호에 사용자 이름(ID)을 사용하면 안 됨",
        ChangePassword_Policy_MaximumLength: "비밀번호 최대 길이:",
        ChangePassword_Policy_MinimumLength: "비밀번호 최소 길이:",
        ChangePassword_Policy_RequireDigit: "적어도 숫자 한 개 포함",
        ChangePassword_Policy_RequireLowercase: "적어도 소문자 글자 한 개 포함",
        ChangePassword_Policy_RequireNonAlphanumeric: "적어도 영숫자가 아닌 글자(!$%@#&^*) 한 개 포함",
        ChangePassword_Policy_RequireUppercase: "적어도 대문자 글자 한 개 포함",
        ChangePassword_Policy_EnforceHistory_Start: "이전",
        ChangePassword_Policy_EnforceHistory_End: "허용되지 않는 비밀번호",
        DeclinedDataPrivacy_Message: "귀하는 이전에 데이터 프라이버시 동의 설명을 거부하였습니다. 계정을 다시 활성화하려면 IT 헬프데스크에 문의하시기 바랍니다. 계정을 다시 활성화할 때까지는 beqom을 이용할 수 없습니다.",
        DeclinedDataPrivacy_Title: "계정 다시 활성화",
        DataPrivacy_EffectiveDate: "유효일",
        no: "아니요",
        yes: "예",
        SettingsLegal_releaseNumber: "빌드:",
        SelfInvite_SignedIntoCompany: "다음 회사로 로그인함:",
        SelfInvite_Welcome: "beqom에 오신 것을 환영합니다!",
        SelfInvite_ThanksForComing: "피드백 커뮤니티에 가입해주셔서 감사합니다.",
        SelfInvite_SendMailShortly: "곧 준비를 마치고 초대장 메일을 보내드리겠습니다.",
        SelfInvite_Select: "그때까지, 간단하게 다음을 선택해주세요.",
        SelfInvite_ThankYou: "감사합니다!",
        SelfInvite_YouWillReceiveInvite: "곧 정식 초대장을 보내드리겠습니다.",
        SelfInvite_ShareLink: "동료 직원도 초대하여 가입을 권유하고자 하는 경우, 이 링크만 공유하면 됩니다.",
        SelfInvite_InviteSent: "가입 요청이 이미 제출되었습니다!",
        SelfInvite_Review: "로그인할 수 있게 되면 바로 이메일을 통해 초대장을 보내드립니다.",
        SelfInvite_CopyToClipboard: "링크를 클립보드에 복사",
        SelfInvite_JoinNow: "지금 가입하세요!",
        LoginForm_CompanyNotFound: "회사 이름을 찾을 수 없습니다. 철자를 확인하고 다시 시도하세요. 더 많은 지원이 필요한 경우 사내 IT 헬프데스크나 시스템 관리자에게 문의하시기 바랍니다.",
        LoginForm_CompanyName: "회사 이름",
        LoginForm_Welcome: "환영합니다!",
        LoginForm_Description: "영감을 자극하는 피드백",
        LoginForm_CompanySubmit: "입력",
        LoginForm_GreatSee: "반갑습니다.",
        LoginForm_logWithTandem: "beqom 로그인 정보로 로그인",
        LoginForm_forgotText: "비밀번호를 잊었거나 처음으로 비밀번호를 설정하고자 하시나요? 아래에 사용자 이름(ID)을 입력하시면 지침을 이메일로 보내드리겠습니다.",
        LoginForm_mailMe: "나에게 이메일로 링크 보내기",
        LoginForm_backToLogin: "로그인 화면으로 돌아가시겠습니까?",
        LoginForm_setMyPassword: "비밀번호 설정",
        LoginForm_checkMail: "beqom 에서 비밀번호 재설정을 위한 고유한 링크를 포함한 메일을 보냈습니다. 이메일 계정을 확인하세요.",
        LoginForm_checkSpam: "받은 편지함에 이 메일이 수신되지 않은 경우, 스팸메일 폴더도 확인하세요.",
        LoginForm_dontReceive: "이메일을 받지 못하셨습니까? %s 앞으로 3분 안에 도착하지 않으면 다시 요청할 수 있습니다!",
        LoginForm_mailMeAgain: "나에게 이메일로 링크 다시 보내기",
        LoginForm_passwordResetLinkSent_Popup: "비밀번호 재설정 링크를 이메일로 보냄",
        ChangePassword_newPasswordRepeat: "새 비밀번호 반복",
        ChangePassword_submit: "비밀번호 설정",
        ChangePassword_setMyPasswordSuccessDesc: "비밀번호가 업데이트되었습니다.",
        ChangePassword_setMyPasswordSuccess: "비밀번호가 %s 설정됨",
        ChangePassword_InvalidLink: "비밀번호 재설정 링크가 더 이상 올바르지 않습니다. 새 링크를 요청하세요.",
        ChangeExpiredPassword_HeaderDesc: "%s 비밀번호가 만료되었습니다. 현재 비밀번호를 변경하세요.",
        SelfInvite_Copied: "복사함",
        SelfInvite_requestSent: "가입 요청을 이미 보냄",
        SelfInvite_ReviewDesc: "beqom 액세스 요청을 검토 중입니다. 곧 이메일 초대장을 보내드리겠습니다.",
        Tandem_On_GooglePlay: "Google Play의 beqom",
        Tandem_On_iTunes: "App Store의 beqom",
        Logout_Expire_Session_Title: "보안 로그인",
        Logout_Expire_Session_Text: "현재 세션이 만료되었습니다. 계속하려면 로그인하세요!",
        ApplicationError_Title: "애플리케이션 오류",
        SnackBar_ErrorId: "오류 ID:",
        SnackBar_Message: "이런, 죄송합니다. beqom 에서 오류가 발생했습니다. 다시 시도하거나 IT 헬프데스크 또는 시스템 관리자에게 문의하여 지원을 받으시기 바랍니다. 오류 ID를 복사하고 다른 상세정보를 함께 제공하면 문제 조사를 속행할 수 있습니다.",
        SnackBar_Copy: "복사",
        SnackBar_IsCopied: "오류 ID가 클립보드에 복사됨",
        ResetPassword_AdditionalDescription: "방금 사용자 이름(ID)이 {username}인 사용자에게 이메일을 보냄",
        SettingsDataPrivacyStatement_popupText1: "다음을 수락하여",
        SettingsDataPrivacyStatement_withUpdates_popupText1: "다음의 업데이트된 버전을 수락하여",
        SettingsDataPrivacyStatement_Read: "데이터 프라이버시 공지",
        SettingsDataPrivacyStatement_popupText2: "계속 진행",
        SettingsDataPrivacyStatement_withUpdates_popupText2: "계속 진행",
        OnboardingWizard_Next: "다음",
        OnboardingWizard_Back: "뒤로",
        MainMenu_EditProfile: "프로필 편집",
        MainMenu_LogOut: "로그아웃",
        IdentityError_PasswordIsExpired: "비밀번호가 만료되었습니다.",
        IdentityError_PasswordMismatch: "비밀번호가 일치하지 않습니다.",
        IdentityError_PasswordPolicyNotFound: "비밀번호 정책을 찾을 수 없습니다.",
        IdentityError_PasswordRequiresDigit: "비밀번호에는 반드시 숫자를 포함해야 합니다.",
        IdentityError_PasswordRequiresLower: "비밀번호에는 반드시 소문자를 포함해야 합니다.",
        IdentityError_PasswordRequiresNonAlphanumeric: "비밀번호에는 반드시 영숫자가 아닌 글자를 포함해야 합니다.",
        IdentityError_PasswordRequiresUniqueChars: "비밀번호에는 반드시 고유한 글자를 포함해야 합니다.",
        IdentityError_PasswordRequiresUpper: "비밀번호에는 반드시 대문자를 포함해야 합니다.",
        IdentityError_PasswordTooLong: "제공한 비밀번호가 너무 깁니다.",
        IdentityError_PasswordTooShort: "제공한 비밀번호가 너무 짧습니다.",
        Office365_AppInfo: "All rights reserved. 빌드: ",
        Office365_GoNext: "다음으로 이동",
        Office365_LoginForm_Company_not_found: "죄송합니다. 입력한 회사 이름이 잘못되었습니다.",
        Office365_LoginForm_companyPlaceholder: "회사 이름",
        Office365_LoginForm_userName_place: "이메일 또는 로그인",
        Office365_DataPrivacyConsentNotice: "데이터 프라이버시 동의 공지",
        Office365_AcceptDpToProceed: "계속 진행하기에 앞서, 우선 다음을 수락하세요. ",
        Office365_Ok: "확인",
        Office365_Logout_Modal: "로그아웃하시겠습니까?",
        MaintenancePage_Title: "곧 복구 예정",
        MaintenancePage_Description: "현재 예약된 유지 관리 작업이 진행 중입니다. 곧 복구될 것입니다. 기다려 주셔서 감사합니다.",
        ExternalParticipant_DataPrivacy_Text: "당사",
        ExternalParticipant_DataPrivacy_Link: "개인정보 보호정책 읽기",
        UpdateApp_Button: "beqom 업데이트",
        UpdateApp_TopBar_Title: "업데이트 필요",
        ExternalDeclineSuccessTitle_Declined: "설문조사 요청을 거부했습니다.",
        ExternalDeclineSuccessMessage: "지금 요청에 답할 수 없다는 사실을 알려주셔서 감사합니다.",
        ExternalUnsubscribeTitle: "{CompanyName} 회사의 모든 설문조사에 참가하지 않으시겠습니까?",
        ExternalUnsubscribeMessage: "{CompanyName} 회사의 모든 설문조사 요청을 옵트아웃하면 당사가 귀하를 다시 초대하거나 설문조사에 관한 알림을 보낼 수 없게 됩니다.",
        ExternalUnsubscribeBtn: "설문조사 옵트아웃",
        ExternalUnsubscribeAction: "{CompanyName} 회사의 모든 설문조사 요청을 옵트아웃하겠습니다.",
        ExternalUnsubscribeSuccessTitle: "{CompanyName} 회사의 모든 추가 설문조사 요청을 옵트아웃했습니다.",
        ExternalUnsubscribeSuccessMessage: "향후 언제든 결정을 바꾸려는 경우, {CompanyName} 회사의 담당자에게 문의하여 beqom 에서 사용자 동의서를 업데이트해달라는 서면 요청서를 보내야 합니다.",
        Teams_WelcomePage_Button_SignIn: "로그인",
        Teams_WelcomePage_Label: "CPM by beqom",
        LoginPage_Moto: "강력한 성능과 빠른 성장",
        LoginPage_BeqomMotto: "to make your people {happy}",
        LoginPage_BeqomMotto_Happy: "happy",
        Teams_WelcomePage_Description: "사용하기 쉬우며 모든 단계에서 측정 가능한 사용자 중심의 beqom Continuous Performance Management(CPM) 플랫폼으로 새로운 사용자 중심의 시대에 대비하세요."

    },
    hu: {
        "version_upgrade_title": "A beqom új verziója már elérhető. A frissítéshez látogass el az Alkalmazás-áruházba!",
        "version_upgrade": "Megjelent a beqom új verziója! Az oldal 3 másodperc múlva frissül, utána folytathatod. Köszönjük!",
        "version_upgrade_app": "",
        "user_deactivated_title": "Ez a felhasználói fiók már nem aktív",
        "user_deactivated": "A fiókod már nem aktív. Támogatásért vedd fel a kapcsolatot az IT ügyfélszolgálattal vagy a rendszergazdával.",
        "user_notfound_title": "Ez a felhasználói fiók nem található a beqomben",
        "user_notfound": "Ezzel az e-mail címmel nincs felhasználó a vállalat  beqom fiókjában. Támogatásért vedd fel a kapcsolatot az IT ügyfélszolgálattal vagy a rendszergazdával.",
        "connection_button_reload": "Újratöltés",
        "logIn": "Bejelentkezés",
        "LoginForm_forgotPass": "Első jelszavadat állítod be/elfelejtetted a jelszavadat?",
        "LoginForm_logWithAzureAd": "Bejelentkezés a saját vállalati bejelentkezési adatokkal",
        "LoginForm_copyright1": "Minden jog fenntartva.",
        "LoginForm_warning": "Figyelem!",
        "LoginForm_ProblemWithTandemAccount": "Problémák vannak a beqom-fiókoddal. Támogatásért vedd fel a kapcsolatot az IT ügyfélszolgálattal vagy a rendszergazdával.",
        "LoginForm_ProblemWithUserCredentials": "A felhasználónév vagy a jelszó helytelen. Kérjük, próbáld újra!",
        "LoginForm_FailedError": "Hoppá, sajnos valami hiba történt a beqomben. Kérjük, próbáld újra, vagy támogatásért vedd fel a kapcsolatot az IT ügyfélszolgálattal vagy a rendszergazdával.",
        "LoginForm_IncorrectCompanyName": "A vállalat neve nem található. Próbáld újra, vagy vedd fel a kapcsolatot a rendszergazdával.",
        "LoginForm_ProblemWithYourCompanyLogin": "Problémák vannak a vállalati bejelentkezési adatokkal. Támogatásért vedd fel a kapcsolatot az IT ügyfélszolgálattal vagy a rendszergazdával.",
        "LoginForm_EmptyCompanyName": "Kérjük, add meg a vállalat nevét.",
        "LoginForm_passwordResetRequired": "Jelszó visszaállítás kötelező a jelenlegi felhasználónál.",
        "LoginForm_companyName": "Vállalat",
        "LoginForm_userName": "Felhasználónév",
        "LoginForm_password": "Jelszó",
        "LoginForm_signInCompanyText": "Ehhez a vállalathoz jelentkeztél be:",
        "continueButton": "Folytatás",
        "cancel": "Mégse",
        "ChangeCompany": "Saját vállalat nevének ismételt megadása",
        "SettingsDataPrivacyStatement_title": "Adatvédelmi nyilatkozat",
        "SettingsDataPrivacyStatement_popupYes": "Elfogadás",
        "SettingsDataPrivacyStatement_popupNo": "Elutasít",
        "SettingsDataPrivacyStatement_popupOk": "OK",
        "SettingsDataPrivacyStatement_CloseAndExit": "Bezárás és kijelentkezés",
        "SettingsDataPrivacyStatement_Version": "Verzió",
        "SettingsDataPrivacyStatement_YouwillBeLogouted": "Ki fog jelentkezni – biztos benne?",
        "ChangePassword_header": "Jelszó megváltoztatása",
        "ChangePassword_currentPassword": "jelenlegi jelszó",
        "ChangePassword_newPassword": "új jelszó",
        "ChangePassword_newPasswordRetype": "új jelszó megerősítése",
        "PasswordChangeMessage": "Jelszómódosítás",
        "PasswordChange_ok": "Jelszavát sikeres módosította és 3 másodpercen belül ki leszel léptetve.",
        "PasswordChange_errNewPasswordsDoNotMatch": "A új jelszavak nem egyeznek meg.",
        "PasswordChange_err": "Probléma merült fel jelszavad módosítása során. Kérjük, próbáld újra!",
        "PasswordResetTitle": "Jelszó beállítása/visszaállítása",
        "PasswordResetMessage": "Jelszó visszaállítása",
        "PasswordReset_ok": "A jelszó-visszaállítási hivatkozást elküldtük az e-mail címedre.",
        "PasswordReset_err": "Sajnos probléma van a jelszavad visszaállításával. Kérjük, próbáld újra!",
        "ChangeExpiredPassword_MessageHeader": "Jelszó lejárt",
        "ChangeExpiredPassword_Message": "A(z) {0} jelszava lejárt. Kérjük, változtassa meg jelszavát.",
        "ChangePassword_Policy_Header": "A jelszavának az alábbi követelményeknek kell megfelelnie: ",
        "ChangePassword_Policy_AllowDictionaryWords": "Szótárban szereplő szavak nem megengedettek",
        "ChangePassword_Policy_AllowPasswordAsUserName": "A felhasználónevét nem használhatod a jelszavadban",
        "ChangePassword_Policy_MaximumLength": "A jelszó maximális hossza",
        "ChangePassword_Policy_MinimumLength": "A jelszó minimális hossza",
        "ChangePassword_Policy_RequireDigit": "Tartalmaznia kell legalább egy számot",
        "ChangePassword_Policy_RequireLowercase": "Tartalmaznia kell legalább egy kisbetűt",
        "ChangePassword_Policy_RequireNonAlphanumeric": "Tartalmaznia kell legalább egy nem alfanumerikus karaktert (!$%@#&^*)",
        "ChangePassword_Policy_RequireUppercase": "Tartalmaznia kell legalább egy nagybetűt",
        "ChangePassword_Policy_EnforceHistory_Start": "Előző",
        "ChangePassword_Policy_EnforceHistory_End": "jelszavak nem megengedettek",
        "DeclinedDataPrivacy_Message": "Korábban elutasítottad az adatkezeléshez hozzájáruló nyilatkozatot. Fiókod újraaktiválásához fordulj az IT ügyfélszolgálatához. Amíg fiókod újra nem aktiválódik; nem tudod használni a beqomet.",
        "DeclinedDataPrivacy_Title": "Fiókodnak újraaktiválása",
        "DataPrivacy_EffectiveDate": "Érvényesség dátuma",
        "no": "Nem",
        "yes": "Igen",
        "SettingsLegal_releaseNumber": "Build:",
        "SelfInvite_SignedIntoCompany": "Ehhez a vállalathoz jelentkeztél be:",
        "SelfInvite_Welcome": "Üdvözjünk a beqomnél!",
        "SelfInvite_ThanksForComing": "Köszönjük, hogy csatlakoztál a visszajelzés közösségünkhöz.",
        "SelfInvite_SendMailShortly": "Hamarosan elvégezzük a hozzád tartozó beállítást, és küldünk egy meghívót.",
        "SelfInvite_Select": "Addig is válasszon bátran",
        "SelfInvite_ThankYou": "Köszönjük!",
        "SelfInvite_YouWillReceiveInvite": "Hamarosan megkapod a hivatalos meghívót.",
        "SelfInvite_ShareLink": "Ha szeretnél egy kollégát is meghívni, akkor osszd meg ezt a hivatkozást.",
        "SelfInvite_InviteSent": "A csatlakozási kérésedet már elküldték!",
        "SelfInvite_Review": "Amint be tudsz jelentkezni, e-mailben kapsz egy meghívót.",
        "SelfInvite_CopyToClipboard": "Hivatkozás másolása a vágólapra",
        "SelfInvite_JoinNow": "Csatlakozz most!",
        "LoginForm_CompanyNotFound": "Vállalatod neve nem található. Ellenőrizd a helyesírást és próbáld újra. További támogatásért vedd fel a kapcsolatot a belső IT ügyfélszolgálattal vagy a rendszergazdával.",
        "LoginForm_CompanyName": "Vállalat neve",
        "LoginForm_Welcome": "Üdvözlünk!",
        "LoginForm_Description": "visszajelzés, amely inspirál",
        "LoginForm_CompanySubmit": "Bevitel",
        "LoginForm_GreatSee": "Örülünk a találkozásnak",
        "LoginForm_logWithTandem": "Jelentkezz be a beqom bejelentkezési adataival",
        "LoginForm_forgotText": "Elfelejtetted a jelszavadat, vagy első alkalommal szeretnél jelszót beállítani? Add meg a felhasználónevet alább, és e-mailben elküldjük az utasításokat",
        "LoginForm_mailMe": "Küldjenek egy hivatkozást e-mailben",
        "LoginForm_backToLogin": "Vissza a bejelentkezéshez?",
        "LoginForm_setMyPassword": "Saját jelszó beállítása",
        "LoginForm_checkMail": "Ellenőrizd az e-mail fiókodban, hogy nem érkezett-e e-mail a beqomtől a jelszó visszaállításához szükséges egyedi hivatkozással.",
        "LoginForm_checkSpam": "Ha nem kapod meg ezt a levelet a beérkező levelek közé, akkor nézd meg a levélszemét mappáit is.",
        "LoginForm_dontReceive": "Nem kaptad meg az e-mailünket? %s Ha a következő 3 percben nem érkezik meg, akkor kérheted újra!",
        "LoginForm_mailMeAgain": "Ismét küldjenek egy hivatkozást e-mailben",
        "LoginForm_passwordResetLinkSent_Popup": "A jelszó-visszaállítási hivatkozást elküldtük az e-mail címedre",
        "ChangePassword_newPasswordRepeat": "új jelszó megismétlése",
        "ChangePassword_submit": "Saját jelszó beállítása",
        "ChangePassword_setMyPasswordSuccessDesc": "Jelszavad sikeresen frissítésre került.",
        "ChangePassword_setMyPasswordSuccess": "A jelszó %s beállítva",
        "ChangePassword_InvalidLink": "A jelszó-visszaállítási hivatkozás már nem érvényes. Kérj új hivatkozást",
        "ChangeExpiredPassword_HeaderDesc": "A(z) %s jelszava lejárt. Változtasd meg a jelenlegi jelszavadat.",
        "SelfInvite_Copied": "Másolva",
        "SelfInvite_requestSent": "Csatlakozásra vonatkozó kérésed már elküldésre került",
        "SelfInvite_ReviewDesc": "A beqomhez való hozzáférési kérésed vizsgálat alatt áll. Hamarosan e-mailben kapsz egy meghívót.",
        "Tandem_On_GooglePlay": "beqom a Google Play áruházban",
        "Tandem_On_iTunes": "beqom az App Store áruházban",
        "Logout_Expire_Session_Title": "Bejelentkezés titkosítás mellett",
        "Logout_Expire_Session_Text": "Jelenlegi munkameneted lejárt. A folytatáshoz jelentkezz be!",
        "ApplicationError_Title": "Alkalmazáshiba",
        "SnackBar_ErrorId": "Hibaazonosító:",
        "SnackBar_Message": "Hoppá, sajnos valami hiba történt a beqomben. Kérjük, próbáld újra, vagy támogatásért vedd fel a kapcsolatot az IT ügyfélszolgálattal vagy a rendszergazdával. Másolja ki a hibaazonosítót az egyéb adatokkal együtt a probléma kivizsgálásának felgyorsítása érdekében.",
        "SnackBar_Copy": "Másolás",
        "SnackBar_IsCopied": "A hibaazonosító átmásolásra került a vágólapra",
        "ResetPassword_AdditionalDescription": "Nemrég küldtünk egy e-mailt a következő felhasználónévvel rendelkező felhasználónak: {username}",
        "SettingsDataPrivacyStatement_popupText1": "Fogadd el a(z)",
        "SettingsDataPrivacyStatement_withUpdates_popupText1": "Fogadd el a frissített",
        "SettingsDataPrivacyStatement_Read": "Adatvédelmi nyilatkozat",
        "SettingsDataPrivacyStatement_popupText2": "folytatáshoz",
        "SettingsDataPrivacyStatement_withUpdates_popupText2": "folytatáshoz",
        "OnboardingWizard_Next": "Következő",
        "OnboardingWizard_Back": "Vissza",
        "MainMenu_EditProfile": "Profil szerkesztése",
        "MainMenu_LogOut": "Kijelentkezés",
        "IdentityError_PasswordIsExpired": "Jelszava lejárt.",
        "IdentityError_PasswordMismatch": "Jelszavak nem egyeznek.",
        "IdentityError_PasswordPolicyNotFound": "Jelszószabályzat nem található.",
        "IdentityError_PasswordRequiresDigit": "A jelszónak számokat is tartalmaznia kell.",
        "IdentityError_PasswordRequiresLower": "A jelszónak kisbetűket is tartalmaznia kell.",
        "IdentityError_PasswordRequiresNonAlphanumeric": "A jelszónak nem alfanumerikus karaktereket is tartalmaznia kell.",
        "IdentityError_PasswordRequiresUniqueChars": "A jelszónak egyedi karaktereket is tartalmaznia kell.",
        "IdentityError_PasswordRequiresUpper": "A jelszónak nagybetűket is tartalmaznia kell.",
        "IdentityError_PasswordTooLong": "A megadott jelszó túl hosszú.",
        "IdentityError_PasswordTooShort": "A megadott jelszó túl rövid.",
        "Office365_AppInfo": "Minden jog fenntartva. Build: ",
        "Office365_GoNext": "Menj a következőre",
        "Office365_LoginForm_Company_not_found": "A megadott vállalatnév nem érvényes",
        "Office365_LoginForm_companyPlaceholder": "Vállalat neve",
        "Office365_LoginForm_userName_place": "E-mail vagy bejelentkezési adatok",
        "Office365_DataPrivacyConsentNotice": "Adatkezeléshez hozzájáruló nyilatkozat",
        "Office365_AcceptDpToProceed": "Mielőtt folytatnád, fogadd el a mi ",
        "Office365_Ok": "Ok",
        "Office365_Logout_Modal": "Biztosan kijelentkezel?",
        "ExternalParticipant_DataPrivacy_Text": "Olvasd el a mi",
        "ExternalParticipant_DataPrivacy_Link": "adatvédelmi nyilatkozatunkat",
        "ExternalUnsubscribeTitle": "Biztos vagy benne, hogy nem kívánsz részt venni {CompanyName} a vállalat egyik kérdőívében sem?",
        "ExternalUnsubscribeMessage": "Ha leiratkozol a(z) {CompanyName} vállalattól származó kérdőív felkérésekről, akkor nem tudunk újra meghívni, és nem tudunk értesítést küldeni a kérdőívekről.",
        "ExternalUnsubscribeBtn": "Leiratkozás a kérdőívekről",
        "ExternalUnsubscribeAction": "Szeretnék leiratkozni a(z) {CompanyName} vállalattól származó kérdőíves felkérésekről.",
        "ExternalUnsubscribeSuccessTitle": "Leiratkoztál a(z) {CompanyName} vállalattól származó minden jövőbeni kérdőíves felkérésről.",
        "ExternalUnsubscribeSuccessMessage": "Ha a jövőben bármikor módosítani szeretnéd a döntésedet, akkor vedd fel a kapcsolatot a(z) {CompanyName} vállalatnál levő ügyintézőjével, és küldj neki írásbeli kérést, hogy a beqom rendszerünkben frissítse a hozzájárulásodat.",
        "ExternalDeclineSuccessTitle_Declined": "Elutasítottad a kérdőívre való felkérést.",
        "ExternalDeclineSuccessMessage": "Köszönjük az értesítésedet, hogy jelenleg nem tudsz válaszolni a felkérésükre.",
        "UpdateApp_Button": "beqom rendszerünk frissítése",
        "UpdateApp_TopBar_Title": "Frissítés szükséges",
        "MaintenancePage_Title": "Hamarosan ismét itt leszünk",
        "MaintenancePage_Description": "Jelenleg tervezett karbantartás folyik. Hamarosan ismét itt leszünk. Köszönjük a türelmedet.",
        "Teams_WelcomePage_Button_SignIn": "Bejelentkezés",
        "Teams_WelcomePage_Label": "CPM by beqom",
        "LoginPage_Moto": "a teljesítményt és növekedést ösztönzi",
        "LoginPage_BeqomMotto": "to make your people {happy}",
        "LoginPage_BeqomMotto_Happy": "happy",
        "Teams_WelcomePage_Description": "Készülj fel egy új korszakra a beqom Continuous Performance Management (CPM) platformmal, amely teljes mértékben az embereket tartja szem előtt, könnyen használható és mérhető."
    }
});

export default lclzStor;
