export enum Pages {
  home = 'tab',
  welcome = 'welcome',
  login = 'login',
  company = 'company',
  giveFeedback = 'give-feedback',
  requestFeedback = 'request-feedback',
  feedbackNotes = 'feedback-notes',
  dataPrivacy = 'data-privacy',
  createCheckin = 'create-checkin',
  createGoal = 'create-goal'
}
