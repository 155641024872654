import * as actionTypes from "./actionTypes";
import { getCurrentUserSettings } from "../shared/src/services/main/MainService";
import { Dispatch } from "../common/types/reduxTypes";
import { Settings } from "../shared/src/models/entities/settings/Settings";

export const getAllSettings = () => {
    return (dispatch: Dispatch) => {
        getCurrentUserSettings().then((result: Settings) => {
            dispatch({ type: actionTypes.GET_SETTINGS, settings: result });
        }).catch(error => console.log(error));
    };
};

// export const geCompanyLogoSettings = () => {
//   return async (dispatch: Dispatch) => {
//       try {
//           const logoSettings = await getCompanyLogoSettings();
//           if(logoSettings){
//               dispatch({ type: actionTypes.GET_LOGO_SETTINGS_SUCCESS, companyLogoSettings: logoSettings });
//           }
//       } catch (error) {
//           dispatch({ type: actionTypes.GET_LOGO_SETTINGS_FAILED});
//       }
//   };
// };
