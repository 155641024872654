import lclzStor from '../shared/src/languages/BaseLang';
import { getCurrentUser, sendUserTimeZone } from '../shared/src/services/main/MainService';
import { getImage } from '../shared/src/services/image/imageService';
import { Dispatch } from '../common/types/reduxTypes';
import * as actionTypes from './actionTypes';
import { logLoginTimings } from './logging';
import { toggleSpinner } from './spinner';
import { getUserByGuid, getLocalisationSources } from '../services/user';
import { UserProfile } from '../shared/src/models/entities/user/UserProfile';

const localisationSourcesLoadedSuccess = () => ({
    type: actionTypes.LOCALISATION_SOURCES_LOADED_SUCCESS
});

export const getUserInfo = (guid: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(toggleSpinner(true));
        try {
            await getUserByGuid(guid);
            const userDetails = await getCurrentUser();
            const userProfile = new UserProfile(userDetails);
            const loadedSources = await getLocalisationSources(userProfile.languageShortName);
            lclzStor.setLanguage(userProfile.languageShortName);
            for (let key in loadedSources) {
                lclzStor[key] = loadedSources[key];
            }

            dispatch(localisationSourcesLoadedSuccess());
            if (userProfile.img) {
                try {
                    const userImage = await getImage(userProfile.img);

                    dispatch({ type: actionTypes.USER_IMAGE, img: userImage });
                } catch (e) {
                    dispatch({ type: actionTypes.USER_IMAGE, img: null });
                }
            } else {
                dispatch({ type: actionTypes.USER_IMAGE, img: null });
            }

            dispatch(success(userProfile));
            dispatch(tellServerUserTimeZone());
            dispatch(logLoginTimings(userProfile));
            dispatch(toggleSpinner(false));
        } catch (e) {
            dispatch(toggleSpinner(false));
            dispatch(failure());
        }
    };
};

function success(userInfo: any) {
    return { type: actionTypes.USER_DETAILS_SUCCESS, userInfo };
}

function failure() {
    return { type: actionTypes.USER_DETAILS_FAILURE };
}

export const setOnboardingDisable = () => {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.ONBOARDING_MODAL, enable: false });
        dispatch({ type: actionTypes.REMOVE_ONBOARDING_DATA });
    };
};

export const logoutModalToggle = (value: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.LOGOUT_MODAL, toggleLogout: value });
    };
};
export const addFbNoteSearch = (value: string) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.FB_ADD_NOTE_SEARCH, fbAction: value });
    };
};
export const clearSearch = () => {
    return (dispatch: Dispatch) => {
        dispatch({ type: actionTypes.FB_CLEAR_NOTE_SEARCH });
    };
};
const tellServerUserTimeZone = () => {
    return (dispatch: Dispatch) => {
        return sendUserTimeZone()
            .then(() => {
                dispatch({ type: actionTypes.USER_TIMEZONE_SEND_SUCCESS });
            });
    };
};
