import * as React from 'react';
import { Flex } from "@fluentui/react-northstar";
import { FA_EXCLAMATION_TRIANGLE } from '../../../shared/src/constants';

export const ShowError = ({ error }) => {
    return error ?
    (
        <Flex vAlign="center" hAlign="center" className="pb-error_row loginFormFieldDiv w-100">
            <div className="pl-0 spacing-right-sm">
                <i className={`${FA_EXCLAMATION_TRIANGLE} fa-light tile-icon`} />
            </div>
            <div className="pl-0">{error}</div>
        </Flex>
    ) : null;
};
