import { Dialog } from '@fluentui/react-northstar';
import { PopUp } from './elements/PopUp';
import { FA_MESSAGE_PEN, FA_XMARK } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import { GiveFeedback } from './GiveFeedback';
import React from 'react';
import { hashHistory } from '../shared/src/utils/history';
import { APP_CONSTANTS } from '../common/constants';

export const GiveFeedbackWidgetMobile = () => {
    const [isFeedbackSent, setIsFeedbackSent] = React.useState(false);
    const onCancel = () => hashHistory.push(APP_CONSTANTS.LINKS.HOME);
    const onSuccess = () => {
        setIsFeedbackSent(true);
    };
    const closeSuccessPopUP = () => {
        setIsFeedbackSent(false);
        hashHistory.push(APP_CONSTANTS.LINKS.HOME);
    };
    return (
        <>
            <Dialog
                content={
                    <PopUp iconName={FA_MESSAGE_PEN} title={lclzStor.GiveFeedback_fbckSent} primaryBtnText={lclzStor.GotIt_Btn} primaryCB={closeSuccessPopUP} />}
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={closeSuccessPopUP} />}
                open={isFeedbackSent}
            />
            <GiveFeedback onCancel={onCancel} onSuccess={onSuccess} />
        </>
    );
};