import { Flex, Text } from '@fluentui/react-northstar';
import React from 'react';
import { MAX_LENGTH_3000 } from '../../shared/src/constants';

export const CharsQty = ({num, maxLength= MAX_LENGTH_3000}) => {
    return (
        <Flex>
            <Flex.Item push>
                <Text size="small" styles={{ color: 'GrayText' }} content={`${num}/${maxLength}`} />
            </Flex.Item>
        </Flex>
    );
};