import { AnyAction } from '@reduxjs/toolkit';
import { IUserStore } from '../common/interfaces/user/IUserStore';
import * as actionTypes from '../actions/actionTypes';
import { UserProfile } from '../shared/src/models/entities/user/UserProfile';

export const initialState: IUserStore = {
    userInfo: {} as UserProfile,
    userImg: '',
    isProfileLoaded: false,
    fbAction: null
};

export function user(state: IUserStore = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.USER_SUCCESS:
            return { ...state, userData: action.data };
        case actionTypes.USER_FAILURE:
            return { ...state, userData: null, isProfileLoaded: false };
        case actionTypes.USER_DETAILS_SUCCESS:
            return { ...state, userInfo: action.userInfo, isProfileLoaded: true };
        case actionTypes.CLEAR_USER:
        case actionTypes.USER_DETAILS_FAILURE:
            return { ...state, userInfo: {} };
        case actionTypes.USER_IMAGE:
            return { ...state, userImg: action.img };
        case actionTypes.SENDER_NAME:
            return { ...state, senderName: action.name };
        case actionTypes.SET_ONBOARDING_DATA:
            return { ...state, onboardingData: action.onboardingData };
        case actionTypes.REMOVE_ONBOARDING_DATA:
            return { ...state, onboardingData: null };
        case actionTypes.FB_ADD_NOTE_SEARCH:
            return { ...state, fbAction: action.fbAction };
        case actionTypes.FB_CLEAR_NOTE_SEARCH:
            return { ...state, fbAction: null };
        default:
            return state;
    }
}
