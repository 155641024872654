import apiGateway from '../shared/src/modules/api/apiGateway';
import ISearchOrgItem from '../shared/src/models/interfaces/search/ISearchOrgItem';
import { IPageResponse } from '../shared/src/models/interfaces/IPageResponse';
import { IUserSearchDataModel } from '../shared/src/models/interfaces/search/IUserSearchDataModel';
import ISearchUser from '../shared/src/models/interfaces/search/ISearchUser';
import { UserActions } from '../shared/src/models/enums/permission/UserActions';
import INote from '../shared/src/models/interfaces/feedbacks/INote';
import IFeedbackMultiple, { IFeedbackShorten } from '../shared/src/models/interfaces/feedbacks/IFeedbackMultiple';
import IFeedbackToMultipleResponse from '../shared/src/models/interfaces/feedbacks/IFeedbackToMultipleResponse';
import IRequestFeedback from '../shared/src/models/interfaces/feedbacks/IRequestFeedback';

export const searchUsersPaged = (searchString: string, excludeList: number[], page: number, size: number = 10, roles: number[], subordinatesOnly: boolean = false, action?: UserActions): Promise<IPageResponse<ISearchUser>> => {
    let queryString = encodeURIComponent(searchString);
    if (excludeList && excludeList.length) {
        queryString = `${queryString}&exclude=${excludeList.join(',')}`;
    }
    if (roles && roles.length) {
        queryString = `${queryString}&roles=${roles.join(',')}`;
    }
    if (subordinatesOnly) {
        queryString = `${queryString}&subordinatesOnly=${subordinatesOnly}`;
    }
    if (action) {
        queryString = `${queryString}&action=${action}`;
    }
    return apiGateway.getAsync(`users/active-paged?search=${queryString}&size=${size}&page=${page}`, true);
};
export const searchOrgItems = (searchString?: string, excludeList?: number[], withoutRoot?: boolean): Promise<ISearchOrgItem[]> => {
    let search = encodeURIComponent(searchString) || '';
    if (excludeList && excludeList.length) {
        search = `${search}&exclude=${excludeList.join(',')}`;
    }
    const additionalParams = withoutRoot ? '&withoutRoot=true' : '';
    return apiGateway.getAsync(`organisation-items?search=${search}${additionalParams}`, true);
};
export const searchOIWithEmptyParent = (searchString: string = '', excludeList: number[] = [], page: number, withoutRoot: boolean, size: number = 10): Promise<IPageResponse<ISearchOrgItem>> => {
    let queryString = encodeURIComponent(searchString);
    if (excludeList && excludeList.length) {
        queryString = `${queryString}&exclude=${excludeList.join(',')}`;
    }
    const additionalParams = withoutRoot ? '&withoutRoot=true' : '';
    return apiGateway.getAsync(`organisation-items/paged?search=${queryString}&size=${size}&page=${page}${additionalParams}`, true);
};
export const searchUsersPagedGuid = (data: IUserSearchDataModel): Promise<IPageResponse<ISearchUser>> => apiGateway.postAsync('users/active-paged', data, true);

export const addNote = (note: INote): Promise<void> => apiGateway.postAsync('feedbacknote', note);
export const sendFeedback = (data: IFeedbackShorten) => {
    return apiGateway.postAsync(`teams/feedback`, data);
};
export const saveMultiFeedback = (feedback: IFeedbackMultiple): Promise<IFeedbackToMultipleResponse> => apiGateway.postAsync('feedbacks/to-multiple', feedback);
export const sendRequestFeedback = (requestData: IRequestFeedback): Promise<string> => apiGateway.postAsync('feedback-requests', requestData);