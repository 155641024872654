import { getIconName, getNotificationImage, isIcon } from '../common/utils/notification';
import { useSelector } from 'react-redux';
import IStore from '../common/interfaces/IStore';
import { useTeams } from '../hooks/useTeams';
import { INotificationItem } from '../shared/src/models/interfaces/notifications/INotificationItem';
import { Avatar, Card, Flex, Text, Divider } from '@fluentui/react-northstar';
import StyledAvatar from '../shared/src/components/Elements/Styled/Avatar/StyledAvatar';
import { getInitials } from '../common/utils/utils';
import { htmlToText } from '../shared/src/utils/common';
import React from 'react';
import { Timer } from './elements/Timer';
import { FA_CIRCLE, FA_TRASH_ALT } from '../shared/src/constants';
import { getUrlToTandem } from '../utils/common';
import * as localStorage from '../shared/src/modules/localStorage';
import { LOCAL_STORAGE_KEYS } from '../common/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import { markNotificationsAsRead } from '../services/Notifications';
import { CustomTooltip } from './elements/CustomTooltip';

 interface IProps {
     notification: INotificationItem;
     deleteNotification: () => void;
 }

export const NotificationItem = (props: IProps) => {
    const { notification } = props;
     const [isRead, setIsRead] = React.useState(notification?.IsRead);
    const { userInfo } = useSelector((state: IStore) => state.user);
    const { theme } = useTeams({})[0];
    const img = getNotificationImage(props.notification, userInfo?.img);
    const { brand } = theme.siteVariables.contextualColors;
    const style = isRead ? null : { backgroundColor: theme.siteVariables.colorScheme.grey.background4 };
    const iconColor = isRead ? theme.siteVariables.white : brand[600];
    const weight = isRead ? 'fa-regular' : 'fa-solid';
    const urlToTandem = getUrlToTandem(localStorage.loadItem(LOCAL_STORAGE_KEYS.COMPANY_DATA).FrontendUrl, '');
    const navigateToTandem = () => {
        window.open(`${urlToTandem}${notification?.TargetUrl}`, '_blank');
        markNotificationsAsRead(notification?.Id)
            .then(() => {
                setIsRead(true);
            });
    };

    const markAsReadHandler = async () => {
        await markNotificationsAsRead(notification?.Id);
        setIsRead(!isRead);
    };
    return (
        <>
            <Card fluid compact className="height-auto notification-card py-2 cursor-pointer" styles={style}>
                <Flex vAlign="center" space="between">
                    <Flex onClick={navigateToTandem} className="flex-grow-1">
                        <div className="user-info medium mr-3">
                            {
                                isIcon(img) ? (
                                    <i className={`${getIconName(img)} fa-regular notification-icon`} style={{color: brand[600] }} />
                                ) : (
                                    <>
                                        {img
                                            ? <StyledAvatar fullName={notification?.FromUser?.FullName} imgId={img} />
                                            : <Avatar getInitials={getInitials} name={notification?.FromUser?.FullName} size="medium" />
                                        }
                                    </>
                                )
                            }
                        </div>
                        <div>
                            <Text size="medium" content={htmlToText(notification?.NotificationText)} as="div" />
                            <Timer minutesDifference={notification?.MinutesDifference} />
                        </div>
                    </Flex>
                    <Flex vAlign="center">
                        <CustomTooltip
                            content={isRead ? lclzStor.Notification_MarkAsNotRead : lclzStor.Notification_MarkAsRead}
                            trigger={<i className={`${FA_CIRCLE} ${weight} unread-icon py-3 px-2 cursor-pointer`} style={{color: iconColor }}
                                        onClick={markAsReadHandler} />}
                        />
                        <CustomTooltip
                            content={lclzStor.Notification_DeleteText}
                            trigger={<i className={`${FA_TRASH_ALT} fa-regular trash-icon py-3 pr-3 pl-2 cursor-pointer`}
                                        onClick={props.deleteNotification} />}
                        />
                    </Flex>
                </Flex>
            </Card>
            <Divider fitted />
        </>
    );
};