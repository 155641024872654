import { FormLabel, TextArea } from '@fluentui/react-northstar';
import { MAX_LENGTH_3000 } from '../../shared/src/constants';
import { CharsQty } from './CharsQty';
import React from 'react';

interface IProps {
    title: string;
    message: string;
    handleMessage: (val: string) => void;
    placeholder: string;
    name: string;
}
export const CommentBlock = (props: IProps) => {
    const {title, message, handleMessage, placeholder, name} = props;
    const handleMessageHandler = (e: React.SyntheticEvent<HTMLElement>) => {
        // @ts-ignore
        const { value } = e.currentTarget;
        handleMessage(value);
    };
    return (
        <>
            <FormLabel htmlFor={name} id={`${name}-label`}>
                {title}
            </FormLabel>
            <TextArea
                fluid
                name={name}
                aria-labelledby={name}
                value={message}
                onChange={handleMessageHandler}
                placeholder={placeholder}
                variables={{ height: 110 }}
                maxLength={MAX_LENGTH_3000}
            />
            <CharsQty num={message.length} />
        </>
    );
};