import React from 'react';
import { Loader, Text } from '@fluentui/react-northstar';
import { getTimeOfTheDay } from '../utils/common';
import { useSelector } from 'react-redux';
import IStore from '../common/interfaces/IStore';
import { getGivenName, htmlToText } from '../shared/src/utils/common';
import HomeWidgets from './HomeWidgets';
import lclzStor from '../shared/src/languages/BaseLang';
import { MAX_LENGTH_3000, TIP_KEYS } from '../shared/src/constants';
import { random } from 'lodash';
import { GiveFeedbackWidgetMobile } from './GiveFeedbackWidgetMobile';
import { APP_CONSTANTS } from '../common/constants';
import { RequestFeedbackWidgetMobile } from './RequestFeedbackWidgetMobile';
import { FeedbackNoteWidgetMobile } from './FeedbackNoteWidgetMobile';
import { Notifications } from './Notifications';
import { FeedbackNote } from './FeedbackNote';
import { useTeams } from '../hooks/useTeams';
import truncate from 'lodash/truncate';
import classnames from 'classnames';

interface IProps {
    match: {
        params: {
            widget: string;
        }
    }
    location: { search?: string}
};

function Home(props: IProps) {
    const { userInfo, isProfileLoaded, fbAction } = useSelector((state: IStore) => state.user);
    const { isLocalizationLoaded } = useSelector((state: IStore) => state.settings);

    const isMainDataLoaded = isProfileLoaded && isLocalizationLoaded;
    const title = isProfileLoaded ? `${getTimeOfTheDay()}, ${getGivenName(userInfo.Name)}!` : '';    const tipKey = TIP_KEYS[random(0, TIP_KEYS.length - 1)];
    const isWidget = Boolean(props.match.params.widget);
    const showAddFbNote = Boolean (props.location?.search) || fbAction;
    const search = decodeURIComponent(props.location?.search || fbAction);
    let searchContent = search.split('&')[0];
    searchContent = htmlToText(searchContent.substring(9)) || '';
    const truncatedSearch = typeof search === 'string' ? truncate(searchContent, {length : MAX_LENGTH_3000, omission: ''}) : '';
    const { theme } = useTeams({})[0];
    const white = theme.siteVariables.colorScheme.white.background1;
    const bkg = showAddFbNote || isWidget ? {backgroundColor : white} : null;
    const pageClasses = classnames('page', {'action-modal': showAddFbNote});
    const mainData = () => (
        <>
            {showAddFbNote && <FeedbackNote search={truncatedSearch} onSuccess={() =>{}} /> }
            {!showAddFbNote && (
                <>
                    <Text content={title} size="largest" as="div" className="my-medium px-1" />
                    <div className="px-1">
                        <Text content={`“${lclzStor[tipKey]}”`} size="medium" />
                        <Text content={lclzStor.LandingPage_CoachingTips} size="medium" styles={{ color: 'GrayText' }} />
                    </div>
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <HomeWidgets />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <Notifications />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
    const getWidget = () => {
        const { widget } = props.match.params;

        if (`/${widget}` === APP_CONSTANTS.LINKS.GIVE_FEEDBACK) {
            return <GiveFeedbackWidgetMobile />;
        }
        if (`/${widget}` === APP_CONSTANTS.LINKS.REQUEST_FEEDBACK) {
            return <RequestFeedbackWidgetMobile />;
        }
        if (`/${widget}` === APP_CONSTANTS.LINKS.FEEDBACK_NOTES) {
            return <FeedbackNoteWidgetMobile />;
        }
        return null;
    };

    const content = () => isWidget ? getWidget() : mainData();

    return (
        <div className={pageClasses} style={bkg}>
            {isMainDataLoaded ? content() : <Loader size="larger" />}
        </div>
    );
}

export default Home;
