import { Flex } from '@fluentui/react-northstar';
import { UserInfo } from '../elements/UserInfo';
import { FA_XMARK } from '../../shared/src/constants';
import { OIInfo } from '../elements/OIInfo';
import * as React from 'react';
import ILinkedItems from '../../shared/src/models/interfaces/search/ILinkedItems';
import lclzStor from '../../shared/src/languages/BaseLang';

interface IProps {
    selectedItems: ILinkedItems;
    removeUser: (user) => void;
    removeOI: (oi) => void;
}
export const SelectedItems = (props: IProps) => {
    const { selectedItems, removeUser, removeOI } = props;
    const removeUserHandler = user => () => removeUser(user);
    const removeOIHandler = oi => () => removeOI(oi);
    const hasUsers = Boolean(selectedItems.Users?.length);
    const hasOrgItems = Boolean(selectedItems.OrgItems?.length);
    const hasSelectedItems = hasUsers || hasOrgItems;
    const oiInfo = (count: number) => count ? `${count} ${lclzStor.SurveyNominations_people}`: null ;
    return(
        <>
        {hasSelectedItems && (
            <Flex gap="gap.small" wrap className="mw-100 truncated-text">
                {selectedItems.Users.map(user => (
                    <Flex gap="gap.small" vAlign="center" key={`slct-tms-${user.Id}`} className="mw-100 truncated-text">
                        <UserInfo
                            img={user.Img}
                            fullName={user.FullName}
                            jobTitle={user.JobTitle}
                            className="mw-100 truncated-text"
                            size="medium"
                        />
                        <i className={`${FA_XMARK} fa-regular cursor-pointer`} onClick={removeUserHandler(user)} />
                    </Flex>
                ))}
                {selectedItems.OrgItems.map(oi => (
                    <Flex gap="gap.small" vAlign="center" key={`slct-ois-${oi.Id}`}>
                        <OIInfo
                            name={oi.Name}
                            info={oiInfo(oi.UsersCount)}
                        />
                        <i className={`${FA_XMARK} fa-regular cursor-pointer`} onClick={removeOIHandler(oi)} />
                    </Flex>
                ))}
            </Flex>
        )}
    </>
    );
};
