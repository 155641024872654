import * as React from 'react';
import { FA_FROWN, FA_SYNC_ALT } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import { Button, Text } from '@fluentui/react-northstar';
import { connect } from 'react-redux';
import IStore from '../common/interfaces/IStore';
import { UserProfile } from "../shared/src/models/entities/user/UserProfile";
import { StyledTeamsIcon } from './elements/StyledITeamsIcon';
import { ILogError } from '../shared/src/models/interfaces/ILogError';
import { getHomepageURL } from '../shared/src/utils/common';
import { isAuthenticated } from '../shared/src/services/user/userService';
import { logErrorAnonymus, logErrorForUser } from '../shared/src/services/diagnostic/logger';

interface IProps {
    userInfo: UserProfile;
    children?: React.ReactNode;
    frontCorrelationId?: string;
    backCorrelationId?: string;
}

interface IState {
    hasError: boolean;
    error: Error;
}

class ErrorBoundaryComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.logError(error, info);
        this.setState({ hasError: true, error });
    }

    render() {
        const { backCorrelationId, frontCorrelationId, children } = this.props;
        const { hasError } = this.state;
        const errorId = backCorrelationId || frontCorrelationId;

        return hasError ? (
            <div className="common-empty-state" >
                <div className="common-empty-state-wrap">
                    <StyledTeamsIcon iconName={FA_FROWN} />
                    <Text>{lclzStor.ApplicationError_Title}</Text>
                    {errorId ? (
                        <>
                            <p className="common-empty-state-message">{lclzStor.SnackBar_Message}</p>
                        </>
                    ) : (
                        <p className="common-empty-state-message">{lclzStor.ApplicationError_Description}</p>
                    )}
                    {this.state.error.message}
                    {this.state.error.stack}
                    <Button primary={true} onClick={this.reloadApp} >
                        <StyledTeamsIcon key="icon" iconName={FA_SYNC_ALT} />
                        {lclzStor.Disabled_GoalsPopup_btn}
                    </Button>
                </div>
            </div>
        ) : (
            children
        );
    }

    reloadApp = () => window.location.href = getHomepageURL();

    logError = (error: Error, info: React.ErrorInfo) => {
        try {
            const frontError: ILogError = {
                Message: error.message,
                Url: window.location.href,
                Area: 3,
                UserId: this.props.userInfo ? this.props.userInfo.id : null,
                Stack: `ErrorStack -> ${error.stack} | ComponentStack -> ${info ? info.componentStack : ''}`
            };
            // use different error loggers depending on whether we are logged in
            if (isAuthenticated()) {
                logErrorForUser(frontError);
            } else {
                logErrorAnonymus(frontError);
            }
        } catch (e) {
            // seems like nothing we can do here.
            // tslint:disable-next-line: no-console
            console.log(e);
        }
    };
}

const mapStateToProps = (state: IStore) => {
    const userInfo = state?.user?.userInfo;
    const { frontCorrelationId, backCorrelationId } = state?.error;

    return {
        userInfo,
        frontCorrelationId,
        backCorrelationId
    };
};

const ErrorBoundary = connect(mapStateToProps, null)(ErrorBoundaryComponent);

export default ErrorBoundary;
