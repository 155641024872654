import React from 'react';
import { useTeams } from '../../hooks/useTeams';

interface IProps {
    type?: string;
    className?: string;
    iconName: string
}
export const StyledTeamsIcon = (props: IProps) => {
    const classes =`${props.iconName} ${props.className || ''} ${props.type || 'fa-regular'}`;
    const { theme } = useTeams({})[0];
    const { brand } = theme.siteVariables.contextualColors;
    return(
        <i className={classes} style={{color: brand[600] }}  />
    );
};