import { FA_MESSAGE_PEN, FA_XMARK } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import Tile from './elements/Tile';
import React from 'react';
import { Dialog } from '@fluentui/react-northstar';
import { GiveFeedback } from './GiveFeedback';
import { PopUp } from './elements/PopUp';
import { hashHistory } from '../shared/src/utils/history';
import { APP_CONSTANTS } from '../common/constants';

const GiveFeedbackWidget = () => {
    const [isGiveFBOpen, setGiveFBOpen] = React.useState<boolean>(false);
    const [isFeedbackSent, setIsFeedbackSent] = React.useState(false);
    const [width] = React.useState<number>(window.innerWidth);
    const isMobileView = width < 640;
    const giveFBOpen = () => {
        if (isMobileView) {
            hashHistory.push(`/tab${APP_CONSTANTS.LINKS.GIVE_FEEDBACK}`);
        }
        else{
            setGiveFBOpen(true);
        }
    };
    const giveFBClose = () => setGiveFBOpen(false);

    const closeSuccessPopUP = () => {
        giveFBClose();
        setIsFeedbackSent(false);
    };
    const onSuccess = () => {
        setIsFeedbackSent(true);
    };
    return (
        <>
            <Tile
                icon={FA_MESSAGE_PEN}
                title={lclzStor.InvitationForm_GiveFeedbackBtn}
                description={lclzStor.GiveFeedback_Description_TeamsLanding}
                onClick={giveFBOpen}
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 home-widget-tile"
            />
            <Dialog
                content={
                    <PopUp iconName={FA_MESSAGE_PEN} title={lclzStor.GiveFeedback_fbckSent} primaryBtnText={lclzStor.GotIt_Btn} primaryCB={closeSuccessPopUP} />}
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={closeSuccessPopUP} />}
                open={isFeedbackSent}
            />
            <Dialog
                content={<GiveFeedback onCancel={giveFBClose} onSuccess={onSuccess} />}
                open={isGiveFBOpen && !isFeedbackSent}
                onOpen={giveFBOpen}
                onCancel={giveFBClose}
                styles={{maxHeight: 600}}
            />
        </>
    );
};
export default GiveFeedbackWidget;