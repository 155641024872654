import * as React from 'react';
import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { isMobileApp } from '../modules/screenSettings';
import * as localStorage from '../modules/localStorage';
import { SortUsersBy } from '../models/enums/utils/SortUsersBy';

export function toDateTimeOffset(date: Date): string {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num: number) => {
        const norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())} ${dif + pad(tzo / 60)}:${pad(tzo % 60)}`;
}

export function isEmptyOrSpaces(str: string): boolean {
    return !str || !str.trim();
}

export function checkEmailValidity(email: string): boolean {
    /* eslint-disable */
    const pattern = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    /* eslint-enable */
    return pattern.test(email);
}

export function equalProperties(a: Object, b: Object): boolean {
    if (a === b) { return true; }
    if (a === null || a === undefined) { return false; }
    if (b === null || b === undefined) { return false; }
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i += 1) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

export function getInputElement(e: React.FormEvent<HTMLInputElement>): HTMLInputElement {
    return ((e || window.event) as React.FormEvent<HTMLInputElement>).target as HTMLInputElement;
}

export function removeDuplicates(itemArray) {
    return itemArray.filter((item, index, self) => index === self.findIndex(feedback => (feedback.Id === item.Id)));
}

export function getArrayIndexByAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export function stopPropagation(e: Event) {
    const event: Event = e || window.event;
    event.stopPropagation
        ? event.stopPropagation()
        : event.cancelBubble = true;
    return false;
}

export function sortArray(behaviours, firstParam, secondParam, isASC) {
    return behaviours.sort((a, b) => {
        if (a[firstParam] < b[firstParam]) {
            return isASC ? 1 : -1;
        }
        if (a[firstParam] > b[firstParam]) {
            return isASC ? -1 : 1;
        }
        if (a[secondParam] > b[secondParam]) {
            return isASC ? 1 : -1;
        }
        if (a[secondParam] < b[secondParam]) {
            return isASC ? -1 : 1;
        }
        return 0;
    });
}

export function getGuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const checkRedirectUrl = (url: string): string => {
    if (!url) { return ''; }
    const regex = /login|company|reset-password/gi;

    return regex.test(url) ? '' : url;
};

export const toMomentDate = (date: string) => {
    return moment(date, date.indexOf('.') !== -1 ? 'DD.MM.YYYY' : 'MM/DD/YYYY');
};

export const saveRedirectUrl = (): void => {
    const redirectUrl = checkRedirectUrl(window.location.href);
    const correctURL = redirectUrl && redirectUrl.split('?')[0];

    if (correctURL && !localStorage.loadItem(LOCAL_STORAGE_KEYS.NOTIFICATION_URL)) {
        localStorage.saveItem(LOCAL_STORAGE_KEYS.NOTIFICATION_URL, correctURL);
    }
};

export const getRedirectUrl = (splited: boolean = true): string => {
    const redirectedFrom: string = checkRedirectUrl(localStorage.loadItem(LOCAL_STORAGE_KEYS.NOTIFICATION_URL));
    localStorage.deleteItem(LOCAL_STORAGE_KEYS.NOTIFICATION_URL);
    if (redirectedFrom && splited) {
        const redirectTo = isMobileApp() ? redirectedFrom.split('#')[1] : redirectedFrom.split('/#')[1];
        return (/#/.test(redirectedFrom) && redirectTo) ? redirectTo : redirectedFrom;
    }
    return redirectedFrom;
};

export const scrollTop = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) element.scrollTop = 0;
};

export const isBase64 = (str: string): boolean => {
    try {
        const notBase64 = /[^A-Z0-9+\\/=]/i;
        const fromHost = str.substring(0, 4);
        const type = str.substr(str.length - 4);
        const allowTypes = ['.png', '.jpg', '.jpeg', '.gif', '.svg'];
        if (fromHost === 'img/' && allowTypes.indexOf(type) !== -1) {
            return true;
        }

        if (typeof str !== 'string' || /^\d+$/.test(str)) {
            return false;
        }

        const parts = str.split(';');
        const mimeType = parts[0].split(':')[1];
        const imageData = parts[1].split(',')[1];

        if ((mimeType !== 'image/jpg') && (mimeType !== 'image/jpeg') && (mimeType !== 'image/png')) {
            return false;
        }

        const len = imageData.length;
        if (!len || len % 4 !== 0 || notBase64.test(imageData)) {
            return false;
        }

        const firstPaddingChar = imageData.indexOf('=');
        return firstPaddingChar === -1 || firstPaddingChar === len - 1 || (firstPaddingChar === len - 2 && imageData[len - 1] === '=');
    } catch (e) {
        return false;
    }
};

export const getClassList = (className: string, defaultClass?: string): string => {
    const classList: string[] = [];
    if (className) {
        classList.push(className);
    }
    if (defaultClass) {
        classList.push(defaultClass);
    }
    return classList.join(' ');
};

export const resizeImage = (base64Str, maxWidth, maxHeight): Promise<string> => {
    return new Promise(resolve => {
        const img = new Image();
        img.src = base64Str;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            let { width, height } = img;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL());
        };
    });
};
export const scrollToBottom = elementId => {
    const objDiv = document.getElementById(elementId);

    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
};

export const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

export const scrollToPageBottom = () => window.scrollTo(0, document.body.scrollHeight);

export const sortUserArray = (items, sortBy: SortUsersBy) => {
    const itemsSorted = [...items];
    switch (sortBy) {
        case SortUsersBy.byImage:
            return itemsSorted.sort((a, b) => {
                if ((!a.Img && !b.Img) || (a.Img && b.Img)) {
                    return a.FullName.localeCompare(b.FullName);
                }
                return !a.Img && b.Img ? 1 : -1;
            });
        case SortUsersBy.byFullName:
            return itemsSorted.sort((a, b) => a.FullName.localeCompare(b.FullName));
        default:
            return itemsSorted;
    }
};
