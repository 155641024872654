import { Button, Checkbox, Flex, Form, FormField, FormLabel, Divider, Dialog, Alert } from '@fluentui/react-northstar';
import lclzStor from '../shared/src/languages/BaseLang';
import React from 'react';
import { useSelector } from 'react-redux';
import IStore from '../common/interfaces/IStore';
import CustomSearch from './Search/CustomSearch';
import { calculateTotalUsers } from '../shared/src/utils/feedbacks/GiveFeedbackUtils';
import { SearchItems } from '../shared/src/models/enums/search/SearchItems';
import ILinkedItems from '../shared/src/models/interfaces/search/ILinkedItems';
import { htmlToText, isBlank } from '../shared/src/utils/common';
import {
    getLibraryOwnersKeyText,
    hasOwners,
    loadBehaviourLibraries,
    loadSharedBehaviourLibraries, removeBehaviourLibraries, SHARED_LIBRARIES_KEY
} from '../utils/behaviours';
import requestErrorHandler from '../common/requestErrorHandler';
import ISearchUser from '../shared/src/models/interfaces/search/ISearchUser';
import ISearchOrgItem from '../shared/src/models/interfaces/search/ISearchOrgItem';
import { ILibraryWithBehaviours } from '../shared/src/models/interfaces/behaviours/library/ILibraryWithBehaviours';
import { ILinkedItemsInfo } from '../shared/src/models/interfaces/behaviours/library/ILinkedItemsInfo';
import { BehLibraries } from './BehLibraries';
import { cloneDeep } from 'lodash';
import IFeedbackMultiple from '../shared/src/models/interfaces/feedbacks/IFeedbackMultiple';
import { saveMultiFeedback } from '../services/feedback';
import { PopupHeader } from './elements/PopupHeader';
import { GiveFBErrorPopup } from './elements/GiveFBErrorPopup';
import { StructuredFBLayout } from './StructuredFBLayout';
import { ErrorGiveFb } from './elements/ErrorGiveFb';

interface IProps {
    onCancel: () => void;
    onSuccess: () => void;
}

export const GiveFeedback = (props: IProps) => {
    const {userInfo} = useSelector((state: IStore) => state.user);
    const {settings} = useSelector((state: IStore) => state.settings);
    const {
        EnableLimitOfSendersNumber, EnableLimitOfSendersNumberToGivingFeedback, AllowHRAdminExceedsLimitOfSendersNumber,
        LimitOfSendersNumber, EnablePublicFeedback, ShareFeedbackPublicDefault, SharePublicFeedbackOnlyForTeam
    } = settings.FeedbackSettings;
    const [IsFeedbackPublic, setIsFeedbackPublic] = React.useState(EnablePublicFeedback && ShareFeedbackPublicDefault);
    const [Message, setMessage] = React.useState('');
    const [MessagePositive, setMessagePositive] = React.useState('');
    const [MessageNegative, setMessageNegative] = React.useState('');
    const [UserIds, setUserIds] = React.useState([]);
    const [OrganisationItemIds, setOrganisationItemIds] = React.useState([]);
    const [totalRespondents, setTotalRespondents] = React.useState(0);
    const [behaviourLibraries, setBehaviourLibraries] = React.useState([]);
    const [linkedLibrariesInfo, setLinkedLibrariesInfo] = React.useState({Users: {}, OrgItems: {}});
    const [selectedBehs, setSelectedBehs] = React.useState([]);
    const [hasBehs, setHasBehs] = React.useState(false);
    const [showErrorPopUP, setShowErrorPopup] = React.useState(false);
    const isMobileView = window.innerWidth < 640;
    const [inProgress, setInProgress] = React.useState(false);

    let linkedSearchListItems: ILinkedItems = {};

    const clearData = () => {
        setHasBehs(false);
        setSelectedBehs([]);
        setLinkedLibrariesInfo({Users: {}, OrgItems: {}});
        setBehaviourLibraries([]);
        setOrganisationItemIds([]);
        setUserIds([]);
        setMessage('');
        setMessagePositive('');
        setMessageNegative('');
        setIsFeedbackPublic(true);
        setShowErrorPopup(false);
        setInProgress(false);
    };
    const cancelHandler = () => {
        clearData();
        props.onCancel();
    };
    const successHandler = () => {
        clearData();
        props.onSuccess();
    };
    React.useEffect(() => {
        if (settings?.BehaviourSettings.EnableBehaviours) {
            const fetchData = async () => {
                const libs = await loadSharedBehaviourLibraries();
                setBehaviourLibraries(libs);
            };
            fetchData().catch(err => requestErrorHandler(err));
        }
    }, [setBehaviourLibraries, settings?.BehaviourSettings.EnableBehaviours]);

    const loadLibraries = (linkedItemType: SearchItems, item: ISearchUser | ISearchOrgItem, total: number) => {
        const loadedLibrariesIds = behaviourLibraries.map(library => library.Id).join(',');
        loadBehaviourLibraries(linkedItemType, loadedLibrariesIds, linkedLibrariesInfo, UpdateBehaviourLibraryAndInfo, total, item.Id);
    };
    const UpdateBehaviourLibraryAndInfo = (libraries: ILibraryWithBehaviours[], linkedLibrariesInfo: ILinkedItemsInfo, total, clearBehaviorSelection: boolean = false) => {
        let behaviourLibs = cloneDeep(behaviourLibraries);
        if (libraries.length) {
            behaviourLibs = cloneDeep(behaviourLibs.concat(libraries));
        }
        behaviourLibs.forEach((library: ILibraryWithBehaviours) => {
            if (hasOwners(library.Id, linkedLibrariesInfo)) {
                const {
                    ownersKey,
                    ownersText
                } = getLibraryOwnersKeyText(library.Id, linkedLibrariesInfo, linkedSearchListItems, total, userInfo);
                library.ownersKey = ownersKey;
                library.ownersText = ownersText;
            } else if (library.isShared) {
                library.ownersKey = SHARED_LIBRARIES_KEY;
                library.ownersText = lclzStor.GiveFeedback_BehaviourLibraryForAll;
            }
        });
        setBehaviourLibraries(behaviourLibs);
        setLinkedLibrariesInfo(linkedLibrariesInfo);
        if (clearBehaviorSelection) {
            setSelectedBehs([]);
            setHasBehs(false);
        }
    };
    const handleMessage = (val: string) => setMessage(val);

    const handleFbTextPositive = (val: string) => setMessagePositive(val);

    const handleMessageNegative = (val: string) => setMessageNegative(val);

    const doesUserOwnBeh = (behId: number, userId: string) => {
        const theLib = behaviourLibraries.find(lib => {
            return lib.Behaviours.some(beh => beh.Id === behId);
        });
        return linkedLibrariesInfo.Users[userId].includes(theLib.Id);
    };
    const doesOrgItemOwnBeh = (behId: number, orgItemId: string) => {
        const theLib = behaviourLibraries.find(lib => {
            return lib.Behaviours.some(beh => beh.Id === behId);
        });
        return linkedLibrariesInfo.OrgItems[orgItemId].includes(theLib.Id);
    };

    const getBehavioursForUser = () => {
        const Users = cloneDeep(linkedLibrariesInfo.Users);
        Object.keys(Users).forEach(key => {
            Users[key] = selectedBehs.filter(behId => {
                return doesUserOwnBeh(behId, key);
            });
        });
        return Users;
    };
    const getBehavioursForOrgItems = () => {
        const OrgItems = cloneDeep(linkedLibrariesInfo.OrgItems);
        Object.keys(OrgItems).forEach(key => {
            OrgItems[key] = selectedBehs.filter(behId => {
                return doesOrgItemOwnBeh(behId, key);
            });
        });
        return OrgItems;
    };
    const submit = async () => {
        const BehavioursForUsers = getBehavioursForUser();
        const BehavioursForOrgItems = getBehavioursForOrgItems();
        const RequestId = null;
        const IsToDirectReports = false;
        const LimitPopupConfirmed = true;
        const data: IFeedbackMultiple = {
            Message,
            MessageNegative,
            MessagePositive,
            IsFeedbackPublic,
            UserIds,
            OrganisationItemIds,
            IsToDirectReports,
            BehavioursForUsers,
            BehavioursForOrgItems,
            RequestId,
            LimitPopupConfirmed
        };
        setInProgress(true);
        try {
            await saveMultiFeedback(data);
            successHandler();
        } catch (err) {
            cancelHandler();
        }
    };
    const handleSubmit = () => {
        const showPopup = isExceeded && allowExceedsLimit;
        if (showPopup) {
            setShowErrorPopup(true);
        } else {
            submit();
        }
    };
    const toggleShare = () => setIsFeedbackPublic(!IsFeedbackPublic);
    const toggleLabel = IsFeedbackPublic ? lclzStor.Toggle_On : lclzStor.Toggle_Off;

    const addUser = (item: any) => {
        UserIds.push(item.Id);
        setUserIds(UserIds);
    };
    const removeUser = (item: any) => {
        const sendToUserIdsNew = UserIds.filter(id => id !== item.Id);
        setUserIds(sendToUserIdsNew);
    };
    const addOI = (item: any) => {
        OrganisationItemIds.push(item.Id);
        setOrganisationItemIds(OrganisationItemIds);
    };
    const removeOI = (item: any) => {
        const sendToOIIdsNew = OrganisationItemIds.filter(id => id !== item.Id);
        setOrganisationItemIds(sendToOIIdsNew);
    };
    const removeLibraries = (linkedItemType: SearchItems, item: any, total: number) => {
        const linkedLibsInfo = removeBehaviourLibraries(linkedItemType, item, linkedLibrariesInfo);
        UpdateBehaviourLibraryAndInfo([], linkedLibsInfo, total);
    };
    const onBehSelect = (id: number) => {
        const isRemoved = selectedBehs.indexOf(id) > -1;
        if (isRemoved) {
            const updated = selectedBehs.filter(beh => beh !== id);
            setSelectedBehs(updated);
            setHasBehs(Boolean(updated?.length));
        } else {
            selectedBehs.push(id);
            setSelectedBehs(selectedBehs);
            setHasBehs(true);
        }
    };
    const changeLinkedList = (type: SearchItems, isAdded: boolean, item: any, selectedItems: ILinkedItems) => {
        const total = calculateTotalUsers(selectedItems, userInfo, false);
        setTotalRespondents(total);
        linkedSearchListItems = selectedItems;
        if (settings?.BehaviourSettings.EnableBehaviours) {
            if (!total) {
                UpdateBehaviourLibraryAndInfo([], {Users: {}, OrgItems: {}}, 0, true);
            } else {
                if (isAdded) {
                    loadLibraries(type, item, total);
                } else {
                    removeLibraries(type, item, total);
                }
            }
        }
        if (type === SearchItems.Users) {
            isAdded ? addUser(item) : removeUser(item);
        }
        if (type === SearchItems.OrgItems) {
            isAdded ? addOI(item) : removeOI(item);
        }
        setHasBehs(Boolean(selectedBehs?.length));
    };
    const restrictionIsEnabled = EnableLimitOfSendersNumber && EnableLimitOfSendersNumberToGivingFeedback;
    const maxRespondents = restrictionIsEnabled && LimitOfSendersNumber;
    const allowExceedsLimit = AllowHRAdminExceedsLimitOfSendersNumber && (userInfo.isAdmin() || userInfo.isHr());
    const isExceeded = totalRespondents > maxRespondents;

    const closeConfirmPopup = () => setShowErrorPopup(false);

    const isSubmitEnabled = () => {
        const hasContent = hasBehs || !isBlank(htmlToText(Message)) || !isBlank(htmlToText(MessagePositive)) || !isBlank(htmlToText(MessageNegative));
        const hasRecipients = Boolean(UserIds.length) || Boolean(OrganisationItemIds.length);
        const validNumberOfRecipient = !restrictionIsEnabled || !isExceeded || allowExceedsLimit;
        return hasRecipients && validNumberOfRecipient && hasContent && !inProgress;
    };
    const errorPopup = () => <GiveFBErrorPopup submit={submit} totalRespondents={totalRespondents}
                                               closeConfirmPopup={closeConfirmPopup} />;
    const showErrorPopupOnDesktopView = showErrorPopUP && !isMobileView;
    const alertMessage = SharePublicFeedbackOnlyForTeam ?
            lclzStor.GiveFeedback_ShareFeedbackWithTeam_hint :
            lclzStor.GiveFeedback_ShareFeedbackPublicly_hint;
    const shareLabel = SharePublicFeedbackOnlyForTeam ? lclzStor.GiveFeedback_ShareFeedbackWithTeam : lclzStor.GiveFeedback_ShareFeedbackPublicly;

    return (
        <div className="ms-Grid">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                    <div className={showErrorPopupOnDesktopView ? 'display-none' : ''}>
                        <PopupHeader title={lclzStor.GiveFeedback_title} onCancel={props.onCancel} />
                        <Form className="give-fb-wrapper" style={{height: 'auto'}}>
                            <FormField>
                                <CustomSearch
                                    changeLinkedList={changeLinkedList}
                                    searchPlaceholder={lclzStor.GiveFeedback_SelectPeople}
                                    searchLabel={lclzStor.Feedback_SendTo}
                                    includeEmptyParentOI={true}
                                    userProfile={userInfo}
                                    settings={settings}
                                />
                            </FormField>
                            <StructuredFBLayout
                                message={Message}
                                messageNegative={MessageNegative}
                                messagePositive={MessagePositive}
                                handleMessage={handleMessage}
                                handleFbTextPositive={handleFbTextPositive}
                                handleMessageNegative={handleMessageNegative}
                            />
                            <BehLibraries
                                behaviourLibraries={behaviourLibraries}
                                linkedLibrariesInfo={linkedLibrariesInfo}
                                onBehSelect={onBehSelect}
                                selectedBehs={selectedBehs}
                            />
                            {EnablePublicFeedback &&
                                (<FormField>
                                    <FormLabel htmlFor="password" id="password-label">
                                        {shareLabel}
                                    </FormLabel>
                                    <Checkbox checked={IsFeedbackPublic} label={toggleLabel} toggle onClick={toggleShare} />
                                    {IsFeedbackPublic && <Alert info className="spacing-top-sm" content={alertMessage} />}
                                </FormField>)}
                            <ErrorGiveFb totalRespondents={totalRespondents} />
                            <Divider fitted className="custom-divider" />
                            <FormField>
                                <Flex gap="gap.small">
                                    <Flex.Item push>
                                        <Button primary={false} onClick={cancelHandler}>{lclzStor.cancel}</Button>
                                    </Flex.Item>
                                    <Button
                                        primary={true}
                                        onClick={handleSubmit}
                                        disabled={!isSubmitEnabled()}
                                        loading={inProgress}
                                    >
                                        {!inProgress && lclzStor.FeedbackOnFeedbackBar_sendButton}
                                    </Button>
                                </Flex>
                            </FormField>
                        </Form>
                        {isMobileView && <Dialog content={errorPopup()} open={showErrorPopUP} />}
                    </div>
                    {showErrorPopupOnDesktopView && errorPopup()}
                </div>
            </div>
        </div>
    );
};
