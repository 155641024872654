import { Button, Divider, Flex, Form, FormField } from '@fluentui/react-northstar';
import lclzStor from '../shared/src/languages/BaseLang';
import React from 'react';
import { useSelector } from 'react-redux';
import IStore from '../common/interfaces/IStore';
import CustomSearch from './Search/CustomSearch';
import { SearchItems } from '../shared/src/models/enums/search/SearchItems';
import ILinkedItems from '../shared/src/models/interfaces/search/ILinkedItems';
import requestErrorHandler from '../common/requestErrorHandler';
import { ListItemType } from '../common/enums/ListItemType';
import { addNote } from '../services/feedback';
import { PopupHeader } from './elements/PopupHeader';
import { CommentBlock } from './elements/CommentBlock';

interface IProps {
    onCancel?: () => void;
    onSuccess: () => void;
    search?: string;
}
export const FeedbackNote = (props: IProps) => {
    const { userInfo } = useSelector((state: IStore) => state.user);
    const { settings } = useSelector((state: IStore) => state.settings);
    let noteTextInitial = Boolean(props.search) ? props.search : '';
    const [ noteText, setNoteText ] = React.useState(noteTextInitial);
    const [linkedPersons, setLinkedPersons] = React.useState<ILinkedItems>({});
    const [inProgress, setInProgress] = React.useState(false);
    const [isSent, setIsSent] = React.useState(false);

    const clearData = () => {
        setInProgress(false);
        setNoteText('');
        setLinkedPersons({});
        setIsSent(true);
    };

    const handleNoteText= (val: string) => {
        setNoteText(val);
    };

    const cancelHandler = () => {
        clearData();
        props.onCancel();
    };

    const saveNote = () => {
        const note = {
            Id: 0,
            Message: noteText,
            FeedbackNoteOfflineSenderIDs: linkedPersons.Users ? linkedPersons.Users.map(li => li.Id) : []
        };
        setInProgress(true);
        addNote(note)
          .then(() => {
            clearData();
            props.onSuccess();
          })
          .catch(requestErrorHandler);
    };

    const isSaveDisabled = !noteText?.trim() || (Boolean(props.search) && isSent);

    const changeLinkedList = (type: SearchItems, isAdded: boolean, item: any, selectedItems: ILinkedItems) => setLinkedPersons(selectedItems);

    return (
        <div className="ms-Grid">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                    <PopupHeader title={lclzStor.FeedbackNote_Title} onCancel={props.onCancel || null} />
                    <Form style={{height: 'auto'}}>
                        <FormField>
                            <CustomSearch
                                changeLinkedList={changeLinkedList}
                                searchPlaceholder={lclzStor.FeedbackNote_SearchPlaceholder}
                                searchLabel={lclzStor.FeedbackNote_SearchLabel}
                                includeEmptyParentOI={true}
                                userProfile={userInfo}
                                settings={settings}
                                type={[ListItemType.Person]}
                            />
                        </FormField>
                        <FormField>
                            <CommentBlock
                                title={lclzStor.FeedbackNote_NoteLabel}
                                name="noteText"
                                message={noteText}
                                placeholder={lclzStor.FeedbackNote_NotePlaceholder}
                                handleMessage={handleNoteText}
                            />
                        </FormField>
                        <Divider fitted className="custom-divider" />
                        <FormField>
                            <Flex gap="gap.small">
                                {Boolean(props.onCancel) ? (
                                    <>
                                        <Flex.Item push>
                                            <Button primary={false} onClick={cancelHandler}>{lclzStor.cancel}</Button>
                                        </Flex.Item>
                                        <Button
                                            primary={true}
                                            disabled={isSaveDisabled || inProgress}
                                            onClick={saveNote}
                                            loading={inProgress}
                                        >
                                            { !inProgress && lclzStor.save}
                                        </Button>
                                    </>
                                ) : (
                                    <Flex.Item push>
                                        <Button
                                            primary={true}
                                            disabled={isSaveDisabled || inProgress}
                                            onClick={saveNote}
                                            loading={inProgress}
                                        >
                                            { !inProgress && lclzStor.save}
                                        </Button>
                                    </Flex.Item>
                                )}
                            </Flex>
                        </FormField>
                    </Form>
                </div>
            </div>
        </div>
    );
};