import lclzStor from '../shared/src/languages/BaseLang';

export const APP_CONSTANTS = {
  LINKS: {
    LOGIN: '/welcome',
    HOME: '/tab',
    GIVE_FEEDBACK: '/give-feedback',
    REQUEST_FEEDBACK: '/request-feedback',
    FEEDBACK_NOTES: '/feedback-notes',
    DATA_PRIVACY: '/data-privacy',
    INVITATIONS: '/invitations',
    SEND_INVITATION: '/send-invitation',
    CREATE_INVITATION: '/create-invitation',
    NOT_LAUNCHED_PAGE: '/not-launched',
    CREATE_CHECKIN: '/create-checkin',
    CREATE_GOAL: '/create-goal',
    CREATE_MENU: '/create-menu',
    PRIVACY: '/privacy',
    TERMS_OF_USE: '/termsofuse'
  },
  EXTERNAL_LINKS: {
    CHECKIN_LIST: '/check-ins',
    CHECKIN_VIEW: '/check-ins/view',
    PERFORMANCE_REVIEW_LIST: '/reviews-list',
    PERFORMANCE_REVIEW_VIEW: '/check-ins/review',
    GOAL_LIST: '/home/goals/my-goals',
    GOAL_VIEW: '/home/goals/goal-view',
    FEEDBACK_RECEIVED_LIST: '/home/feedback-centre/received',
    FEEDBACK_SENT_LIST: '/home/feedback-centre/sent',
    FEEDBACK_REQUESTS_LIST: '/home/feedback-centre/requests'
  }
};

export const LOCAL_STORAGE_KEYS = {
  COMPANY_DATA: 'companyData',
  COMPANY_NAME: 'companyName',
  SSO_LOGIN_URL: 'SSO_LOGIN_URL',
  ACCESS_TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
  LOGIN_TIMINGS: 'LOGIN_TIMINGS'
};

export const SESSION_STORAGE_KEYS = {
  STOP_AUTO_SSO: 'stopAutoSSO',
  STOP_AUTO_COMPANY: 'stopCompanyDetection',
  SESSION_ID: 'SessionId'
};

export const AUTOCLOSE = 8000;
export const AUTOCLOSE_FAST = 5000;
export const requestMenuItems = () =>  [
  {
    key: lclzStor.RequestFeedback_AboutMe,
    content: lclzStor.RequestFeedback_AboutMe
  },
  {
    key: lclzStor.RequestFeedback_AboutMyTeam,
    content: lclzStor.RequestFeedback_AboutMyTeam
  }
];