import * as React from 'react';
import { Role } from '../../shared/src/models/enums/Role';
import { getTestSelector, isArrayEqual } from '../../shared/src/utils/common';
import ILinkedItems from '../../shared/src/models/interfaces/search/ILinkedItems';
import { UserActions } from '../../shared/src/models/enums/permission/UserActions';
import { SearchItems } from '../../shared/src/models/enums/search/SearchItems';
import { UserProfile } from '../../shared/src/models/entities/user/UserProfile';
import { Settings } from '../../shared/src/models/entities/settings/Settings';
import ISearchUser from '../../shared/src/models/interfaces/search/ISearchUser';
import ISearchOrgItem from '../../shared/src/models/interfaces/search/ISearchOrgItem';
import {  MIN_SEARCH_CHARACTERS } from '../../shared/src/constants';
import { ISearchOptions } from '../../shared/src/models/interfaces/search/ISearchOptions';
import { scrollTop } from '../../shared/src/utils/helpers';
import { searchOIWithEmptyParent, searchUsersPaged } from '../../services/feedback';
import SearchDropdown from './SearchDropdown';
import lclzStor from '../../shared/src/languages/BaseLang';
import { SearchInput } from './SearchInput';
import uuid from 'uuid/v4';
import { ListItemType } from '../../common/enums/ListItemType';
import { searchDirectReports } from '../../services/user';
import requestErrorHandler from '../../common/requestErrorHandler';
import { SelectedItems } from './SelectedItems';

const PAGE_SIZE = 10;

interface IProps {
    changeLinkedList: (type: SearchItems, isAdded: boolean,item: any, selectedItems: ILinkedItems) => void;
    linkedList?: ILinkedItems;
    searchPlaceholder?: string;
    searchLabel?: string;
    type?: number[];
    classes?: string;
    disabledDelete?: number[];
    roles?: Role[];
    userPermission?: UserActions;
    id?: string;
    includeEmptyParentOI?: boolean;
    blurHandler?: () => void;
    disableSearchTermLengthLimit?: boolean;
    excludeList?: number[];
    excludeListGuid?: string[];
    userProfile: UserProfile;
    settings: Settings;
    shouldBeDisabled?: boolean;
    isDisabled?: boolean;
    showSearchError?: boolean;
    excludeCurrentUserFromOI?: boolean;
    subordinatesOnly?: boolean;
    testSelector?: string;
}

interface IState {
    foundItems: ILinkedItems;
    selectedItems: ILinkedItems;
    focusedSearch: boolean;
    isLoading: boolean;
    allIsLoaded: boolean;
    searchOptions: ISearchOptions;
    isSearchEnabled: boolean;
    value: string;
    searchKey: string;
}

class CustomSearch extends React.Component<IProps, IState> {
    static getDerivedStateFromProps(props: IProps, state: IState) {
        const { linkedList } = props;
        if ((linkedList?.Users && !isArrayEqual(linkedList?.Users, state.selectedItems.Users)) ||
            (linkedList?.OrgItems && !isArrayEqual(linkedList?.OrgItems, state.selectedItems.OrgItems))
        ) {
            return {
                selectedItems: {
                    Users: props.linkedList.Users || [],
                    OrgItems: props.linkedList.OrgItems || [],
                    DirectReports: []
                }
            };
        }
        return null;
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            foundItems: {},
            selectedItems: {
                Users: (props.linkedList?.Users) || [],
                OrgItems: (props.linkedList?.OrgItems) || []
            },
            focusedSearch: false,
            isLoading: false,
            allIsLoaded: true,
            searchOptions: this.getInitialSearchOptions(),
            isSearchEnabled: true,
            value:'',
            searchKey: uuid()
        };
    }

    checkType = (type: ListItemType, searchAll: boolean = true): boolean => {
      return this.props.type?.includes(type) || (searchAll && !this.props.type);
    };

    onFocusHandler = () => {
        this.setState({ focusedSearch: true });
    };
    onBlurHandler = () => {
        this.setState({ focusedSearch: false });
    };
    clearInput = () => {
        this.setState({
            foundItems: {},
            focusedSearch: false,
            value: '',
            searchKey: uuid()
        });
    };
    searchItems = async (searchOptions: ISearchOptions) => {
        const searchString = this.state.value;
        const { excludeList, userProfile, settings } = this.props;
        if (searchString?.length >= MIN_SEARCH_CHARACTERS) {
            this.setState({ isLoading: true });
            const { selectedItems, foundItems } = this.state;
            let toUsers = selectedItems?.Users?.map(li => li.Id);
            toUsers = excludeList ? toUsers.concat(excludeList) : toUsers;
            const withoutRoot = !userProfile.canSendToAllcompany(settings);
            const emptyResult = { Results: [], PageCount: 0, CurrentPage: 1 };
            const nextSearchOptions = this.getEmptySearchOptions();
            nextSearchOptions.orgItems = searchOptions.orgItems;
            let OrgItems: ISearchOrgItem[] = [];
            let DirectReports: ISearchUser[] = [];
            let Users: ISearchUser[] = [];
            let allIsLoaded = true;
            let UserResults;

            try {
                if (!searchOptions.users.allIsLoaded) {
                    UserResults = this.checkType(ListItemType.Person)
                      ? await searchUsersPaged(searchString, toUsers, searchOptions.users.page, PAGE_SIZE, this.props.roles || [], this.props.subordinatesOnly, this.props.userPermission)
                      : emptyResult;
                    Users = searchOptions.users.page === 1 ? UserResults.Results : (foundItems.Users || []).concat(UserResults.Results);
                    nextSearchOptions.users = {
                        page: UserResults.CurrentPage,
                        allIsLoaded: UserResults.PageCount <= 1 || UserResults.CurrentPage === UserResults.PageCount
                    };
                }
                if (nextSearchOptions.users.allIsLoaded && !searchOptions.orgItems.allIsLoaded) {
                    const toOrganisationItems = selectedItems.OrgItems.map(li => li.Id);
                    nextSearchOptions.orgItems = {
                        page: 1,
                        allIsLoaded: true
                    };
                    if (this.checkType(ListItemType.OrganisationItem)) {
                      if (this.props.includeEmptyParentOI) {
                          // TODO Remove searchOIWithEmptyParent after backend side was done
                          const OrgItemResults = await searchOIWithEmptyParent(searchString, toOrganisationItems, searchOptions.orgItems.page, withoutRoot);

                          OrgItems = searchOptions.orgItems.page === 1 ? OrgItemResults.Results : (foundItems.OrgItems || []).concat(OrgItemResults.Results);
                          nextSearchOptions.orgItems = {
                              page: OrgItemResults.CurrentPage,
                              allIsLoaded: OrgItemResults.PageCount <= 1 || OrgItemResults.CurrentPage === OrgItemResults.PageCount
                          };
                      }
                    }
                    if (nextSearchOptions.orgItems.allIsLoaded) {
                        DirectReports = this.checkType(ListItemType.MyDirectReports)
                        && !selectedItems.DirectReports?.length
                        && lclzStor.Search_MyDirectReports.toLowerCase().includes(searchString.toLowerCase()) ? await searchDirectReports() : [];
                    }
                }
                if (searchOptions.users.page === 1 && searchOptions.orgItems.page === 1) {
                    scrollTop('custom-search-result-list');
                }
                allIsLoaded = nextSearchOptions.users.allIsLoaded && nextSearchOptions.orgItems.allIsLoaded;
                return {
                    allIsLoaded,
                    foundItems: {
                        Users,
                        DirectReports,
                        OrgItems
                    },
                    searchOptions: nextSearchOptions
                };
            } catch (error) {
                requestErrorHandler(error);
                return {
                    searchOptions: this.getEmptySearchOptions(),
                    foundItems: {},
                    allIsLoaded: true
                };
            }
        }
        return {
            searchOptions: this.getEmptySearchOptions(),
            foundItems: {},
            allIsLoaded: true
        };
    };

    onFoundClick = (type: SearchItems, item) => {
        const { selectedItems } = this.state;
        if (type === SearchItems.DirectReports) {
            selectedItems[type] = item;
        } else {
            selectedItems[type].push(item);
        }
        this.props.changeLinkedList(type, true, item, selectedItems);
        this.setState({ selectedItems }, this.clearInput);
    };

    loadItemsHandler = async (searchOptions: ISearchOptions) => {
        const result = await this.searchItems(searchOptions);
        if (result.foundItems) {
            this.setState({ ...result, isLoading: false });
        }
    };

    onInputHandler = (value: string) => {
        const searchOptions = this.getInitialSearchOptions();
        if (value) {
            this.setState({ isSearchEnabled: true, isLoading: true, value }, () => this.loadItemsHandler(searchOptions));
        } else {
            this.setState({ searchOptions, foundItems: {}, isSearchEnabled: false, value: '' });
        }
    };

    getEmptySearchOptions = (allIsLoaded: boolean = true): ISearchOptions => {
        return {
            users: {
                allIsLoaded,
                page: 1
            },
            orgItems: {
                allIsLoaded,
                page: 1
            }
        };
    };

    getInitialSearchOptions = () => {
        const searchOptions = this.getEmptySearchOptions();
        searchOptions.users.allIsLoaded = false;
        searchOptions.orgItems.allIsLoaded = false;
        return searchOptions;
    };
    removeUserHandler = (user: any) => {
        const { Users } = this.state.selectedItems;
        const filtered = Users.filter(item => item.Id !== user.Id );
        const selectedItems = {...this.state.selectedItems, Users: filtered};
        this.setState({selectedItems});
        this.props.changeLinkedList(SearchItems.Users, false, user, selectedItems);
    };
    removeOIHandler = (oi: any) => {
        const { OrgItems } = this.state.selectedItems;
        const filtered = OrgItems.filter(item => item.Id !== oi.Id );
        const selectedItems = {...this.state.selectedItems,OrgItems: filtered};
        this.setState({selectedItems});
        this.props.changeLinkedList(SearchItems.OrgItems, false, oi, selectedItems);
    };

    render() {
        const { id, classes} = this.props;
        const { foundItems, focusedSearch, searchOptions, allIsLoaded, isLoading, selectedItems} = this.state;

        return (
            <div
                className={`search-nominations ${classes || ''}`}
                {...getTestSelector(this.props.testSelector || 'search')}
                id={id}
                role="search"
            >
                <span className={focusedSearch ? 'search-shadow' : ''} onClick={this.clearInput} />
                <div className="search-wrapper">
                    <SearchInput
                        onInput={this.onInputHandler}
                        onFocus={this.onFocusHandler}
                        onBlur={this.onBlurHandler}
                        key={this.state.searchKey}
                        label={this.props.searchLabel}
                        placeholder={this.props.searchPlaceholder}
                        isDisabled={this.props.isDisabled}
                    />
                    <SearchDropdown
                        foundItems={foundItems}
                        onFoundClick={this.onFoundClick}
                        searchInput={this.state.value}
                        isLoading={isLoading}
                        onLoadMore={this.loadItemsHandler}
                        searchOptions={searchOptions}
                        allIsLoaded={allIsLoaded}
                        searchOnfocus={false}
                        userProfile={this.props.userProfile}
                        isInputInFocus={focusedSearch}
                    />
                    <SelectedItems selectedItems={selectedItems} removeUser={this.removeUserHandler} removeOI={this.removeOIHandler} />
                </div>
            </div>
        );
    }
}

export default CustomSearch;
