export enum EGoalSettings {
    Enable = 'EnableGoals',
    EnablePublic = 'EnableSharingGoals',
    EnableGoalsDeletion = 'EnableGoalsDeletion',
    DefaultStatusPublicGoalsPublic = 'DefaultStatusPublicGoalsPublic',
    EnableAbilityLibrariesTags = 'EnableAbilityLibrariesTags',
    EnableAllowEmployeeToAssignToEveryone = 'EnableAllowEmployeeToAssignToEveryone',
    EnableAllowManagerToAssignToEveryone = 'EnableAllowManagerToAssignToEveryone',
    ManagerCanAssignGoalFor = 'ManagerCanAssignGoalFor',
    EnableAllowHRToAssignToEveryone = 'EnableAllowHRToAssignToEveryone',
    EnableAllowManagerToSeeAllFeedbacksAttachedToGoal = 'EnableAllowManagerToSeeAllFeedbacksAttachedToGoal',
    EnableGoalsBehaviours = 'EnableGoalsBehaviours',
    EnablePublicGoalToAllCompany = 'EnablePublicGoalToAllCompany',
    EnableSharingGoals = 'EnableSharingGoals',
    Types = 'GoalTypeSettings',
    EnableOrganizationalGoals = 'EnableOrganizationalGoals',
    EnableTagForOrganizationalGoals = 'EnableTagForOrganizationalGoals',
    EnableGoalAlignment = 'EnableGoalAlignment',
    EnableGoalsLibraryWidget = 'EnableGoalsLibraryWidget',
    EnablePriorityField = 'EnablePriorityField',
    EnableTrackingStatusField = 'EnableTrackingStatusField',
    AllowReopenCompleted = 'AllowReopenCompleted',
    EnableManagerToAssignToDirectReports = 'EnableManagerToAssignToDirectReports',
    OrganisationGoalCanBeCreated = 'OrganisationGoalCanBeCreated',
    EnableGoalsCycles = 'EnableGoalsCycles',
    GoalsCyclesSettings = 'GoalsCyclesSettings'
}
