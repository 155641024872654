import { FA_COMMENT_ALT_PLUS, FA_XMARK } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import Tile from './elements/Tile';
import React from 'react';
import { Dialog } from '@fluentui/react-northstar';
import { PopUp } from './elements/PopUp';
import { RequestFeedback } from './RequestFeedback';
import { hashHistory } from '../shared/src/utils/history';
import { APP_CONSTANTS } from '../common/constants';

const RequestFeedbackWidget = () => {
    const [isGiveFBOpen, setGiveFBOpen] = React.useState<boolean>(false);
    const [isRequestSent, setIsRequestSent] = React.useState(false);
    const [width] = React.useState<number>(window.innerWidth);
    const isMobileView = width < 640;
    const giveFBOpen = () => {
        if (isMobileView) {
            hashHistory.push(`/tab${APP_CONSTANTS.LINKS.REQUEST_FEEDBACK}`);
        }
        else {
            setGiveFBOpen(true);
        }
    };
    const giveFBClose = () => setGiveFBOpen(false);

    const closeSuccessPopUP = () => {
        giveFBClose();
        setIsRequestSent(false);
    };
    const onSuccess = () => {
        setIsRequestSent(true);
    };
    return (
        <>
            <Tile
                icon={FA_COMMENT_ALT_PLUS}
                title={lclzStor.InvitationForm_RequestFeedbackBtn}
                description={lclzStor.RequestFeedback_Description_TeamsLanding}
                onClick={giveFBOpen}
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 home-widget-tile"
            />
            <Dialog
                content={
                    <PopUp
                        iconName={FA_COMMENT_ALT_PLUS}
                        title={lclzStor.GiveFeedback_fbckRequestSent}
                        primaryBtnText={lclzStor.GotIt_Btn}
                        primaryCB={closeSuccessPopUP}
                    />
                }
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={closeSuccessPopUP} />}
                open={isRequestSent}
            />
            <Dialog
                content={<RequestFeedback onCancel={giveFBClose} onSuccess={onSuccess} />}
                open={isGiveFBOpen && !isRequestSent}
                onOpen={giveFBOpen}
                onCancel={giveFBClose}
                styles={{maxHeight: 720}}
            />
        </>
    );
};
export default RequestFeedbackWidget;