import moment from 'moment-timezone';
import apiGateway from '../../modules/api/apiGateway';
import { UserProfile } from '../../models/entities/user/UserProfile';
import { Settings } from '../../models/entities/settings/Settings';
import IExternalUser from '../../models/interfaces/IExternalUser';
import { ExternalUserSettings } from '../../models/entities/settings/ExternalUserSettings';

export const getCurrentUser = (): Promise<UserProfile> => {
    return apiGateway.getAsync('users/me');
};

export const getCurrentUserSettings = (): Promise<Settings> => {
    return apiGateway.getAsync('settings');
};

export const sendUserTimeZone = (): Promise<void> => {
    return apiGateway.putAsync('users/me/timezone', `"${moment.tz.guess()}"`);
};

export const getCurrentExternalUser = (): Promise<IExternalUser> => {
  return apiGateway.getAsync('external-users');
};

export const getCurrentExternalUserSettings = (): Promise<ExternalUserSettings> => {
  return apiGateway.getAsync('external-users/settings');
};
