import { isMobileApp } from './screenSettings';

const useLocalStorage = isMobileApp() || process.env.REACT_APP_CLIENT_DESKTOP_ID === 'Teams';

export const loadItem = (name: string): any => {
    try {
        const serializedItem = useLocalStorage ? localStorage.getItem(name) : sessionStorage.getItem(name);
        if (serializedItem === null) {
            return undefined;
        }
        return JSON.parse(serializedItem);
    } catch (err) {
        return undefined;
    }
};

export const saveItem = (name: string, item: any): void => {
    try {
        const serializedItem = JSON.stringify(item);
        useLocalStorage
            ? localStorage.setItem(name, serializedItem)
            : sessionStorage.setItem(name, serializedItem);
    } catch (err) {
        console.warn(err);
    }
};

export const deleteItem = (name: string): void => {
    try {
        useLocalStorage
            ? localStorage.removeItem(name)
            : sessionStorage.removeItem(name);
    } catch (err) {
        console.warn(err);
    }
};

export const deleteAll = (): void => {
    useLocalStorage
        ? localStorage.clear()
        : sessionStorage.clear();
};
