import * as qs from 'qs';
import { LOCAL_STORAGE_KEYS } from '../common/constants';
import { ICompanyData } from '../shared/src/models/interfaces/ICompanyData';
import * as localStorage from '../shared/src/modules/localStorage';
import * as microsoftTeams from "@microsoft/teams-js";
import { loginByToken } from '../shared/src/modules/loginManagement';
import { fetchAsync } from '../shared/src/modules/http';
import { Method } from 'axios';

const responseType = 'code';
const scope = ['openid'];
const redirectUrl = `${window.location.href.split('#')[0]}code.html`.replace('index.html', '');
let usedCode = '';

export const samlAuth = (companyData: ICompanyData): Promise<any> => {
    return new Promise((resolve, reject) => {
        const region = companyData.CognitoUserPoolId.split('_')[0];
        let authHost = `https://${companyData.CognitoPoolDomainName}.auth.${region}.amazoncognito.com`;
        let authUrl = `${authHost}/oauth2/authorize?idp_identifier=${companyData.CognitoIdpIdentifier}&redirect_uri=${redirectUrl}&response_type=${responseType}&client_id=${companyData.CognitoClientId}&scope=${scope}`;
        let isLoaded: boolean = false;
        localStorage.saveItem(LOCAL_STORAGE_KEYS.SSO_LOGIN_URL, authUrl);

        const dialogUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/sso.html';

        const success = code => {
          // Log timings
          // ---------
          const timings = {
            StartedSSO: Date.now(),
            CompanyName: companyData.CompanyName
          };
          localStorage.saveItem(LOCAL_STORAGE_KEYS.LOGIN_TIMINGS, timings);
          // ---------
          if (code && code !== usedCode && !isLoaded) {
            // Log timings
            // ---------
            const timings = localStorage.loadItem(LOCAL_STORAGE_KEYS.LOGIN_TIMINGS) || {};
            timings.EndedSSO = Date.now();
            timings.StartedLogin = Date.now();
            localStorage.saveItem(LOCAL_STORAGE_KEYS.LOGIN_TIMINGS, timings);
            // ---------

            usedCode = code;

            exchangeCodeForTokens(companyData, code)
              .then(data => {
                loginByToken(data['id_token'])
                  .then(token => {
                    resolve(token);
                  })
                  .catch(error => {
                    reject(error);
                  });
              })
              .catch(error => {
                reject(error);
              });
          }
        };

        const failure = reason => reject({reason});

        microsoftTeams.authentication.authenticate({
            url: dialogUrl,
            width: 600,
            height: 535,
            successCallback: success,
            failureCallback: failure
        });
    });
};

export const exchangeCodeForTokens = (companyData: ICompanyData, code: string) : Promise<any> => {
    const data = {
      grant_type: 'authorization_code',
      client_id: companyData.CognitoClientId,
      redirect_uri: redirectUrl,
      code: code
    };
    const region = companyData.CognitoUserPoolId.split('_')[0];
    const url = `https://${companyData.CognitoPoolDomainName}.auth.${region}.amazoncognito.com/oauth2/token`;

    const options = {
      url,
      method: 'POST' as Method,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data)
    };

    return fetchAsync(options);
};
