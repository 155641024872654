export enum LoginResultStatus {
    Successful,
    Failed,
    Expired,
    ProblemWithTandemAccount,
    ProblemWithUserCredentials,
    IncorrectCompanyName,
    ProblemWithYourCompanyLogin,
    EmptyLogin,
    EmptyPassword
}
