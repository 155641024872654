import { getAccessToken } from '../../modules/tokens';
import apiGateway from '../../modules/api/apiGateway';
import IUserSubordinates from '../../models/interfaces/users/IUserSubordinates';
import { IUser } from '../../models/interfaces/IUser';
import IUserProfile from '../../models/interfaces/IUserProfile';
import IUserRatings from '../../models/interfaces/users/IUserRatings';
import IUserLight from '../../models/interfaces/IUserLight';
import { IPageResponse } from '../../models/interfaces/IPageResponse';
import IUserSearchLight from '../../models/interfaces/IUserSearchLight';
import { IUserNetwork } from '../../models/interfaces/users/IUserNetwork';

export const isAuthenticated = (): boolean => {
    const token = getAccessToken();
    return !!token;
};

export const getUserSubordinates = (userId: number): Promise<IUserSubordinates> => {
    return apiGateway.getAsync(`users/${userId}/subordinates`);
};

export const getUserLightModelById = (userId: number): Promise<IUser> => {
    return apiGateway.getAsync(`users/${userId}/light-model`);
};

export const getUserProfileModelById = (userId: number): Promise<IUserProfile> => {
    return apiGateway.getAsync(`users/${userId}/profile-model`);
};

export const getDashboardRatings = (userId: number): Promise<IUserRatings> => {
    return apiGateway.getAsync(`users/${userId}/dashboard-ratings`);
};

export const getUserShortModelByGuid = (guid: string): Promise<IUserLight> => {
    return apiGateway.getAsync(`users/${guid}/short-model`);
};
export const getUserLightModelByGuid = (guid: string): Promise<IUser> => {
    return apiGateway.getAsync(`users/${guid}/light-model-guid`);
};

export const searchUsersForCreateGoal = (searchString: string, excludeList: number[], page: number, size: number = 15): Promise<IPageResponse<IUser>> => {
    let queryString = encodeURIComponent(searchString);
    const pageNumber = page ? `&page=${page}` : '';
    if (excludeList && excludeList.length) {
        queryString = `${queryString}&exclude=${excludeList.join(',')}`;
    }
    return apiGateway.getAsync(`users/search-for-goals?search=${queryString}&size=${size}${pageNumber}`, true);
};
export const getOrgItemUsersCount = (requestModel: {UserId?: string, OrgItemId?: string}): Promise<number> => {
    return apiGateway.postAsync('users/count-for-orgitem', requestModel);
};
export const searchAllUsersPaged = (searchString: string, page: number, size: number = 15): Promise<IPageResponse<IUserSearchLight>> => {
    const pageNumber = page ? `&page=${page}` : '';
    const search = searchString ? `&search=${encodeURIComponent(searchString)}` : '';
    return apiGateway.getAsync(`users/all-paged?size=${size}${search}${pageNumber}`, true);
};
export const getUserNetwork = (userId: number): Promise<IUserNetwork> => {
    return apiGateway.getAsync(`users/${userId}/profile-relations`);
};
export const searchManagersUsers = (searchString: string, excludeList: string[], page: number, size: number = 15): Promise<IPageResponse<IUser>> => {
    let queryString = encodeURIComponent(searchString);
    const pageNumber = page ? `&page=${page}` : '';
    if (excludeList && excludeList.length) {
        queryString = `${queryString}&exclude=${excludeList.join(',')}`;
    }
    return apiGateway.getAsync(`users/managers-paged?search=${queryString}&size=${size}${pageNumber}`, true);
};
