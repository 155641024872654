import * as React from 'react';
import SearchDropdown from './SearchDropdown';
import ILinkedItems from '../../shared/src/models/interfaces/search/ILinkedItems';
import { Settings } from '../../shared/src/models/entities/settings/Settings';
import { RadioGroup, Flex, Card } from '@fluentui/react-northstar';
import { ISearchOptions } from '../../shared/src/models/interfaces/search/ISearchOptions';
import { GoalPeriod } from '../../shared/src/models/enums/goals/GoalPeriod';
import { getSetting, isArrayEqual } from '../../shared/src/utils/common';
import lclzStor from '../../shared/src/languages/BaseLang';
import { EGoalSettings } from '../../shared/src/models/enums/settings/GoalSettings';
import { ESettings } from '../../shared/src/models/enums/settings/Settings';
import { getPeriodStartDate } from '../../shared/src/utils/goals/goalsUtils';
import { FA_XMARK, SQL_DATE_FORMAT } from '../../shared/src/constants';
import { scrollTop } from '../../shared/src/utils/helpers';
import requestErrorHandler from '../../common/requestErrorHandler';
import { UserProfile } from '../../shared/src/models/entities/user/UserProfile';
import { searchForIndividualGoals } from '../../services/goals';
import { GoalsInfo } from '../elements/GoalsInfo';
import { SearchInput } from './SearchInput';
import uuid from 'uuid/v4';

interface IProps {
    changeLinkedList: (isAdded: boolean, item: any) => void;
    linkedList?: ILinkedItems;
    searchPlaceholder?: string;
    searchLabel?: string;
    className?: string;
    id?: string;
    excludeList?: number[];
    userProfile: UserProfile;
    settings: Settings;
    showSearchError?: boolean;
    searchOnFocus?: boolean;
}

interface IState {
    foundItems: ILinkedItems;
    selectedItems: ILinkedItems;
    focusedSearch: boolean;
    isLoading: boolean;
    allIsLoaded: boolean;
    searchOptions: ISearchOptions;
    goalPeriod: GoalPeriod;
    value: string;
    searchKey: string;
}

class GoalsSearch extends React.Component<IProps, IState> {
    static getDerivedStateFromProps(props: IProps, state: IState) {
        const { linkedList } = props;
        if (linkedList?.Goals && !isArrayEqual(linkedList?.Goals, state.selectedItems.Goals)) {
            return {
                selectedItems: {
                    Goals: props.linkedList.Goals || []
                }
            };
        }
        return null;
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            foundItems: {},
            selectedItems: {
                Goals: props.linkedList?.Goals || []
            },
            focusedSearch: false,
            isLoading: false,
            allIsLoaded: true,
            searchOptions: this.getInitialSearchOptions(),
            goalPeriod: GoalPeriod.CurrentAndFuture,
            value: '',
            searchKey: uuid()
        };
    }

    onFocusHandler = () => {
        const searchOptions = this.getInitialSearchOptions();
        this.setState({ focusedSearch: true, isLoading: this.props.searchOnFocus },
            () => this.loadItemsHandler(searchOptions));
    };
    onBlurHandler = () => {
        this.setState({ focusedSearch: false });
    };
    clearInput = () => {
        this.setState({
            foundItems: {},
            focusedSearch: false,
            value: '',
            searchKey: uuid()
        });
    };
    changeGoalSearchPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPeriod = (e && e.target && +e.target.value) as GoalPeriod;
        this.setState({ goalPeriod: newPeriod });
    };
    changeGoalSearchPeriodCopy = (a,b) => {
        const newPeriod = (b.value) as GoalPeriod;
        this.setState({ goalPeriod: newPeriod });
    };

    isGoalCyclesEnabled = () => getSetting(this.props.settings, ESettings.Goal, EGoalSettings.EnableGoalsCycles);

    getGoalSearchStartDate = () => {
        if (this.isGoalCyclesEnabled()) {
            return this.state.goalPeriod === GoalPeriod.All ? null : getPeriodStartDate(this.props.settings).format(SQL_DATE_FORMAT);
        }
        return null;
    };

    onFoundClick = (type: string, item) => {
        const { selectedItems } = this.state;
        selectedItems[type].push(item);
        this.props.changeLinkedList( item, true);
        this.setState({ selectedItems }, this.clearInput);
    };

    onRemove = goal => () => {
        const { selectedItems } = this.state;
        const filtered = selectedItems.Goals.filter(item => item.Id !== goal.Id);
        this.props.changeLinkedList( goal, false);
        this.setState({selectedItems: {Goals: filtered}});
    };

    loadItemsHandler = async (searchOptions: ISearchOptions) => {
        const result = await this.searchGoals(searchOptions);
        if (result.foundItems) {
            this.setState({ ...result, isLoading: false });
        }
    };
    private PAGE_SIZE: 10;


    searchGoals = async (searchOptions: ISearchOptions) => {
        this.setState({ isLoading: true });
        const { foundItems, selectedItems } = this.state;
        const { excludeList, searchOnFocus } = this.props;
        const nextSearchOptions = this.getEmptySearchOptions();
        const startDateTime = this.getGoalSearchStartDate();
        let allIsLoaded = true;
        const clearSearch = {
            searchOptions: this.getEmptySearchOptions(),
            foundItems: {},
            allIsLoaded: true
        };
        try {
            if (!searchOptions.goals.allIsLoaded) {
                const goalsToExclude = [...selectedItems.Goals.map(goal => goal.Id), ...excludeList || []];
                const isFocused = searchOnFocus && !this.state.value;
                const pageSize = isFocused ? 3 : this.PAGE_SIZE;
                const goalsResults = await searchForIndividualGoals(this.state.value, searchOptions.goals.page, goalsToExclude, pageSize, startDateTime);
                const Goals = searchOptions.goals.page === 1 ? goalsResults.Goals : foundItems.Goals.concat(goalsResults.Goals);
                nextSearchOptions.goals = {
                    page: searchOptions.goals.page + 1,
                    allIsLoaded: goalsResults.Goals?.length < 10 || goalsResults.Goals?.length === 0
                };

                if (searchOptions.goals.page === 1) {
                    scrollTop('custom-search-result-list');
                }
                allIsLoaded = nextSearchOptions.goals.allIsLoaded || isFocused;
                return {
                    allIsLoaded,
                    foundItems: { Goals },
                    searchOptions: nextSearchOptions
                };
            }
        } catch (error) {
            requestErrorHandler(error);
            return clearSearch;
        }
        return clearSearch;
    };

    onInputHandler = (value: string) => {
        const searchOptions = this.getInitialSearchOptions();
            if (value) {
            this.setState({ isLoading: true, value }, () => this.loadItemsHandler(searchOptions));
        } else {
            this.setState({ searchOptions, foundItems: {}, value: '' });
        }
    };

    getEmptySearchOptions = (allIsLoaded: boolean = true): ISearchOptions => {
        return {
            goals: {
                allIsLoaded,
                page: 1
            }
        };
    };

    getInitialSearchOptions = () => {
        const searchOptions = this.getEmptySearchOptions();
        searchOptions.goals.allIsLoaded = false;

        return searchOptions;
    };

    render() {
        const { searchPlaceholder, searchLabel, id, className } = this.props;
        const { focusedSearch, foundItems,  isLoading, allIsLoaded, searchOptions } = this.state;
        const goalCycleOption = [
            {
                key: GoalPeriod.CurrentAndFuture,
                label: lclzStor.GoalPage_Filters_Period_CurrentAndFuture,
                value: GoalPeriod.CurrentAndFuture,
            },
            {
                key: GoalPeriod.All,
                label: lclzStor.GoalPage_Filters_Period_All,
                value: GoalPeriod.All,
            },
        ];

        return (
            <div
                className={`search-wrapper goals-search ${className || ''}`}
                id={id}
                role="search"
            >
                <div className="fake-blur-area" onClick={this.clearInput} />
                <SearchInput
                    placeholder={searchPlaceholder || lclzStor.Select_goal}
                    label={searchLabel || lclzStor.Search_tagPriority}
                    key={this.state.searchKey}
                    onInput={this.onInputHandler}
                    onFocus={this.onFocusHandler}
                    onBlur={this.onBlurHandler}
                />
                {this.isGoalCyclesEnabled() && (
                    <RadioGroup
                        onCheckedValueChange={this.changeGoalSearchPeriodCopy}
                        defaultCheckedValue={GoalPeriod.CurrentAndFuture}
                        items={goalCycleOption}
                    />
                )}
                <SearchDropdown
                    foundItems={foundItems}
                    onFoundClick={this.onFoundClick}
                    searchInput={this.state.value}
                    isLoading={isLoading}
                    onLoadMore={this.loadItemsHandler}
                    searchOptions={searchOptions}
                    allIsLoaded={allIsLoaded}
                    searchOnfocus={true}
                    userProfile={this.props.userProfile}
                    isInputInFocus={focusedSearch}
                />
                {Boolean(this.state.selectedItems.Goals.length) && this.state.selectedItems.Goals.map(goal => (
                    <Card fluid compact ghost key={`sltd-tms-${goal.Id}`} className="height-auto" >
                        <Flex>
                            <GoalsInfo goal={goal} className="w-100 cursor-pointer" />
                            <Flex.Item push>
                                <i className={`${FA_XMARK} fa-regular cursor-pointer p-2`} onClick={this.onRemove(goal)} />
                            </Flex.Item>
                        </Flex>
                    </Card>
                ))}
            </div>
        );
    }
}

export default GoalsSearch;
