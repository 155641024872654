import classnames from 'classnames';
import * as React from 'react';
import styled from "styled-components";
import { SCREEN_SIZES } from '../../../../constants';
import { IName } from '../../../../models/interfaces/IName';
import { ColorOptions } from '../../../../models/types/ColorOptions';
import { getTestSelector } from "../../../../utils/common";
import Image from '../../Image/Image';

interface IProps {
    color?: ColorOptions;
    fullName?: string;
    name?: IName;
    initials?: string;
    imgId: string;
    imgIdn?: string;
    imgTitle?: string;
    className?: string;
    testSelector?: string;    
    size?: string;
    onClick?: () => void;
}

const Avatar = ({ name, fullName, initials, imgId, imgIdn, imgTitle, className, testSelector, size, onClick }: IProps) => {
    const defaultInitials = (fullName: string) => {
      const parts = fullName.replace(/\(.*\)/, '').split(/\s/);
      const mainParts = parts?.length > 2
        ? [parts[0], parts[parts.length -1 ]]
        : parts;
      return mainParts
          .map(part => part.substring(0, 1))
          .filter(v => !!v)
          .join('')
          .toUpperCase();
    };

    const userInitials = React.useMemo(() => {
        if (initials)
            return initials;
        if (name)
            return `${name.GivenName?.substring(0, 1)?.toUpperCase()}${name.FamilyName?.substring(0, 1)?.toUpperCase()}`;
        if (fullName)
            return defaultInitials(fullName);
        
        return '';
    }, [initials, name, fullName]);

    const hasImage = !(!imgId || imgId === 'null');

    return hasImage ? (
        <Image
            imgId={imgId}
            imgIdn={imgIdn}
            imgTitle={imgTitle}
            testSelector={testSelector}
            imgAlt={fullName}
            imgClass={className}
            onClick={onClick}
        />
    ) : (
      <div
          id={imgIdn}
          role="img"
          className={classnames('initials-avatar', className, size)}
          title={imgTitle}
          {...getTestSelector(testSelector)}
          onClick={onClick}
      >
          <div>{userInitials}</div>
      </div>
    );
};

const StyledAvatar: React.FunctionComponent<IProps> = styled(Avatar)`
  &.initials-avatar {
      border-radius: 50%;
      background-color: ${props => props.color ? props.theme[props.color] : props.theme.lines};
      display: inline-block;
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      font-size: 16px;
      &.xll {
        width: 90px;
        height: 90px;
        font-size: 36px;        
      }
      &.xl {
        width: 72px;
        height: 72px;
        font-size: 26px;
      }
      &.lg {
        width: 64px;
        height: 64px;
        font-size: 24px;
      }
      &.md {
        width: 54px;
        height: 54px;
        font-size: 22px;
      }
      &.smd {
          width: 48px;
          height: 48px;
          font-size: 18px;
      }
      &.sm {
        width: 42px;
        height: 42px;
        font-size: 16px;
      }
      &.xssm {
        width: 28px;
        height: 28px;
        font-size: 10px;
      }
      &.xs {
        width: 24px;
        height: 24px;
        font-size: 10px;
      }
      div {
          color: ${props => props.theme.textPrimary};
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: inherit;
          font-weight: inherit;
      }
      @media (max-width: ${SCREEN_SIZES.MOBILE}) {
          &:hover {
              opacity: 1;
          };
      }
  }
`;

export default StyledAvatar;
