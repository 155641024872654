import { AnyAction } from '@reduxjs/toolkit';
import { TOGGLE_SPINNER } from '../actions/spinner';
import { ISpinnerStore } from '../common/interfaces/spinner/ISpinnerStore';

const initialState: ISpinnerStore = {
  show: false
};

export const spinner = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case TOGGLE_SPINNER:
            return { ...state, show: action.toggle };
        default:
            return state;
    }
};

