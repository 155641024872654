import { Flex, Text } from '@fluentui/react-northstar';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { UserInfo } from './UserInfo';
import IIndividualGoalGeneralForm
    from '../../shared/src/models/interfaces/priorities/individualPriorities/IIndividualGoalGeneralForm';
import { htmlToText } from '../../shared/src/utils/common';
import { CircleProgressBarType } from '../../shared/src/models/enums/elements/CircleProgressBarType';
import { useTeams } from '../../hooks/useTeams';
import { FA_CHECK, FA_USER_PLUS } from '../../shared/src/constants';
import { IndividualGoalStatus } from '../../shared/src/models/enums/priorities/IndividualGoalStatus';
import React from 'react';

interface IProps {
    goal: IIndividualGoalGeneralForm
    className: string;
}
export const GoalsInfo = (props: IProps) => {
    const { theme } = useTeams({})[0];
    const { brand } = theme.siteVariables.contextualColors;
    const greenDark = theme.siteVariables.colorScheme.green.foreground;
    const grey = theme.siteVariables.colorScheme.grey.background4;
    const greyDark = theme.siteVariables.colorScheme.grey.background5;
    const isClosed = props.goal.Status === IndividualGoalStatus.ClosedDone;
    const isClosedPendingApproval = props.goal.Status === IndividualGoalStatus.CompletedPendingApproval;
    const showIcon = isClosed || isClosedPendingApproval;
    const brandPathColor = props.goal?.Progress === 0 ? brand[300] : brand[600];
    const pathColor = showIcon ? greenDark : brandPathColor;
    const trailColor = grey;
    const iconName = isClosed ? FA_CHECK : FA_USER_PLUS;
    return (
        <Flex gap="gap.small" className="goal-info p-2 truncated-text" vAlign="center">
            <CircularProgressbarWithChildren
                value={props.goal?.Progress}
                className={`circle-bar__size-${CircleProgressBarType.xs} `}
                styles={buildStyles({
                    pathColor, trailColor,
                    textColor: brand[800],
                    backgroundColor: brand[600]
                })}
            >
                <>
                    {showIcon && <i className={`${iconName} fa-regular`} style={{color: greenDark }} />}
                    <Text content={`${props.goal.Progress}%`} styles={{color: greyDark}} />
                </>
            </CircularProgressbarWithChildren>
            <div className="mw-100">
                <Text size="medium" content={htmlToText(props.goal.Title)} as="div" truncated className="truncated-text" />
                {props.goal?.Owner && (
                    <UserInfo
                        img={props.goal?.Owner?.PhotoId}
                        fullName={props.goal?.Owner?.FullName}
                        size="smallest"
                        textSize="small"
                        textColor={greyDark}
                    />
                )}
            </div>
        </Flex>
    );
};