import * as React from 'react';
import lclzStor from '../../shared/src/languages/BaseLang';
import ILinkedItems from '../../shared/src/models/interfaces/search/ILinkedItems';
import { SearchItems } from '../../shared/src/models/enums/search/SearchItems';
import { ISearchOptions } from '../../shared/src/models/interfaces/search/ISearchOptions';
import { MIN_SEARCH_CHARACTERS } from '../../shared/src/constants';
import { UserProfile } from '../../shared/src/models/entities/user/UserProfile';
import { Text, Card, Loader } from '@fluentui/react-northstar';
import { UserInfo } from '../elements/UserInfo';
import { OIInfo } from '../elements/OIInfo';
import InfiniteScroll from "react-infinite-scroll-component";
import { GoalsInfo } from '../elements/GoalsInfo';

interface IProps {
    foundItems: ILinkedItems;
    onFoundClick: (type: SearchItems, item: any) => void;
    searchInput?: string;
    onLoadMore: (options: ISearchOptions) => void;
    isLoading: boolean;
    allIsLoaded: boolean;
    searchOptions: ISearchOptions;
    excludeCurrentUserFromOI?: boolean;
    userProfile?: UserProfile;
    searchOnfocus?: boolean;
    isInputInFocus: boolean;
}

const SearchDropdown = (props: IProps) => {
    const { foundItems, searchInput, isLoading, isInputInFocus, allIsLoaded, onLoadMore, searchOptions } = props;
    const hasUsers = Boolean(foundItems?.Users?.length);
    const hasOI = Boolean(foundItems?.OrgItems?.length);
    const hasGoals = Boolean(foundItems?.Goals?.length);
    const foundItemsExist = hasUsers || hasOI || hasGoals || Boolean(foundItems.DirectReports?.length);

    const searchAvailable = (searchInput && searchInput.toString().trim().length >= MIN_SEARCH_CHARACTERS) || props.searchOnfocus;
    const noResults = (searchAvailable && !foundItemsExist && !isLoading) && isInputInFocus;

    const onFoundClick = (type: SearchItems, item) => () => {
        props.onFoundClick(type, item);
    };

    const loadMoreHandler = () => {
        const nextSearchOptions = { ...searchOptions };
        if (searchOptions.users && !searchOptions.users?.allIsLoaded) {
            nextSearchOptions.users.page = searchOptions.users.page + 1;
        } else if (searchOptions.orgItems && !searchOptions.orgItems?.allIsLoaded) {
            nextSearchOptions.orgItems.page = searchOptions.orgItems.page + 1;
        } else if (searchOptions.excluded && !searchOptions.excluded.allIsLoaded) {
            nextSearchOptions.excluded.page = searchOptions.excluded.page + 1;
        }
        onLoadMore(nextSearchOptions);
    };
    const loader = (
        <Loader size="smallest" />
    );
    const inputItems = () => {
        const oiInfo = (count: number) => count ? `${count} ${lclzStor.SurveyNominations_people}`: null;
        let items = [];
        if (hasUsers) {
            const users = foundItems?.Users?.map(user =>(
                <Card fluid compact onClick={onFoundClick(SearchItems.Users, user)} key={`fnd-tms-${user.Id}`} className="truncated-text height-auto">
                    <UserInfo img={user.Img} fullName={user.FullName} jobTitle={user.JobTitle} className="w-100 xs cursor-pointer" />
                </Card>
            ));
            items = items.concat(users);
        }
        if (hasOI) {
            const oi = foundItems?.OrgItems?.map(oi =>(
                <Card fluid compact onClick={onFoundClick(SearchItems.OrgItems, oi)} key={`fnd-tms-${oi.Id}`} className="height-auto">
                    <OIInfo name={oi.Name} info={oiInfo(oi.UsersCount)} className="w-100 xs cursor-pointer" />
                </Card>
            ));
            items = items.concat(oi);
        }
        if (hasGoals) {
            const goals = foundItems?.Goals?.map(goal =>(
                <Card fluid compact
                      onClick={onFoundClick(SearchItems.Goals, goal)}
                      key={`fnd-tms-${goal.Id}`}
                      className="height-auto"
                >
                    <GoalsInfo goal={goal} className="w-100 xs cursor-pointer"  />
                </Card>
            ));
            items = items.concat(goals);
        }
        return items;
    };

    const showLoader = props.isLoading && props.isInputInFocus && !foundItemsExist;
    const showDropdown = noResults || showLoader || foundItemsExist;
    const items = inputItems();
    return showDropdown ? (
        <div
            className="search-dropdown overflow-scroll"
            id="scrollableDiv"
        >
            {noResults &&
            <Card fluid className="height-auto">
                <Text className="notFoundMsg" content={lclzStor.Search_noResultsFound} />
            </Card>
            }
            {foundItemsExist && (
                <InfiniteScroll
                    dataLength={items?.length}
                    next={loadMoreHandler}
                    hasMore={!allIsLoaded}
                    loader={loader}
                    scrollableTarget="scrollableDiv"
                    height={250}
                >
                    {items}
                </InfiniteScroll>
            )}
        </div>
    ) : null;
};

export default SearchDropdown;
