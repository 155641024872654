import moment, { Moment } from 'moment';
import 'moment/min/locales';
import {REVIEW_FORMAT, DATE_WITHOUT_TIMEZONE, DATE_TIME_YEAR_FORMAT, DATE_TIME_YEAR_FORMAT_RU} from '../../constants';
type dateTypeOptions = Moment | Date | string;

export const convertDateToObject = (date: string, language?: string): Moment => {
    return moment(date).local().locale(language || 'en');
};

export const convertDateToString = (date: dateTypeOptions): string => {
    return date ? moment(date).utc().format() : '';
};

export const convertUTCDateToLocalTime = (date: dateTypeOptions, format?: string, language?: string): string => {
    return moment.utc(date).local().locale(language || 'en').format(format);
};

export const convertDateToLocalTime = (date: dateTypeOptions, language?: string,  format?: string): string => {
    return moment(date).local().locale(language || 'en').format(format || REVIEW_FORMAT);
};

export const convertDateToLocalTimeSetOnLastSecond = (date: Date): string => {
    return moment.utc(date).local().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).format(DATE_WITHOUT_TIMEZONE);
};

export const convertDateToLocalTimeSetOnStartOfTheDay = (date: Date | Moment): string => {
    return moment.utc(date).local().startOf('day').format(DATE_WITHOUT_TIMEZONE);
};

export const getDateFormat = (local: string = '') => {
     return local === 'ru' ? DATE_TIME_YEAR_FORMAT_RU : DATE_TIME_YEAR_FORMAT;
};

export const nearestMinutes = (interval: number, date: moment.Moment) => {
    const roundedMinutes = Math.round(date.clone().minute() / interval) * interval;
    return date.clone().minute(roundedMinutes).second(0);
};

export const nearestPastMinutes = (interval: number, date: moment.Moment) => {
    const roundedMinutes = Math.floor(date.minute() / interval) * interval;
    return date.clone().minute(roundedMinutes).second(0);
};

export const nearestFutureMinutes = (interval: number, date: moment.Moment) => {
    const roundedMinutes = Math.ceil(date.minute() / interval) * interval;
    return date.clone().minute(roundedMinutes).second(0);
};
