import globalEventDistributor from '../helpers/globalEventDistributor';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const loginSuccess = (token: string) => globalEventDistributor.dispatch({
    token, type: LOGIN_SUCCESS
});

export const userLogoutSuccess = () => globalEventDistributor.dispatch({
    type: USER_LOGOUT_SUCCESS
});

export const userLogoutFailed = () => globalEventDistributor.dispatch({
    type: USER_LOGOUT_FAILED
});
