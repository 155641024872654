import axios, { AxiosInstance, AxiosError, CancelTokenSource } from 'axios';
import { isMobileApp } from '../screenSettings';
import { VERSION_1_0 } from '../../constants';
import { getCurrentCompanyName } from '../../utils/companyUtils';
import { ErrorType } from '../../models/enums/errorHandler/ErrorType';

enum HttpMethodType {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DELETE = 'delete'
}

const clientId = isMobileApp() ? process.env.REACT_APP_CLIENT_MOBILE_ID : process.env.REACT_APP_CLIENT_DESKTOP_ID;

abstract class AbstractAxiosService {
    protected axiosInstance: AxiosInstance;
    protected isIdentityReq: boolean;
    private _errorHandler: (error: AxiosError, description?: string) => void;
    private _setFrontErrorId: (correlationId: string) => void;
    private _setBackErrorId: (correlationId: string) => void;
    private cancelTokens: CancelTokenSource[];
    private timerIds: number[];

    constructor(serviceUrl: string, contentType: string, isIdentityReq: boolean = false) {
        this.axiosInstance = axios.create({
            baseURL: serviceUrl,
            headers: {
                'Content-Type': contentType,
                common: {
                    platform: isMobileApp() ? 'app' : 'web',
                    ...isIdentityReq && getCurrentCompanyName
                        ? { company: encodeURIComponent(getCurrentCompanyName()) || '' } : {}
                },
                get: {
                    client_id: clientId
                },
                post: {
                    client_id: clientId
                }
            }
        });
        this.isIdentityReq = isIdentityReq;
        this.cancelTokens = [];
        this.timerIds = [];
    }

    public setErrorHandlers = (errorHandler: (error: AxiosError, description?: string) => void, frontErrorIdHandler: (correlationId: string) => void, backErrorIdHandler: (correlationId: string) => void) => {
        this._errorHandler = errorHandler;
        this._setFrontErrorId = frontErrorIdHandler;
        this._setBackErrorId = backErrorIdHandler;
    };

    public get errorHandler() { return this._errorHandler; };
    public get setFrontErrorId() { return this._setFrontErrorId; };
    public get setBackErrorId() { return this._setBackErrorId; };

    public getUrl() {
        return this.axiosInstance.defaults.baseURL;
    }

    public cancelRequests = () => {
        if (this.cancelTokens?.length) {
            this.cancelTokens.forEach(token => token.cancel());
            this.cancelTokens = [];
        }
    };

    public cancelTimeouts = () => {
        if (this.timerIds?.length) {
            this.timerIds.forEach(timerId => clearTimeout(timerId));
            this.timerIds = [];
        }
    };

    private cancelTimeout = (timerId: number) => {
        clearTimeout(timerId);
        this.timerIds = this.timerIds.filter(id => id !== timerId);
    };

    setHeaderCompanyParam = (type: HttpMethodType) => {
        if (this.isIdentityReq) {
            this.axiosInstance.defaults.headers[type]['company'] = encodeURIComponent(getCurrentCompanyName());
        }
    };

    getPlatform = () => (isMobileApp() ? 'app' : 'web');

    get(url: string, callbackSuccess: any, callbackError?: any): void {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.setHeaderCompanyParam(HttpMethodType.GET);
        if (this.isIdentityReq) {
            this.axiosInstance.defaults.headers.get['company'] = encodeURIComponent(getCurrentCompanyName());
        }
        this.axiosInstance
            .get(url)
            .then(({ data }) => callbackSuccess(data))
            .catch((error: AxiosError) => {
                callbackError ? callbackError(error) : (this.errorHandler && this.errorHandler(error));
            });
    }

    getAsync(url: string, isCancelable: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            let source: CancelTokenSource;
            if (isCancelable) {
                const cancelToken = axios.CancelToken;
                source = cancelToken.source();
                this.cancelTokens.push(source);
            }
            this.axiosInstance.defaults.baseURL = this.getUrl();
            this.setHeaderCompanyParam(HttpMethodType.GET);
            this.axiosInstance
                .get(url, {...(isCancelable ? { cancelToken: source.token } : {})})
                .then(({ data }) => resolve(data))
                .catch((error: AxiosError) => {
                    (this.errorHandler && this.errorHandler(error));
                    reject(error);
                });
        });
    }

    downloadAsync(url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            const timerId = setTimeout(() => this.errorHandler && this.errorHandler({ response: { data: ErrorType.longrunningdownload } } as AxiosError), 15000) as any;
            this.cancelTokens.push(source);
            this.timerIds.push(timerId);
            this.axiosInstance.defaults.baseURL = this.getUrl();
            this.setHeaderCompanyParam(HttpMethodType.GET);
            this.axiosInstance
                .get(url, { cancelToken: source.token, responseType: 'blob' })
                .then(response => {
                    this.cancelTimeout(timerId);
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    this.cancelTimeout(timerId);
                    reject(error);
                });
        });
    }

    postAsync(url: string, postdata?: any, isCancelable: boolean = false, timeout?: number): Promise<any> {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.setHeaderCompanyParam(HttpMethodType.POST);
        if (timeout) {
            this.axiosInstance.defaults.timeout = timeout;
        }
        let source: CancelTokenSource;
        if (isCancelable) {
            const cancelToken = axios.CancelToken;
            source = cancelToken.source();
            this.cancelTokens.push(source);
        }
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post(url, postdata, {...(isCancelable ? { cancelToken: source.token } : {})})
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error: AxiosError) => {
                    (this.errorHandler && this.errorHandler(error));
                    reject(error);
                });
        });
    }

    put(url: string, callbackSuccess: any, putdata?: any, callbackError?: any): void {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.setHeaderCompanyParam(HttpMethodType.PUT);
        this.axiosInstance
            .put(url, putdata)
            .then(({ data }) => {
                callbackSuccess(data);
            })
            .catch((error: AxiosError) => callbackError ? callbackError(error) : (this.errorHandler && this.errorHandler(error)));
    }

    putAsync(url: string, putdata?: any, version?: number): Promise<any> {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.axiosInstance.defaults.headers.put['X-API-Version'] = version || VERSION_1_0;
        this.setHeaderCompanyParam(HttpMethodType.PUT);
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .put(url, putdata)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error: AxiosError) => {
                    (this.errorHandler && this.errorHandler(error));
                    reject(error);
                });
        });
    }

    patchAsync(url: string, patchdata?: any): Promise<any> {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.setHeaderCompanyParam(HttpMethodType.PATCH);
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .patch(url, patchdata)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error: AxiosError) => {
                    (this.errorHandler && this.errorHandler(error));
                    reject(error);
                });
        });
    }

    delete(url: string, callbackSuccess: any, callbackError?: any): void {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.setHeaderCompanyParam(HttpMethodType.DELETE);
        this.axiosInstance
            .delete(url)
            .then(({ data }) => {
                callbackSuccess(data);
            })
            .catch((error: AxiosError) => callbackError ? callbackError(error) : (this.errorHandler && this.errorHandler(error)));
    }

    deleteAsync(url: string): Promise<any> {
        this.axiosInstance.defaults.baseURL = this.getUrl();
        this.setHeaderCompanyParam(HttpMethodType.DELETE);
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .delete(url)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error: AxiosError) => {
                    (this.errorHandler && this.errorHandler(error));
                    reject(error);
                });
        });
    }
}

export default AbstractAxiosService;
