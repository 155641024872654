import { IAdditionalData } from '../../../../shared/src/models/interfaces/pushNotifications/IAdditionalData';
import { IUserResult } from '../../models/interfaces/pushNotifications/IUserResult';


interface ISubscriptionResult {
    from: IUserResult;
    to: IUserResult;
}

class PushNotificationService {
    private openedCallback: (d: IAdditionalData) => void;

    notificationOpenedCallback = (jsonData: any) => {
        let notification: any;
        let additionalData: any;
        try {
            notification = jsonData.notification;
            additionalData = notification.additionalData;
            if (typeof additionalData === 'string' || additionalData instanceof String) {
                additionalData = JSON.parse(additionalData as any);
            }
            if (this.openedCallback && typeof this.openedCallback === 'function') {
                this.openedCallback(additionalData);
            }
        } catch (e: any) {
            // tslint:disable-next-line: no-console
            console.log(e?.message);
        }
    };

    init = (oneSignalAppId): void => {
        const oneSignalInit = () => {           
            window.plugins.OneSignal.setAppId(oneSignalAppId);
            window.plugins.OneSignal.setNotificationOpenedHandler(this.notificationOpenedCallback);
            window.plugins.OneSignal.promptForPushNotificationsWithUserResponse();
        };
        document.addEventListener('deviceready', oneSignalInit, false);
    };

    setOpenedCallback = (callback: (d: IAdditionalData) => void): void => {
        this.openedCallback = callback;
    };

    getIds = (callback: (id: string) => void) => {
        try {
            window.plugins.OneSignal.addSubscriptionObserver((result: ISubscriptionResult) => callback(result.to.userId));
            window.plugins.OneSignal.getDeviceState((result: IUserResult) => callback(result.userId));
        } catch (e) {
            // tslint:disable-next-line: no-console
            console.log('Push notifications did not initialized');
        }
    };
}

const pushNotificationService = new PushNotificationService();

export default pushNotificationService;
