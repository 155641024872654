import { Dialog } from '@fluentui/react-northstar';
import { PopUp } from './elements/PopUp';
import { FA_NOTE, FA_XMARK } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import React from 'react';
import { hashHistory } from '../shared/src/utils/history';
import { APP_CONSTANTS } from '../common/constants';

import { FeedbackNote } from './FeedbackNote';

export const FeedbackNoteWidgetMobile = () => {
    const [isNoteSent, setIsNoteSent] = React.useState(false);
    const onCancel = () => hashHistory.push(APP_CONSTANTS.LINKS.HOME);
    const onSuccess = () => {
        setIsNoteSent(true);
    };
    const closeSuccessPopUP = () => {
        setIsNoteSent(false);
        hashHistory.push(APP_CONSTANTS.LINKS.HOME);
    };
    return (
        <>
            <Dialog
                content={
                    <PopUp iconName={FA_NOTE} title={lclzStor.FeedbackNote_Saved} primaryBtnText={lclzStor.GotIt_Btn} primaryCB={closeSuccessPopUP} />}
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={closeSuccessPopUP} />}
                open={isNoteSent}
            />
            <FeedbackNote onCancel={onCancel} onSuccess={onSuccess} />
        </>
    );
};