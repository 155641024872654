import { getRefreshToken, setRefreshToken, setAccessToken, getAccessToken } from './tokens';
import { isMobileApp } from './screenSettings';
import lclzStor from '../languages/BaseLang';
import { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import { IIdentityResponse } from '../models/interfaces/identity/IIdentityResponse';
import { userLogout } from './userLogout';
import { refreshTokenAsync } from './loginManagement';
import { openCoreModal } from '../utils/modalsReduxUtils';

let authTokenRequest: Promise<IIdentityResponse>;
export const tokenNotExpired = (token: string): boolean => {
  const jwt: any = jwt_decode(token);
  return jwt.exp > Date.now() / 1000;
};

const getNewTokensAsync = (): Promise<IIdentityResponse> => {
    const refreshToken = getRefreshToken();
    const logout = userLogout();

    if (!authTokenRequest) {
        authTokenRequest = refreshTokenAsync(refreshToken);
        authTokenRequest.then((data: IIdentityResponse) => {
            authTokenRequest = null;
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
        }).catch((error: AxiosError) => {
            authTokenRequest = null;
            if (!isMobileApp() && navigator.onLine && error.code !== 'ECONNABORTED') {
                logout();
            } else if (isMobileApp() && getAccessToken() && getRefreshToken()) {
                logout();
                openCoreModal({
                    title: lclzStor.Logout_Expire_Session_Title,
                    subtitle: lclzStor.Logout_Expire_Session_Text,
                    autoClose: true
                });
            } else if (error && error.response && error.response.data && error.response.data.error === 'invalid_grant') {
                logout();
            }
        });
    }
    return authTokenRequest;
};

const getFreshTokenAsync = (response?: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();

        if (accessToken && tokenNotExpired(accessToken) && !response) {
            resolve(accessToken);
        } else if (refreshToken) {
            getNewTokensAsync().then((data: IIdentityResponse) => {
                return resolve(data.access_token);
            }).catch((error: AxiosError) => {
                return reject(error);
            });
        }
    });
};

export default getFreshTokenAsync;
