import { Tooltip } from '@fluentui/react-northstar';
import React from 'react';

interface IProps {
    content: string;
    trigger: any;
    timeout?: number;
}
export const CustomTooltip = (props: IProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [width] = React.useState<number>(window.innerWidth);
    const isMobileView = width < 700;
    const close = () => setIsOpen(false);
    const open = () => {
        setIsOpen(true);
        if (isMobileView) {
            setTimeout(close, props.timeout || 2000);
        }
    };

    return (
        <div
            onMouseEnter={open}
            onMouseLeave={close}
            onScroll={close}
            onWheel={close}
            onTouchStart={open}
        >
            <Tooltip
                pointing={!isMobileView}
                open={isOpen}
                content={props.content}
                trigger={props.trigger}
                mouseLeaveDelay={1}
                position= "below"
                unstable_disableTether="all"
                unstable_pinned={true}
            />
        </div>
    );
};