import { Action, combineReducers } from '@reduxjs/toolkit';

import { auth } from './auth';
import { spinner } from './spinner';
import { user } from './user';
import { settings } from './settings';
import { CLEAR_STORE } from '../actions/actionTypes';
import { error } from './error';
import { modals } from './modals';

const reducers = combineReducers({
    auth,
    spinner,
    user,
    settings,
    error,
    modals
});

const rootReducer = (state: any, action: Action) => {
    if (action.type === CLEAR_STORE) {
        state = undefined;
    }
    return reducers(state, action);
};

export default rootReducer;
