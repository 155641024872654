import { Input, SearchIcon } from '@fluentui/react-northstar';
import lclzStor from '../../shared/src/languages/BaseLang';
import * as React from 'react';
import { SEARCH_TIMEOUT } from '../../shared/src/constants';

interface IProps {
    label?: string;
    placeholder?: string;
    onInput: (val: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    isDisabled?: boolean;
};

export const SearchInput = (props: IProps) => {
    const { label, placeholder, onBlur, onFocus, onInput } = props;
    let timeout = null;
    const onInputHandler = (e: React.SyntheticEvent<HTMLElement>) => {
        // @ts-ignore
        const { value } = e.currentTarget;
        if (timeout != null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => onInput(value), SEARCH_TIMEOUT);
    };
    const onBlurHandler = () => {
        onBlur && onBlur();
    };
    const onFocusHandler = () => {
        onFocus && onFocus();
    };
    return (
        <Input
            name="search"
            aria-labelledby="search-label"
            id="search"
            onChange={onInputHandler}
            icon={<SearchIcon />}
            placeholder={placeholder || lclzStor.GiveFeedback_SelectPeople}
            fluid
            label={label || lclzStor.Feedback_SendTo}
            iconPosition="start"
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            disabled={props.isDisabled}
        />
    );
};
