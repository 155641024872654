
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from './tokens';
import * as sessionStorage from './sessionStorage';
import * as localStorage from './localStorage';
import { CLIENT_IDS, LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../constants';
import { client_id_env, navigate } from '../utils/common';
import { Pages } from '../models/enums/layout/Pages';
import { isMobileApp } from './screenSettings';
import { removePushNotificationsDevice } from './pushNotifications';
import { userLogoutFailed, userLogoutSuccess } from '../actions/loginActions';
import { unblurModalBackground } from '../utils/modals';
import { AxiosPromise } from 'axios';
import { IRevokeParams } from '../models/interfaces/identity/identityParams';
import { IIdentityResponse } from '../models/interfaces/identity/IIdentityResponse';
import { getBackendUrl, postAsync } from './http';
import * as qs from 'qs';
import { IdentityGrandTypes } from '../models/enums/login/IdentityGrandTypes';

export const clearUserData = () => {
    const isTeams = client_id_env === CLIENT_IDS.Teams;
    revokeRefreshTokenAsync(getRefreshToken(), getAccessToken());
    revokeRefreshUserTokenAsync(getAccessToken());
    sessionStorage.deleteItem(process.env.REACT_APP_SESSION_ID);
    sessionStorage.deleteItem(SESSION_STORAGE_KEYS.DEFAULT_COMPANY_LANGUAGE);
    sessionStorage.deleteItem(SESSION_STORAGE_KEYS.PROXY_MODE);
    localStorage.deleteItem(LOCAL_STORAGE_KEYS.NOTIFICATION_URL);
    localStorage.deleteItem(LOCAL_STORAGE_KEYS.SHOULD_LANDING_PAGE_BE_VISITED);
    setAccessToken(null);
    setRefreshToken(null);
    navigate(isTeams? Pages.welcome : Pages.login);
    userLogoutSuccess();
};

export const userLogout = () => {
    return () => {
        const mainUserLogout = () => {
            if (getAccessToken()) {
                const clearData = () => {
                    if (client_id_env !== CLIENT_IDS.Teams){
                        unblurModalBackground();
                    }
                    clearUserData();
                };
                sessionStorage.saveItem(SESSION_STORAGE_KEYS.STOP_AUTO_SSO, true);
                if (isMobileApp()) {
                    removePushNotificationsDevice(clearData);
                    if (typeof StatusBar !== 'undefined') {
                        StatusBar.backgroundColorByHexString('#FFF');
                        StatusBar.styleDefault();
                    }
                } else {
                    clearData();
                }
            } else {
                userLogoutFailed();
            }
        };
        // if user acting as proxy we need to finish proxy session first
        if (sessionStorage.loadItem(SESSION_STORAGE_KEYS.PROXY_MODE)) {
            clearProxyUser(getAccessToken())
                .then(data => {
                    // now we can clear data for main user using his access and refresh tokens
                    setAccessToken(data.access_token);
                    setRefreshToken(data.refresh_token);
                    mainUserLogout();
                })
                .catch(mainUserLogout);
        } else {
            // just logout main user
            mainUserLogout();
        }

    };
};

const revokeRefreshTokenAsync = (
  refreshToken: string,
  accessToken: string
): Promise<AxiosPromise<IIdentityResponse>> => {

    const data: IRevokeParams = {
        token_type_hint: IdentityGrandTypes.refresh_token,
        token: refreshToken,
        access_token: accessToken,
        client_id: client_id_env,
        correlationId: sessionStorage.loadItem(process.env.REACT_APP_SESSION_ID)
    };
    const params = qs.stringify(data);

    if (isMobileApp()) {
        postAsync('https://4q1h0ikse5.execute-api.eu-west-1.amazonaws.com/dev/log', `revokeRefreshToken params: ${params}`);
    }

    const config = {
      headers: {
        Company: encodeURIComponent(sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY)?.CompanyName) || ''
      }
    };

    return postAsync(`${getIdentityUrl()}connect/revocation`, params, config);
};

const revokeRefreshUserTokenAsync = (accessToken: string): Promise<AxiosPromise<void>> => {
    return postAsync(`${getBackendUrl()}sessions/revoked-tokens`, JSON.stringify(accessToken), getHeadersConfig(accessToken));
};

const clearProxyUser = (accessToken: string): Promise<IIdentityResponse> => {
    return postAsync(`${getBackendUrl()}proxy/user/off`, {}, getHeadersConfig(accessToken));
};

const getHeadersConfig = (accessToken: string) => {
    return {
        headers: {
            Company: encodeURIComponent(sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY)?.CompanyName) || '',
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'v': process.env.REACT_APP_TANDEM_VERSION,
            'client_id': client_id_env,
            sessionId: sessionStorage.loadItem(process.env.REACT_APP_SESSION_ID)
        }
    };
};

const getIdentityUrl = () => sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY)?.IdentityUrl || process.env.REACT_APP_IDENTITY_URL;
