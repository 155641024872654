import { FA_TIMES_CIRCLE } from '../constants';
import lclzStor from '../languages/BaseLang';
import IOnboardingPage from '../models/interfaces/users/IOnboardingPage';
import IModalBody from '../models/interfaces/elements/IModalBody';
import { editLeaveModal } from './goals/goalsUtils';
import { hashHistory } from './history';
import { closeCoreModal, openCoreModal } from './modalsReduxUtils';

export const showValidationModal = (reason: string, statusCode?: number): void => {
    const closeModal = (): void => {
        closeCoreModal();
    };
    const title = statusCode !== 413 ? reason : lclzStor['{L_Settings.UpdateCompanyLogo.ImageSizeIncorrect_L}'];

    if (title) {
        openCoreModal({
            title,
            icon: {
                iconName: FA_TIMES_CIRCLE,
                weight: 'light',
                type: 'danger'
            },
            secondaryBtnText: lclzStor.PhotoValidation_backToUpload,
            secondaryBtnCallback: closeModal
        });
    }
};

export const leaveRouteHandler = (nextPath: string, leaveRouteEnabled: boolean, toggleLeaveRoute: (val: boolean) => void) => {
    if (routeIsTheSame(hashHistory.location.pathname, nextPath)) {
        return false;
    }
    const leaveSuccess = () => {
        closeCoreModal();
        hashHistory.push(nextPath);
    };
    const leaveCancel = () => {
        toggleLeaveRoute(false);
        closeCoreModal();
    };
    if (!leaveRouteEnabled) {
        toggleLeaveRoute(true);
        editLeaveModal(leaveSuccess, leaveCancel);
        return false;
    }
    return true;
};

export const saveSettingsSuccessModal = () => {
    openCoreModal({
        title: lclzStor.CompanySettings_SavedSuccess,
        autoClose: true,
        isVertical: true,
        secondaryBtnText: lclzStor.GotIt_Btn,
        secondaryBtnCallback: () => closeCoreModal()
    });
};

export const blurModalBackground = () => {
    document.getElementById('core').style.filter = 'blur(4px)';
    document.getElementById('login').style.filter = 'blur(4px)';
};

export const routeIsTheSame = (currentPath: string, nextPath: string) => {
    // @ts-ignore
    return nextPath === currentPath || !isNaN(currentPath.replace(`${nextPath}/`, ''));
};

export const unblurModalBackground = () => {
    document.getElementById('core').style.filter = 'none';
    document.getElementById('login').style.filter = 'none';
};

export const prepareOnBoardingModalBody = (item: IOnboardingPage): IModalBody => {
    const { Image, Text } = item;

    return {
        icon: {
            iconName: Image,
            type: 'secondary'
        },
        title: Text
    };
};
