import lclzStor from '../shared/src/languages/BaseLang';
import { Card, Text, Loader, Dialog } from '@fluentui/react-northstar';
import React from 'react';
import requestErrorHandler from '../common/requestErrorHandler';
import {
    deleteAllNotification, deleteNotification,
    getNotifications
} from '../services/Notifications';
import { INotificationItem } from '../shared/src/models/interfaces/notifications/INotificationItem';
import { NotificationItem } from './NotificationItem';
import { FA_BELL_SLASH, FA_TRASH_ALT, FA_XMARK } from '../shared/src/constants';
import { PopUp } from './elements/PopUp';
import { useTeams } from '../hooks/useTeams';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cloneDeep, concat, uniqBy } from 'lodash';

export const Notifications = () => {
    const [notifications, setNotifications] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isMoreLoading, setIsMoreLoading] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(1);
    const setIsDeleteOpenHandler = () => setIsDeleteOpen(!isDeleteOpen);
    const { theme } = useTeams({})[0];
    const ITEMS_PER_PAGE = 10;
    React.useEffect(() => {
            const fetchData = async () => {
                const list = await getNotifications(10, 1);
                setNotifications(list.ItemList);
                setIsLoading(false);
                setHasMore(list.PageCount > 1);
            };
            fetchData().catch(err => requestErrorHandler(err));

    }, [setNotifications]);

    const showEmptyState = !isLoading && !Boolean(notifications?.length);
    const loadMoreHandler = () => {
        const fetchData = async () => {
            const nextPage = currentPage + 1;
            setIsMoreLoading(true);
            const list = await getNotifications(10, nextPage);
            const updatedList = uniqBy(concat(cloneDeep(notifications), list.ItemList ), 'Id');
            setNotifications(updatedList);
            setCurrentPage(nextPage);
            setHasMore(list.PageCount > currentPage);
            setIsMoreLoading(false);
        };
        if (!isMoreLoading) {
            fetchData().catch(err => requestErrorHandler(err));
        }
    };

    const deleteAll = async () => {
        await deleteAllNotification();
        setNotifications([]);
        setIsDeleteOpen(false);
    };
    const deleteNotificationHandler = (id: number) => async () => {
        await deleteNotification(id);
        const updList = notifications.filter(item => item.Id !== id);
        if (hasMore) {
            const oneNotification = await getNotifications(1, updList.length + 1);
            updList.push(oneNotification.ItemList[0]);
        }
        setNotifications(updList);
    };
    return (
        <div className="notification-widget-tile">
            <Card elevated fluid compact className="pt-3 px-3">
                <Text content={lclzStor.Notifications_Notifications_title} size="larger" as="div" className="mb-2" />
                {isLoading && <Loader />}
                <div className="overflow-scroll" id="scrollableDiv" >
                    {Boolean(notifications?.length) &&
                    <>
                        <InfiniteScroll
                            dataLength={ITEMS_PER_PAGE * currentPage}
                            next={loadMoreHandler}
                            hasMore={hasMore}
                            loader={<Loader size="smallest" />}
                            scrollableTarget="scrollableDiv"
                        >
                            {notifications.map((notification: INotificationItem)  => {
                                return (
                                    <NotificationItem
                                        notification={notification}
                                        key={`sltd-tms-${notification?.Id}`}
                                        deleteNotification={deleteNotificationHandler(notification?.Id)}
                                    />  );
                            })}
                        </InfiniteScroll>
                    </>}
                </div>
                {showEmptyState && (
                    <>
                        <i className={`${FA_BELL_SLASH} fa-regular empty-state-icon`} style={{ color: theme.siteVariables.colors.grey[400] }} />
                        <Text content={lclzStor.Notification_NoNotificationsText} size="medium" align="center" />
                    </>
                )}
            </Card>
            <Dialog
                content={
                    <PopUp
                        iconName={FA_TRASH_ALT}
                        title={lclzStor.Notifications_DeleteAll_ConfTitle}
                        secondaryBtnText={lclzStor.no}
                        secondaryCB={setIsDeleteOpenHandler}
                        primaryBtnText={lclzStor.yes}
                        primaryCB={deleteAll}
                    />}
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={setIsDeleteOpenHandler} />}
                open={isDeleteOpen}
            />
        </div>
    );
};