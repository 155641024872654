import { INotificationItem } from '../../shared/src/models/interfaces/notifications/INotificationItem';
import { NotificationType } from '../../shared/src/models/enums/notifications/NotificationType';

export const getIconName = (url: string) => {
    const urlArray = url.split('/');
    const iconName = urlArray[urlArray.length - 1].split('.')[0];
    return `far fa-${iconName}`;
};
export const isIcon = (url: string) => {
    const patt = new RegExp('.svg');
    return patt.test(url);
};
export const getNotificationImage = (notification: INotificationItem, profileImage: string) => {
    switch (notification?.Type) {
        case NotificationType.AdminApproveInvite:
            return notification?.FromUser ? notification.FromUser?.Img : profileImage;
        case NotificationType.UpcomingHardDeletion:
            return notification?.NotificationImg;
        default:
            return notification?.FromUser ? notification.FromUser?.Img : notification?.NotificationImg;
    }
};