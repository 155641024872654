export enum NotificationType {
  LetsMeetFeedback = 1,
  FavoriteFeedback,
  ThanksFeedback,
  ShareWithFeedback,
  Welcome,
  NewToApprovePriority,
  NewPriority,
  UpdatedPriorityByManager,
  UpdatedPriorityByUser,
  ApprovedPriority, // 10
  DeclinePriority,
  SharedWithPriority,
  WellDonePriority,
  LetsMeetPriority,
  UpdatePriorityDeliverableCompleted,
  UpdatePriorityPriorityCompleted,
  NewFeedback,
  PasswordExpiry,
  NotDeliveringFeedbacksForRequest, // reminder to answer pending feedback request
  NotDeliveringFeedbacks, // 20 - reminder to give feedback
  NewFeedbackRequest,
  NotRateFeedbacks, // reminder to rate feedback
  UpdatePriorityPriorityClosed,
  NewPublicFeedback,
  FeedbackOnFeedbackForSender, // 25
  FeedbackOnFeedbackForRecipient,
  EndorseFeedbackForRecipient,
  EndorseFeedbackForSender,
  FeedbackReceivedOnRequest,
  ReminderPendingRequestFromUser, // 30
  SurveyOwnerAdded,
  SurveyStartAddParticipantForNominant,
  SurveyStartAddParticipantForManagerByNominant,
  SurveyStartAssessmentParticipant,
  SurveyStartAssessmentNominant, // 35
  CheckinAdded,
  CheckinFieldsChanged,
  CheckinCommentAdded,
  CheckinCompleted,
  CheckinDeleted, // 40
  CheckinDateOverdue,
  EmployeeManagerChanged,
  LetsMeetRequest,
  AdminApproveInvite,
  AdminApproveSelfInvite, // 45
  UserInvitation,
  UserReInvitation,
  SelfInvitation,
  InvitedUserOnline,
  SomeoneDeletesMyGoal,       // 50
  SomeoneDeletesOtherGoal,
  UserDeletesOwnGoal,
  UserInvitationNotMsg,
  UserReInvitationNotMsg,
  NewPriorityNotApproved,
  SurveyPulsStart,
  SurveyUpwardStart,
  PerformanceCheckinManagerSubmits,
  PerformanceCheckinEmployeeAccepts,
  PerformanceCheckinEmployeeDeclined,
  UpcomingHardDeletion,
  CheckinAssigned,
  SurveyStartAssessmentNotifyExternalParticipant = 84,
  GoalUpdatedByCreatorNotifyManager,
  ViewCompletedSurveyResponsesNotifyExternalParticipant,
  NudgeNotification,
  EmployeeHasSuggestedAdditionalReviewers= 88
}
