import {
    Button,
    Flex,
    Form,
    FormField,
    Text,
    Menu,
    tabListBehavior,
    Divider,
    MenuItemProps,
    Dialog
} from '@fluentui/react-northstar';
import lclzStor from '../shared/src/languages/BaseLang';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import IStore from '../common/interfaces/IStore';
import CustomSearch from './Search/CustomSearch';
import { SearchItems } from '../shared/src/models/enums/search/SearchItems';
import ILinkedItems from '../shared/src/models/interfaces/search/ILinkedItems';
import requestErrorHandler from '../common/requestErrorHandler';
import { getBehaviourLibrariesForUser } from '../services/behaviours';
import { calculateTotalUsers } from '../shared/src/utils/feedbacks/GiveFeedbackUtils';
import { htmlToText, isBlank } from '../shared/src/utils/common';
import { BehTag } from './BehTag';
import GoalsSearch from './Search/GoalsSearch';
import IRequestFeedback from '../shared/src/models/interfaces/feedbacks/IRequestFeedback';
import { sendRequestFeedback } from '../services/feedback';
import { ListItemType } from '../common/enums/ListItemType';
import { IUser } from '../shared/src/models/interfaces/IUser';
import { PopupHeader } from './elements/PopupHeader';
import { requestMenuItems } from '../common/constants';
import { CommentBlock } from './elements/CommentBlock';
import { ErrorRequestFB } from './elements/ErrorRequestFb';
import { RequestErrorPopup } from './elements/RequestErrorPopup';

interface IProps {
    onCancel: () => void;
    onSuccess: () => void;
}

export const RequestFeedback = (props: IProps) => {
    const {userInfo} = useSelector((state: IStore) => state.user);
    const {settings} = useSelector((state: IStore) => state.settings);
    const {
        EnableLimitOfSendersNumber, EnableLimitOfSendersNumberToRequestingFeedback, LimitOfSendersNumber,
        AllowHRAdminExceedsLimitOfSendersNumber, EnableRequestFeedbackBehavior
    } = settings.FeedbackSettings;
    const [Message, setMessage] = React.useState('');
    const [UserIds, setUserIds] = React.useState([]);
    const [OrganisationItemIds, setOrganisationItemIds] = React.useState([]);
    const [behaviourLibraries, setBehaviourLibraries] = React.useState([]);
    const [selectedBehs, setSelectedBehs] = React.useState([]);
    const [hasBehs, setHasBehs] = React.useState(false);
    const [totalRespondents, setTotalRespondents] = React.useState(0);
    const [GoalIds, setGoalIds] = React.useState([]);
    const isHrOrAdmin = userInfo.isAdmin() || userInfo.isHr();
    const [showErrorPopUP, setShowErrorPopup] = React.useState(false);
    const [isAbout, setIsAbout] = React.useState(false);
    const [userAbout, setUserAbout] = React.useState<IUser>(null);
    const isMobileView = window.innerWidth < 640;
    const showErrorPopupOnDesktopView = showErrorPopUP && !isMobileView;
    const [inProgress, setInProgress] = React.useState(false);

    const clearData = () => {
        setHasBehs(false);
        setSelectedBehs([]);
        setBehaviourLibraries([]);
        setOrganisationItemIds([]);
        setUserIds([]);
        setMessage('');
        setInProgress(false);
    };
    const cancelHandler = () => {
        clearData();
        props.onCancel();
    };
    const successHandler = () => {
        clearData();
        props.onSuccess();
    };
    React.useEffect(() => {
        if (settings?.BehaviourSettings.EnableBehaviours && EnableRequestFeedbackBehavior) {
            const fetchData = async () => {
                const libs = await getBehaviourLibrariesForUser(userInfo.id);
                setBehaviourLibraries(libs.LibrariesInfo);
            };
            fetchData().catch(err => requestErrorHandler(err));
        }
    }, [setBehaviourLibraries, settings?.BehaviourSettings.EnableBehaviours, EnableRequestFeedbackBehavior, userInfo.id]);

    const handleMessage = (val: string) => {
        setMessage(val);
    };

    const submit = async () => {
        const data: IRequestFeedback = {
            Message, UserIds, OrganisationItemIds,
            GoalIds: isAbout || !GoalIds.length ? null : GoalIds,
            BehaviourList: isAbout ? null : selectedBehs,
            IsToDirectReports: false,
            LimitPopupConfirmed: isHrOrAdmin,
            AboutUserId: userAbout?.IdentityId
        };
        setInProgress(true);
        try {
            await sendRequestFeedback(data);
            successHandler();
        } catch (err) {
            cancelHandler();
        }
    };
    const submitHandler = () => {
        if (isExceeded && allowExceedsLimit) {
            setShowErrorPopup(true);
        } else {
            submit();
        }
    };
    const isSubmitEnabled = () => {
        const hasRecipients = (Boolean(UserIds.length) || Boolean(OrganisationItemIds.length)) && (!isAbout || Boolean(userAbout));
        const hasContent = (hasBehs && !isAbout) || !isBlank(htmlToText(Message));
        const validNumberOfRecipient = !isRestrictionEnabled || !isExceeded || allowExceedsLimit;
        return hasRecipients && validNumberOfRecipient && hasContent;
    };
    const addOI = (item: any) => {
        OrganisationItemIds.push(item.Id);
        setOrganisationItemIds(OrganisationItemIds);
    };
    const removeOI = (item: any) => {
        const sendToOIIdsNew = OrganisationItemIds.filter(id => id !== item.Id);
        setOrganisationItemIds(sendToOIIdsNew);
    };
    const addUser = (item: any) => {
        UserIds.push(item.Id);
        setUserIds(UserIds);
    };
    const removeUser = (item: any) => {
        const sendToUserIdsNew = UserIds.filter(id => id !== item.Id);
        setUserIds(sendToUserIdsNew);
    };

    const onBehSelect = (id: number) => () => {
        const isRemoved = selectedBehs.indexOf(id) > -1;
        if (isRemoved) {
            const updated = selectedBehs.filter(beh => beh !== id);
            setSelectedBehs(updated);
            if (!updated.length) {
                setHasBehs(Boolean(updated.length));
            }
        } else {
            selectedBehs.push(id);
            setSelectedBehs(selectedBehs);
            setHasBehs(true);
        }
    };
    const changeLinkedList = (type: SearchItems, isAdded: boolean, item: any, selectedItems: ILinkedItems) => {
        const total = calculateTotalUsers(selectedItems, userInfo, false);
        setTotalRespondents(total);
        if (type === SearchItems.Users) {
            isAdded ? addUser(item) : removeUser(item);
        }
        if (type === SearchItems.OrgItems) {
            isAdded ? addOI(item) : removeOI(item);
        }
    };
    const changeUserAbout = (type: SearchItems, isAdded: boolean, item: any) => {
        setUserAbout(isAdded ? item : null);
    };

    const isRestrictionEnabled = EnableLimitOfSendersNumber && EnableLimitOfSendersNumberToRequestingFeedback;
    const maxRespondents = isRestrictionEnabled && LimitOfSendersNumber;
    const allowExceedsLimit = AllowHRAdminExceedsLimitOfSendersNumber && isHrOrAdmin;
    const isExceeded = totalRespondents > maxRespondents;
    const showBehs = Boolean(behaviourLibraries?.length) && !isAbout;
    const showGoals = !isAbout && settings.GoalSettings.EnableGoals;

    const changeLinkedGoal = (item: any, isAdded: boolean) => {
        if (isAdded) {
            GoalIds.push(item.Id);
            setGoalIds(GoalIds);
        } else {
            const ids = GoalIds.filter(id => id !== item.Id);
            setGoalIds(ids);
        }
    };

    const closeConfirmPopup = () => {
        setShowErrorPopup(false);
    };
    const errorPopup = () => (
        <RequestErrorPopup submit={submit} totalRespondents={totalRespondents} closeConfirmPopup={closeConfirmPopup} />);

    const showAboutMyTeam = userInfo.isManager() && settings.FeedbackSettings.EnableFeedbackRequestAboutSubordinate;
    const changeIsAbout = (event: SyntheticEvent<HTMLElement, Event>, data?: MenuItemProps) => {
        setIsAbout(Boolean(data.index));
    };
    const fbPlaceholder = isAbout ? lclzStor.RequestFeedback_plHoldContext_OnBehalf : lclzStor.RequestFeedback_plHoldContext;
    const fbTitle = isAbout ? lclzStor.FbCentre_addYourComment_OnBehalf : lclzStor.FbCentre_addYourComment;
    return (
        <div className="ms-Grid">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                    <div className={showErrorPopupOnDesktopView ? 'display-none' : ''}>
                        <PopupHeader title={lclzStor.RequestFeedback_title} onCancel={props.onCancel} />
                        {showAboutMyTeam &&
                        <div className="my-3 tabs-menu">
                            <Menu
                                defaultActiveIndex={+isAbout}
                                items={requestMenuItems()}
                                underlined
                                primary
                                accessibility={tabListBehavior}
                                aria-label="reguest-fb"
                                onItemClick={changeIsAbout}
                            />
                        </div>
                        }
                        <Form className="give-fb-wrapper" style={{height: 'auto'}}>
                            {isAbout && (
                                <FormField>
                                    <CustomSearch
                                        changeLinkedList={changeUserAbout}
                                        searchPlaceholder={lclzStor.RequestFeedback_SelectPerson}
                                        searchLabel={lclzStor.RequestFeedback_WhoRequestOn}
                                        userProfile={userInfo}
                                        settings={settings}
                                        type={[ListItemType.Person]}
                                        subordinatesOnly={true}
                                        isDisabled={Boolean(userAbout)}
                                        excludeList={UserIds}
                                        key="is-about-search"
                                    />
                                </FormField>
                            )}
                            <FormField>
                                <CustomSearch
                                    changeLinkedList={changeLinkedList}
                                    searchPlaceholder={lclzStor.GiveFeedback_SelectPeople}
                                    searchLabel={lclzStor.RequestFeedback_WhoRequestFrom}
                                    includeEmptyParentOI={true}
                                    userProfile={userInfo}
                                    settings={settings}
                                    key="is-to-search"
                                    excludeList={userAbout?.Id ? [userAbout.Id] : null}
                                />
                            </FormField>
                            <FormField>
                                <CommentBlock title={fbTitle} name="fbText" message={Message}
                                              placeholder={fbPlaceholder} handleMessage={handleMessage} />
                            </FormField>
                            {showBehs && (
                                <>
                                    <Text size="medium"
                                          className="my-2 px-1">{lclzStor.FeedbackRequest_Behaviour_Title}</Text>
                                    {behaviourLibraries.map(library => (
                                        <div key={`lb-${library.Id}`}>
                                            <Text
                                                size="small"
                                                content={htmlToText(library.Name)}
                                                styles={{color: 'GrayText'}}
                                                className="px-1"
                                            />
                                            {library.Behaviours.map(beh => (
                                                <BehTag
                                                    beh={beh}
                                                    onSelect={onBehSelect(beh.Id)}
                                                    selectedBehs={selectedBehs}
                                                    key={`beh-${beh.Id}`}
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </>
                            )}
                            {showGoals &&
                            <GoalsSearch userProfile={userInfo} settings={settings} searchOnFocus={true}
                                         changeLinkedList={changeLinkedGoal} />
                            }
                            <ErrorRequestFB totalRespondents={totalRespondents} />
                            <Divider fitted className="custom-divider" />
                            <FormField className="mb-3">
                                <Flex gap="gap.small">
                                    <Flex.Item push>
                                        <Button primary={false} onClick={cancelHandler}>{lclzStor.cancel}</Button>
                                    </Flex.Item>
                                    <Button
                                        primary={true}
                                        onClick={submitHandler}
                                        disabled={!isSubmitEnabled() || inProgress}
                                        loading={inProgress}
                                    >
                                        {!inProgress && lclzStor.FeedbackOnFeedbackBar_sendButton}
                                    </Button>
                                </Flex>
                            </FormField>
                        </Form>
                        {isMobileView && <Dialog content={errorPopup()} open={showErrorPopUP} />}
                    </div>
                    {showErrorPopupOnDesktopView && errorPopup()}
                </div>
            </div>
        </div>
    );
};