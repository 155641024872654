import apiGateway from '../../modules/api/apiGateway';
import IOneSignalSettings from '../../models/interfaces/pushNotifications/IOneSignalSettings';

export const deletePushNotificationDeviceAsync = (device: string): Promise<void> => apiGateway.deleteAsync(`notifications/push-devices/${device}`);

export const getOneSignalSettingsAsync = (): Promise<IOneSignalSettings> => apiGateway.getAsync('settings/push');

export const pushDevicesAsync = (id: string): Promise<void> => apiGateway.postAsync('notifications/push-devices', { id });

export const makeNotificationRead = (id: number): Promise<void> => apiGateway.putAsync(`notifications/${id}/read`);
