import { getAccessToken } from './tokens';
import pushNotificationService from '../services/pushNotification/pushNotificationService';
import IOneSignalSettings from '../models/interfaces/pushNotifications/IOneSignalSettings';
import { checkMobilePlatform } from './screenSettings';
import {
    deletePushNotificationDeviceAsync, getOneSignalSettingsAsync, makeNotificationRead, pushDevicesAsync
} from '../services/pushNotifications/PushNotificationsService';
import { MobilePlatform } from '../models/enums/MobilePlatform';
import { IAdditionalData } from '../models/interfaces/pushNotifications/IAdditionalData';
import * as localStorage from './localStorage';
import { LOCAL_STORAGE_KEYS } from '../constants';

let oneSignalSettings: IOneSignalSettings;

const getIds = (callback: (id: string) => void) => {
    pushNotificationService.getIds((id: string) => {
        try {
            localStorage.saveItem(LOCAL_STORAGE_KEYS.DEVICE_PUSH_ID, id);
            callback(id);
        } catch (error: any) {
            console.log(error);
        }
    });
};

export const initPushNotificationService = () => {
    if (!getAccessToken()) {
        return;
    }
    if (!checkMobilePlatform(MobilePlatform.android) && !checkMobilePlatform(MobilePlatform.iOS)) {
        return;
    }
    const handleOpen = (additionalData: IAdditionalData) => {
        if (!additionalData) {
            return;
        }
        if (additionalData.targetUrl && additionalData.targetUrl !== window.location.pathname) {
            window.location.href = `#${additionalData.targetUrl}`;
        }
        if (additionalData.notificationId) {
            makeNotificationRead(additionalData.notificationId);
        }
    };
    getOneSignalSettings((settings: IOneSignalSettings) => {
        pushNotificationService.setOpenedCallback(handleOpen);
        pushNotificationService.init(settings.OneSignalAppID);
        getIds(pushDevicesAsync);
    });
};

export const removePushNotificationsDevice = async (callback?: () => void) => {
    try {
        const id = localStorage.loadItem(LOCAL_STORAGE_KEYS.DEVICE_PUSH_ID);
        if (id) {
            const result = await deletePushNotificationDeviceAsync(id);
            localStorage.deleteItem(LOCAL_STORAGE_KEYS.DEVICE_PUSH_ID);
            // tslint:disable-next-line: no-console
            console.log('Push notifications disabled', result);
        }
    } catch (error: any) {
        console.log(error);
    } finally {
        callback && callback();
    }
};

export const getOneSignalSettings = (callback: (settings: IOneSignalSettings) => void): void => {
    if (oneSignalSettings === undefined) {
        getOneSignalSettingsAsync()
            .then((settings: IOneSignalSettings) => {
                oneSignalSettings = settings;
                callback(oneSignalSettings);
            });
    } else {
        callback(oneSignalSettings);
    }
};
