import IModalBody from '../models/interfaces/elements/IModalBody';
import { IModalType } from '../models/enums/main/modals/IModalType';

class ModalReduxUtils {
    private closeModal: () => void;
    private openModal: (modalBody: IModalBody, modalType?: IModalType) => void;
    private updateModal: (modalBody: IModalBody) => void;

    public setModalHandlers = (closeModal: () => void, openModal: (modalBody: IModalBody, modalType?: IModalType) => void, updateModal: (modalBody: IModalBody) => void) => {
        this.closeModal = closeModal;
        this.openModal = openModal;
        this.updateModal = updateModal;
    };

    public closeCoreModal = () => this.closeModal();
    public openCoreModal = (modalBody: IModalBody, modalType?: IModalType) => this.openModal(modalBody, modalType);
    public updateCoreModal = (modalBody: IModalBody) => this.updateModal(modalBody);
}

const { closeCoreModal, openCoreModal, updateCoreModal, setModalHandlers } = new ModalReduxUtils();

export { closeCoreModal, openCoreModal, updateCoreModal, setModalHandlers };
