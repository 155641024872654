import axios, { AxiosRequestConfig } from 'axios';
import * as sessionStorage from './sessionStorage';
import { SESSION_STORAGE_KEYS } from '../constants';

export const fetchAsync = (config: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        axios(config)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const getAsync = (url: string, config?): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(url, config)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const postAsync = (url: string, values?: any, config?): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(url, values, config)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const putAsync = (url: string, values?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.put(url, values)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const patchAsync = (url: string, values?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.patch(url, values)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const deleteAsync = (url: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete(url)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const getBackendUrl = (showApi: boolean = true) => {
    return sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY) && sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY).BackendUrl ?
        `${sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY).BackendUrl}${showApi ? 'api/' : ''}` :
        `${process.env.REACT_APP_BACKEND_URL}${showApi ? 'api/' : ''}`;
};
