import { hashHistory } from "../../shared/src/utils/history";
import { Pages } from "../enums/Pages";

export const getVersion = () => {
  return process.env.REACT_APP_TANDEM_VERSION;
};

export const navigate = (page?: Pages) => {
  hashHistory.push(`/${page}`);
};

export const getCompanyNameFromEmail = (email: string): string => {
  if (!email) return '';
  const domain = email.substring(email.lastIndexOf("@") +1);
  return domain.substring(0, domain.lastIndexOf("."));
};
export const getInitials = (name: string) => {
  const parts = name.replace(/\(.*\)/, '').split(/\s/);
  const mainParts = parts?.length > 2
      ? [parts[0], parts[parts.length -1 ]]
      : parts;
  return mainParts
      .map(part => part.substring(0, 1))
      .filter(v => !!v)
      .join('')
      .toUpperCase();
};