import jwt_decode from 'jwt-decode';
import { isEqual, unescape, xorWith } from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import Parser from 'html-react-parser';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { getAccessToken } from '../modules/tokens';
import lclzStor from '../languages/BaseLang';
import { hashHistory } from './history';
import { isMobileApp } from '../modules/screenSettings';
import {
    REVIEW_FORMAT,
    HOURS_IN_DAY,
    MOBILE_BREAKPOINT,
    POSSESSIVE_SOURCES,
    TICKS_PER_MILLISECOND,
    UNIX_EPOCH_TICKS
} from '../constants';
import IParams from '../models/interfaces/IParams';
import { IName } from '../models/interfaces/IName';
import { SyntheticEvent } from 'react';
import { Pages } from '../models/enums/layout/Pages';
import { Settings } from '../models/entities/settings/Settings';
import isEmpty from 'lodash/isEmpty';
import { EMOJIS_REGEX } from './editor/editorConstants';
import IMappedEmoji from '../models/interfaces/editor/IMappedEmoji';
import IMappedLink from '../models/interfaces/editor/IMappedLink';
import { ILibraryWithBehaviours } from '../models/interfaces/behaviours/library/ILibraryWithBehaviours';
import DOMPurify from 'dompurify';
import truncate from 'lodash/truncate';

export const getCurrentUserGuid = () => {
    const token = getAccessToken();
    const jwt: any = jwt_decode(token);
    return jwt && jwt.sub;
};

// tslint:disable-next-line:variable-name
export const client_id_env = isMobileApp()
    ? process.env.REACT_APP_CLIENT_MOBILE_ID
    : process.env.REACT_APP_CLIENT_DESKTOP_ID;

export const getVersion = () => {
    return `${lclzStor.SettingsLegal_releaseNumber} ${process.env.REACT_APP_TANDEM_VERSION}`;
};

export const htmlToText = (text: string): string | null => {
    return text ? unescape(text.replace(/(\r|\n|&nbsp;|<\/?[^>]+(>|$))/g, '').replace(/&lt;/g, '&lt; ').replace(/&#x27;/g, '\'').replace(/&#x60;/g, '`')) : null;
};

export const replaceAmpersand = (text: string) => {
    return text ? text.replace(/&amp;/g, '&') : null;
};

export const replaceEndOfParagraphWithSpace = (text: string) => {
    return text ? text.replace(/<\/p>/g, ' ') : null;
};

export const replaceBlockOfWhitespaces = (text: string) => {
    return text.replace(/<p>\s*<\/p>/g, '<p></p>');
};

export const removeTags = (text: string) => {
    if (typeof text === 'string') {
        return text.replace(/(<\/?[^>]+(>|$))/g, ' ');
    }
    return null;
};

// It should check does rich-editor string contain some symbols except tags and whitespaces.
// It should be used for validation of rich-editor values in forms.
export const isMarkupStringHasLength = (text?: string): boolean => {
    return Boolean(htmlToText(text)?.replace(/\s*/g, '').length);
};

export const textToHtml = (text: string, link = true) => {
    if (typeof text === 'string') {
        const cleanedText = DOMPurify.sanitize(text, { ADD_ATTR: ['target'] });
        let textToParse = link ? replaceUrlToTag(cleanedText) : removeHrefTags(cleanedText);
        textToParse = unescape(textToParse.replace(/&lt;/g, '&lt; '));
        textToParse = replaceBlockOfWhitespaces(textToParse);
        textToParse = replaceEmojiToWrapper(textToParse);
        const containerTextWithWrapper = `<span class="style-wrap">${textToParse}</span>`;
        return Parser(containerTextWithWrapper);
    }
    return null;
};

export const removeHrefTags = (text: string) => {
    const pattern = /<(a|\/a)[^>]{0,}>/g;
    return text ? text.replace(pattern, '') : text;
};

const isLink = (arg: any): arg is IMappedLink => {
    return arg.url !== undefined;
};

export const getMarkupTemplate = (item): string => {
    if (isLink(item)) {
        return `<a href="${item.url}" target="_blank">${item.text}</a>`;
    }
    return `<span class="custom-emoji">${item.text}</span>`;
};

export const getReplacedText = (itemsList, text: string, regex: RegExp): string => {
    let replacedText = '';
    let newText = text;
    const sortedList = itemsList.sort((a, b) => a.index - b.index);
    sortedList.forEach(item => {
        const index = newText.indexOf(item.text);
        const prevText = newText.slice(0, index);
        if (!prevText || (prevText && !prevText.match(regex) && !item.text.startsWith('href='))) {
            replacedText += `${prevText ? `${prevText} ` : ''}${getMarkupTemplate(item)}`;
        } else {
            replacedText += `${prevText || ''}${item.text}`;
        }
        newText = newText.slice(index + item.text.length);
    });
    if (newText) {
        replacedText += newText;
    }
    return replacedText;
};

export const getMappedEmojis = (matchesList: string[], contentText: string): IMappedEmoji[] => {
    const uniqueEmojis = new Set([...matchesList]);
    let mappedEmojis: IMappedEmoji[] = [];

    uniqueEmojis.forEach((emoji: string) => {
        let index = contentText.indexOf(emoji);
        const mappedUniquesEmojis = [];

        while (index !== -1) {
            mappedUniquesEmojis.push({
                index,
                lastIndex: index + emoji.length,
                text: emoji
            });
            index = contentText.indexOf(emoji, index + 1);
        }

        mappedEmojis = [...mappedEmojis, ...mappedUniquesEmojis];
    });

    return mappedEmojis;
};

export const replaceEmojiToWrapper = (text: string) => {
    const emojis = text.match(EMOJIS_REGEX);

    if (typeof emojis !== 'undefined' && emojis !== null) {
        const mappedEmojis: IMappedEmoji[] = getMappedEmojis(emojis, text);

        return getReplacedText(mappedEmojis, text, EMOJIS_REGEX);
    }

    return text;
};

export const replaceUrlToTag = (text: string) => {
    const linkify = linkifyIt();
    const rex = /(href="|href=&quot;|href=\\")$/;
    linkify.tlds(tlds);
    const links: IMappedLink[] = linkify.match(text);

    if (typeof links !== 'undefined' && links !== null) {
        return getReplacedText(links, text, rex);
    }
    return text;
};

export const removeSpecialSymbols = (text: string) => {
    return text.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
};

export const removeHtmlTags = (text: string) => {
    const pattern = /<(span|\/span|strong|\/strong|a|\/a|br|div|\/div|p|\/p)[^>]{0,}>/g;
    return text ? text.replace(pattern, '') : null;
};

export const redirectedFromLogin = () => {
    return hashHistory && hashHistory.location && hashHistory.location.state && hashHistory.location.state['fromLogin'];
};

export const getHistoryState = (name: string) => {
    return hashHistory && hashHistory.location && hashHistory.location.state && hashHistory.location.state[name];
};

export const getUrlParam = (props: IParams, param: string) => {
    return props && props.match && props.match.params && props.match.params[param];
};

export const getUrlPathParam = (props: IParams, param: string) => {
    return props && props.location && props.location && props.location[param];
};

export const getSetting = (settings: Settings, setting: string, subsetting: string) => {
    return settings && settings[setting] && settings[setting][subsetting];
};

export const isTextHighlited = (text: string) => {
    return text ? /<span.*class=.highlight/.test(text) : false;
};

export const getHighlightedText = (text, highlight) => {
    if (isTextHighlited(text)) {
        return text;
    }
    const foundText = highlight ? removeSpecialSymbols(highlight) : '';
    text = removeHtmlTags(replaceEndOfParagraphWithSpace(text));
    const parts = text.split(new RegExp(`(${foundText})`, 'gi'));
    return parts.map((part, i) => {
        return part.toLowerCase() === foundText?.toLowerCase() ? `<span key="fs-${i}" class="highlight">${part}</span>` : part;
    }).join('');
};

export const navigate = (page?: Pages) => {
    hashHistory.push(`/${page}`);
};

export const navigateBack = () => {
    redirectedFromLogin() ? navigate(Pages.home) : hashHistory.goBack();
};

export const arrayDifference = (a, i) => {
    let diff = [];
    let array;
    let newArray = i;
    if (a.length > i.length) {
        array = a;
        newArray = i;
    } else {
        array = i;
        newArray = a;
    }

    if (array.length > 0) {
        diff = array.filter(item => newArray.indexOf(item) === -1);
    }
    return diff;
};

export const checkConnection = () => {
    if (isMobileApp()) {
        const networkState = navigator.connection.type;

        const states = {};
        states[Connection.UNKNOWN] = 'Unknown connection';
        states[Connection.ETHERNET] = 'Ethernet connection';
        states[Connection.WIFI] = 'WiFi connection';
        states[Connection.CELL_2G] = 'Cell 2G connection';
        states[Connection.CELL_3G] = 'Cell 3G connection';
        states[Connection.CELL_4G] = 'Cell 4G connection';
        states[Connection.CELL] = 'Cell generic connection';
        states[Connection.NONE] = 'No network connection';

        if (
            states[networkState].indexOf('WiFi') !== -1 ||
            states[networkState].indexOf('Cell') !== -1
        ) {
            return true;
        }
        return false;
    }

    return navigator.onLine;
};

export const getRandomString = (): string => {
    return `tandem${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`;
};
export const getRandomSmString = (): string => {
    return Math.floor(Math.random() * 1000).toString();
};

export const getTestSelector = (testSelector?: string, suffix?: string) => {
    return (testSelector) ? { 'data-e2e-selector': buildTestSelector(testSelector, suffix) } : {};
};

export const buildTestSelector = (testSelector?: string, suffix?: string): string => {
    return (testSelector) ? `${testSelector}${suffix || ''}` : '';
};

export const isDarkBackground = (hex: string) => {
    const color = hexToRgb(String(hex));
    if (!color) {
        return null;
    }
    // Counting the perceptived luminance - human eye favors green color
    const luminance = (0.299 * color.red + 0.587 * color.green + 0.114 * color.blue) / 255;

    return luminance < 0.5;
};

export const hexToRgb = (hex: string) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const newhex = hex.replace(shorthandRegex, (_, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newhex);

    return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
    } : null;
};

export const hexToRgbA = (hex: string, alpha: number = 1) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        // tslint:disable-next-line: no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
    }
    return '';
};
// is alternative to rgba with opacity, uses white instead of opacity and returns hex color
export const flattenRgba = (hex: string, alpha: number = 1) => {
    const white = { r: 255, g: 255, b: 255 };
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        // tslint:disable-next-line: no-bitwise
        const rgb = { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255 };
        const flatten = {
            r: Math.trunc((1 - alpha) * white.r + alpha * rgb.r),
            g: Math.trunc((1 - alpha) * white.g + alpha * rgb.g),
            b: Math.trunc((1 - alpha) * white.b + alpha * rgb.b)
        };
        return `#${flatten.r.toString(16)}${flatten.g.toString(16)}${flatten.b.toString(16)}`;
    }
    return '';
};
export const lightenDarkenColor = (color: string, percent: number) => {
    let col = color;
    if (color && color[0] === '#') {
        col = col.slice(1);
    }
    const num = parseInt(col, 16);
    const amt = Math.round(2.55 * percent);
    /* eslint-disable */
    // tslint:disable-next-line: no-bitwise
    const R 	= (num >> 16) + amt;
    // tslint:disable-next-line: no-bitwise
    const B 	= (num >> 8 & 0x00FF) + amt;
    /* eslint-enable */
    // tslint:disable-next-line: no-bitwise
    const G 	= (num & 0x0000FF) + amt;

    return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)}`;
};

export const getDaysAgo = (hoursAgo: number): string => {
    if (hoursAgo > HOURS_IN_DAY) {
        const fullDays = Math.floor(hoursAgo / HOURS_IN_DAY);
        return fullDays > 1 ?
            lclzStor.InvitationForm_DaysAgo.replace('{count}', fullDays) :
            lclzStor.InvitationForm_DayAgo;
    }
    const fullHours = Math.floor(hoursAgo);
    if (hoursAgo < 1) {
        return lclzStor.InvitationForm_JustNow;
    }
    return fullHours > 1 ?
        lclzStor.InvitationForm_HoursAgo.replace('{count}', fullHours) :
        lclzStor.InvitationForm_HourAgo;
};

export const utcToLocalTime = (date: string | Object, language: string, format?: string) => {
    return moment.utc(date).local().locale(language || 'en').format(format || REVIEW_FORMAT);
};

export const getHomepageURL = () => {
    return window.location.href.split('#')[0];
};

export const isDesktopView = () => {
    return window.innerWidth > MOBILE_BREAKPOINT;
};

export const isNumberExists = (data: string) => {
    return /^\d+$/.test(data);
};

export const sortASC = (firstElem: string | number, secondElem: string | number) => {
    if (firstElem < secondElem) {
        return -1;
    }
    if (firstElem > secondElem) {
        return 1;
    }
    return 0;
};

export const addOrRemoveElement = (id: string | number, array: any[]) => {
    const index = array.indexOf(id);
    ~index ? array.splice(index, 1) : array.push(id);
    return array;
};

export const cropText = (text: string, qtyOfSymbols: number) => {
    if (!text) {
        return '';
    }
    return htmlToText(text).length > qtyOfSymbols ? `${htmlToText(text).substring(0, qtyOfSymbols)}...` : htmlToText(text);
};

export const copyToClipboard = (text: string) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
};

export const getFreshListTick = () => {
    const currentTime = new Date().getTime();
    return currentTime * TICKS_PER_MILLISECOND + UNIX_EPOCH_TICKS;
};

export const isArrayEqual = (x, y) => {
    const diff = xorWith(x, y, isEqual);
    return !diff.length;
};

export const isIOSApp = () => {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};
export const isIE = (): boolean => {
    return /MSIE \d|Trident.*rv:/.test(navigator.userAgent);
};

export const replaceName = (key: string, name: IName) => {
    const isPossesiveCase = POSSESSIVE_SOURCES.length && POSSESSIVE_SOURCES.some(item => item === key);
    const nickName = name.NickName ? ` (${name.NickName})` : '';
    const combinedName = `${name.GivenName}${isPossesiveCase ? lclzStor.PossessiveCase : ''}${nickName}`;
    return lclzStor[key] && lclzStor[key].replace(/{Name}|_/g, combinedName);
};
export const getFullName = (name: IName) => {
    return `${getGivenName(name)} ${name.FamilyName}`;
};
export const getGivenName = (name: IName, isPossessiveCase = false) => {
    return `${name.GivenName}${isPossessiveCase ? lclzStor.PossessiveCase : ''}${name.NickName ? ` (${name.NickName})` : ''}`;
};

export const getBehavioursNumber = (behaviours: ILibraryWithBehaviours[]): number => {
    return behaviours.reduce((acc, item) => acc + item.Behaviours.length, 0);
};

export const stopEvent = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
};

export const jsonToUrlEncoded = obj => Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');

export const isObjectEmpty = (obj: Object) => {
    return obj && Object.entries(obj).length === 0 && obj.constructor === Object;
};

export function isBlank(value: any): boolean {
    return isEmpty(value) || (typeof value === 'string' && !/\S/.test(value));
}

// Keyboard Dismissal Leaves Viewport Shifted Fix, remove when WKWebView is updated and it is fixed
export const onBlurIOs = () => isIOSApp() && window.scrollBy(0, 0);

export const isActivePath = (path: string, index, exact: boolean = false) => {
    if (exact) {
        return path === window.location.hash;
    }
    const activePath =  window.location.hash.split('?')[0];
    const page = activePath.split('/')[index];
    return page === path;
};
export const replaceNewRow = (value: string): string => {
    if (!isDesktopView()) {
        value = value?.replace(/\n/g, ' <br/> ');
    }
    return value;
};
export const getIconWeight = (type: string) => {
    switch (type.trim()) {
        case 'fad':
            return 'duotone';
        case 'fas':
            return 'solid';
        case 'fal':
            return 'light';
        default:
            return 'regular';
    }
};
export const isValidJsonString = str => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
    return true;
};
export const removeTempId = <T extends { TempId?: string }>(items: T[]): T[] => {
    return items.map(item => {
        if (item.hasOwnProperty('TempId')) {
            delete item.TempId;
        }
        return item;
    });
};
//to fix cognitive complexity
export const arrayHasLength = (arr: any[]) => Boolean(arr?.length);

export const trimFileName = (name: string, qty:number = 199) => {
    return truncate(htmlToText(name), {length: qty}).split('.').join('_');
};
