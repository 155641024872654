import apiGateway from '../../modules/api/apiGateway';
import { ILogError } from '../../models/interfaces/ILogError';
import { IFrontNavigation } from '../../models/interfaces/IFrontNavigation';
import { ILoginPerfomance } from '../../models/interfaces/ILoginPerfomance';

export const logErrorForUser = (error: ILogError) => {
    return apiGateway.postAsync('logs/error', error);
};

export const logErrorAnonymus = (error: ILogError) => {
    return apiGateway.postAsync('logs/error-anonymus', error);
};

export const logPageNavigation = (navigationData: IFrontNavigation) => {
    return apiGateway.postAsync('logs/navigation', navigationData);
};

export const logLoginPerfomance = (data: ILoginPerfomance) => {
    return apiGateway.postAsync('logs/login-performance', data);
};

export const logUserEvent = (event: string): Promise<void> => {
    return apiGateway.postAsync('logs/user-event', `"${event}"`);
};
