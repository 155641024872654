import { Flex, Text } from '@fluentui/react-northstar';
import { FA_XMARK } from '../../shared/src/constants';
import React from 'react';
interface IProps {
    title: string;
    onCancel: ()=> void;
}
export const PopupHeader = (props: IProps) => {
    const {title, onCancel} = props;
    return (
        <Flex className="mb-2">
            <Text size="large" content={title} weight="bold" />
            {onCancel && (
                <Flex.Item push>
                    <i className={`${FA_XMARK} fa-regular cursor-pointer`} onClick={onCancel} />
                </Flex.Item>
            )}
        </Flex>
    );
};