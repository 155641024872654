import { getVersion } from '../../../common/utils/utils';
import lclzStor from '../../../shared/src/languages/BaseLang';
import { Text } from "@fluentui/react-northstar";

export const Copyright = () => {
    return (
        <div className="copyrightTandem">
            <Text size="smaller">Copyright &copy; {new Date().getFullYear()} beqom S.A</Text>
            <br />
            <Text size="smaller">{lclzStor.Office365_AppInfo} {getVersion()}</Text>
        </div>
    );
};
