import { FA_NOTE, FA_XMARK } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import Tile from './elements/Tile';
import React from 'react';
import { Dialog } from '@fluentui/react-northstar';
import { FeedbackNote } from './FeedbackNote';
import { PopUp } from './elements/PopUp';
import { hashHistory } from '../shared/src/utils/history';
import { APP_CONSTANTS } from '../common/constants';

const FeedbackNoteWidget = () => {
    const [isFeedbackNoteOpen, setIsFeedbackNoteOpen] = React.useState<boolean>(false);
    const [isFeedbackNoteCreated, setIsFeedbackNoteCreated] = React.useState(false);
    const [width] = React.useState<number>(window.innerWidth);
    const isMobileView = width < 640;
    const openModal = () => {
        if (isMobileView) {
            hashHistory.push(`/tab${APP_CONSTANTS.LINKS.FEEDBACK_NOTES}`);
        }
        else {
            setIsFeedbackNoteOpen(true);
        }

    };
    const closeModal = () => setIsFeedbackNoteOpen(false);
    const closeSuccessPopUP = () => {
        closeModal();
        setIsFeedbackNoteCreated(false);
    };
    const onSuccess = () => setIsFeedbackNoteCreated(true);
    return (
        <>
            <Tile
                icon={FA_NOTE}
                title={lclzStor.AddFeedbackNote_Btn}
                description={lclzStor.AddNote_Description_TeamsLanding}
                onClick={openModal}
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 home-widget-tile"
            />
            <Dialog
                content={
                    <PopUp iconName={FA_NOTE} title={lclzStor.FeedbackNote_Saved} primaryBtnText={lclzStor.GotIt_Btn} primaryCB={closeSuccessPopUP} />}
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={closeSuccessPopUP} />}
                open={isFeedbackNoteCreated}
            />
            <Dialog
                content={<FeedbackNote onCancel={closeModal} onSuccess={onSuccess} />}
                open={isFeedbackNoteOpen}
                onOpen={openModal}
                onCancel={closeModal}
            />
        </>

    );
};
export default FeedbackNoteWidget;