import { Reducer } from 'redux';
import { SET_BACK_ERROR_ID, DELETE_ERROR_ID, SET_FRONT_ERROR_ID, SET_MAINTENANCE_MODE } from '../actions/actionTypes';
import IErrorState from '../common/interfaces/error/IErrorState';
import { AnyAction } from '@reduxjs/toolkit';

export const initialState: IErrorState = {
    backCorrelationId: null,
    frontCorrelationId: null,
    isMaintenanceMode: false
};

export const error: Reducer<IErrorState> = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SET_BACK_ERROR_ID:
            return { ...state, backCorrelationId: action.backCorrelationId };
        case DELETE_ERROR_ID:
            return { ...state, backCorrelationId: null };
        case SET_FRONT_ERROR_ID:
            return { ...state, frontCorrelationId: action.frontCorrelationId };
        case SET_MAINTENANCE_MODE:
            return { ...state, isMaintenanceMode: action.isMaintenanceMode };
        default:
            return state;
    }
};
