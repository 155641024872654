import moment, { Moment } from 'moment';
import 'moment/min/locales';
import { uniqBy } from 'lodash';
import lclzStor from '../../languages/BaseLang';
import { GoalType } from '../../models/enums/goals/GoalType';
import { IDeliverable } from '../../models/interfaces/priorities/IDeliverable';
import { ErrorType } from '../../models/enums/errorHandler/ErrorType';
import {
    FA_CHECK_CIRCLE,
    FA_BAN,
    FA_CHECK,
    FA_TRASH_ALT,
    FA_THUMBS_UP,
    FA_EXCLAMATION_CIRCLE,
    FA_LONG_ARROW_ALT_DOWN,
    FA_LONG_ARROW_ALT_UP,
    FA_MINUS,
    FA_PENCIL,
    FA_PLAY_CIRCLE,
    FA_TIMES,
    FA_USER_CLOCK,
    ITEMS_PER_PAGE,
    REVIEW_FORMAT,
    SQL_DATE_FORMAT, DATE_WITHOUT_TIMEZONE, FA_SITEMAP
} from '../../constants';
import {
    getHistoryState,
    getSetting,
    htmlToText
} from '../common';
import { IGoalFreshListItem } from '../../models/interfaces/priorities/fresh-list/IGoalFreshListItem';
import { GoalSectionType } from '../../models/enums/goals/GoalSectionType';
import { hashHistory } from '../history';
import { ESettings } from '../../models/enums/settings/Settings';
import { EGoalSettings } from '../../models/enums/settings/GoalSettings';
import { EGoalPublicStatus } from '../../models/enums/goals/GoalPublicStatus';
import IGoalExtended from '../../models/interfaces/priorities/IGoalExtended';
import { IUser } from '../../models/interfaces/IUser';
import { UserProfile } from '../../models/entities/user/UserProfile';
import { Settings } from '../../models/entities/settings/Settings';
import IIndividualGoalPriority from '../../models/interfaces/priorities/individualPriorities/IIndividualGoalPriority';
import { IndividualGoalPriorityTypes } from '../../models/enums/priorities/IndividualGoalPriorityTypes';
import IIndividualGoalTrackingStatus from '../../models/interfaces/priorities/individualPriorities/IIndividualGoalTrackingStatus';
import { IndividualGoalTrackingStatuses } from '../../models/enums/priorities/IndividualGoalTrackingStatuses';
import { IndividualGoalStatus } from '../../models/enums/priorities/IndividualGoalStatus';
import { IIconTypeData } from '../../models/interfaces/elements/IIconTypeData';
import ISingleImprovementMessage from '../../models/interfaces/priorities/individualPriorities/ISingleImprovementMessage';
import IColorTheme from '../../models/interfaces/IColorTheme';
import { IGoalType } from '../../models/interfaces/settings/goals/IGoalSettingsModel';
import { TypeOptions } from '../../components/Elements/Styled/Icon/StyledIcon';
import { IModalType } from '../../models/enums/main/modals/IModalType';
import { closeCoreModal, openCoreModal } from '../modalsReduxUtils';
import { GoalPeriod } from '../../models/enums/goals/GoalPeriod';
import IGoalFilters from '../../models/interfaces/priorities/IGoalFilters';

const AUTOCLOSE_TIME = 6000;

export const getIndividualGoalPriorities = (): IIndividualGoalPriority[] => {
    return [
        {
            Id: IndividualGoalPriorityTypes.NotSelected,
            Name: lclzStor.IndividualGoal_GoalPriorities_NotSelected,
            Img: FA_TIMES,
            Type: 'disabled'
        },
        {
            Id: IndividualGoalPriorityTypes.High,
            Name: lclzStor.IndividualGoal_GoalPriorities_High,
            Img: FA_LONG_ARROW_ALT_UP,
            Type: 'danger'
        },
        {
            Id: IndividualGoalPriorityTypes.Medium,
            Name: lclzStor.IndividualGoal_GoalPriorities_Medium,
            Img: FA_MINUS,
            Type: 'orange'
        },
        {
            Id: IndividualGoalPriorityTypes.Low,
            Name: lclzStor.IndividualGoal_GoalPriorities_Low,
            Img: FA_LONG_ARROW_ALT_DOWN,
            Type: 'disabled'
        }
    ];
};

export const getIndividualGoalTrackingStatuses = (): IIndividualGoalTrackingStatus[] => {
    return [
        {
            Id: IndividualGoalTrackingStatuses.NotSelected,
            Name: lclzStor.IndividualGoal_TrackingStatus_NotSelected,
            Img: FA_TIMES,
            Type: 'disabled'
        },
        {
            Id: IndividualGoalTrackingStatuses.OnTrack,
            Name: lclzStor.IndividualGoal_TrackingStatus_OnTrack,
            Img: FA_THUMBS_UP,
            Type: 'green'
        },
        {
            Id: IndividualGoalTrackingStatuses.AtRisk,
            Name: lclzStor.IndividualGoal_TrackingStatus_AtRisk,
            Img: FA_EXCLAMATION_CIRCLE,
            Type: 'orange'
        },
        {
            Id: IndividualGoalTrackingStatuses.Blocked,
            Name: lclzStor.IndividualGoal_TrackingStatus_Blocked,
            Img: FA_BAN,
            Type: 'danger'
        }
    ];
};

export const getNecessaryEntityByType = <T, K extends { Id: T }>(type: T, list: K[]): K => {
    return list.find(({ Id }) => Id === type);
};

export const getGoalPlaceholderByType = (goalType: GoalType) => {
    switch (goalType) {
    case GoalType.Development:
        return lclzStor.SetGoal_Development_placeholder;
    case GoalType.Professional:
        return lclzStor.SetGoal_Professional_placeholder;
    case GoalType.Personal:
        return lclzStor.SetGoal_Personal_placeholder;
    case GoalType.Business:
        return lclzStor.SetGoal_Business_placeholder;
    default:
        return lclzStor.ExistingPriority_plHoldWhatWeAreTrying;
    }
};

export const getGoalDescriptionByType = (goalType: GoalType) => {
    switch (goalType) {
    case GoalType.Development:
        return lclzStor.IndividualGoal_TypeDescription_Development;
    case GoalType.Professional:
        return lclzStor.IndividualGoal_TypeDescription_Professional;
    case GoalType.Personal:
        return lclzStor.IndividualGoal_TypeDescription_Personal;
    case GoalType.Business:
        return lclzStor.IndividualGoal_TypeDescription_Business;
    default:
        return lclzStor.IndividualGoal_TypeDescription_Development;
    }
};

export const goToGoalCenter = () => hashHistory.push('/home/priorities/my-goals');
export const goToApprovalGoalPage = () => hashHistory.push('/home/priorities/approval-pending');

export const areAllDeliverablesCompleted = (keyDeliverables: IDeliverable[]) => {
    return keyDeliverables.every(item => item.IsCompleted);
};

export const confirmGoalComplete = (failCb: () => void, successCb: () => void) => {
    openCoreModal({
        description: lclzStor.Priorities_markPriorityAsCompletedQuestion,
        primaryBtnCallback: failCb,
        secondaryBtnCallback: successCb
    }, IModalType.confirm);
};

export const confirmGoalDeletion = (successCb: () => void, isShared?: boolean) => {
    const title = isShared ? lclzStor.Goals_RemoveFromShare_Popup_Title : lclzStor.GoalsDelete_DeletePopup_title;
    const subtitle = isShared ? lclzStor.Goals_RemoveFromShare_Popup_Subitle : '';
    openCoreModal({
        title,
        subtitle,
        secondaryBtnCallback: successCb
    }, IModalType.confirm);
};

export const confirmGoalReopen = (successCb: () => void) => {
  openCoreModal({
      title: lclzStor.GoalView_ReopenGoal_Popup_Message,
      secondaryBtnCallback: successCb
  }, IModalType.confirm);
};

export const getGoalErrorMessage = (goalError: ErrorType.goalDeletionNotAllowed | ErrorType.goalDeletionSettingDisabled) => {
    if (goalError === ErrorType.goalDeletionNotAllowed) {
        return lclzStor.GoalsDeletionError_goalDeletionNotAllowed;
    }
    return lclzStor.GoalsDeletionError_goalDeletionSettingDisabled;
};

export const approvedSuccessModal = (name: string) => {
    openCoreModal({
        icon: { iconName: FA_CHECK_CIRCLE, type: 'secondary' },
        title: lclzStor.GoalsCentre_ApprovedPopup_Title,
        subtitle: lclzStor.GoalsCentre_ApprovedPopup_SubTitle.replace('{FullName}', name),
        secondaryBtnCallback: () => closeCoreModal(),
        secondaryBtnText: lclzStor.GoalsCentre_GotIt,
        isVertical: true,
        autoClose: true,
        autoCloseTime: AUTOCLOSE_TIME
    });
};

export const invalidGoalDueDateModal = () => {
    openCoreModal({
        title: lclzStor.GoalEdit_IncorrectDatesPopup_Title,
        subtitle: lclzStor.GoalEdit_IncorrectDatesPopup_Description,
        secondaryBtnCallback: () => closeCoreModal(),
        secondaryBtnText: lclzStor.GoalsCentre_GotIt
    });
};

export const setGoalSuccessModal = (icon: string, title: string, cb: () => void, color: string = 'secondary') => {
    const closeCb = () => {
        closeCoreModal();
        cb();
    };
    openCoreModal({
        title,
        icon: {
            iconName: icon,
            type: color as TypeOptions
        },
        secondaryBtnCallback: () => closeCb(),
        secondaryBtnText: lclzStor.GoalsCentre_GotIt,
        isVertical: true,
        autoClose: true,
        autoCloseTime: AUTOCLOSE_TIME,
        hideOverlayOnClick: false,
        callbackAfterClose: () => cb()
    });
};
export const declinedSuccessModal = (name: string, isLetsMeet: boolean) => {
    const title = isLetsMeet ? lclzStor.GoalsCentre_DeclinedPopup_TitleLetsmeet : lclzStor.GoalsCentre_DeclinedPopup_Title;
    const subTitle = isLetsMeet ? lclzStor.GoalsCentre_DeclinedPopup_SubTitleLetsmeet : lclzStor.GoalsCentre_DeclinedPopup_SubTitle;
    openCoreModal({
        title,
        icon: { iconName: FA_EXCLAMATION_CIRCLE, type: 'secondary' },
        subtitle: subTitle.replace('{Name}', name),
        secondaryBtnCallback: () => closeCoreModal(),
        secondaryBtnText: lclzStor.GoalsCentre_GotIt,
        isVertical: true,
        autoClose: true,
        autoCloseTime: AUTOCLOSE_TIME
    });
};

export const cancelObjectiveModal = (successCb: () => void, rejectCb: () => void) => {
    openCoreModal({
        description: lclzStor.GoalsCentre_CancelComment,
        primaryBtnCallback: rejectCb,
        secondaryBtnCallback: successCb,
        primaryBtnText: lclzStor.GoalsCentre_Cancel,
        secondaryBtnText: lclzStor.GoalsCentre_ContinueEditing
    }, IModalType.confirm);
};

export const editLeaveModal = (successCb: () => void, cancelCb: () => void) => {
    openCoreModal({
        title: lclzStor.UserFormExitPopup_NoChanges_Title,
        primaryBtnText: lclzStor.UserFormExitPopup_NoChanges_ExitButton,
        primaryBtnCallback: successCb,
        secondaryBtnText: lclzStor.UserFormExitPopup_NoChanges_SaveAsDraft,
        secondaryBtnCallback: cancelCb,
        hideOverlayOnClick: false
    });
};

export const assignGoalModal = (usersCount: number, successCb: () => void) => {
    openCoreModal({
        title: lclzStor.formatString(lclzStor.GoalCreation_AssignToTeam_ConfirmationPopup, usersCount),
        primaryBtnText: lclzStor.yes,
        primaryBtnCallback: () => { closeCoreModal(); successCb(); },
        secondaryBtnText: lclzStor.no,
        secondaryBtnCallback: () => closeCoreModal(),
        hideOverlayOnClick: false
    });
};

export const getGoalCommentTitle = (isDecline: boolean, declineReason: string) => {
    if (declineReason && !!htmlToText(declineReason)) {
        return isDecline ? lclzStor.GoalsCentre_GoalDeclined_WithComment : lclzStor.GoalsCentre_GoalApprove_WithComment;
    }
    return isDecline ? lclzStor.GoalsCentre_GoalDeclined : lclzStor.GoalsCentre_GoalApprove;
};

export const getUpdatedGoalsList = (goalsFreshList: IGoalFreshListItem[], currentGoalsList: IGoalFreshListItem[]) => {
    const addedGoals: IGoalFreshListItem[] = goalsFreshList.filter(item => item.IsAdded);
    const updatedList: IGoalFreshListItem[] = currentGoalsList.map(goalItem => {
        const freshListEditedItem = goalsFreshList.find(freshListItem => goalItem.Id === freshListItem.Id);
        return freshListEditedItem || goalItem;
    });
    return uniqBy([...addedGoals, ...updatedList], 'Id');
};

export const getCountNameByType = (goalType: GoalSectionType) => {
    switch (goalType) {
    case GoalSectionType.MyGoals: return 'GoalsCount';
    case GoalSectionType.PriorityCompleted: return 'GoalsCompletedCount';
    case GoalSectionType.PriorityPending: return 'GoalsPendingCount';
    case GoalSectionType.PriorityShared:
    default: return 'GoalsSharedCount';
    }
};

export const getSettingByTypeId = (settings: Settings, goalType: GoalType, getApproval: boolean, getTagsEnabled: boolean = false) => {
    const goalTypes = getSetting(settings, ESettings.Goal, EGoalSettings.Types);
    const settingById: IGoalType = goalTypes?.find(item => item.GoalType === goalType);
    if (!settingById) {
        return false;
    }
    if (getApproval) {
        return settingById.Enabled && settingById.NeedsApproval;
    }
    if (getTagsEnabled) {
        return settingById.Enabled && settingById.TagsEnabled;
    }
    return false;
};
export const getInfoText = (accsessId: number, countUsers: number) => {
    switch (accsessId) {
    case EGoalPublicStatus.Shared:
        return countUsers === 1
            ? lclzStor.GoalsStatus_SharedNote
            : lclzStor.GoalsStatus_SharedNote_Multiple.replace('{count}', countUsers);
    case EGoalPublicStatus.Public:
        return lclzStor.GoalsStatus_PublicNote;
    case EGoalPublicStatus.Private:
        return lclzStor.GoalsStatus_PrivateNote;
    default:
        return '';
    }
};
export const isSharedWithMe = (goalData: IGoalExtended, userProfile: UserProfile) => {
    return goalData.ExistingShareWith?.length && goalData.ExistingShareWith.some(item => item.Id === userProfile.id);
};
export const isUserMySubordinate = (id: number, subordinates: IUser[]) => {
    return subordinates.length && subordinates.some(item => item.Id === id);
};
export const getGoalDataByType = (type: IndividualGoalStatus): IIconTypeData => {
    switch (type) {
    case IndividualGoalStatus.Draft:
        return {
            icon: FA_PENCIL,
            color: 'disabled',
            tooltip: lclzStor.GoalsStatus_Draft
        };
    case IndividualGoalStatus.CreatedPendingApproval:
        return {
            icon: FA_USER_CLOCK,
            color: 'secondary',
            tooltip: lclzStor.PriorityStatus_ApprovalPending
        };
    case IndividualGoalStatus.CompletedPendingApproval:
        return {
            icon: FA_USER_CLOCK,
            color: 'green',
            tooltip: lclzStor.PriorityStatus_Completed
        };
    case IndividualGoalStatus.Declined:
        return {
            icon: FA_EXCLAMATION_CIRCLE,
            color: 'danger',
            tooltip: lclzStor.PriorityStatus_Declined
        };
    case IndividualGoalStatus.ClosedDone:
        return {
            icon: FA_CHECK,
            color: 'green',
            tooltip: lclzStor.PriorityStatus_Closed
        };
    case IndividualGoalStatus.Archived:
        return {
            icon: FA_TRASH_ALT,
            color: 'danger',
            tooltip: lclzStor.PriorityStatus_Deleted
        };
    case IndividualGoalStatus.InProgress:
        return {
            icon: FA_PLAY_CIRCLE,
            color: 'secondary',
            tooltip: lclzStor.PriorityStatus_InProgres
        };
    default:
        return null;
    }
};
export const getGoalDataByTrackingStatus = (type: IndividualGoalTrackingStatuses): IIconTypeData => {
    switch (type) {
    case IndividualGoalTrackingStatuses.OnTrack:
        return {
            tooltip: lclzStor.IndividualGoal_TrackingStatus_OnTrack,
            icon: FA_THUMBS_UP,
            color: 'green'
        };
    case IndividualGoalTrackingStatuses.AtRisk:
        return {
            tooltip: lclzStor.IndividualGoal_TrackingStatus_AtRisk,
            icon: FA_EXCLAMATION_CIRCLE,
            color: 'orange'
        };
    case IndividualGoalTrackingStatuses.Blocked:
        return {
            tooltip: lclzStor.IndividualGoal_TrackingStatus_Blocked,
            icon: FA_BAN,
            color: 'danger'
        };
    default:
        return null;
    }
};

export const getGoalDataByPriority = (type: IndividualGoalPriorityTypes): IIconTypeData => {
    switch (type) {
    case IndividualGoalPriorityTypes.High:
        return {
            tooltip: lclzStor.IndividualGoal_GoalPriorities_High,
            icon: FA_LONG_ARROW_ALT_UP,
            color: 'danger'
        };
    case IndividualGoalPriorityTypes.Medium:
        return {
            tooltip: lclzStor.IndividualGoal_GoalPriorities_Medium,
            icon: FA_MINUS,
            color: 'orange'
        };
    case IndividualGoalPriorityTypes.Low:
        return {
            tooltip: lclzStor.IndividualGoal_GoalPriorities_Low,
            icon: FA_LONG_ARROW_ALT_DOWN,
            color: 'disabled'
        };
    default: return null;
    }
};
export const disableLoadMore = (length: number, currentPage: number) => {
    const minValue = ((currentPage - 1) * ITEMS_PER_PAGE) + 1;
    return (((length % ITEMS_PER_PAGE) < ITEMS_PER_PAGE) && (length % ITEMS_PER_PAGE) > 0) || Boolean(length && (length < minValue));
};

export const getHistoryApprovalLabel = (item: ISingleImprovementMessage) => {
    const approvementMessage = (item.Message ? lclzStor.GoalsCentre_GoalApprove_WithComment : lclzStor.GoalsCentre_GoalApprove);
    const declineMessage = (item.Message ? lclzStor.GoalsCentre_GoalDeclined_WithComment : lclzStor.GoalsCentre_GoalDeclined);
    return (item.IsApprovement ? approvementMessage : declineMessage)
        .replace('{FullName}', item.MessageAuthor.FullName);
};
export const confirmIndividualGoalComplete = (successCb: () => void) => {
    openCoreModal({
        description: lclzStor.Priorities_markPriorityAsCompletedQuestion,
        primaryBtnCallback: () => closeCoreModal(),
        secondaryBtnCallback: successCb
    }, IModalType.confirm);
};
export const getPriorityHintById = (id: GoalType) => {
    switch (id) {
    case GoalType.Development:
        return lclzStor.GoalsSettings_DevelopmentHint;
    case GoalType.Personal:
        return lclzStor.GoalsSettings_PersonalHint;
    case GoalType.Professional:
        return lclzStor.GoalsSettings_ProfessionalHint;
    case GoalType.Business:
        return lclzStor.GoalsSettings_BusinessHint;
    default:
        return '';
    }
};
export const getPriorityTypeNameById = (id: GoalType) => {
    switch (id) {
    case GoalType.Development:
        return lclzStor['{L_PriorTypeDev_L}'];
    case GoalType.Personal:
        return lclzStor['{L_PriorTypePersonal_L}'];
    case GoalType.Professional:
        return lclzStor['{L_PriorTypeProfessional_L}'];
    case GoalType.Business:
        return lclzStor['{L_PriorTypeBusiness_L}'];
    default:
        return '';
    }
};
export const getPriorityTypeIdByName = (name: string): GoalType => {
    switch (name) {
    case '{L_PriorTypeDev_L}':
        return GoalType.Development;
    case '{L_PriorTypePersonal_L}':
        return GoalType.Personal;
    case '{L_PriorTypeProfessional_L}':
        return GoalType.Professional;
    case '{L_PriorTypeBusiness_L}':
        return GoalType.Business;
    default:
        return null;
    }
};
export const getRedirectToGoalsList = (ownerId: number, identityId: string, currentIdentityId: string, reviewId?: string) => {
    const myTeamUrl = ownerId ? `/home/goals/my-team-goals/${ownerId}` : '/home/goals/my-team-goals';
    const myGoalsUrl = identityId === currentIdentityId ? '/home/goals/my-goals' : myTeamUrl;
    const redirectUrl = reviewId && reviewId !== '0'
        ? `/check-ins/review/${reviewId}`
        : myGoalsUrl;
    const backUrl = getHistoryState('backUrl');
    return backUrl || redirectUrl;
};
export const getCircleBarColor = (status: IndividualGoalStatus, theme: IColorTheme) => {
    return (status === IndividualGoalStatus.ClosedDone || status === IndividualGoalStatus.CompletedPendingApproval) ? theme.green : theme.teal;
};
export const getSliderLabelPosition = (val: number) => {
    switch (val) {
    case 0:
        return `calc(${val}% - 0px)`;
    case 1:
        return `calc(${val}% - 4px)`;
    case 2:
        return `calc(${val}% - 6px)`;
    case 3:
        return `calc(${val}% - 8px)`;
    case 4:
        return `calc(${val}% - 10px)`;
    case 100:
        return `calc(${val}% - 22px)`;
    default:
        return `calc(${val}% - 12px)`;
    }
};
export const getRangeSlider = (val: number) => val < 5 && (`transform: translateX(${val * -10}%)!important`);

export const getClumpedTextHeight = (rowHeight: number, rowsNumber: number, shouldViewMoreBeVisible: boolean = true) => {
    return rowHeight * rowsNumber + (shouldViewMoreBeVisible ? 20 : 0);
};

export const getGoalPeriodInfoText = (period: GoalPeriod, start: Moment, language: string, end?: string) => {
    const startDate = start.locale(language);
    const endDate = end ? moment(end).locale(language) : null;

    switch (period) {
      case GoalPeriod.Custom:
          return lclzStor.formatString(lclzStor.GoalPage_Filters_Period_Custom_Info, { startDate: startDate.format(REVIEW_FORMAT), endDate: endDate?.format(REVIEW_FORMAT) });
      case GoalPeriod.CurrentAndFuture:
          return lclzStor.formatString(lclzStor.GoalPage_Filters_Period_CurrentAndFuture_Info, { startDate: startDate.format(REVIEW_FORMAT) });
      case GoalPeriod.CurrentOnly:
          return lclzStor.formatString(lclzStor.GoalPage_Filters_Period_CurrentOnly_Info, { startDate: startDate.format(REVIEW_FORMAT), endDate: endDate?.format(REVIEW_FORMAT) });
      case GoalPeriod.LastYear:
          return lclzStor.formatString(lclzStor.GoalPage_Filters_Period_LastYear_Info, { startDate: startDate.format(REVIEW_FORMAT), endDate: endDate?.format(REVIEW_FORMAT) });
      case GoalPeriod.All:
          return lclzStor.GoalPage_Filters_Period_All_Info;
      default:
          return lclzStor.GoalPage_Filters_Period_All_Info;
    }
};

export const getFiltersCount = (filter: IGoalFilters, isDesktop: boolean) => {
    let totalCount = filter.Period !== GoalPeriod.CurrentAndFuture ? 1 : 0;
    totalCount += filter.IndividualTypes?.length || 0;
    totalCount += filter.GoalStatuses?.filter(status => status !== IndividualGoalStatus.CompletedPendingApproval)?.length || 0;
    if (!isDesktop) {
        totalCount += filter.Search ? 1 : 0;
    }

    return totalCount;
};

export const getMomentDate = (settings: Settings, format: string): moment.Moment => {
    const cyclesSettings = getSetting(settings, ESettings.Goal, EGoalSettings.GoalsCyclesSettings);
    const fullYear = new Date().getFullYear();
    const cycleStartInCurrentYear = moment(`${fullYear}-${cyclesSettings.StartingMonth}-${cyclesSettings.StartingDay}`, format);
    const dateNow = moment();
    const cycleStartYear = cycleStartInCurrentYear.isAfter(dateNow) ? fullYear - 1 : fullYear;

    return moment(`${cycleStartYear}-${cyclesSettings.StartingMonth}-${cyclesSettings.StartingDay}`, format);
};

export const getPeriodStartDate = (settings: Settings) => {
    const cyclesSettings = getSetting(settings, ESettings.Goal, EGoalSettings.GoalsCyclesSettings);

    return cyclesSettings ? getMomentDate(settings, SQL_DATE_FORMAT) : moment().startOf('year');
};

export const getPeriodStartDateForLibraryRequest = (settings: Settings): string => {
    const goalCyclesEnabled = getSetting(settings, ESettings.Goal, EGoalSettings.EnableGoalsCycles);

    return goalCyclesEnabled ? getPeriodStartDate(settings).format(DATE_WITHOUT_TIMEZONE) : '';
};

export const getRelevantPeriodStartDate = (settings: Settings, date: string) => {
    const cyclesSettings = getSetting(settings, ESettings.Goal, EGoalSettings.GoalsCyclesSettings);
    if (cyclesSettings) {
        let periodStartDate = getMomentDate(settings, SQL_DATE_FORMAT);
        const targetDate = moment(date);
        while (targetDate.isBefore(periodStartDate, 'day')) {
            periodStartDate = periodStartDate.subtract(1, 'year');
        }
        return periodStartDate.format(SQL_DATE_FORMAT);
    }
    return null;
};
export const approveSingleGoalOnList = (cb: () => void) => {
    openCoreModal({
        description: lclzStor.GoalsList_ApproveConfPopupSingle,
        secondaryBtnCallback: () => cb()
    }, IModalType.confirm);
};
export const approveMultiGoalOnList = (cb: () => void, description) => {
    openCoreModal({
        description,
        secondaryBtnCallback: () => cb()
    }, IModalType.confirm);
};
export const approveSingleGoalOnListSuccess = (cb: () => void, areGoalsMultiple: boolean = false) => {
    openCoreModal({
        icon: { iconName: FA_CHECK_CIRCLE, type: 'secondary' },
        title: areGoalsMultiple ? lclzStor.GoalsCentre_ApprovedPopupMultiple_Title : lclzStor.GoalsCentre_ApprovedPopup_Title,
        autoClose: false,
        isVertical: true,
        secondaryBtnText: lclzStor.GotIt_Btn,
        hideOverlayOnClick: false,
        secondaryBtnCallback: () => cb()
    });
};
export const confirmOrgGoal = (cb: () => void) => {
    openCoreModal({
        icon: { iconName: FA_SITEMAP, type: 'secondary' },
        title: lclzStor.CreateOrgGoal_ConfPopUp_Title,
        subtitle: lclzStor.CreateOrgGoal_ConfPopUp_Description,
        primaryBtnText: lclzStor.CreateOrgGoal_ConfPopUp_Cancel,
        primaryBtnCallback: () => closeCoreModal(),
        secondaryBtnText: lclzStor.CreateOrgGoal_ConfPopUp_Yes,
        secondaryBtnCallback: cb
    });
};