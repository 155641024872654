export enum Pages {
    home = 'home',
    login = 'login',
    welcome = 'welcome',
    priorities = 'priorities',
    company = 'company',
    profile = 'profile',
    administration = 'administration',
    resetPassword = 'reset-password',
    manageQuestionBank = 'administration/manage-question-bank',
    createSurvey = 'administration/manage-surveys/survey/0/create-survey',
    feedbackReceived = 'home/feedback-centre/received',
    feedbackAboutMyTeam = 'home/feedback-centre/about-my-team-requests'
}