import lclzStor from '../../shared/src/languages/BaseLang';
import { PopUp } from './PopUp';
import React from 'react';

interface IProps {
    submit: () => void;
    totalRespondents: number;
    closeConfirmPopup: () => void;
}
export const RequestErrorPopup = (props: IProps) => {
    const subTitle = lclzStor.formatString(lclzStor.RestrictRespondents_RequestFBModal_Description, {quantity: props.totalRespondents});
    return(
        <PopUp
            title={lclzStor.RestrictRespondents_FBModal_Title}
            subTitle={subTitle}
            primaryBtnText={lclzStor.RestrictRespondents_FBModal_Send}
            primaryCB={props.submit}
            secondaryBtnText={lclzStor.RestrictRespondents_FBModal_Cancel}
            secondaryCB={props.closeConfirmPopup}
        />
    ) ;
};
