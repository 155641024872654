import IRoleModel from '../../interfaces/users/IRoleModel';
import { Role } from '../../enums/Role';
import { IFrontAllowances } from '../../interfaces/permission/IFrontAllowances';
import { FrontAllowance } from '../../enums/permission/FrontAllowance';
import { IName } from '../../interfaces/IName';
import { Settings } from '../settings/Settings';
import { UserActions } from '../../enums/permission/UserActions';

export class UserProfile {
    img = 'empty.png';
    fullName: string;
    jobTitle: string;
    email: string;
    orgItemId: number;
    id: number;
    reportsTo: UserProfile;
    roles: IRoleModel[];
    languageShortName: string;
    receiveEmailNotifications: boolean;
    FrontAllowances: IFrontAllowances[];
    AvailableActions: UserActions[];
    Name: IName;
    IsFirstTimeLogin: boolean;
    IdentityId: string;
    constructor(props: any = {}) {
        this.img = props.Img;
        this.fullName = props.FullName;
        this.jobTitle = props.JobTitle;
        this.id = props.Id;
        this.email = props.Email;
        this.orgItemId = props.OrganizationItemId;
        this.reportsTo = props.ReportsTo ? new UserProfile(props.ReportsTo) : null;
        this.roles = props.Roles;
        this.languageShortName = props.Language;
        this.receiveEmailNotifications = props.ReceiveEmailNotifications;
        this.FrontAllowances = props.FrontAllowances;
        this.Name = props.Name;
        this.IsFirstTimeLogin = props.IsFirstTimeLogin;
        this.IdentityId = props.IdentityId;
        this.AvailableActions = props.AvailableActions || [];
    }

    get userName() {
        return this.fullName?.split(' ')[0];
    }

    setImg(img: string) {
        this.img = img;
    }

    isInRole(id: number) {
        if (this.roles?.length) {
            return this.roles.some(role => role.Id === id);
        }
        return false;
    }

    isManager() {
        return this.isInRole(Role.ManagerSelfServe);
    }

    isHr() {
        return this.isInRole(Role.HumanResources);
    }

    isAdmin() {
        return this.isInRole(Role.Administrator);
    }

    isCompliance() {
        return this.isInRole(Role.Compliance);
    }

    canSendToAllcompany(settings: Settings) {
        return this.isAdmin() || this.isHr() || settings.FeedbackSettings.AnyOneCanSendToAllCompany;
    }

    canDo(action: FrontAllowance) {
        const frontAllowances: IFrontAllowances[] = this.FrontAllowances ?? [];

        return frontAllowances.some((item: IFrontAllowances) => Number(FrontAllowance[item.FrontArea]) === action && item.Allowed);
    }

    hasPermission(action: UserActions) {
        return this.AvailableActions.some(item => item === action);
    }
}
