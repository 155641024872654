import * as React from 'react';
import { uniqBy } from 'lodash';
import lclzStor from '../../languages/BaseLang';
import {
    FA_COFFEE, ICON_CONGRATS, IMAGE_XL, IMAGE_LARGE, FA_SAD_TEAR
} from '../../constants';
import {
    redirectedFromLogin,
    navigate,
    isDesktopView,
    replaceName,
    getGivenName, htmlToText
} from '../common';
import { hashHistory } from '../history';
import ILinkedItems from '../../models/interfaces/search/ILinkedItems';
import ISearchUser from '../../models/interfaces/search/ISearchUser';
import ISearchOrgItem from '../../models/interfaces/search/ISearchOrgItem';
import IGiveFeedback from '../../models/interfaces/feedbacks/IGiveFeedback';
import { IName } from '../../models/interfaces/IName';
import { Pages } from '../../models/enums/layout/Pages';
import { UserProfile } from '../../models/entities/user/UserProfile';
import { IShareWithInfo } from '../../models/interfaces/feedbacks/IShareWithInfo';
import { ILibraryWithBehaviours } from '../../models/interfaces/behaviours/library/ILibraryWithBehaviours';
import { IFeedbackShortView } from '../../models/interfaces/feedbacks/IFeedbackShortView';
import { IUser } from '../../models/interfaces/IUser';
import { Settings } from '../../models/entities/settings/Settings';
import { closeCoreModal, openCoreModal } from '../modalsReduxUtils';

export const feedbackSavedPopup = (navigateSuccess: boolean = false) => {
    openCoreModal({
        icon: {
            iconName: ICON_CONGRATS,
            type: 'secondary'
        },
        title: lclzStor.GiveFeedback_fbckSent,
        isVertical: true,
        secondaryBtnText: lclzStor.GotIt_Btn,
        secondaryBtnCallback: () => closeCoreModal()
    });
    if (navigateSuccess) {
        navigate(Pages.home);
    }
};

export const confirmErrorPopup = (successCb: () => void, subtitle: string) => {
    openCoreModal({
        subtitle,
        title: lclzStor.RestrictRespondents_FBModal_Title,
        primaryBtnText: lclzStor.RestrictRespondents_FBModal_Cancel,
        primaryBtnCallback: () => closeCoreModal(),
        primaryBtnType: 'simple',
        secondaryBtnText: lclzStor.RestrictRespondents_FBModal_Send,
        secondaryBtnCallback: successCb,
        hideOverlayOnClick: false
    });
};

export const showSharedListModal = (title: string, userList: JSX.Element[]) => {
    openCoreModal({
        title,
        body: userList,
        classes: 'modal-shared-users',
        showCloseIcon: true
    });
};

export const letsMeetPopup = (name: IName) => {
    openCoreModal({
        description: replaceName('GiveFeedback_LetsMeetSentRequest', name),
        icon: {
            iconName: FA_COFFEE,
            type: 'teal'
        },
        isVertical: true,
        autoClose: true
    });
};

export const letsMeetOrCommentPopup = (saveCb: (feedback: IGiveFeedback, cb: () => any) => void, feedback: IGiveFeedback, body: React.ReactNode, name: IName) => {
    const saveOnlyComment = () => {
        saveCb({ ...feedback, IsLetsMeetRequests: false }, () => feedbackSavedPopup());
    };
    const saveOnlyLetsMeet = () => {
        saveCb({
            ...feedback, Message: '', MessagePositive: '', MessageNegative: '', BehaviourList: [], FeedbackRequestBehavioursRepliesList: []
        }, () => letsMeetPopup(name));
    };

    openCoreModal({
        body,
        description: lclzStor.GiveFeedback_LetsMeetOrCommentTitle,
        primaryBtnText: lclzStor.GiveFeedback_CommentOnly,
        primaryBtnCallback: saveOnlyComment,
        primaryBtnType: 'primary',
        secondaryBtnText: lclzStor.GiveFeedback_LetsMeetOnly,
        secondaryBtnCallback: saveOnlyLetsMeet,
        hideOverlayOnClick: false
    });
};

export const redirectAfterSave = (onBack?: () => void) => {
    onBack
        ? onBack()
        : redirectedFromLogin()
            ? navigate(Pages.home)
            : hashHistory.goBack();
};

export const getImageSize = () => (isDesktopView() ? IMAGE_XL : IMAGE_LARGE);

export const calculateTotalUsers = (linkedList: ILinkedItems, userProfile: UserProfile, includeCurrentUser: boolean, aboutUser?: UserProfile) => {
    let totalRespondents = 0;
    if (linkedList.Users) {
        linkedList.Users.forEach(item => {
            const alreadyLinked = checkLinkedUser(linkedList, item);
            const alreadyLinkedDirect = linkedList.DirectReports && linkedList.DirectReports.some(directReport => item.Id === directReport.Id);
            if (!alreadyLinked && !alreadyLinkedDirect) {
                totalRespondents += 1;
            }
        });
    }
    if (linkedList.DirectReports) {
        linkedList.DirectReports.forEach(item => {
            const alreadyLinked = checkLinkedUser(linkedList, item);
            if (!alreadyLinked && (!aboutUser || (item.IdentityId !== aboutUser?.IdentityId))) {
                totalRespondents += 1;
            }
        });
    }
    if (linkedList.OrgItems) {
        linkedList.OrgItems.forEach(item => {
            const alreadyLinked = linkedList.OrgItems.some(orgitem => orgitem.SubordinateOiIds.some(id => id === item.Id));
            if (!alreadyLinked) {
                let usersCount = orgItemWithoutCurrentUser(userProfile, item, includeCurrentUser);
                if (aboutUser) {
                    const aboutUsersCount = orgItemWithoutCurrentUser(aboutUser, item, includeCurrentUser);
                    if (aboutUsersCount < item.UsersCount) {
                        usersCount -= 1;
                    }
                }
                totalRespondents += usersCount || 0;
            }
        });
    }
    return totalRespondents;
};

export const checkLinkedUser = (linkedList: ILinkedItems, item: ISearchUser) => linkedList.OrgItems.some(orgitem => item.OrganisationItemOfUserId === orgitem.Id || orgitem.SubordinateOiIds.some(id => id === item.OrganisationItemOfUserId));

export const orgItemWithoutCurrentUser = (userProfile: UserProfile, item: ISearchOrgItem, includeCurrentUser: boolean = false) => {
    const currentUserOrgItemId = userProfile?.orgItemId;
    return ((item.Id === currentUserOrgItemId || item.SubordinateOiIds.includes(currentUserOrgItemId)) && !includeCurrentUser)
        ? item.UsersCount - 1
        : item.UsersCount;
};

export const comparedListsEqual = (prev: number[], next: ILinkedItems) => {
    if (!prev.length && !(next.Users && next.Users.length)) {
        return true;
    } if (prev.length && next.Users && next.Users.length === prev.length) {
        return !next.Users.filter(nextUser => !prev.includes(nextUser.Id)).length;
    }
    return false;
};

export const allPropertiesAreEmpty = (list: ILinkedItems) => {
    if (!list || Object.keys(list).length === 0) {
        return true;
    }
    return Object.keys(list).every(key => !list[key].length);
};
export const getUserWhoShared = (share: IShareWithInfo[], currentUserId: number) => {
    if (share?.length) {
        let whoShared = share.filter((item: IShareWithInfo) => item.ToUser.Id === currentUserId);
        if (!whoShared.length) {
            whoShared = share.filter((item: IShareWithInfo) => item.FromUser.Id === currentUserId);
        }
        if (!whoShared.length) {
            whoShared = [share[0]];
            whoShared[0].ShareWithMessage = '';
        }
        return whoShared[0];
    }
    return null;
};
export const isFeedbackSharedWithMe = (shares: IShareWithInfo[], currentUserId: number) => shares?.length
    && shares?.some(i => i.ToUser.Id === currentUserId);

export const getUniqueUsersListWhoShared = (share: IShareWithInfo[]) => uniqBy(share.map(i => i.FromUser), 'Id');

export const getUsersListNamesWhoShared = (share: ISearchUser[], excludeId: number): string => {
    const filtered = share.filter(i => i.Id !== excludeId);
    return filtered.length ? filtered.map(i => i.FullName).join(', ') : null;
};
export const getRequestDetailsLabel = (isFeedbackSent: boolean, name: IName, isPlural: boolean) => {
    const behavioursLabelSent = name && (isPlural
        ? lclzStor.formatString(lclzStor.FeedbackRequest_RequestedBehaviours_multipleSent, { Name: getGivenName(name) })
        : lclzStor.formatString(lclzStor.FeedbackRequest_RequestedBehaviours_aloneSent, { Name: getGivenName(name) }));
    const behavioursLabelReceived = isPlural
        ? lclzStor.FeedbackRequest_RequestedBehaviours_multiple
        : lclzStor.FeedbackRequest_RequestedBehaviours_alone;
    return isFeedbackSent ? behavioursLabelSent : behavioursLabelReceived;
};

export const getStructuredFeedbackPositiveLabel = (fromUser: IName, toUser: IName, fromCurrentUser: boolean, toCurrentUser: boolean, isMultiple: boolean, aboutUser?: IName) => {

    const fromUserName = getGivenName(fromUser, true);

    const fromCurrentLabelPositive = isMultiple ? lclzStor.FeedbackReplyMulti_PositiveFeedback :
        Boolean(aboutUser) ? replaceName('FeedbackReply_PositiveFeedback_AboutView', aboutUser) : replaceName('FeedbackReply_PositiveFeedback', fromUser);

    const otherUserMultiple = isMultiple &&
         lclzStor.formatString(lclzStor.Feedback_PositiveFeedbackSharedPlural, { FromUserName: fromUserName });

    const otherUserSingle = !isMultiple && Boolean(aboutUser) ?
        lclzStor.formatString(lclzStor.Feedback_PositiveFeedbackReceived_About, { FromUserName: fromUserName, ToUserName: getGivenName(aboutUser) })
        : lclzStor.formatString(lclzStor.Feedback_PositiveFeedbackShared, { FromUserName: fromUserName, ToUserName: getGivenName(toUser) });

    const otherPositiveLabel = otherUserMultiple || otherUserSingle;

    const toCurrentUserLabel = aboutUser ?
        lclzStor.formatString(lclzStor.Feedback_PositiveFeedbackReceived_About, { FromUserName: fromUserName, ToUserName: getGivenName(aboutUser) })
        : replaceName('Feedback_PositiveFeedback', fromUser);

    return fromCurrentUser ? fromCurrentLabelPositive : toCurrentUser ? toCurrentUserLabel : otherPositiveLabel;
};

export const getStructuredFeedbackNegativeLabel = (fromUser: IName, toUser: IName, toCurrentUser: boolean, isMultiple: boolean, aboutUser?: IName) => {
    const fromCurrentLabelNegative = isMultiple ? lclzStor.FeedbackReplyMulti_NegativeFeedback : replaceName('FeedbackReply_NegativeFeedback', toUser);
    const toCurrentLabel = lclzStor.Feedback_NegativeFeedback;
    return Boolean(aboutUser) ? replaceName('FeedbackReply_NegativeFeedback_AboutView', aboutUser) :
        toCurrentUser ? toCurrentLabel : fromCurrentLabelNegative;
};

export const getMainLabel = (aboutUser: boolean, fromCurrentUser: boolean, name: IName) => aboutUser ?
    fromCurrentUser ? lclzStor.FeedbackReply_ContextFeedback_About : replaceName('Feedback_ContextFeedback_About', name)
    : fromCurrentUser ? lclzStor.FeedbackReply_ContextFeedback : replaceName('Feedback_ContextFeedback', name);

export const getLinkedPrioritiesLabel = (isPlural: boolean) => (isPlural
    ? lclzStor.FBCentre_RequestLinkedToThisGoalPlural : lclzStor.FBCentre_RequestLinkedToThisGoal);

export const getJoinedBehavioursNames = (behaviourLibraries: ILibraryWithBehaviours[], strengthTagsSelected: number[]): string => {
    let behavioursArray = [];

    behaviourLibraries.forEach((library: ILibraryWithBehaviours) => {
        behavioursArray = behavioursArray.concat(library.Behaviours
            .filter(item => strengthTagsSelected.includes(item.Id))
            .map(item => htmlToText(item.Name)));
    });

    return behavioursArray.join(', ');
};
export const getFeedbackInfo = (feedbackItem: IFeedbackShortView[]) => feedbackItem.map(item => {
    item.FullName = item.FromUser.FullName;
    item.Img = item.FromUser.Img;
    return item;
});
export const isUserLaunched = (profile: IUser, settings: Settings) => profile.IsLaunched || profile.IsLoggedIn
    || !settings.UserInvitationSettings?.IsEnabledAdminApproval
    || !settings.UserInvitationSettings?.IsEnabledUserInvitation;

export const showFeedbackWidgetMultiple = (isSettingOn: boolean) => isSettingOn && (Boolean(lclzStor.Feedback_Golden_Rules) || Boolean(lclzStor.FeedbackWidget_KeyWords));

export const showFeedbackWidgetSingle = (isSettingOn: boolean, dataLoaded: boolean, isRequest: boolean) => showFeedbackWidgetMultiple(isSettingOn)
    && dataLoaded && !isRequest;

export const replyAboutConfirmation = (cb: () => void, name: string) => {
    const subtitle = lclzStor.formatString(lclzStor.ReplyRequestAbout_Description, { Name: name });
    openCoreModal({
        subtitle,
        title: lclzStor.ReplyRequestAbout_Heading,
        secondaryBtnText: lclzStor.ReplyRequestAbout_Send,
        secondaryBtnCallback: () => cb(),
        primaryBtnText: lclzStor.ReplyRequestAbout_GoBack,
        primaryBtnCallback: () => closeCoreModal()
    });
};
export const showNotFoundModal = () => {
    openCoreModal({
        icon: {
            iconName: FA_SAD_TEAR,
            size: '5x',
            type: 'secondary'
        },
        title: lclzStor.Page404_title,
        secondaryBtnText: lclzStor.Page404_backHome_btn,
        secondaryBtnCallback: () => {
            closeCoreModal();
            hashHistory.push('/home');
        },
        hideOverlayOnClick: false,
        isVertical: true
    });
};
