import { Button, Text, Flex, Form, Input, FormField, FormLabel } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeCompany, loginBySaml, clearErrors, regularLogin } from "../../../actions/auth";
import IStore from "../../../common/interfaces/IStore";
import lclzStor from "../../../shared/src/languages/BaseLang";
import { ICompanyData } from "../../../shared/src/models/interfaces/ICompanyData";
import { Copyright } from "./Copyright";
import { ShowError } from "./ShowError";
import { loadItem } from '../../../shared/src/modules/sessionStorage';
import { SESSION_STORAGE_KEYS } from "../../../common/constants";
import { LogoPanel } from '../../elements/LogoPanel';

const Login = ({showCompany}) => {
  const dispatch: any = useDispatch();
  const { companyData, loginError } = useSelector((state: IStore) => state.auth);
  const handleLoginBySaml = (company: ICompanyData) => dispatch(loginBySaml(company, false, () => {}));
  const handleChangeCompany = () => dispatch(changeCompany());
  const handleLoginByPasswords = (companyName: string, username: string, password: string) => dispatch(regularLogin(companyName, username, password));
  const handleClearErrors = () => dispatch(clearErrors());
  const [loginWithUsername, setLoginWithUsername] = useState<boolean>(!companyData?.CognitoUserPoolId || false);
  const [userCredentials, setUserCredentials] = useState<{ username: string; password: string }>({ username: '', password: '' });

  useEffect(() => {
    const stopAutoSSO = loadItem(SESSION_STORAGE_KEYS.STOP_AUTO_SSO);
    const isSSOEnabled = companyData?.CognitoPoolDomainName && companyData?.CognitoIdpIdentifier;
    if (isSSOEnabled && !stopAutoSSO) {
        loginSaml();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = () => handleLoginByPasswords(companyData?.CompanyName, userCredentials.username, userCredentials.password);

  const loginSaml = () => {
    handleClearErrors();
    handleLoginBySaml(companyData);
  };

  const onChangeCompany = () => {
    handleClearErrors();
    handleChangeCompany();
    showCompany();
  };

  const enableLoginWithCredentials = () => {
    handleClearErrors();
    setLoginWithUsername(true);
  };

  const handleChange = (e: React.SyntheticEvent<HTMLElement>) => {
    // @ts-ignore
    const { name, value } = e.currentTarget;
    setUserCredentials(prevState => ({...prevState, [name]: value}));
  };

  const isLoginButtonEnabled = !!userCredentials.password && !!userCredentials.username;

  return (
    <Flex vAlign="center" hAlign="center" column={true}>
      <LogoPanel isSmall={true} />
      <Flex className="spacing-bottom-xlg" vAlign="center" hAlign="center" column={true}>
          <Text size="small" content={lclzStor.LoginForm_signInCompanyText} />
          <Text size="medium" weight="semibold" >{companyData.CompanyName}</Text>
      </Flex>
      <Flex className="spacing-bottom-xlg" vAlign="center" hAlign="center" column={true}>
        <Text size="large" weight="bold" className="spacing-bottom-xlg" content={lclzStor.LoginForm_GreatSee} />
        <ShowError error={loginError} />
        {loginWithUsername && (
          <Form className="spacing-bottom-xlg">
            <FormField className="auth-field">
              <FormLabel htmlFor="username" id="username-label">
                {lclzStor.LoginForm_userName}
              </FormLabel>
              <Input
                name="username"
                fluid={true}
                aria-labelledby="username-label"
                id="username"
                value={userCredentials.username}
                placeholder={lclzStor.LoginForm_userName}
                onChange={handleChange}
              />
            </FormField>
            <FormField className="auth-field">
              <FormLabel htmlFor="password" id="password-label">
                {lclzStor.LoginForm_password}
              </FormLabel>
              <Input
                type="password"
                name="password"
                fluid={true}
                aria-labelledby="password-label"
                id="password"
                value={userCredentials.password}
                placeholder={lclzStor.LoginForm_password}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <Button fluid={true} primary={true} onClick={handleLogin} disabled={!isLoginButtonEnabled}>{lclzStor.logIn}</Button>
            </FormField>
          </Form>
        )}
        {!loginWithUsername &&
          <Flex hAlign="center" className="spacing-bottom-xlg">
            {companyData?.CognitoUserPoolId &&
              <Button
                primary={true}
                className="ms-welcome__action sign_in_button"
                onClick={loginSaml}
              >
                {lclzStor.LoginForm_logWithAzureAd}
              </Button>
            }
          </Flex>
        }
        <div className="pb-auth_links">
          {loginWithUsername && companyData?.CognitoUserPoolId &&
            <div className="loginButtonDiv forgotPasswordLink">
              <Button primary={true} text={true} onClick={loginSaml}>
                {lclzStor.LoginForm_logWithAzureAd}
              </Button>
            </div>
          }
          {!loginWithUsername &&
            <div
              className="loginButtonDiv forgotPasswordLink"
            >
              <Button primary={true} text={true} onClick={enableLoginWithCredentials}>
                {lclzStor.LoginForm_logWithTandem}
              </Button>
            </div>
          }
          <div className="loginButtonDiv forgotPasswordLink">
            <Button primary={true} text={true} onClick={onChangeCompany}>
              {lclzStor.ChangeCompany}
            </Button>
          </div>
        </div>
      </Flex>
      <Copyright />
    </Flex>
  );
};

export default Login;
