import { groupLibrariesByOwners } from '../utils/behaviours';
import { ILibraryWithBehaviours } from '../shared/src/models/interfaces/behaviours/library/ILibraryWithBehaviours';
import { ILinkedItemsInfo } from '../shared/src/models/interfaces/behaviours/library/ILinkedItemsInfo';
import { Text } from '@fluentui/react-northstar';
import { htmlToText } from '../shared/src/utils/common';
import React from 'react';
import { BehTag } from './BehTag';

interface IProps {
    behaviourLibraries: ILibraryWithBehaviours[];
    linkedLibrariesInfo: ILinkedItemsInfo;
    onBehSelect: (id: number) => void;
    selectedBehs: number[];
}

export const BehLibraries = (props: IProps) => {
const librariesByOwners  = groupLibrariesByOwners(props.behaviourLibraries, props.linkedLibrariesInfo);
const onBehSelect = (id: number) => () => {
    props.onBehSelect(id);
};
    return(
        <>
            {Object.keys(librariesByOwners).map(key => (
                <div key={`${key}-${librariesByOwners[key][0].ownersKey} `}>
                    <Text size="medium" className="my-2 px-1">{librariesByOwners[key][0].ownersText}</Text>
                    {librariesByOwners[key].map(library => (
                        <div className="my-2" key={`lb-${library.Id}-${librariesByOwners[key][0].ownersKey}`}>
                            <Text
                                size="small"
                                content={htmlToText(library.Name)}
                                styles={{ color: 'GrayText' }}
                                className="px-1"
                            />
                            {library.Behaviours.map(beh => (
                                <BehTag
                                    beh={beh}
                                    onSelect={onBehSelect(beh.Id)}
                                    selectedBehs={props.selectedBehs}
                                    key={`beh-${beh.Id}-${librariesByOwners[key][0].ownersKey}`}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};
