import { Flex,Text  } from '@fluentui/react-northstar';
import { FA_USERS } from '../../shared/src/constants';
interface IProps {
    name: string;
    info: string;
    className?: string;
}
export const OIInfo = (props: IProps) => {
    return (
        <Flex gap="gap.small" className={`${props.className || ''} oi-info`} vAlign="center">
            <Flex className="oi-icon-wrapper" vAlign="center" hAlign="center">
                <i className={`${FA_USERS} fa-solid`} />
            </Flex>
            <div>
                <Text size="medium" content={props.name} as="div" />
                {Boolean(props.info) && <Text size="small" content={props.info} styles={{ color: 'GrayText' }} />}
            </div>
        </Flex>
    );
};