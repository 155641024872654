import { ILibrariesWithBehaviours } from '../shared/src/models/interfaces/behaviours/library/ILibrariesWithBehaviours';
import apiGateway from '../shared/src/modules/api/apiGateway';
import { IBehaviourLibrariesForMultipleUsers } from '../shared/src/models/interfaces/behaviours/library/IBehaviourLibrariesForMultipleUsers';

export const getBehaviourLibrariesForCompany = (): Promise<ILibrariesWithBehaviours> => apiGateway.getAsync('behaviours/for-company');

export const getBehaviourLibrariesForUser = (userId: string | number, loadedLibraryIds: string = ''): Promise<ILibrariesWithBehaviours> => {
    const libsToExclude = loadedLibraryIds ? `?libsToExclude=${loadedLibraryIds}` : '';
    return apiGateway.getAsync(`behaviours/for-user/${userId}${libsToExclude}`);
};

export const getBehaviourLibrariesForOrgItem = (orgItemId: string | number, loadedLibraryIds: string = ''): Promise<ILibrariesWithBehaviours> => {
    const libsToExclude = loadedLibraryIds ? `?libsToExclude=${loadedLibraryIds}` : '';
    return apiGateway.getAsync(`behaviours/for-orgitem/${orgItemId}${libsToExclude}`);
};
export const getBehaviourLibrariesForDR = (loadedLibraryIds: string = ''): Promise<IBehaviourLibrariesForMultipleUsers> => {
    const libsToExclude = loadedLibraryIds ? `?libsToExclude=${loadedLibraryIds}` : '';
    return apiGateway.getAsync(`behaviours/for-direct-reports${libsToExclude}`);
};
