import { createStore, applyMiddleware, Store } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import IStore from './common/interfaces/IStore';

const configureStore = (preloadedState): Store<IStore> => {
  const middlewares = [thunk];

  if (process.env.NODE_ENV !== `production`) {
    const logger = createLogger();
    middlewares.push(logger);
  }
  return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(...middlewares)
  );
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;