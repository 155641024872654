export enum ListItemType {
  Person = 1 << 0,                    //1
  AllPriority = 1 << 1,               //2
  Feedback = 1 << 2,                  //4
  Behaviour = 1 << 3,                 //8
  OrganisationItem = 1 << 4,          //16
  MyPriority = 1 << 5,                //32
  MySubordinates = 1 << 6,            //64
  MyDirectReports = 1 << 7,           //128
  AllUsers = 1 << 8,                  //256
  Request = 1 << 9,                   //512
  OrganisationItemAdmin = 1 << 10,    //1024
  Country = 1 << 11,                  //2048
  PersonGuid = 1 << 12,               //4096
  Managers = 1 << 13,                 //8192
  AlignedGoals = 1 << 14,             //16384
  GeneralSearch = Person | AllPriority | Feedback | Behaviour | OrganisationItem | Request
}
