import * as React from 'react';
import { DEFAULT_IMAGE } from '../../../constants';
import { getImage, getImageAnonymous } from '../../../services/image/imageService';
import { getTestSelector } from '../../../utils/common';
import { isBase64 } from '../../../utils/helpers';

interface IProps {
    imgId: string;
    imgIdn?: string;
    imgClass?: string;
    imgTitle?: string;
    imgAlt?: string;
    onClick?: () => void;
    testSelector?: string;
    isCompanyImage?: boolean;
    isTagImage?: boolean;
    isAnonymousImage?: boolean;
}

interface IState {
    imgUrl: string;
}

export default class Image extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { imgUrl: DEFAULT_IMAGE };
    }

    componentDidMount() {
        this.checkImgType(this.props.imgId);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.imgId !== this.props.imgId) {
            this.checkImgType(this.props.imgId);
        }
    }

    render() {
        return this.state.imgUrl ?
            (
              <img
                src={this.state.imgUrl}
                className={this.props.imgClass || ''}
                id={this.props.imgIdn || ''}
                alt={this.props.imgAlt || this.props.imgTitle || 'image'}
                title={this.props.imgTitle}
                onClick={this.handleClick}
                {...getTestSelector(this.props.testSelector)}
              />
            ) : null;
    }

    private checkImgType = (imgId: string) => {
        !imgId || imgId === 'null' ?
          this.setState({ imgUrl: DEFAULT_IMAGE }) :
            isBase64(imgId) ? this.setState({ imgUrl: imgId }) : this.getImageUrl(imgId);
    };

    private getImageUrl(imgId: string) {
        const { isCompanyImage, isTagImage, isAnonymousImage } = this.props;
        const imageRequest = isAnonymousImage ? getImageAnonymous(imgId) : getImage(imgId, isCompanyImage, isTagImage);
        imageRequest
            .then((imgUrl: string) => this.setState({ imgUrl }))
            .catch(error => console.log(error));
    }

    private handleClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
}
