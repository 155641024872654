import { Alert } from '@fluentui/react-northstar';
import React from 'react';
import lclzStor from '../../shared/src/languages/BaseLang';
import { textToHtml } from '../../shared/src/utils/common';
import { useSelector } from 'react-redux';
import IStore from '../../common/interfaces/IStore';
interface IProps {
    totalRespondents: number
}
export const ErrorGiveFb = (props: IProps) => {
    const { userInfo } = useSelector((state: IStore) => state.user);
    const { settings } = useSelector((state: IStore) => state.settings);
    const { EnableLimitOfSendersNumber, EnableLimitOfSendersNumberToGivingFeedback, AllowHRAdminExceedsLimitOfSendersNumber,
        LimitOfSendersNumber } = settings.FeedbackSettings;
    const respLabel = props.totalRespondents > 1 ?
        lclzStor.RestrictRespondents_GiveFB_Total.replace('{total}', props.totalRespondents) :
        lclzStor.RestrictRespondents_GiveFB_TotalSolo;
    const restrictionIsEnabled = EnableLimitOfSendersNumber && EnableLimitOfSendersNumberToGivingFeedback;
    const maxRespondents = restrictionIsEnabled && LimitOfSendersNumber;
    const allowExceedsLimit = AllowHRAdminExceedsLimitOfSendersNumber && (userInfo.isAdmin() || userInfo.isHr());
    const isExceeded = props.totalRespondents > maxRespondents;
    const getErrorMsg = () => {
        const label = isExceeded ?
            lclzStor.RestrictRespondents_GiveFB_Notify.replace('{max}', maxRespondents).replace('{diff}', props.totalRespondents - maxRespondents) : '';
        const showLabel = restrictionIsEnabled && isExceeded && !allowExceedsLimit;
        return showLabel ? <Alert danger fitted content={textToHtml(label)} /> : null;
    };
    return (
        <>
            {props.totalRespondents ? ( <Alert warning fitted content={respLabel} /> ) : null}
            {getErrorMsg()}
        </>
    );
};