import { AxiosPromise } from 'axios';
import loginApi from './api/loginApi';
import * as qs from 'qs';
import * as sessionStorage from './sessionStorage';
import { isMobileApp } from './screenSettings';
import { IIdentityResponse } from '../models/interfaces/identity/IIdentityResponse';
import {
    IExternalTokenParams,
    ILoginParams,
    IRefreshParams,
    IRevokeParams,
    ITokenParams
} from '../models/interfaces/identity/identityParams';
import { ICompanyData } from '../models/interfaces/ICompanyData';
import { IdentityGrandTypes } from '../models/enums/login/IdentityGrandTypes';
import { getAsync, getBackendUrl, postAsync } from './http';
import { IFaviconSettingsModel } from '../models/interfaces/settings/companyLogo/IFaviconSettingsModel';
import { client_id_env } from '../utils/common';

export const getCompanyDataAsync = (name: string): Promise<ICompanyData> => {
    return loginApi.getAsync(`companies/${encodeURIComponent(name)}/${client_id_env}`);
};

export const getCompanyFaviconSettings = (name: string): Promise<IFaviconSettingsModel> => {
    const backendUrl = getBackendUrl();
    const config = { headers: {
            Company: encodeURIComponent(name) || ''
        }
    };
    return getAsync(`${backendUrl}settings/company-logo/favicon`, config);
};

export const unsubscribeExternal = (companyName: string, secret: string): Promise<void> => {
    const backendUrl = getBackendUrl();
    const config = { headers: {
            Company: encodeURIComponent(companyName) || '',
            v: process.env.REACT_APP_TANDEM_VERSION,
            client_id: client_id_env,
            'Content-Type': 'application/json'
        }
    };
    return postAsync(`${backendUrl}external-users/optout`, `"${secret}"`, config);
};

export const loginByPassword = (companyName: string, userName: string, password: string) => {
    const data: ILoginParams = {
        password,
        grant_type: IdentityGrandTypes.password,
        username: userName,
        company: companyName,
        scope: process.env.REACT_APP_SCOPE,
        client_id: client_id_env
    };
    const params = qs.stringify(data);

    return loginApi.postAsync('connect/token', params);
};

export const loginByToken = (token: string) => {
    const data: ITokenParams = {
        token,
        grant_type: IdentityGrandTypes.cognito,
        scope: process.env.REACT_APP_SCOPE,
        client_id: client_id_env
    };
    const params = qs.stringify(data);

    return loginApi.postAsync('connect/token', params);
};

export const loginExternalBySecret = (company: string, externalUserSecret: string) => {
    const data: IExternalTokenParams = {
        company,
        externalUserSecret,
        grant_type: IdentityGrandTypes.external,
        client_id: client_id_env
    };
    const params = qs.stringify(data);

    return loginApi.postAsync('connect/token', params);
};

export const refreshTokenAsync = (
    refreshToken: string
): Promise<IIdentityResponse> => {
    const data: IRefreshParams = {
        grant_type: IdentityGrandTypes.refresh_token,
        refresh_token: refreshToken,
        client_id: client_id_env
    };
    const params = qs.stringify(data);
    if (isMobileApp()) {
        postAsync('https://4q1h0ikse5.execute-api.eu-west-1.amazonaws.com/dev/log', `refreshToken params: ${params}`);
    }

    return loginApi.postAsync('connect/token', params, false, 30000);
};

export const revokeRefreshTokenAsync = (
    refreshToken: string,
    accessToken: string
): Promise<AxiosPromise<IIdentityResponse>> => {

    const data: IRevokeParams = {
        token_type_hint: IdentityGrandTypes.refresh_token,
        token: refreshToken,
        access_token: accessToken,
        client_id: client_id_env,
        correlationId: sessionStorage.loadItem(process.env.REACT_APP_SESSION_ID)
    };
    const params = qs.stringify(data);

    if (isMobileApp()) {
        postAsync('https://4q1h0ikse5.execute-api.eu-west-1.amazonaws.com/dev/log', `revokeRefreshToken params: ${params}`);
    }

    return loginApi.postAsync('connect/revocation', params);
};

export const requestResetPasswordAsync = (
    company: string,
    username: string
) => {
    const data = {
        company,
        username
    };
    const params = qs.stringify(data);
    return loginApi.postAsync(`users/request-reset-password`, params);
};

export const resetPasswordAsync = (
    company: string,
    userid: string,
    code: string,
    newPassword: string
) => {
    const data = {
        newPassword,
        company,
        userid,
        code: decodeURIComponent(code)
    };
    const params = qs.stringify(data);
    return loginApi.postAsync('users/reset-password', params);
};

export const checkToken = (
    userid: string,
    code: string
) => {
    const data = {
        userId: userid,
        token: decodeURIComponent(code)
    };
    const params = qs.stringify(data);
    return loginApi.postAsync('users/verify-reset-token', params);
};

export const changePasswordAsync = (
    company: string,
    username: string,
    currentPassword: string,
    newPassword: string
) => {
    const data = {
        company,
        username,
        currentPassword,
        newPassword
    };
    const params = qs.stringify(data);
    return loginApi.postAsync('users/change-password', params);
};
