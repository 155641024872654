import { Checkbox, Flex } from '@fluentui/react-northstar';
import { htmlToText } from '../shared/src/utils/common';
import React from 'react';
import { IBehaviourShort } from '../shared/src/models/interfaces/behaviours/library/IBehaviourShort';
import { FA_INFO_CIRCLE } from '../shared/src/constants';
import { useTeams } from '../hooks/useTeams';
import { CustomTooltip } from './elements/CustomTooltip';

interface IProps {
    beh: IBehaviourShort;
    onSelect: () => void;
    selectedBehs: number[]
}
export const BehTag = (props: IProps) => {
    const { beh, onSelect, selectedBehs} = props;
    const { theme } = useTeams({})[0];
    const { brand } = theme.siteVariables.contextualColors;
    const icon = <i className={`${FA_INFO_CIRCLE} fa-regular py-1 pr-2`} style={{color: brand[600]}} />;

    const [checked, setChecked] = React.useState(selectedBehs.some(item => item === beh.Id));
    const onClick = () => {
        setChecked(!checked);
        onSelect();
    };

    return (
        <Flex gap="gap.medium" vAlign="center" className="pb-1" >
            <Checkbox label={htmlToText(beh.Name)} onClick={onClick} checked={checked} />
            {beh.Description &&
                <CustomTooltip content={htmlToText(beh.Description)} trigger={icon} timeout={4000} />}
        </Flex>
    );
};