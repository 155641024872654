import { Dialog } from '@fluentui/react-northstar';
import { PopUp } from './elements/PopUp';
import { FA_COMMENT_ALT_PLUS, FA_XMARK } from '../shared/src/constants';
import lclzStor from '../shared/src/languages/BaseLang';
import React from 'react';
import { hashHistory } from '../shared/src/utils/history';
import { APP_CONSTANTS } from '../common/constants';
import { RequestFeedback } from './RequestFeedback';

export const RequestFeedbackWidgetMobile = () => {
    const [isRequstSent, setIsRequestSent] = React.useState(false);
    const onCancel = () => hashHistory.push(APP_CONSTANTS.LINKS.HOME);
    const onSuccess = () => {
        setIsRequestSent(true);
    };
    const closeSuccessPopUP = () => {
        setIsRequestSent(false);
        hashHistory.push(APP_CONSTANTS.LINKS.HOME);
    };
    return (
        <>
            <Dialog
                content={
                    <PopUp
                        iconName={FA_COMMENT_ALT_PLUS}
                        title={lclzStor.GiveFeedback_fbckRequestSent}
                        primaryBtnText={lclzStor.GotIt_Btn}
                        primaryCB={closeSuccessPopUP}
                    />}
                headerAction={<i className={`${FA_XMARK} fa-regular`} onClick={closeSuccessPopUP} />}
                open={isRequstSent}
            />
            <RequestFeedback onCancel={onCancel} onSuccess={onSuccess} />
        </>
    );
};