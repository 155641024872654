import ISearchUser from "../shared/src/models/interfaces/search/ISearchUser";
import apiGateway from "../shared/src/modules/api/apiGateway";

export const getUserByGuid = (guid: string) => {
  return apiGateway.getAsync(`users/${guid}/short-model`);
};

export const getLocalisationSources = (lang: string) => {
  return apiGateway.getAsync(`localization/patchs/${lang}`);
};

export const searchDirectReports = (): Promise<ISearchUser[]> => apiGateway.getAsync('users/me/direct-reports-list', true);
