import lclzStor from '../../shared/src/languages/BaseLang';
import { Alert } from '@fluentui/react-northstar';
import { textToHtml } from '../../shared/src/utils/common';
import React from 'react';
import { useSelector } from 'react-redux';
import IStore from '../../common/interfaces/IStore';

interface IProps {
    totalRespondents: number
}

export const ErrorRequestFB = (props: IProps) => {
    const { totalRespondents } = props;
    const { userInfo } = useSelector((state: IStore) => state.user);
    const { settings } = useSelector((state: IStore) => state.settings);
    const { EnableLimitOfSendersNumber, EnableLimitOfSendersNumberToRequestingFeedback, LimitOfSendersNumber,
        AllowHRAdminExceedsLimitOfSendersNumber } = settings.FeedbackSettings;
    const isHrOrAdmin = userInfo.isAdmin() || userInfo.isHr();
    const isRestrictionEnabled = EnableLimitOfSendersNumber && EnableLimitOfSendersNumberToRequestingFeedback;
    const maxRespondents = isRestrictionEnabled && LimitOfSendersNumber;
    const allowExceedsLimit = AllowHRAdminExceedsLimitOfSendersNumber && isHrOrAdmin;
    const isExceeded = totalRespondents > maxRespondents;
    const respLabel = totalRespondents > 1 ?
        lclzStor.RestrictRespondents_RequestFB_Total.replace('{total}', totalRespondents) :
        lclzStor.RestrictRespondents_RequestFB_TotalSolo;
    const label = isExceeded && isRestrictionEnabled && !allowExceedsLimit &&
        lclzStor.RestrictRespondents_GiveFB_Notify.replace('{max}', maxRespondents).replace('{diff}', totalRespondents - maxRespondents);
    return (
        <>
            {totalRespondents ? ( <Alert warning fitted content={respLabel} /> ) : null}
            {label ? <Alert danger fitted content={textToHtml(label)} /> : null}
        </>
    );
};