export enum Role {
    EmployeeSelfServe = 1,
    ManagerSelfServe = 2,
    HumanResources = 3,
    Administrator = 4,
    ITAdministrator = 5,
    Support,
    Compliance,
    Nudges
}
