import AbstractAxiosService from './AbstractAxiosService';
import * as sessionStorage from '../sessionStorage';
import { SESSION_STORAGE_KEYS } from '../../constants';

class LoginApiService extends AbstractAxiosService {

    constructor(identityUrl: string | undefined = 'https://login.mytandem.eu/') {
        super(identityUrl, 'application/x-www-form-urlencoded', true);
    }

    getUrl() {
        return sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY)?.IdentityUrl || process.env.REACT_APP_IDENTITY_URL;
    }
}

const loginApi = new LoginApiService(sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY)?.IdentityUrl || process.env.REACT_APP_IDENTITY_URL);

export default loginApi;
