import { Flex, Text, Avatar, SizeValue } from '@fluentui/react-northstar';
import StyledAvatar from '../../shared/src/components/Elements/Styled/Avatar/StyledAvatar';
import { getInitials } from '../../common/utils/utils';
interface IProps {
    img: string;
    fullName: string;
    jobTitle?: string;
    className?: string;
    size?: SizeValue;
    textSize?: SizeValue;
    textColor?: string;
}
export const UserInfo = (props: IProps) => {
    return (
        <Flex gap="gap.small" className={`${props.className || ''} user-info ${props.size || 'medium'}`} vAlign="center">
            {props.img
                ? <StyledAvatar fullName={props.fullName} imgId={props.img} />
                : <Avatar getInitials={getInitials} name={props.fullName} size={props.size || 'medium'} />
            }
            <div>
                {props.fullName && (
                    <Text
                        size={props.textSize || 'medium'}
                        content={props.fullName}
                        as="div"
                        truncated
                        className="mw-100"
                        styles={props.textColor ? {color: props.textColor} : null}
                    />
                )}
                {props.jobTitle &&
                <Text size="small" content={props.jobTitle} styles={{ color: 'GrayText' }} />}
            </div>
        </Flex>
    );
};