import AbstractAxiosService from './AbstractAxiosService';
import { addResponseInterceptor, addRequestInterceptor } from '../interceptors';
import * as sessionStorage from '../sessionStorage';
import { SESSION_STORAGE_KEYS } from '../../constants';

class ApiGateway extends AbstractAxiosService {
    constructor(backendUrl: string | undefined = 'https://mytandem.eu/backend/api/', contentType: string | undefined = 'application/json' ) {
        super(backendUrl, contentType);

        addRequestInterceptor(this.axiosInstance);
        addResponseInterceptor(this.axiosInstance, this);
    }

    public getUrl() {
        return sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY) && sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY).BackendUrl ?
            `${sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY).BackendUrl}api/` :
            `${process.env.REACT_APP_BACKEND_URL}api/`;
    }
}
const backendUrl = sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY) && sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY).BackendUrl ?
    sessionStorage.loadItem(SESSION_STORAGE_KEYS.COMPANY).BackendUrl :
    `${process.env.REACT_APP_BACKEND_URL}api/`;

const apiGateway = new ApiGateway(backendUrl);

export const apiGatewayFile = new ApiGateway(backendUrl, 'multipart/form-data');

export default apiGateway;
