import { Dialog } from '@fluentui/react-northstar';
import { bindActionCreators } from 'redux';
import * as actionTypes from '../actions/actionTypes';
import { logout } from '../actions/auth';
import store from '../configureStore';
import lclzStor from '../shared/src/languages/BaseLang';
import { ErrorType } from '../shared/src/models/enums/errorHandler/ErrorType';

const requestErrorHandler = (error, description?: string) => {
    const errorData = error.response?.data;
    if (errorData) {
        const dispatch = store.dispatch;
        const logoutFunction = bindActionCreators(logout, dispatch);
        const showModal = prepareModal(dispatch, logoutFunction);
        switch(errorData.toString().toLowerCase())
        {
            case ErrorType.privacyconsentnecessaryacceptonly:
            case ErrorType.privacyconsentexpiredacceptonly:
            case ErrorType.privacydeclinedacceptonly:
                const {token} = store.getState().auth;
                if (token) {
                    dispatch({ type: actionTypes.DATA_PRIVACY_MODAL, toggleDataPrivacy: true, isAcceptOnly: true });
                }
                break;
            case ErrorType.privacyconsentnecessary:
            case ErrorType.privacyconsentexpired:
            case ErrorType.privacydeclined:
                const {token: authToken} = store.getState().auth;
                if (authToken) {
                    dispatch({ type: actionTypes.DATA_PRIVACY_MODAL, toggleDataPrivacy: true });
                }
                break;
            case ErrorType.userdeactivated:
                showModal(lclzStor.user_deactivated_title,
                    lclzStor.user_deactivated);
                break;
            case ErrorType.usernotlaunched:
                window.location.href = "#/not-launched";
                break;
            case ErrorType.usernotfound:
                showModal(lclzStor.user_notfound_title,
                    lclzStor.user_notfound);
                break;
            case ErrorType.privacydeclinedneedlogout:
                showModal(lclzStor.DeclinedDataPrivacy_Title,
                    lclzStor.DeclinedDataPrivacy_Message);
                break;
            default:
                break;
        }
    }
};

export default requestErrorHandler;

const prepareModal = (dispatch, logoutFunction) => (textMsg: string, descriptionMsg: string = '', autoCloseAction?: any) => {
    <Dialog
      content="Parse capacitor"
      header="Connect bandwidth"
      headerAction="Generate interface"
      closeOnOutsideClick={false}
    />;
};
