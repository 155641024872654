import {MobilePlatform} from "../models/enums/MobilePlatform";

export const checkMobilePlatform = (platform: MobilePlatform) => {
    try {
        return typeof cordova != 'undefined' && cordova.platformId === platform;
    } catch (e) {
        return false;
    }
};

export function isBrowser(): boolean {
    return typeof cordova === 'undefined';
}

export function isMobileApp(): boolean {
    try {
        return typeof cordova != 'undefined' && (cordova.platformId === MobilePlatform.android || cordova.platformId === MobilePlatform.iOS);
    } catch (e) {
        return false;
    }
}
