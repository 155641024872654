import {
    getBehaviourLibrariesForCompany, getBehaviourLibrariesForDR,
    getBehaviourLibrariesForOrgItem,
    getBehaviourLibrariesForUser
} from '../services/behaviours';
import lclzStor from '../shared/src/languages/BaseLang';
import { groupBy } from 'lodash';
import { ILinkedItemsInfo } from '../shared/src/models/interfaces/behaviours/library/ILinkedItemsInfo';
import { ILibraryWithBehaviours } from '../shared/src/models/interfaces/behaviours/library/ILibraryWithBehaviours';
import { SearchItems } from '../shared/src/models/enums/search/SearchItems';
import { ILibrariesWithBehaviours } from '../shared/src/models/interfaces/behaviours/library/ILibrariesWithBehaviours';
import { IBehaviourLibrariesForMultipleUsers } from '../shared/src/models/interfaces/behaviours/library/IBehaviourLibrariesForMultipleUsers';
import { getGivenName } from '../shared/src/utils/common';
import { calculateTotalUsers } from '../shared/src/utils/feedbacks/GiveFeedbackUtils';
import ILinkedItems from '../shared/src/models/interfaces/search/ILinkedItems';
import { UserProfile } from '../shared/src/models/entities/user/UserProfile';

export const SHARED_LIBRARIES_KEY = 'SHARED_LIBRARIES';

export const loadSharedBehaviourLibraries = async (): Promise<ILibraryWithBehaviours[]> => {
    const result = await getBehaviourLibrariesForCompany();
    if (result.LibrariesInfo.length) {
        const { LibrariesInfo } = result;
        LibrariesInfo.forEach((library: ILibraryWithBehaviours) => {
            library.isShared = true;
            library.ownersKey = SHARED_LIBRARIES_KEY;
            library.ownersText = lclzStor.GiveFeedback_BehaviourLibraryForAll;
        });
        return LibrariesInfo;
    }
    return [];
};
export const hasOwners = (libraryId: number, linkedLibrariesInfo: ILinkedItemsInfo): boolean => Object.keys(linkedLibrariesInfo.Users).some(userId => linkedLibrariesInfo.Users[userId].includes(libraryId))
    || Object.keys(linkedLibrariesInfo.OrgItems).some(orgItemId => linkedLibrariesInfo.OrgItems[orgItemId].includes(libraryId));

export const groupLibrariesByOwners = (behaviourLibraries: ILibraryWithBehaviours[], linkedLibrariesInfo: ILinkedItemsInfo): ILibraryWithBehaviours[] => {
   const filtered = behaviourLibraries
        .filter(library => {
            return (library.Behaviours.length && (hasOwners(library.Id, linkedLibrariesInfo))) || library.isShared;
        });

    // @ts-ignore
    return (
        Boolean(behaviourLibraries?.length)
            ? groupBy(filtered, 'ownersKey')
            : {}
    );
};
export const loadBehaviourLibraries = (
    linkedItemType: SearchItems,
    loadedLibrariesIds: string,
    linkedLibrariesInfo: ILinkedItemsInfo,
    callBack: (newLibraries: ILibraryWithBehaviours[],
    linkedLibrariesInfo: ILinkedItemsInfo, total: number) => void,
    total: number,
    itemId?: number
) => {
    let newLibraries = [];
    const addToCommonList = (libraries: ILibraryWithBehaviours[]) => {
        if (libraries.length) { // if we have new libraries - add them to the common list
            newLibraries = libraries;
        }
    };

    switch (linkedItemType) {
        case SearchItems.DirectReports:
            getBehaviourLibrariesForDR(loadedLibrariesIds).then((result: IBehaviourLibrariesForMultipleUsers) => {
                addToCommonList(result.LibrariesInfo);
                const LinkedLibrariesInfo = linkedLibrariesInfo; // mapping between users and their libraries in format: userId->[list of library ids]
                Object.keys(result.LibrariesIdsBySetOfUsers).forEach(key => {
                    const userIds = key.split(',');
                    userIds.forEach(userId => {
                        let librariesInfo = LinkedLibrariesInfo.Users[userId];
                        if (librariesInfo && librariesInfo.length) {
                            librariesInfo = librariesInfo.concat(result.LibrariesIdsBySetOfUsers[key]);
                            LinkedLibrariesInfo.Users[userId] = librariesInfo;
                        } else {
                            LinkedLibrariesInfo.Users[userId] = result.LibrariesIdsBySetOfUsers[key];
                        }
                    });
                });
                callBack(newLibraries, LinkedLibrariesInfo, total);
            });
            break;
        case SearchItems.Users:
            getBehaviourLibrariesForUser(itemId, loadedLibrariesIds).then((result: ILibrariesWithBehaviours) => {
                addToCommonList(result.LibrariesInfo);
                const LinkedLibrariesInfo = linkedLibrariesInfo;
                if (result.LibrariesIds.length) {
                    LinkedLibrariesInfo.Users[itemId] = result.LibrariesIds;
                }
                callBack(newLibraries, LinkedLibrariesInfo, total);
            });
            break;
        case SearchItems.OrgItems:
            getBehaviourLibrariesForOrgItem(itemId, loadedLibrariesIds).then((result: ILibrariesWithBehaviours) => {
                addToCommonList(result.LibrariesInfo);
                const LinkedLibrariesInfo = linkedLibrariesInfo;
                if (result.LibrariesIds.length) {
                    LinkedLibrariesInfo.OrgItems[itemId] = result.LibrariesIds; // mapping between orgItems and their libraries in format: orgItemId->[list of library ids]
                }
                callBack(newLibraries, LinkedLibrariesInfo, total);
            });
            break;
        default:
            break;
    }
};
export const getLibraryOwnersKeyText = (libraryId: number, linkedLibrariesInfo: ILinkedItemsInfo, linkedItems: ILinkedItems, totalRespondents: number, userProfile: UserProfile) => {
    const users = [];
    const orgItems = [];
    Object.keys(linkedLibrariesInfo.OrgItems)
        .forEach(orgItemId => {
            if (linkedLibrariesInfo.OrgItems[orgItemId].includes(libraryId)) {
                const orgItem = linkedItems.OrgItems.find(item => item.Id === +orgItemId);
                orgItems.push(orgItem);
            }
        });
    Object.keys(linkedLibrariesInfo.Users)
        .forEach(userId => {
            if (linkedLibrariesInfo.Users[userId].includes(libraryId)) {
                let user = linkedItems.Users.find(item => item.Id === +userId);
                users.push(user);
            }
        });
    const ownersCount = calculateTotalUsers({ Users: users, OrgItems: orgItems }, userProfile, false);
    const sortedUsers = [...users].sort((a, b) => a.Name.GivenName.localeCompare(b.Name.GivenName));
    const sortedOrgItems = [...orgItems].sort((a, b) => a.Name.localeCompare(b.Name));
    const userNames = sortedUsers.map(user => user && getGivenName(user.Name)).join(', ');
    const orgItemNames = sortedOrgItems.map(item => item.Name).join(', ');
    const ownersKey = `${sortedUsers.map(user => user.Id).join('_')}-${sortedOrgItems.map(item => item.Id).join('_')}`;

    let ownersText = lclzStor.GiveFeedback_BehaviourLibraryForAll;

    if (!orgItems.length && users.length === 1) {
        ownersText = lclzStor.GiveFeedback_BehaviourLibraryForSingle.replace('{user}', userNames);
    } else if (!orgItems.length && users.length && users.length !== totalRespondents) {
        ownersText = lclzStor.GiveFeedback_BehaviourLibraryForFew
            .replace('{users}', userNames);
    } else if (!users.length && orgItems.length) {
        ownersText = lclzStor.GiveFeedback_BehaviourLibraryForFew
            .replace('{users}', orgItemNames);
    } else if (users.length && orgItems.length && ownersCount < totalRespondents) {
        ownersText = lclzStor.GiveFeedback_BehaviourLibraryForFew
            .replace('{users}', `${userNames}, ${orgItemNames}`);
    }
    return { ownersText, ownersKey };
};

export const removeBehaviourLibraries = (linkedItemType: SearchItems, item: any, linkedLibrariesInfo: ILinkedItemsInfo) => {
    switch (linkedItemType) {
        case SearchItems.DirectReports:
            item.forEach(user => {
                delete linkedLibrariesInfo.Users[user.Id];
            });
            break;
        case SearchItems.Users:
            delete linkedLibrariesInfo.Users[item.Id];
            break;
        case SearchItems.OrgItems:
            delete linkedLibrariesInfo.OrgItems[item.Id];
            break;
        default:
            break;
    }
    return linkedLibrariesInfo;
};
const addBehaviours = (type: keyof ILinkedItemsInfo, linkedBehavioursInfo: ILinkedItemsInfo, linkedLibrariesInfo: ILinkedItemsInfo,
                       itemId: string, libraryId: number, behaviours: number[]) => {
    if (linkedLibrariesInfo[type][itemId].includes(libraryId)) {
        let linkedBehaviours = linkedBehavioursInfo[type][itemId];
        if (linkedBehaviours && linkedBehaviours.length) {
            linkedBehaviours = linkedBehaviours.concat(behaviours);
            linkedBehavioursInfo[type][itemId] = linkedBehaviours;
        } else {
            linkedBehavioursInfo[type][itemId] = behaviours;
        }
    }
};

export const GetBehavioursForLinkedItems = (strengthTagsSelected: number[], linkedLibrariesInfo: ILinkedItemsInfo, selectedLibraryBehaviours: {[libraryId: string]: number[]}) => {
    // prepare mapping between selected Users/OrgItems and selected behaviours
    const linkedBehavioursInfo = { Users: {}, OrgItems: {} };
    if (strengthTagsSelected.length) {
        Object.keys(selectedLibraryBehaviours)
            .forEach(libraryId => {
                Object.keys(linkedLibrariesInfo.Users)
                    .forEach(userId => {
                        addBehaviours('Users', linkedBehavioursInfo, linkedLibrariesInfo, userId, +libraryId, selectedLibraryBehaviours[libraryId]);
                    });
                Object.keys(linkedLibrariesInfo.OrgItems)
                    .forEach(orgItemId => {
                        addBehaviours('OrgItems', linkedBehavioursInfo, linkedLibrariesInfo, orgItemId, +libraryId, selectedLibraryBehaviours[libraryId]);
                    });
            });
    }
    return linkedBehavioursInfo;
};