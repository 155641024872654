import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'fastestsmallesttextencoderdecoder';
import { render } from 'react-dom';
import App from "./components/App";
import { Provider } from 'react-redux';
import store from './configureStore';
import * as sessionStorage from './shared/src/modules/sessionStorage';
import { SESSION_STORAGE_KEYS } from './common/constants';

import './styles/main.scss';

sessionStorage.deleteItem(SESSION_STORAGE_KEYS.STOP_AUTO_COMPANY);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
