import { Button, Text, Flex, Form, Input, FormField, FormLabel } from "@fluentui/react-northstar";
import { useEffect } from "react";
import { SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { getCompanyDetails } from "../../../actions/auth";
import lclzStor from "../../../shared/src/languages/BaseLang";
import { Copyright } from "./Copyright";
import * as microsoftTeams from "@microsoft/teams-js";
import { getCompanyNameFromEmail } from "../../../common/utils/utils";
import { loadItem } from '../../../shared/src/modules/sessionStorage';
import { SESSION_STORAGE_KEYS } from "../../../common/constants";
import { useSelector } from "react-redux";
import IStore from "../../../common/interfaces/IStore";
import { ShowError } from "./ShowError";
import { LogoPanel } from '../../elements/LogoPanel';

const Company = ({showLogin}) => {
  const dispatch: any = useDispatch();
  const getCompanyData = (companyName: string) => dispatch(getCompanyDetails(companyName, showLogin));
  const error = useSelector((state: IStore) => state.auth.error);
  const [enteredCompanyName, setEnteredCompany] = useState<string>('');


  useEffect(() => {
    const stopAutoDetection = loadItem(SESSION_STORAGE_KEYS.STOP_AUTO_COMPANY);
    microsoftTeams.getContext(context => {
      const name = getCompanyNameFromEmail(context.userPrincipalName);    
      if (!stopAutoDetection) {
        getCompany(name);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  const handleChange = (e: SyntheticEvent<HTMLElement>) => setEnteredCompany(e.target.value);

  const getCompany = (emailCompanyName = '') => {
    const companyName = emailCompanyName || enteredCompanyName;
    getCompanyData(companyName);
  };

  const handleCompanyGet = () => {
    getCompany();
  };

  return (
    <Flex className="company-form" vAlign="center" hAlign="center" column={true}>
      <LogoPanel isSmall={true} />
      <Flex className="spacing-bottom-xlg" vAlign="center" hAlign="center" column={true}>
        <Text size="large" weight="bold" className="spacing-bottom-xlg" content={lclzStor.LoginForm_Welcome} />
        <ShowError error={error.response?.status && lclzStor.LoginForm_IncorrectCompanyName} />
        <Form>
          <FormField className="auth-field">
            <FormLabel htmlFor="company-name" id="company-name-label">
              {lclzStor.LoginForm_companyName}
            </FormLabel>
            <Input
              name="company-name"
              aria-labelledby="company-name-label"
              id="company-name"
              className="fullWidth"
              value={enteredCompanyName}
              placeholder={lclzStor.LoginForm_CompanyName}
              onChange={handleChange}
            />
          </FormField>
          <FormField>
            <Button
              fluid={true}
              className="fullWidth"
              primary={true}
              onClick={handleCompanyGet}
              disabled={!enteredCompanyName}>
                {lclzStor.LoginForm_CompanySubmit}
            </Button>
          </FormField>
        </Form>
      </Flex>      
      <Copyright />
    </Flex>
  );
};

export default Company;
