// SIGNIN
export const TOGGLE_SIGNIN = 'TOGGLE_SIGNIN';

//COMPANY
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAILURE = 'COMPANY_FAILURE';
export const COMPANY_CHANGE = 'COMPANY_CHANGE';

//LOGIN
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

//TOKENS
export const CLEAR_TOKEN = 'CLEAR_TOKEN';

//USER
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';
export const USER_IMAGE = 'USER_IMAGE';
export const SENDER_NAME = 'SENDER_NAME';
export const USER_TIMEZONE_SEND_SUCCESS = 'USER_TIMEZONE_SEND_SUCCESS';
export const SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA';
export const REMOVE_ONBOARDING_DATA = 'REMOVE_ONBOARDING_DATA';

//FEEDBACK
export const FEEDBACK_ADD_SUCCESS = 'FEEDBACK_ADD_SUCCESS';
export const FEEDBACK_ADD_FAILURE = 'FEEDBACK_ADD_FAILURE';
export const FB_ADD_NOTE_SEARCH = 'FB_ADD_NOTE_SEARCH';
export const FB_CLEAR_NOTE_SEARCH = 'FB_CLEAR_NOTE_SEARCH';

//STORE
export const CLEAR_STORE = 'CLEAR_STORE';

//MODALS
export const DATA_PRIVACY_MODAL = 'DATA_PRIVACY_MODAL';
export const MESSAGE_MODAL = 'MESSAGE_MODAL';
export const LOGOUT_MODAL = 'LOGOUT_MODAL';
export const CANCEL_MODAL = 'CANCEL_MODAL';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const INVITE_MODAL = 'INVITE_MODAL';
export const ONBOARDING_MODAL = 'ONBOARDING_MODAL';

//SETTINGS
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_LOGO_SETTINGS_SUCCESS = 'GET_LOGO_SETTINGS_SUCCESS';
export const GET_LOGO_SETTINGS_FAILED = 'GET_LOGO_SETTINGS_FAILED';
export const LOCALISATION_SOURCES_LOADED_SUCCESS = 'LOCALISATION_SOURCES_LOADED_SUCCESS';

//DP
export const GET_DP = 'GET_DP';

//COUNTERS
export const LOAD_COUNTERS_SUCCESS = 'LOAD_COUNTERS_SUCCESS';
export const LOAD_COUNTERS_FAILED = 'LOAD_COUNTERS_FAILED';

//INVITATIONS
export const GET_INVITE_LIST_SUCCESS = 'GET_INVITE_LIST_SUCCESS';
export const GET_INVITE_LIST_FAILURE = 'GET_INVITE_LIST_FAILURE';
export const GET_RECIPIENT_SUCCESS = 'GET_RECIPIENT_SUCCESS';
export const SET_RECIPIENT_SUCCESS = 'SET_RECIPIENT_SUCCESS';
export const SEARCH_RECIPIENTS = 'SEARCH_RECIPIENTS';
export const CLEAR_RECIPIENTS = 'CLEAR_RECIPIENTS';
export const INVITATION_LOADING = 'INVITATION_LOADING';

// LOGGING
export const LOG_LOGGING_TIMINGS_SUCCESS = 'LOG_LOGGING_TIMINGS_SUCCESS';

// ERRORS
export const SET_BACK_ERROR_ID = 'SET_BACK_ERROR_ID';
export const DELETE_ERROR_ID = 'DELETE_ERROR_ID';
export const SET_FRONT_ERROR_ID = 'SET_FRONT_ERROR_ID';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';
