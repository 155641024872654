import axios, { AxiosInstance } from 'axios';
import uuid from 'uuid/v4';
import getFreshTokenAsync from './getFreshTokenAsync';
import * as sessionStorage from './sessionStorage';
import lclzStor from '../languages/BaseLang';
import { FA_REDO } from '../constants';
import { getCurrentCompanyName } from '../utils/companyUtils';
import { TypeOptions, WeightOptions } from '../components/Elements/Styled/Icon/StyledIcon';
import { client_id_env } from '../utils/common';
import { openCoreModal } from '../utils/modalsReduxUtils';
import { setMaintenanceMode } from '../actions/errorActions';
import AbstractAxiosService from './api/AbstractAxiosService';

export const addRequestInterceptor = (axiosInstance: AxiosInstance, isIdentityReq?: boolean) => {
    axiosInstance.interceptors.request.use(
        config => {
            return new Promise((resolve, reject) => {
                getFreshTokenAsync()
                    .then(token => {
                        config.headers.Authorization = `Bearer ${token}`;
                        config.headers['v'] = process.env.REACT_APP_TANDEM_VERSION;
                        config.headers['client_id'] = client_id_env;
                        config.headers[process.env.REACT_APP_SESSION_ID] = sessionStorage.loadItem(process.env.REACT_APP_SESSION_ID);
                        config.headers[process.env.REACT_APP_CORRELATION_ID] = uuid();
                        if (isIdentityReq) {
                            config.headers['company'] = getCurrentCompanyName();
                        }
                        return resolve(config);
                    })
                    .catch(error => {
                        return reject(error);
                    });
            });
        },
        err => Promise.reject(err)
    );
};

export const addResponseInterceptor = (axiosInstance: AxiosInstance, service: AbstractAxiosService) => {
    axiosInstance.interceptors.response.use(
        response => {
            const isUrlOfErrorRequest = response.config?.url.endsWith('/error') || response.config?.url.endsWith('/error-anonymus');

            isUrlOfErrorRequest && service?.setFrontErrorId && service.setFrontErrorId(response.config?.headers?.CorrelationId);
            if (response.status === 205) {
                const reloadPage = {
                    icon: {
                        type: 'primary' as TypeOptions,
                        iconName: FA_REDO,
                        weight: 'sold' as WeightOptions
                    },
                    title: lclzStor.Disabled_GoalsPopup_title,
                    subtitle: lclzStor.Disabled_GoalsPopup_text,
                    secondaryBtnText: lclzStor.Disabled_GoalsPopup_btn,
                    secondaryBtnCallback: () => window.location.reload(),
                    hideOverlayOnClick: false,
                    isVertical: true
                };
                openCoreModal(reloadPage);
            }
            return response;
        },
        error => {
            const originalRequest = error.config;

            if (
                error.response &&
                error.response.status === 401 &&
                !originalRequest._retry
            ) {
                return getFreshTokenAsync(error.response.status === 401)
                    .then(token => {
                        originalRequest._retry = true;
                        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                        originalRequest.headers['Authorization'] = `Bearer ${token}`;

                        return axios(originalRequest);
                    })
                    .catch(tokenerror => Promise.reject(tokenerror));
            }
            error.response?.status === 500 && service?.setBackErrorId && service.setBackErrorId(error.response?.config?.headers?.CorrelationId);
            error.response?.status === 503 && setMaintenanceMode();
            return Promise.reject(error);
        }
    );
};
