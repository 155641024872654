import { StyledTeamsIcon } from './StyledITeamsIcon';
import { Button, Flex, Text } from '@fluentui/react-northstar';
import React from 'react';

interface IProps {
    primaryBtnText?: string;
    primaryCB?: () => void;
    secondaryBtnText?: string;
    secondaryCB?: () => void;
    iconName?: string;
    title?: string;
    subTitle?: string
}

export const PopUp = (props: IProps) => {
    const {primaryBtnText, primaryCB, iconName, title, secondaryBtnText, secondaryCB, subTitle } = props;
    return (
        <>
            {iconName && (
                <Flex hAlign="center" className="w-100">
                    <StyledTeamsIcon iconName={iconName} className="popup-icon" />
                </Flex>
            )}
            {title && (
                <Flex hAlign="center" className="w-100 my-2">
                    <Text content={title} size="large" as="div" weight="bold" />
                </Flex>
            )}
            {subTitle && (
                <Flex hAlign="center" className="w-100 my-2">
                    <Text content={subTitle} size="medium" as="div" />
                </Flex>
            )}
            <Flex hAlign="center" className="w-100 popup-btns" gap="gap.medium">
                {secondaryBtnText && (
                    <Button primary={false} onClick={secondaryCB} >
                        {secondaryBtnText}
                    </Button>
                )}
                {primaryBtnText && (
                    <Button primary={true} onClick={primaryCB} >
                        {primaryBtnText}
                    </Button>
                )}
            </Flex>
        </>
    );
};