export enum ESettings {
    Feedback = 'FeedbackSettings',
    Goal = 'GoalSettings',
    Report = 'ReportSettings',
    EmailNotification = 'EmailNotificationSettings',
    Feedback360 = 'Feedback360Settings',
    Checkin = 'CheckinSettings',
    UsageReport = 'UsageReportSettings',
    SessionTimeout = 'SessionTimeoutSettings',
    CompanyLogo = 'CompanyLogoSettings',
    Notification = 'NotificationSettings',
    AnalyticsCentre = 'AnalyticsCentreSettings',
    PrivacyStatement = 'PrivacyStatementSettings',
    Behaviour = 'BehaviourSettings',
    Invitation = 'UserInvitationSettings',
    RegionalSettings = 'RegionalSettings',
    QuestionBank = 'QuestionBankSettings',
    HomePageSettings = 'HomePageSettings',
    UserProxySettings = 'UserProxySettings'
}
