export enum ErrorType {
    versionupgrade = 'versionupgrade',
    userdeactivated = 'userdeactivated',
    usernotfound = 'usernotfound',
    privacyconsentnecessary = 'privacyconsentnecessary',
    privacyconsentnecessaryacceptonly = 'privacyconsentnecessaryacceptonly',
    privacyconsentexpired = 'privacyconsentexpired',
    privacyconsentexpiredacceptonly = 'privacyconsentexpiredacceptonly',
    privacydeclined = 'privacydeclined',
    privacydeclinedacceptonly = 'privacydeclinedacceptonly',
    wrongRelatedToId = 'wrong relatedtoid',
    unauthenticated = 'unauthenticated',
    privacydeclinedneedlogout = 'privacydeclinedneedlogout',
    userdeletedfromsurvey = 'UserDeletedFromPassingSurvey',
    nominantdeletedfromsurvey = 'NominantDeletedFromSurvey',
    usernotlaunched = 'usernotlaunched',
    alreadySelfInvited = 'youalreadyselfinvited',
    alreadyInvited = 'you already invited',
    goalDeletionSettingDisabled = 'goalDeletionSettingDisabled',
    goalDeletionNotAllowed = 'goalDeletionNotAllowed',
    directReportLimitReached = 'directReportLimitReached',
    noAssessment = 'no assessment',
    alreadyDeclined = 'alreadydeclined',
    reviewtemplateisnotvalid = 'reviewtemplateisnotvalid',
    longrunningdownload = 'longrunningdownload',
    GoalsCannotBeLinked = 'goalscannotbelinked'
}
