import * as localStorage from './localStorage';
import * as sessionStorage from './sessionStorage';
import { isMobileApp } from './screenSettings';

const storage = isMobileApp() ? localStorage : sessionStorage;

export function setAccessToken(token: any) {
    if (token) {
        storage.saveItem(process.env.REACT_APP_ACCESS_TOKEN, token);
    } else {
        storage.deleteItem(process.env.REACT_APP_ACCESS_TOKEN);
    }
}

export function setRefreshToken(token: any) {
    if (token) {
        storage.saveItem(process.env.REACT_APP_REFRESH_TOKEN, token);
    } else {
        storage.deleteItem(process.env.REACT_APP_REFRESH_TOKEN);
    }
}

export function getAccessToken() {
    return storage.loadItem(process.env.REACT_APP_ACCESS_TOKEN);
}

export function getRefreshToken() {
    return storage.loadItem(process.env.REACT_APP_REFRESH_TOKEN);
}
