import { FormField } from '@fluentui/react-northstar';
import { CommentBlock } from './elements/CommentBlock';
import lclzStor from '../shared/src/languages/BaseLang';
import React from 'react';
import { useSelector } from 'react-redux';
import IStore from '../common/interfaces/IStore';
interface IProps {
    message: string;
    messagePositive: string;
    messageNegative: string;
    handleMessage: (val) => void;
    handleFbTextPositive: (val) => void;
    handleMessageNegative: (val) => void;
}

export const StructuredFBLayout = (props: IProps) => {
    const { message, messagePositive, messageNegative, handleMessage, handleFbTextPositive, handleMessageNegative} = props;
    const { settings } = useSelector((state: IStore) => state.settings);
    return (
        <>
            <FormField>
                <CommentBlock
                    title={lclzStor.GiveFeedback_ContextFeedback}
                    name="fbText"
                    message={message}
                    placeholder={lclzStor.GiveFeedback_ContextFeedbackPlaceHolder}
                    handleMessage={handleMessage}
                />
            </FormField>
            {settings.FeedbackSettings.EnableStructuredFeedback && (
                <>
                    <FormField>
                        <CommentBlock
                            title={lclzStor.GiveFeedback_PositiveFeedback}
                            name="fbPositive"
                            message={messagePositive}
                            placeholder={lclzStor.GiveFeedback_PositiveFeedbackPlaceHolder}
                            handleMessage={handleFbTextPositive}
                        />
                    </FormField>
                    <FormField>
                        <CommentBlock
                            title={lclzStor.GiveFeedback_NegativeFeedback}
                            name="negative"
                            message={messageNegative}
                            placeholder={lclzStor.GiveFeedback_NegativeFeedbackPlaceHolder}
                            handleMessage={handleMessageNegative}
                        />
                    </FormField>
                </>
            )}
        </>
    );
};
