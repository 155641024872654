import { useState } from "react";
import { useSelector } from "react-redux";
import IStore from "../../../common/interfaces/IStore";
import Company from "./Company";
import Login from "./Login";

const Auth = () => {
  const { companyData } = useSelector((state: IStore) => state.auth);
  const [ isCompanyView, setIsCompanyView ] = useState<boolean>(!companyData);

  const showCompanyForm = () => setIsCompanyView(true);
  const showLoginForm = () => setIsCompanyView(false);

  return isCompanyView ? <Company showLogin={showLoginForm} /> : <Login showCompany={showCompanyForm} />;
};

export default Auth;
