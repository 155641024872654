import lclzStor from '../shared/src/languages/BaseLang';

export const getTimeOfTheDay = () => {
    const date = new Date();
    const time = date.getHours();
    if (time >= 5 && time < 12) {
        return lclzStor.GoodMorning_TeamsLanding;
    }
    if (time >= 12 && time < 17) {
        return lclzStor.GoodAfternoon_TeamsLanding;
    }
    if (time >= 17 && time < 24) {
        return lclzStor.GoodEvening_TeamsLanding;
    }
    if (time >= 0 && time < 5) {
        return lclzStor.GoodNight_TeamsLanding;
    }
    return "";
};
export const getUrlToTandem = (domain: string, page: string): string => {
    const resolvedDomain = domain || `${process.env.FRONTEND_URL}/`;
    return `${resolvedDomain}#${page}`;
};