import apiGateway from '../../modules/api/apiGateway';
import { IImageBase64 } from '../../models/interfaces/IImageBase64';
import { getCurrentCompanyName } from '../../utils/companyUtils';
import { getAsync, getBackendUrl } from '../../modules/http';

export const getBehaviourImage = (imgId: string | number): Promise<string> => {
    const pictureVersion = new Date().getTime();
    return apiGateway.getAsync(`images/behaviours/${imgId}?v=${pictureVersion}`);
};

export const getBehaviourLibImage = (imgId: string | number): Promise<string> => {
    return apiGateway.getAsync(`images/behaviourlibrary/${imgId}`);
};

export const getImage = (imgId: string | number, isCompanyImage: boolean = false, isTagImage: boolean = false): Promise<string> => {
    const params = isCompanyImage ? `?company=${encodeURIComponent(getCurrentCompanyName())}` : '';
    const url = isTagImage ? `goaltag/${imgId}` : `${imgId}`;
    return apiGateway.getAsync(`images/${url}${params}`);
};

export const getImageAnonymous = (imgId: string | number): Promise<string> => {
    const backendUrl = getBackendUrl();
    return getAsync(`${backendUrl}images/company-logo/${encodeURIComponent(getCurrentCompanyName())}/${imgId}`);
};

export const setProfileImage = (data: IImageBase64): Promise<string> => {
    return apiGateway.postAsync('images/profile', data);
};

export const deleteProfileImage = (): Promise<void> => {
    return apiGateway.deleteAsync('images/profile');
};
