import * as actionTypes from "./actionTypes";
import { toggleSpinner } from "./spinner";
import { Dispatch } from "../common/types/reduxTypes";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "../common/constants";
import { Pages } from "../common/enums/Pages";
import { navigate } from "../common/utils/utils";
import lclzStor from "../shared/src/languages/BaseLang";
import { LoginResultStatus } from "../shared/src/models/enums/LoginResultStatus";
import { ICompanyData } from "../shared/src/models/interfaces/ICompanyData";
import * as localStorage from "../shared/src/modules/localStorage";
import * as sessionStorage from '../shared/src/modules/sessionStorage';
import { getCompanyDataAsync, loginByPassword } from "../shared/src/modules/loginManagement";
import requestErrorHandler from "../common/requestErrorHandler";
import { samlAuth } from "../services/auth";
import { setAccessToken, setRefreshToken } from "../shared/src/modules/tokens";

export const getCompanyDetails = (сompanyName: string, goToLogin: () => void) => {
    return (dispatch: Dispatch) => {
        dispatch(toggleSpinner(true));
        localStorage.saveItem(LOCAL_STORAGE_KEYS.COMPANY_NAME, сompanyName);
        getCompanyDataAsync(сompanyName)
            .then((data: ICompanyData) => {
                localStorage.saveItem(LOCAL_STORAGE_KEYS.COMPANY_DATA, data);
                dispatch(success(data));
                if (!!data.CognitoUserPoolId) {
                  dispatch(loginBySaml(data, true, goToLogin));
                } else {
                  dispatch(toggleSpinner(false));
                  goToLogin();
                }
            })
            .catch((error: any) => {
                localStorage.deleteItem(LOCAL_STORAGE_KEYS.COMPANY_DATA);
                dispatch(failure(error));
                dispatch(toggleSpinner(false));
            });
    };

    function success(data: any) { return { type: actionTypes.COMPANY_SUCCESS, data, error: {} }; }
    function failure(error: string) { return { type: actionTypes.COMPANY_FAILURE, error }; }
};

export const changeCompany = () => {
    return (dispatch: Dispatch) => {
        localStorage.deleteItem(LOCAL_STORAGE_KEYS.COMPANY_DATA);
        sessionStorage.saveItem(SESSION_STORAGE_KEYS.STOP_AUTO_COMPANY, true);
        dispatch(change());
    };

    function change() { return { type: actionTypes.COMPANY_CHANGE }; }
};

export const regularLogin = (companyName: string, userName: string, password: string) => {
    return (dispatch: Dispatch) => {
        loginByPassword(companyName, userName, password)
            .then(data => {
                setAccessToken(data.access_token);
                setRefreshToken(data.refresh_token);
                dispatch(success(data.access_token));
                navigate(Pages.home);
            })
            .catch(error => {
                dispatch(failure(validateLoginResult(error)));
            });

        function success(token: string) { return { type: actionTypes.LOGIN_SUCCESS, token }; }
        function failure(error: string) { return { type: actionTypes.LOGIN_FAILURE, error }; }
    };
};

export const loginBySaml = (companyData: ICompanyData, isAutoSSO: boolean = false, goToLogin: () => void) => {
    return (dispatch: Dispatch) => {
      dispatch(toggleSpinner(true));

      samlAuth(companyData)
        .then(data => {
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            dispatch(success(data.access_token));
            dispatch(toggleSpinner(false));
            navigate(Pages.home);
        })
        .catch(error => {
            if (error && !(error === 'CancelledByUser')) {
              dispatch(failure(lclzStor.LoginForm_ProblemWithTandemAccount));
            }
            sessionStorage.saveItem(SESSION_STORAGE_KEYS.STOP_AUTO_SSO, true);
            if (isAutoSSO) {
              goToLogin();
            }
            dispatch(toggleSpinner(false));
        });
    };

    function success(token: string) { return { type: actionTypes.LOGIN_SUCCESS, token }; }
    function failure(error: string) { return { type: actionTypes.LOGIN_FAILURE, error }; }
};

export const logout = () => {
  return (dispatch: Dispatch) => {
      try {
          //revokeRefreshTokenAsync();
          //revokeRefreshUserTokenAsync();
          dispatch(clearTokens());
      } catch (error) {
          requestErrorHandler(error);
      }
  };
};

export const clearTokens = () => {
    return (dispatch: Dispatch) => {
        sessionStorage.saveItem(SESSION_STORAGE_KEYS.STOP_AUTO_SSO, true);
        sessionStorage.deleteItem(SESSION_STORAGE_KEYS.SESSION_ID);
        sessionStorage.deleteItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        sessionStorage.deleteItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        dispatch(clear());
        dispatch(clearUser());
    };

    function clear() { return { type: actionTypes.CLEAR_TOKEN }; }
    function clearUser() { return { type: actionTypes.CLEAR_USER }; }
};

export const clearErrors = () => ({type: actionTypes.CLEAR_TOKEN});

export const validateLoginResult = (result: any) => {
  if (isPasswordExpired(result)) {
      return lclzStor.LoginForm_ProblemWithTandemAccount;
  } else {
      return result.Status === LoginResultStatus.ProblemWithTandemAccount ? lclzStor.LoginForm_ProblemWithTandemAccount : lclzStor.LoginForm_ProblemWithUserCredentials;
  }
};

const isPasswordExpired = (result: any) => {
    return result.response && result.response.data.error_description === 'password_expired';
};
