import * as actionTypes from '../actions/actionTypes';
import * as localStorage from '../shared/src/modules/localStorage';
import { IAuthStore } from '../common/interfaces/auth/IAuthStore';
import { LOCAL_STORAGE_KEYS } from '../common/constants';
import { AnyAction } from '@reduxjs/toolkit';
import { getAccessToken } from '../shared/src/modules/tokens';

export const initialState: IAuthStore = {
    newUser: true,
    companyData: localStorage.loadItem(LOCAL_STORAGE_KEYS.COMPANY_DATA),
    token: getAccessToken(),
    error: {},
    isLoading: true,
    isAuthenticated: false,
    loginError: ''
};

export function auth(state: IAuthStore = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.TOGGLE_SIGNIN:
            return { ...state, newUser: action.value };
        case actionTypes.COMPANY_SUCCESS:
            return { ...state, companyData: action.data, error: action.error };
        case actionTypes.COMPANY_FAILURE:
            return { ...state, companyData: null, error: action.error };
        case actionTypes.COMPANY_CHANGE:
            return { ...state, companyData: null };
        case actionTypes.LOGIN_SUCCESS:
            return { ...state, token: action.token, isLoading: false, isAuthenticated: true, loginError: '' };
        case actionTypes.LOGIN_FAILURE:
            return { ...state, token: null, loginError: action.error };
        case actionTypes.CLEAR_TOKEN:
            return { ...state, token: null, loginError: '' };
        case actionTypes.CLEAR_STORE:
            return {};
        default:
            return state;
    }
}
