import { AnyAction } from '@reduxjs/toolkit';
import { ISettingsStore } from '../common/interfaces/settings/ISettingsStore';
import * as actionTypes from '../actions/actionTypes';
import { Settings } from '../shared/src/models/entities/settings/Settings';

const initialState: ISettingsStore = {
    settings: {} as Settings,
    language: "",
    isLocalizationLoaded: false
};

export function settings(state: ISettingsStore = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.GET_SETTINGS:
            return { ...state,
                settings: action.settings
            };
        case actionTypes.GET_LOGO_SETTINGS_SUCCESS:
            return {
                ...state,
                companyLogoSettings: action.companyLogoSettings
            };
        case actionTypes.LOCALISATION_SOURCES_LOADED_SUCCESS:
            return {
                ...state,
                isLocalizationLoaded: true
            };
        case actionTypes.GET_LOGO_SETTINGS_FAILED:
        default:
            return state;
    }
}
