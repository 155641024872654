import apiGateway from '../shared/src/modules/api/apiGateway';
import { INotificationList } from '../shared/src/models/interfaces/notifications/INotificationList';

export const markAllNotificationsAsRead = (): Promise<void> => apiGateway.putAsync('notifications/mark-all');

export const markNotificationsAsRead = (id: number): Promise<void> => apiGateway.putAsync(`notifications/${id}/mark`);

export const getNotifications = (count: number, page?: number): Promise<INotificationList> => apiGateway.getAsync(`notifications/received?size=${count}&page=${page || 1}`);

export const deleteNotification = (notificationId: number): Promise<void> => apiGateway.deleteAsync(`notifications/${notificationId}`);

export const deleteAllNotification = (): Promise<void> => apiGateway.deleteAsync('notifications/all');
