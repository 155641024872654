export enum FrontAllowance {
    ViewMyCoachingReport,
    ViewNotesOnDirectReportsDashboard,
    AddANoteOnDirectReportDashboard,
    ViewAnalyticsCenter,
    ViewPasswordPolicy,
    ViewDataPrivacySettings,
    ViewSessionTimeOutSettings,
    AddUsers,
    ManageUserSettings,
    ViewUserSettings,
    ManageOrgUnits,
    ViewOrgUnits,
    ViewBehavioursSettings,
    ViewFeedbackSettings,
    ViewTimeZoneSettings,
    ViewCompanyLogoSettings,
    ViewSchedulerSettings,
    ViewNotificationSettings,
    ViewPerformanceProcessSettings,
    CanManageSurveys,
    CanManageCheckins,
    CanManageReports,
    CanManageGoals,
    CanAccessSettings,
    CanManageDataPrivacySettings,
    CanManageDataRetentionPolicySettings,
    CanManageOutlook,
    CanAdminInvitation,
    CanManageCompanyThemes,
    CanManageOnboardingSettings,
    CanManageHomePageSettings
}
