import * as React from 'react';
import moment from 'moment';
import 'moment/min/locales';
import { MILLISECONDS_PER_MINUTE, REVIEW_FORMAT } from '../../shared/src/constants';
import { Text } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import IStore from '../../common/interfaces/IStore';
import { getDateFormat } from '../../shared/src/utils/dateTime/dateTimeUtils';

interface IProps {
    minutesDifference: number;
    shortTime?: boolean;
}

export const Timer = (props: IProps) => {
    const { userInfo } = useSelector((state: IStore) => state.user);
    const initTimestamp = new Date().getTime();

    const setFormat = () => {
        const {  shortTime, minutesDifference } = props;
        moment.locale(userInfo.languageShortName);
        if (shortTime) {
            return moment(initTimestamp - minutesDifference * MILLISECONDS_PER_MINUTE).format(REVIEW_FORMAT);
        }
        return moment(initTimestamp - minutesDifference * MILLISECONDS_PER_MINUTE).format(getDateFormat(userInfo.languageShortName));
    };
    return (
        <Text size="small" content={setFormat()} />
    );
};
